import { Component, Inject, Injectable } from '@angular/core'
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog'
import { MatButtonModule } from '@angular/material/button'
import { FormsModule } from '@angular/forms'
import { MatInputModule } from '@angular/material/input'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatTabsModule } from '@angular/material/tabs'
import { MatIconModule } from '@angular/material/icon'
import { MatRipple } from '@angular/material/core'
import { FileService } from '../../../../services'
import { Loading } from '../../../../globals'
import { CustomSnackBar } from '../../../../components/snackbar/snackbar'
import { DomSanitizer } from '@angular/platform-browser'
import { readHTML } from '../../../../helpers'
import { HeaderBHBComponent } from '../../../../components/preview-bhb/header/header'
import { FooterBHBComponent } from '../../../../components/preview-bhb/footer/footer'

@Component({
  selector: 'app-modal-preview',
  templateUrl: './modal-preview.html',
  styleUrls: ['./modal-preview.scss'],
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatTabsModule,
    MatIconModule,
    MatRipple,
    HeaderBHBComponent,
    FooterBHBComponent,
  ],
})
export class ModalPreviewPolicyBHBComponent {
  readonly readHTML = readHTML
  policies: any

  constructor(
    public dialogRef: MatDialogRef<ModalPreviewPolicyBHBComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public fileService: FileService,
    public loading: Loading,
    public customSnackBar: CustomSnackBar,
    public sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    // console.log(this.data)
    this.setData()
  }

  setData() {
    this.policies = this.data.policies
  }

  onClose(): void {
    this.dialogRef.close()
  }
}
