<app-select
  [control]="cms_link_list_id"
  [datas]="listAddLink"
  fieldValue="id"
  placeholder="เลือก"
  (onSelectionChange)="onClickAddLink($event)"
  [showTrigger]="isTrggier ? triggerSelectName || trigger_name : ''">
  <ng-template #optionRef let-option>
    <!-- <div (click)="onClickAddLink(option.id,controlLinkName)" class="select-link"></div> -->
    {{ option.name }}
  </ng-template>
</app-select>
