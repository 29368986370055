import { Injectable } from '@angular/core'
import { CustomSnackBar } from '../../components/snackbar/snackbar'
import { Loading } from '../loading'
import { __searchAndFitler } from './_mix'

@Injectable({ providedIn: 'root' })
export class FilterTableProduct extends __searchAndFitler {
  constructor(public loading: Loading, public customSnackBar: CustomSnackBar) {
    const filter = {
      status_ids: [],
      status_stock_ids: [],
      status_product_ids: [],
      is_launch: false,
    }
    super('/product', filter)
  }

  initData() {}

  get isFilter() {
    const data = this.getData()
    return data.filter && Object.keys(data.filter).length
  }

  get isFilterWishList() {
    const data = this.getDataWishList()
    return data.filter && Object.keys(data.filter).length
  }

  getData() {
    const data: any = {
      filter: {},
    }

    const searchValue = this.search.value

    if (searchValue) {
      data.search = searchValue
    }
    if (this.filter['status_stock_ids'].length) {
      data.filter.status_stock_ids = this.filter['status_stock_ids']
    }
    if (this.filter['status_product_ids'].length) {
      data.filter.status_product_ids = this.filter['status_product_ids']
    }
    if (this.filter['is_launch']) {
      data.filter.is_launchs = [true]
    }

    return data
  }

  getDataWishList() {
    const data: any = {
      filter: {},
    }

    const searchValue = this.search.value

    if (searchValue) {
      data.search = searchValue
    }
    if (this.filter['status_ids'].length) {
      data.filter.status_ids = this.filter['status_ids']
    }

    return data
  }

  isChecked(id: any, key: string) {
    return this.filter[key].some((val: any) => val === id)
  }

  change(id: any, key: string) {
    const data = this.filter[key]
    if (data.includes(id)) {
      data.splice(data.indexOf(id), 1)
    } else {
      data.push(id)
    }
  }

  remove(id: any, key: string) {
    this.change(id, key)
  }
}
