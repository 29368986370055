import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core'
import { Router } from '@angular/router'
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  FormsModule,
  Validators,
} from '@angular/forms'
import {
  EmailReg,
  ValidateForm,
  setErrorForm,
  ValidateArrayForm,
  Moment,
  convertDateToApi,
  formatPrice,
  Pagination,
  formatPriceAutoDecimal,
} from '../../helpers'
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog'
import { CompanyProfileService, CaratService, ProductCategoryService } from '../../services'
import { CustomSnackBar } from '../../components/snackbar/snackbar'
import { ACTION_ACCESS, APP_PERMISSION, Loading, Profile } from '../../globals'
import { forkJoin } from 'rxjs'
import { MatRadioModule } from '@angular/material/radio'
import { MatRipple } from '@angular/material/core'
import { MatIconModule } from '@angular/material/icon'
import { MatTabsModule } from '@angular/material/tabs'
import { MatButtonModule } from '@angular/material/button'
import { MatInputModule } from '@angular/material/input'
import { MatFormFieldModule } from '@angular/material/form-field'
import { imports } from '../../imports'
import { InputComponent } from '../input/input'
import { TextareaComponent } from '../text-area/text-area'
import { SelectComponent } from '../select/select'
import { InputSearchComponent } from '../input-search/input-search'
import { MatPaginator } from '@angular/material/paginator'
import { InputNumberComponent } from '../input-number/input-number'
import { MenuService } from 'ngx-editor/lib/modules/menu/menu.service'
import { CMSMenuService } from '../../services/cms-menu.service'

@Component({
  selector: 'app-modal-select-category-product',
  standalone: true,
  imports: [
    ...imports,
    InputComponent,
    MatRadioModule,
    SelectComponent,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatIconModule,
    MatRipple,
    InputSearchComponent,
    InputNumberComponent,
    MatPaginator,
  ],
  templateUrl: './modal-select-category-product.html',
  styleUrls: ['./modal-select-category-product.scss'],
})
export class ModalSelectCategoryProductComponent implements OnInit {
  // @Input() form: any = new FormGroup('')
  // @Input() sectionIndex: number = 0
  // @Input() showButtonDelete: boolean = false
  // @Output() deleteSection = new EventEmitter()
  readonly APP_PERMISSION = APP_PERMISSION
  readonly ACTION_ACCESS = ACTION_ACCESS
  readonly formatPrice = formatPrice
  readonly formatPriceAutoDecimal = formatPriceAutoDecimal
  readonly pagination = new Pagination({
    sortName: 'code',
  })

  // mobile = window.matchMedia('(max-width: 1000px)')

  isEdit: boolean = false
  isView: boolean = false
  rerender = false
  pageNumber = new FormControl(1)

  formSearch = new FormGroup({
    search_name: new FormControl(),
  })
  form = new FormGroup({
    category_id: new FormControl(),
    category_name: new FormControl(),
  })

  categoryList: any[] = []

  constructor(
    public dialogRef: MatDialogRef<ModalSelectCategoryProductComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public profile: Profile,
    public router: Router,
    public dialog: MatDialog,
    public customSnackBar: CustomSnackBar,
    public loading: Loading,
    public productCategoryService: ProductCategoryService
  ) {}

  ngOnInit(): void {
    this.getCategoryList()
  }

  onSearch() {
    this.pagination.reset()
    this.getCategoryList()
  }

  getCategoryList() {
    const pagination = this.pagination.get()
    const payload = {
      search: this.formSearch.value.search_name || undefined,
      ...pagination,
    }

    this.loading.start()
    this.productCategoryService.getProductCategoryList(payload).subscribe(res => {
      if (!res.is_error) {
        this.categoryList = res.data.records
        this.pagination.setFromResponse(res.data)
      } else {
        this.customSnackBar.fail()
      }
      this.loading.stop()
    })
  }

  onClose(): void {
    this.dialogRef.close(false)
  }

  onConfirm(): void {
    this.dialogRef.close(this.form.getRawValue())
  }

  changeCategory(category_id: any, category_name: any) {
    this.form.controls['category_id'].setValue(category_id)
    this.form.controls['category_name'].setValue(category_name)
  }
}
