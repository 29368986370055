import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms'
import { imports } from '../../../imports'
import { InputComponent } from '../../../components/input/input'
import { TextareaComponent } from '../../../components/text-area/text-area'
import {
  EmailReg,
  ValidateForm,
  setErrorForm,
  ValidateArrayForm,
  Moment,
  convertDateToApi,
} from '../../../helpers'
import { UploadImageProfileComponent } from '../../../components/upload-image-profile/upload-image-profile'
import { ModalLeaveComponent } from '../../../components/modal-leave/modal-leave'
import { MatDialog } from '@angular/material/dialog'
import { CompanyProfileService, CaratService } from '../../../services'
import { CustomSnackBar } from '../../../components/snackbar/snackbar'
import { ACTION_ACCESS, APP_PERMISSION, Loading, Profile } from '../../../globals'
import { forkJoin } from 'rxjs'
import { ModalConfirmComponent } from '../../../components/modal-confirm/modal-confirm'
import { InputNumberComponent } from '../../../components/input-number/input-number'
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
  CdkDrag,
  CdkDropList,
} from '@angular/cdk/drag-drop'
import { DatePickerComponent } from '../../../components/date-picker/date-picker'
import { ShippingService } from '../../../services/shipping.service'
import { SHIPPING_TYPE } from '../../../helpers/shipping'

@Component({
  selector: 'app-shipping-manage',
  standalone: true,
  imports: [
    ...imports,
    InputComponent,
    TextareaComponent,
    UploadImageProfileComponent,
    ModalLeaveComponent,
    InputNumberComponent,
    DatePickerComponent,
    CdkDrag,
    CdkDropList,
  ],
  templateUrl: './shipping-manage.html',
  styleUrls: ['./shipping-manage.scss'],
})
export class ShippingManageComponent implements OnInit {
  readonly APP_PERMISSION = APP_PERMISSION
  readonly ACTION_ACCESS = ACTION_ACCESS

  // mobile = window.matchMedia('(max-width: 1000px)')

  isEdit: boolean = false
  isView: boolean = false
  dataManage: any
  id: any = 0

  form = new FormGroup({
    condition_type: new FormControl<any>(SHIPPING_TYPE.STANDARD),
    standard_cost: new FormControl(null, [Validators.required, Validators.max(1000000000)]),

    min_order: new FormControl(null, [
      (control: AbstractControl) => {
        const parent = control.parent
        if (
          parent &&
          [SHIPPING_TYPE.MIN_TOTAL, SHIPPING_TYPE.MIN_TOTAL_AND_COUNT_PRODUCT].includes(
            parent.value.condition_type
          ) &&
          typeof control.value != 'number'
        ) {
          return { required: true }
        }
        return null
      },
    ]),
    min_no_order: new FormControl(null, [
      (control: AbstractControl) => {
        const parent = control.parent
        if (
          parent &&
          [SHIPPING_TYPE.COUNT_PRODUCT, SHIPPING_TYPE.MIN_TOTAL_AND_COUNT_PRODUCT].includes(
            parent.value.condition_type
          ) &&
          typeof control.value != 'number'
        ) {
          return { required: true }
        }
        return null
      },
    ]),
    shipping_cost: new FormControl(null, [
      (control: AbstractControl) => {
        const parent = control.parent
        if (
          parent &&
          [
            SHIPPING_TYPE.MIN_TOTAL,
            SHIPPING_TYPE.COUNT_PRODUCT,
            SHIPPING_TYPE.MIN_TOTAL_AND_COUNT_PRODUCT,
          ].includes(parent.value.condition_type) &&
          typeof control.value != 'number'
        ) {
          return { required: true }
        }
        return null
      },
    ]),
  })

  conditionsList = [
    { id: SHIPPING_TYPE.STANDARD, name: 'ไม่มีกำหนด' },
    { id: SHIPPING_TYPE.MIN_TOTAL, name: 'ยอดคำสั่งซื้อขั้นต่ำ' },
    { id: SHIPPING_TYPE.COUNT_PRODUCT, name: 'จำนวนสินค้าที่ซื้อขั้นต่ำ' },
    {
      id: SHIPPING_TYPE.MIN_TOTAL_AND_COUNT_PRODUCT,
      name: 'ยอดคำสั่งซื้อและจำนวนสินค้าที่ซื้อขั้นต่ำ',
    },
  ]

  constructor(
    public profile: Profile,
    public router: Router,
    public dialog: MatDialog,
    public customSnackBar: CustomSnackBar,
    public loading: Loading,
    public ShippingService: ShippingService,
    public companyProfileService: CompanyProfileService
  ) {}

  ngOnInit(): void {
    if (this.router.url.includes('view')) {
      this.isView = true
    }
    if (this.router.url.includes('edit')) {
      this.isEdit = true
    }
    this.initData()
  }

  initData() {
    this.loading.start()
    this.ShippingService.shippingQuery({}).subscribe(res => {
      if (res) {
        if (!res.is_error) {
          let data = res.data?.[0]
          this.id = data?.id
          this.form.setValue({
            condition_type: data.condition_type || SHIPPING_TYPE.STANDARD,
            standard_cost: data.standard_cost || 0,

            min_order: data.min_order || 0,
            min_no_order: data.min_no_order || 0,
            shipping_cost: data.shipping_cost || 0,
          })
        }
      }
      this.loading.stop()
    })
  }

  resetValidate() {
    const formValue = this.form.getRawValue()
    this.form.reset({
      standard_cost: formValue.standard_cost,
      condition_type: formValue.condition_type,
    })
  }

  confirmSave(): void {
    ValidateForm(this.form)
    if (!this.form.valid) {
      return
    }

    const dialogRefConfirm = this.dialog.open(ModalConfirmComponent, {
      data: {},
    })

    dialogRefConfirm.afterClosed().subscribe(result => {
      if (result) {
        this.onSave()
      }
    })
  }

  onSave() {
    let payload = {
      condition_type: this.form.value.condition_type,
      standard_cost: this.form.value.standard_cost || undefined,
      min_order: this.form.value.min_order || undefined,
      min_no_order: this.form.value.min_no_order || undefined,
      shipping_cost: this.form.value.shipping_cost || undefined,
    }

    const API = this.id
      ? this.ShippingService.updateShipping(this.id, payload)
      : this.ShippingService.createShipping(payload)
    this.loading.start()
    API.subscribe(res => {
      if (!res.is_error) {
        this.customSnackBar.success('บันทึกสำเร็จ')
        this.isSaved = true
        this.router.navigate(['/setting/shipping-manage/view'])
      } else {
        this.customSnackBar.failSave()
      }
      this.loading.stop()
    })
  }

  isSaved = false
  openModalCancel(resolveFromRouter: (value: boolean) => void): void {
    if (this.isEdit && this.form.dirty && !this.isSaved) {
      const dialogCancel = this.dialog.open(ModalLeaveComponent, {
        data: {},
      })

      dialogCancel.afterClosed().subscribe(result => {
        resolveFromRouter(!!result)
      })
    } else {
      resolveFromRouter(true)
    }
  }
}
