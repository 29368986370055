<div class="page-content">
  <div class="breadcrumb-wrapper">
    <div class="breadcrumb-text breadcrumb-link">ตั้งค่า</div>
    @if (profile.companyId == COMPANY_ID.BHB) {
    <div class="breadcrumb-separator">/</div>
    <div class="breadcrumb-text breadcrumb-link">ข้อมูลสินค้า</div>
    }
    <div class="breadcrumb-separator">/</div>
    <div class="breadcrumb-text">หมวดหมู่สินค้า</div>
  </div>

  <div class="title-wrapper">หมวดหมู่สินค้า</div>

  <div class="page-content-inner">
    <div class="top-content-wrapper mb-3">
      <app-search
        [control]="filterTableProductCategory.search"
        placeholder="ค้นหา"
        (onEnter)="paginationCategory.reset();getCategoryList()"></app-search>

      <!-- @if (profile.permissions.checkAccess([APP_PERMISSION.PRODUCT_CATEGORY],
      [ACTION_ACCESS.CREATE])) { -->
      <button
        matRipple
        class="btn btn-primary"
        (click)="openModalCategory('เพิ่มหมวดหมู่สินค้า', 'add')">
        <span class="icon material-symbols-outlined fill">add_circle</span>
        เพิ่มหมวดหมู่สินค้า
      </button>
      <!-- } -->
    </div>

    <!-- Table -->
    <div class="table-responsive table-main-wrapper">
      <table class="table table-fixed-column">
        <thead>
          <tr>
            <th width="280">ชื่อหมวดหมู่สินค้าหลัก (ภาษาไทย)</th>
            <th width="280">ชื่อหมวดหมู่สินค้าหลัก (ภาษาอังกฤษ)</th>
            <th width="180">หมวดหมู่ย่อย</th>
            <th width="150">สถานะ</th>
            <th width="220">อัปเดตโดย</th>
            <th width="175">อัปเดตล่าสุด</th>
            <th width="120" class="fixed box-shadow-left">จัดการ</th>
          </tr>
        </thead>

        <tbody>
          @for (item of categoryList; track $index) {
          <tr>
            <td>
              <app-text-tooltip> {{ item.name || '-' }} </app-text-tooltip>
            </td>
            <td>
              <app-text-tooltip> {{ item.name_en || '-' }} </app-text-tooltip>
            </td>
            <td>
              @if (item?.product_sub_categories !== null ) {
              <span (click)="openModalSubcategory(item)" class="subcategory"
                >{{item?.product_sub_categories?.length}} หมวดหมู่</span
              >
              }@else{ - }
            </td>
            <td>
              <app-status-user [status]="item.is_active ? 'active' : 'inactive'">
                {{ item.is_active ? 'ใช้งาน' : 'ไม่ใช้งาน' }}
              </app-status-user>
            </td>
            <td>{{item.updated_by ? item.updated_by.full_name : ''}}</td>
            <td>{{Moment(item.updated_at).format('DD-MM-YYYY, HH:mm')}}</td>
            <td class="fixed box-shadow-left">
              <div class="action-button-wrapper">
                @if (item.action.edit) {
                <button
                  matRipple
                  matTooltip="แก้ไข"
                  matTooltipPosition="above"
                  class="btn btn-outline-secondary btn-icon"
                  (click)="openModalCategory('แก้ไขหมวดหมู่สินค้า','edit',item)">
                  <span class="icon material-symbols-outlined fill">edit</span>
                </button>
                } @if (item.action.delete) {
                <button
                  matRipple
                  matTooltip="ลบ"
                  matTooltipPosition="above"
                  class="btn btn-outline-danger btn-icon"
                  [disabled]="!item.can_delete"
                  (click)="confirmDelete(item)">
                  <span class="icon material-symbols-outlined fill">delete</span>
                </button>
                }
              </div>
            </td>
          </tr>
          }
        </tbody>
      </table>

      @if(!categoryList.length) {
      <div class="data-not-found-wrapper">ไม่พบหมวดหมู่สินค้า กรุณาเพิ่มหมวดหมู่สินค้า</div>
      }
    </div>

    <!-- Pagination -->
    @if (categoryList.length) {
    <div class="pagination-wrapper">
      <mat-paginator
        [pageIndex]="paginationCategory.data.pageIndex"
        [length]="paginationCategory.data.length"
        [pageSize]="paginationCategory.data.pageSize"
        [pageSizeOptions]="paginationCategory.data.pageSizeOptions"
        aria-label="Select page"
        (page)="paginationCategory.setFromPaginator($event);getCategoryList()">
      </mat-paginator>
    </div>
    }
  </div>
</div>
