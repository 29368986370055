<div class="page-content-inner">
  <b class="mb-1">อันดับลูกค้า</b>

  <div class="d-flex justify-content-between flex-wrap mt-4">
    <div class="d-flex flex-wrap gap-3 mb-3">
      <div>
        <app-search
          [control]="search"
          placeholder="ค้นหา"
          (onEnter)="getCustomerRanking()"></app-search>
      </div>

      <div class="select-top">
        <app-select
          [control]="top"
          [datas]="topList"
          fieldValue="value"
          placeholder="เลือก"
          [remove_bottom]="true"
          (selectionChange)="getCustomerRanking()">
          <ng-template #optionRef let-option> {{ option.name }} </ng-template>
        </app-select>
      </div>

      <app-date-picker-range
        [controlStr]="dateStr"
        [controlEnd]="dateEnd"
        [hideError]="true"
        (onChange)="getCustomerRanking()"></app-date-picker-range>
    </div>
    <div>
      @if (profile.permissions.checkAccess([APP_PERMISSION.REPORT], [ACTION_ACCESS.VIEW])) {
      <button
        matRipple
        class="btn btn-outline-secondary"
        (click)="openModalDownloadFile('ดาวน์โหลดไฟล์อันดับลูกค้า', { message: 'คุณต้องการดาวน์โหลดไฟล์อันดับลูกค้าใช่หรือไม่' })">
        <span class="icon material-symbols-outlined fill">download_2</span>
        ส่งออกไฟล์
      </button>
      }
    </div>
  </div>

  <div class="table-responsive-no-height mt-3">
    <table class="table table-fixed-column">
      <thead>
        <tr>
          <th width="100" class="text-center">อันดับ</th>
          <th width="200">ชื่อลูกค้า</th>
          <th width="200">เบอร์โทรศัพท์</th>
          <th width="160">ช่องทางโซเชียล</th>
          <th width="140" class="text-end">รวมยอดขาย</th>
          <th width="140" class="text-end">รวมกำไรสุทธิ</th>
        </tr>
      </thead>

      <tbody>
        @for (item of list; track item; let i = $index) {
        <tr>
          <td class="text-center">{{ i+1 }}</td>
          <td>
            @if (item.customer_name) {
            <a
              class="color--brand-blue text-decoration-none"
              [href]="'/customer/view/' + item.customer_id"
              target="_blank">
              <app-text-tooltip>
                {{ showCustomerName(item.customer_name, item.customer_last_name) }}
              </app-text-tooltip>
            </a>
            } @else { - }
          </td>
          <td>{{ item.telephone || '-' }}</td>
          <td>
            @for (c of item.channels; track $index) {
            <div class="d-flex align-items-center me-3">
              @if (channelIcon[c.channel_id]) {
              <img
                src="/assets/images/icons/icon-social-{{ channelIcon[c.channel_id] }}.svg"
                class="me-1" />
              }
              <app-text-tooltip>
                <span
                  [innerHTML]="(c.channel_id == channelId.none ? c.channel : c.channel_name) || '-'">
                </span>
              </app-text-tooltip>
            </div>
            } @empty {-}
          </td>
          <td class="text-end">{{ formatPrice(item.total_price) }}</td>
          <td class="text-end">{{ formatPrice(item.total_margin) }}</td>
        </tr>
        }
      </tbody>
      @if (list.length) {
      <tfoot>
        <tr class="tr-sum-total-reprot">
          <td colspan="4">รวม</td>
          <td class="text-end">{{ formatPrice(sumField('total_price')) }}</td>
          <td class="text-end">{{ formatPrice(sumField('total_margin')) }}</td>
        </tr>
      </tfoot>
      }
    </table>

    @if(!list.length) {
    <div class="data-not-found-wrapper">ไม่พบข้อมูล</div>
    }
  </div>
</div>
