<div id="text-editor" class="text-editor">
  <div class="editor {{ control.touched && control.invalid ? 'is-error' : ''}}">
    <ngx-editor-menu [editor]="editor" [toolbar]="toolbar" [customMenuRef]="customMenu">
    </ngx-editor-menu>
    <ng-template #customMenu>
      <div class="NgxEditor__Seperator"></div>
      @if (toolbarImg) {
      <div class="d-flex">
        <input type="file" #fileEditor style="width: 0" (change)="onChangeInsertImage($event)" />
        <div
          class="NgxEditor__MenuItem NgxEditor__MenuItem--Icon"
          style="cursor: default"
          (click)="fileEditor.click()">
          <span class="icon material-symbols-outlined">imagesmode</span>
        </div>
      </div>
      }
    </ng-template>
    <ngx-editor
      [class.is-error]="control.touched && control.invalid"
      [editor]="editor"
      [(ngModel)]="html"
      (ngModelChange)="formControl.setValue($event == '<p></p>' ? '' : $event)"
      [placeholder]="placeholder">
    </ngx-editor>

    @if (control.touched && control.invalid) {
      <div class="font-12 is-error mat-mdc-form-field-subscript-wrapper"> {{ errorText }}</div>
    }
  </div>

  <!-- @if (control.invalid) {
  <div class="editor" [class.is-error]="control.invalid">
    <ngx-editor [editor]="editor" [(ngModel)]="html" [placeholder]="placeholder"> </ngx-editor>
  </div>

  <mat-error>
    <ng-content select="[error]"></ng-content>
  </mat-error>
  } -->
</div>
