import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { Service } from './service'

@Injectable({
  providedIn: 'root',
})
export class CurrencyService {
  constructor(private service: Service) {}
  private path = '/v1/currency-rates'

  getCurrencyRateQuery(payload: any): Observable<any> {
    return this.service.post(`${this.path}/query`, payload)
  }

}
