<mat-form-field
  appearance="outline"
  class="select-custom-arrow {{ type == 'select-bank' ? 'select-bank' : 'select' }} {{ remove_bottom && 'remove-bottom' }}">
  <mat-select
    #select
    [formControl]="formControl"
    [placeholder]="placeholder"
    [multiple]="multiple"
    [compareWith]="compareWith"
    [hideSingleSelectionIndicator]="true"
    (selectionChange)="selectionChange.emit($event)"
    [placeholder]="placeholder"
    (opened)="inputSearch.focus();onOpen.emit(searchText())"
    (closed)="searchText.set('')">
    <mat-select-trigger>
      <div class="d-flex" [ngStyle]="{ 'margin-top': '1px' }">
        @for (option of values; track $index) {@if ($index > 0) {, }
        <ng-container *ngTemplateOutlet="triggerRef; context: { $implicit: option }"></ng-container>
        }
      </div>
    </mat-select-trigger>

    <div class="search-select-sync">
      <input
        type="text"
        [(ngModel)]="searchText"
        class="form-control input w-100"
        placeholder="ค้นหา"
        (keydown)="$event.stopPropagation()"
        #inputSearch
        (input)="handleSearch()" />

      <div class="box-spinner-select-sync">
        @if (loading) {
        <app-spinner-custom
          [show]="true"
          [inline]="true"
          size="20px"
          borderWidth="3px"
          aria-hidden="true" />
        }
      </div>
    </div>
    @for (option of values; track $index) {
    <mat-option
      [value]="option"
      [class.d-none]="true"
      *ngIf="isNoOption(option)"
      aria-hidden="true">
    </mat-option>
    }
    <!-- ~ -->
    @if (datas.length) {
    <!-- ~ -->
    @for (option of datas; track option) {
    <mat-option
      [value]="option"
      [disabled]="disabledNotAllOption && option[fieldValue] != 'all'"
      (onSelectionChange)="onSelectionChange.emit($event)">
      <ng-container *ngTemplateOutlet="optionRef; context: { $implicit: option }"></ng-container>
    </mat-option>
    }
    <!-- ~ -->
    } @else {
    <mat-option [disabled]="true" class="option-customer-item text-center select-not-found-sync">
      {{ empty }}
    </mat-option>
    }
  </mat-select>

  @if (control.invalid) {
  <mat-error>
    <ng-content select="[error]"></ng-content>
  </mat-error>
  }

  <div matSuffix class="select-suffix d-flex align-items-center">
    <img src="/assets/images/icons/icon-select.svg" />
  </div>
</mat-form-field>
