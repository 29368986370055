<div mat-dialog-title class="dialog-title">
  <div class="dialog-title-head">นำสินค้าขึ้นเว็บ</div>
  <button class="btn btn-transparent btn-icon" (click)="onClose()">
    <img src="../assets/images/icons/icon-close.svg" />
  </button>
</div>

<mat-dialog-content>
  <div class="modal-content">
    <div class="detail-list-wrapper">
      <label> การนำสินค้าขึ้นเว็บผู้ใช้งานต้องกรอกข้อมูลตามเงื่อนไข ดังนี้ </label>
      <ul class="detail-list mt-2">
        <li>
          สินค้าหลักต้องมีรูปภาพหน้าปก, รหัสสินค้าจริง, หมวดหมู่, หมวดหมู่ย่อย, แบรนด์, สินค้าสำหรับ
        </li>
        <li>สินค้าย่อยต้องมีรูปภาพหน้าปก, รหัสสินค้าจริง</li>
        <li>สถานะสินค้าต้องเปิดใช้งาน</li>
      </ul>
    </div>

    <div class="error-wrapper error-section">
      <div class="text-wrapper">
        <div class="error-text-wrapper">
          <div class="error-text text-center">
            กรอกข้อมูลไม่ครบถ้วน ตรวจสอบข้อมูลและกดปุ่ม “นำสินค้าขึ้นเว็บ“ อีกครั้ง
          </div>
          <div class="error-text-row-wrapper mt-1">
            <div class="error-text-row">
              <div class="error-info">
                @for (item of errors; track $index) {
                <div><b>{{ item.code }}:</b> {{ item.text }}</div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="footer-button-wrapper d-flex justify-content-end w-100">
    <button matRipple class="btn btn-primary btn-md" (click)="onClose()">ปิด</button>
  </div>
</mat-dialog-actions>
