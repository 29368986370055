import { Component, EventEmitter, Input, Output } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { MatInputModule } from '@angular/material/input'
import { MatSelectModule } from '@angular/material/select'

import { ReactiveFormsModule } from '@angular/forms'
import { InputSearchComponent } from '../../../../../components/input-search/input-search'
import { CustomSnackBar } from '../../../../../components/snackbar/snackbar'
import { FilterTableHistoryOrderDeleted, Loading } from '../../../../../globals'
import { imports } from '../../../../../imports'
import { RoleService } from '../../../../../services'
import { ModalFilterComponent } from '../modal-filter/modal-filter'

@Component({
  selector: 'app-search-filter-history-order-deleted',
  standalone: true,
  imports: [...imports, MatInputModule, MatSelectModule, ReactiveFormsModule, InputSearchComponent],
  templateUrl: './search-filter.html',
  styleUrls: ['./search-filter.scss'],
})
export class SearchFilterComponent {
  @Input() placeholder = ''

  @Output() onConfirm = new EventEmitter()

  constructor(
    public dialog: MatDialog,
    public roleService: RoleService,
    public loading: Loading,
    public customSnackBar: CustomSnackBar,
    public filterTableHistoryOrderDeleted: FilterTableHistoryOrderDeleted
  ) {}

  ngOnInit(): void {
    this.filterTableHistoryOrderDeleted.initData()
  }

  async openModalFilter() {
    const dialogRef = this.dialog.open(ModalFilterComponent, {
      data: {
        title: 'ตัวกรอง',
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.onConfirm.emit()
      } else {
        this.filterTableHistoryOrderDeleted.close()
      }
    })
  }

  afterFilter() {
    this.filterTableHistoryOrderDeleted.confirm()
    this.onConfirm.emit()
  }
}
