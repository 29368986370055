<div class="card-product">
  <div class="favourit">
    <img
      src="/assets/images/icons/icon-favorite.svg"
      alt="favourit"
      width="20"
      class="cursor-pointer" />
  </div>

  <div class="box-image">
    <div class="product-image">
      <img
        [src]="productData.colorImage?.url || defaultUrl"
        [alt]="productData.colorImage?.name"
        class="cursor-pointer"
        loading="lazy" />
    </div>
  </div>

  <div class="product-detail mt-2">
    <span class="text-new"> New </span>
    <!-- ~ -->

    <div class="b-product align-items-center justify-content-between gap-2 mt-2">
      <div class="div-product-name">
        &nbsp;
        <span class="product-name flex-1"> {{ productData.color_detail_with_default.name }} </span>
      </div>
    </div>

    <div class="product-price flex gap-2 mt-2">
      <span class="text-base-regular">
        ฿ {{ formatPrice(productData.color_default_selling_price) }}
      </span>
    </div>
  </div>
</div>
