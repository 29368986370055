<div class="page-content">
  <div class="breadcrumb-wrapper">
    <div class="breadcrumb-text breadcrumb-link">ตั้งค่า</div>
    <div class="breadcrumb-separator">/</div>
    <div class="breadcrumb-text">เชื่อมต่อ Marketplace</div>
  </div>

  <div class="title-wrapper">เชื่อมต่อ Marketplace</div>

  <div class="tabs-wrapper">
    <mat-tab-group
      animationDuration="0ms"
      [disableRipple]="true"
      [selectedIndex]="tab"
      (selectedIndexChange)="changeTab($event)">
      <mat-tab>
        <ng-template mat-tab-label>
          <div class="d-flex align-items-center">
            <img src="/assets/images/icons/icon-social-shopee.svg" class="me-2" />
            <div>Shopee</div>
          </div>
        </ng-template>
        <div class="tabs-content-wrapper">
          @if (!shopeeConnected) {
          <div class="d-flex flex-column justify-content-center p-4">
            <div class="d-flex justify-content-center">
              <img src="/assets/images/logos/logo-shopee.svg" width="100" />
            </div>
            <div class="d-flex justify-content-center mt-4">
              <div>
                <b class="font-20">เชื่อมต่อกับ Shopee </b>
                <div class="mb-3">
                  เป็นการเชื่อมต่อข้อมูลสินค้า สต๊อก และรวมออเดอรมาจัดการในที่เดียว
                </div>

                <b class="font-20">การเตรียมสินค้า</b>
                <div>1. ร้านค้าจำเป็นต้องมีสินค้าทั้งใน Shopee และ Brandname Hunter</div>
                <div>
                  2. ในระบบ Shopee ตั้งรหัสสินค้า “Parent sku” ให้ตรงกับ “รหัสสินค้า” ในระบบ
                  Brandname Hunter
                </div>
                <div>
                  3. ในกรณีที่มีสินค้าย่อย ในระบบ Shopee จะตัองตั้ง “เลข sku” ให้ตรงกับ
                  “รหัสสินค้าย่อย” ในระบบ Brandname Hunter
                </div>
                <div>
                  4. การเชื่อมต่อสินค้านั้นๆ ครั้งแรก จะยึดเอาสต๊อกของ Brandname Hunter เป็นหลัก
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center">
              @if (profile.permissions.checkAccess([APP_PERMISSION.MARKETPLACE],
              [ACTION_ACCESS.UPDATE])) {
              <button
                matRipple
                type="button"
                class="btn btn-primary mt-4"
                (click)="connectShopee()">
                เชื่อมต่อ Shopee
              </button>
              }
            </div>
          </div>
          } @else {
          <div class="d-flex justify-content-between align-items-center">
            <div class="title-blue">การเชื่อมต่อ Shopee</div>
            @if (profile.permissions.checkAccess([APP_PERMISSION.MARKETPLACE],
            [ACTION_ACCESS.UPDATE])) {
            <button
              class="btn btn-outline-danger btn-md"
              matRipple
              (click)="openModalUnconnectAccount('ยืนยันยกเลิกการเชื่อมต่อ', MARKETPLACE_SHOP.SHOPEE)">
              ยกเลิกการเชื่อมต่อบัญชี
            </button>
            }
          </div>

          <div class="row mb-3">
            <div class="col-12 col-sm-6">
              <div class="d-flex justify-content-center">
                <img src="/assets/images/icons/icon-store-sp.svg" width="100" />
              </div>
              <div class="text-center mt-2"><b class="font-18">ชื่อบัญชีร้านค้า</b></div>

              <div class="border-bottom d-flex justify-content-between mt-3">
                <span>สถานะการเชื่อมต่อ</span>
                <app-status-user status="active" [fixed_width]="false"
                  >เชื่อมต่อเรียบร้อย</app-status-user
                >
              </div>
              <div class="border-bottom d-flex justify-content-between">
                <div>
                  อัปเดตจำนวนสต๊อกจาก Brandname Hunter ไปยัง Shopee
                  <!-- ~ -->
                  @if (maketplace.shopee_stock_updated_at) {
                  <div class="update-text mt-1 d-flex align-items-center gap-1">
                    <img src="assets/images/icons/icon-refresh-update.svg" />
                    อัปเดตล่าสุด: {{ formatUpdate(maketplace.shopee_stock_updated_at) }}
                  </div>
                  }
                </div>
                <div>
                  @if (profile.permissions.checkAccess([APP_PERMISSION.MARKETPLACE],
                  [ACTION_ACCESS.UPDATE])) {
                  <mat-slide-toggle
                    hideIcon="true"
                    color="primary"
                    class="slide-toggle"
                    [formControl]="shopee_is_stock_connected"
                    (click)="openModalConnect(MARKETPLACE_SHOP.SHOPEE)">
                  </mat-slide-toggle>
                  }
                </div>
              </div>
              <div class="border-bottom d-flex justify-content-between">
                <div>
                  เชื่อมต่อออเดอร์จาก Shopee เข้า Brandname Hunter
                  <!-- ~ -->
                  @if (maketplace.shopee_order_updated_at) {
                  <div class="update-text mt-1 d-flex align-items-center gap-1">
                    <img src="assets/images/icons/icon-refresh-update.svg" />
                    อัปเดตล่าสุด: {{ formatUpdate(maketplace.shopee_order_updated_at) }}
                  </div>
                  }
                </div>
                <div>
                  @if (profile.permissions.checkAccess([APP_PERMISSION.MARKETPLACE],
                  [ACTION_ACCESS.UPDATE])) {
                  <mat-slide-toggle
                    hideIcon="true"
                    color="primary"
                    class="slide-toggle"
                    [formControl]="shopee_is_order_connected"
                    (click)="openModalConnect(MARKETPLACE_SHOP.SHOPEE)">
                  </mat-slide-toggle>
                  }
                </div>
              </div>
            </div>
          </div>
          }
        </div>
      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label>
          <div class="d-flex align-items-center">
            <img src="/assets/images/icons/icon-social-lazada.svg" class="me-2" />
            <div>Lazada</div>
          </div>
        </ng-template>
        <div class="tabs-content-wrapper">
          @if (!lazadaConnected) {
          <div class="d-flex flex-column justify-content-center p-4">
            <div class="d-flex justify-content-center">
              <img src="/assets/images/logos/logo-lazada.svg" width="100" />
            </div>
            <div class="d-flex justify-content-center mt-4">
              <div>
                <b class="font-20">เชื่อมต่อกับ Lazada </b>
                <div class="mb-3">
                  เป็นการเชื่อมต่อข้อมูลสินค้า สต๊อก และรวมออเดอรมาจัดการในที่เดียว
                </div>

                <b class="font-20">การเตรียมสินค้า</b>
                <div>1. ร้านค้าจำเป็นต้องมีสินค้าทั้งใน Lazada และ Brandname Hunter</div>
                <div>
                  2. ในระบบ Lazada ตั้งรหัสสินค้า “Parent sku” ให้ตรงกับ “รหัสสินค้า” ในระบบ
                  Brandname Hunter
                </div>
                <div>
                  3. ในกรณีที่มีสินค้าย่อย ในระบบ Lazada จะตัองตั้ง “เลข sku” ให้ตรงกับ
                  “รหัสสินค้าย่อย” ในระบบ Brandname Hunter
                </div>
                <div>
                  4. การเชื่อมต่อสินค้านั้นๆ ครั้งแรก จะยึดเอาสต๊อกของ Brandname Hunter เป็นหลัก
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center">
              @if (profile.permissions.checkAccess([APP_PERMISSION.MARKETPLACE],
              [ACTION_ACCESS.UPDATE])) {
              <button
                matRipple
                type="button"
                class="btn btn-primary mt-4"
                (click)="connectLazada()">
                เชื่อมต่อ Lazada
              </button>
              }
            </div>
          </div>
          } @else {
          <div class="d-flex justify-content-between align-items-center">
            <div class="title-blue">การเชื่อมต่อ Lazada</div>
            @if (profile.permissions.checkAccess([APP_PERMISSION.MARKETPLACE],
            [ACTION_ACCESS.UPDATE])) {
            <button
              class="btn btn-outline-danger btn-md"
              matRipple
              (click)="openModalUnconnectAccount('ยืนยันยกเลิกการเชื่อมต่อ', MARKETPLACE_SHOP.LAZADA)">
              ยกเลิกการเชื่อมต่อบัญชี
            </button>
            }
          </div>

          <div class="row mb-3">
            <div class="col-12 col-sm-6">
              <div class="d-flex justify-content-center">
                <img src="/assets/images/icons/icon-store-lz.svg" width="100" />
              </div>
              <div class="text-center mt-2"><b class="font-18">ชื่อบัญชีร้านค้า</b></div>

              <div class="border-bottom d-flex justify-content-between mt-3">
                <span>สถานะการเชื่อมต่อ</span>
                <app-status-user status="active" [fixed_width]="false"
                  >เชื่อมต่อเรียบร้อย</app-status-user
                >
              </div>
              <div class="border-bottom d-flex justify-content-between">
                <div>
                  อัปเดตจำนวนสต๊อกจาก Brandname Hunter ไปยัง Lazada
                  <!-- ~ -->
                  @if (maketplace.lazada_stock_updated_at) {
                  <div class="update-text mt-1 d-flex align-items-center gap-1">
                    <img src="assets/images/icons/icon-refresh-update.svg" />
                    อัปเดตล่าสุด: {{ formatUpdate(maketplace.lazada_stock_updated_at) }}
                  </div>
                  }
                </div>
                <div>
                  @if (profile.permissions.checkAccess([APP_PERMISSION.MARKETPLACE],
                  [ACTION_ACCESS.UPDATE])) {
                  <mat-slide-toggle
                    hideIcon="true"
                    color="primary"
                    class="slide-toggle"
                    [formControl]="lazada_is_stock_connected"
                    (click)="openModalConnect(MARKETPLACE_SHOP.LAZADA)">
                  </mat-slide-toggle>
                  }
                </div>
              </div>
              <div class="border-bottom d-flex justify-content-between">
                <div>
                  เชื่อมต่อออเดอร์จาก Lazada เข้า Brandname Hunter
                  <!-- ~ -->
                  @if (maketplace.lazada_order_updated_at) {
                  <div class="update-text mt-1 d-flex align-items-center gap-1">
                    <img src="assets/images/icons/icon-refresh-update.svg" />
                    อัปเดตล่าสุด: {{ formatUpdate(maketplace.lazada_order_updated_at) }}
                  </div>
                  }
                </div>
                <div>
                  @if (profile.permissions.checkAccess([APP_PERMISSION.MARKETPLACE],
                  [ACTION_ACCESS.UPDATE])) {
                  <mat-slide-toggle
                    hideIcon="true"
                    color="primary"
                    class="slide-toggle"
                    [formControl]="lazada_is_order_connected"
                    (click)="openModalConnect(MARKETPLACE_SHOP.LAZADA)">
                  </mat-slide-toggle>
                  }
                </div>
              </div>
            </div>
          </div>
          }
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>

  @if ((shopeeConnected && tab == 0) || (lazadaConnected && tab == 1)) {
  <div class="page-content-inner mt-3">
    <div class="d-flex justify-content-between">
      <div class="title-blue">รายการสินค้า</div>

      @if (profile.permissions.checkAccess([APP_PERMISSION.MARKETPLACE], [ACTION_ACCESS.CREATE])) {
      <button
        matRipple
        class="btn btn-primary"
        (click)="openModalAddProduct('เพิ่มสินค้าจากสต๊อก', 'add')">
        <span class="icon material-symbols-outlined fill">add_circle</span>
        เพิ่มสินค้าจากสต๊อก
      </button>
      }
    </div>

    <div class="table-responsive-detail mt-3">
      <table class="table table-fixed-column table-detail">
        <thead>
          <tr>
            <th width="110">รูปสินค้า</th>
            <th width="180">รหัสสินค้า</th>
            <th width="280">ชื่อสินค้า</th>
            <th width="140" class="text-end">ราคาขาย (บาท)</th>
            <th width="70" class="text-end">สต๊อก</th>
            <th width="90" class="text-end">
              <div class="d-flex align-items-center justify-content-end">
                จอง
                <span
                  matTooltipClass="tooltip-black"
                  matTooltip="สถานะจองคือสินค้าที่อยู่ในออเดอร์ที่ยังไม่ปิดรายการ เมื่อออเดอร์ถูกปิดรายการจึงจะตัดสต๊อก"
                  matTooltipPosition="above"
                  class="icon material-symbols-outlined fill ms-1 cursor-pointer"
                  >info</span
                >
              </div>
            </th>
            <th width="80" class="text-end">คงเหลือ</th>
            <th width="120">สถานะเชื่อมต่อ</th>
            @if (profile.permissions.checkAccess([APP_PERMISSION.MARKETPLACE],
            [ACTION_ACCESS.UPDATE])) {
            <th width="240" class="fixed box-shadow-left text-center">จัดการ</th>
            }
          </tr>
        </thead>

        <tbody>
          @for (item of productList; track $index) {
          <tr>
            <td>
              <img
                src="{{ productImage(item)?.file?.thumbnail || '/assets/images/icons/img-default-table.svg'}}"
                class="img-product" />
            </td>
            <td>{{ item.code }}</td>
            <td>{{ item.name }}</td>
            <td class="text-end">{{ formatPrice(item.selling_price) }}</td>
            <td class="text-end">{{ formatPriceAutoDecimal(item.stock) }}</td>
            <td class="text-end">{{ formatPriceAutoDecimal(item.book) }}</td>
            <td class="text-end">{{ formatPriceAutoDecimal(item.remain) }}</td>
            @if (profile.permissions.checkAccess([APP_PERMISSION.MARKETPLACE],
            [ACTION_ACCESS.UPDATE])) {
            <td>
              @if (tab === 0 ? maketplace.shopee_is_stock_connected :
              maketplace.lazada_is_stock_connected) {
              <app-status-user
                [status]="item.is_connected ? 'active' : 'inactive'"
                [fixed_width]="false">
                @if (item.is_connected) { เชื่อมต่อ } @else { ไม่ได้เชื่อมต่อ }
              </app-status-user>
              } @else { - }
            </td>
            <td class="fixed box-shadow-left">
              <div class="d-flex justify-content-center">
                <button
                  matRipple
                  class="btn btn-outline-secondary"
                  (click)="openModalAdjustStock('ปรับสต๊อก',item)">
                  ปรับสต๊อก
                </button>

                @if (item.is_connected) {
                <button
                  matRipple
                  class="btn btn-outline-danger ms-2"
                  (click)="openModalUnconnectProduct('ยืนยันยกเลิกเชื่อมต่อสินค้า', item)">
                  ยกเลิกเชื่อมต่อ
                </button>
                } @else {
                <button
                  matRipple
                  class="btn btn-outline-secondary ms-2"
                  (click)="openModalConnectProduct(item)">
                  เชื่อมต่อ
                </button>
                }
              </div>
            </td>
            }
          </tr>
          }
        </tbody>
      </table>

      @if(!productList.length) {
      <div class="data-not-found-wrapper">ไม่พบข้อมูล</div>
      }
    </div>

    @if (tab === 0 && shopeeList.length) {
    <div class="pagination-wrapper">
      <mat-paginator
        [pageIndex]="paginationShopee.data.pageIndex"
        [length]="paginationShopee.data.length"
        [pageSize]="paginationShopee.data.pageSize"
        [pageSizeOptions]="paginationShopee.data.pageSizeOptions"
        (page)="paginationShopee.setFromPaginator($event);getShopeeList()"
        aria-label="Select page">
      </mat-paginator>
    </div>
    } @else if (tab === 1 && lazadaList.length) {
    <div class="pagination-wrapper">
      <mat-paginator
        [pageIndex]="paginationLazada.data.pageIndex"
        [length]="paginationLazada.data.length"
        [pageSize]="paginationLazada.data.pageSize"
        [pageSizeOptions]="paginationLazada.data.pageSizeOptions"
        (page)="paginationLazada.setFromPaginator($event);getLazadaList()"
        aria-label="Select page">
      </mat-paginator>
    </div>
    }
  </div>
  }
</div>
