<div class="page-content">
  <div class="breadcrumb-wrapper">
    <div class="breadcrumb-text breadcrumb-link">ตั้งค่า</div>
    <div class="breadcrumb-separator">/</div>
    <div class="breadcrumb-text">ข้อมูลค่าขนส่ง</div>
    @if (isEdit) {
    <div class="breadcrumb-separator">/</div>
    <div class="breadcrumb-text">แก้ไข</div>
    }
  </div>

  <div class="title-wrapper">ข้อมูลค่าขนส่ง</div>

  <div class="page-content-inner d-flex flex-column">
    <div class="d-flex justify-content-between align-items-center">
      <div class="title-blue">กำหนดค่าจัดส่งปกติ</div>
      @if (isView) {
      <button
        matRipple
        class="btn btn-primary btn-md"
        [routerLink]="'/setting/shipping-manage/edit'">
        แก้ไขข้อมูล
      </button>
      }
    </div>
    <div class="row">
      <div class="col-12 col-md-6 col-lg-4">
        <label class="form-label mt-2">ค่าจัดส่งปกติ <span class="text-danger">*</span></label>
        <app-input-number
          [disabled]="isView"
          [control]="form.controls['standard_cost']"
          unit="บาท"
          placeholder="0.00"
          [min]="0"
          [max]="1000000"
          [useZero]="true">
          @if (form.controls['standard_cost'].hasError('required')) {
          <ng-container error>กรุณากรอกข้อมูล</ng-container>
          }
        </app-input-number>
      </div>
    </div>
    <div class="title-blue mt-3">กำหนดเงื่อนไข</div>
    <mat-radio-group [formControl]="form.controls['condition_type']" (change)="resetValidate()">
      <div class="radio-button-wrapper list-wrapper d-flex flex-wrap gap-3">
        @for (item of conditionsList; track $index) {
        <mat-radio-button [value]="item.id" [disabled]="isView"> {{item.name}} </mat-radio-button>
        }
      </div>
    </mat-radio-group>
    @if (form.value['condition_type'] != 1) {
    <div class="row mt-3">
      @if (form.value['condition_type'] == 2 || form.value['condition_type'] == 4) {
      <div class="col-12 col-md-6 col-lg-4">
        <label class="form-label"> ยอดคำสั่งซื้อขั้นต่ำ <span class="text-danger">*</span> </label>
        <app-input-number
          [disabled]="isView"
          [control]="form.controls['min_order']"
          placeholder="0.00"
          unit="บาท"
          [min]="0"
          [max]="1000000"
          [useZero]="true">
          @if (form.controls['min_order'].hasError('required')) {
          <ng-container error>กรุณากรอกข้อมูล</ng-container>
          }
        </app-input-number>
      </div>
      } @if (form.value['condition_type'] == 3 || form.value['condition_type'] == 4) {
      <div class="col-12 col-md-6 col-lg-4">
        <label class="form-label">
          จำนวนสินค้าที่ซื้อขั้นต่ำ <span class="text-danger">*</span>
        </label>
        <app-input-number
          [disabled]="isView"
          [control]="form.controls['min_no_order']"
          placeholder="0"
          [min]="0"
          [max]="1000000"
          [useZero]="true">
          @if (form.controls['min_no_order'].hasError('required')) {
          <ng-container error>กรุณากรอกข้อมูล</ng-container>
          }
        </app-input-number>
      </div>
      }
      <div class="col-12 col-md-6 col-lg-4">
        <label class="form-label"> ค่าส่ง <span class="text-danger">*</span> </label>
        <app-input-number
          [disabled]="isView"
          [control]="form.controls['shipping_cost']"
          placeholder="0.00"
          unit="บาท"
          [useZero]="true"
          [min]="0"
          [max]="1000000">
          @if (form.controls['shipping_cost'].hasError('required')) {
          <ng-container error>กรุณากรอกข้อมูล</ng-container>
          }
        </app-input-number>
      </div>
    </div>
    } @if(isEdit) {
    <div class="page-footer-btn pb-0">
      <div class="d-flex flex-wrap justify-content-end gap-2">
        <button
          matRipple
          class="btn btn-outline-secondary min-w-100px"
          [routerLink]="'/setting/shipping-manage/view'">
          ยกเลิก
        </button>
        <button matRipple class="btn btn-primary min-w-100px" (click)="confirmSave()">
          บันทึก
        </button>
      </div>
    </div>
    }
  </div>
</div>
