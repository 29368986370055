<div class="page-main-content">
  <div class="breadcrumb-wrapper">
    <div class="breadcrumb-text breadcrumb-link color--brand-blue--f" (click)="toList()">
      สินค้า
    </div>
    <div class="breadcrumb-separator">/</div>
    @if(isCreate) { @if (!isRelatePage.show) {

    <div class="breadcrumb-text">เพิ่มสินค้า</div>
    }@else {
    <div
      class="breadcrumb-text breadcrumb-link color--brand-blue--f"
      (click)="isRelatePage.show = false">
      เพิ่มสินค้า
    </div>
    <div class="breadcrumb-separator">/</div>
    <div class="breadcrumb-text">
      <!-- ~ -->
      @if (isRelatePage.page == 1) {Complete the look}
      <!-- ~ -->
      @if (isRelatePage.page == 2) {You may like color}
      <!-- ~ -->
      @if (isRelatePage.page == 3) {You may like style}
      <!-- ~ -->
      @if (isRelatePage.page == 4) {You may like color}
    </div>
    } } @else if(isView) { @if (!isRelatePage.show) {

    <div class="breadcrumb-text">ข้อมูลสินค้า</div>
    }@else {
    <div
      class="breadcrumb-text breadcrumb-link color--brand-blue--f"
      (click)="isRelatePage.show = false">
      ข้อมูลสินค้า
    </div>
    <div class="breadcrumb-separator">/</div>
    <div class="breadcrumb-text">
      <!-- ~ -->
      @if (isRelatePage.page == 1) {Complete the look}
      <!-- ~ -->
      @if (isRelatePage.page == 2) {You may like color}
      <!-- ~ -->
      @if (isRelatePage.page == 3) {You may like style}
      <!-- ~ -->
      @if (isRelatePage.page == 4) {You may like color}
    </div>
    } } @else if(isEdit) { @if (!isRelatePage.show) {
    <div class="breadcrumb-text breadcrumb-link color--brand-blue--f" (click)="toView()">
      ข้อมูลสินค้า
    </div>
    <div class="breadcrumb-separator">/</div>
    <div class="breadcrumb-text">แก้ไขสินค้า</div>

    }@else {
    <div class="breadcrumb-text breadcrumb-link color--brand-blue--f" (click)="toView()">
      ข้อมูลสินค้า
    </div>
    <div class="breadcrumb-separator">/</div>
    <div
      class="breadcrumb-text breadcrumb-link color--brand-blue--f"
      (click)="isRelatePage.show = false">
      แก้ไขสินค้า
    </div>
    <div class="breadcrumb-separator">/</div>
    <div class="breadcrumb-text">
      <!-- ~ -->
      @if (isRelatePage.page == 1) {Complete the look}
      <!-- ~ -->
      @if (isRelatePage.page == 2) {You may like color}
      <!-- ~ -->
      @if (isRelatePage.page == 3) {You may like style}
      <!-- ~ -->
      @if (isRelatePage.page == 4) {You may like color}
    </div>
    } }
  </div>

  @if (!isRelatePage.show) { @if(isView) {
  <div class="title-wrapper">ข้อมูลสินค้า</div>
  } @else {
  <div class="title-wrapper">{{ isCreate ? 'เพิ่มสินค้า' : 'แก้ไขสินค้า' }}</div>
  }

  <div class="page-content-inner mb-3">
    <div class="row mb-4">
      <div class="col-12 col-lg-5">
        @if(isView) {
        <div class="title-blue">ข้อมูลสินค้า</div>
        } @else {
        <div class="title-blue">รูปภาพ</div>
        }
        <div class="mt-3 font-14">รูปภาพหน้าปก</div>
        <div class="my-2">
          <app-upload-image-profile
            class="my-sm-3 mb-2"
            [type]="isView ? 'view' : 'edit'"
            [logoDefault]="imageCover?.thumbnail"
            (upload)="uploadImageCover($event)"
            (onDelete)="deleteImageCover()"
            imageDefault="/assets/images/default-image-product.png"></app-upload-image-profile>

          <div class="mt-2">
            <span class="font-14"
              >รูปภาพเพิ่มเติม
              <span class="gray">(จำนวน {{ imageDetails.length }}/9)</span>
            </span>
            <app-upload-image-more
              [type]="isView ? 'view' : 'edit'"
              [control]="form.controls.files"
              (upload)="form.controls.files.setValue($event)"></app-upload-image-more>
          </div>
          @if (!isView) {
          <div class="font-14 gray">หมายเหตุ:</div>
          <div>
            <ul class="font-14 gray">
              <li>ขนาดรูปที่แนะนำ 1500px x 2000px (อัตราส่วน 3:4)</li>
              <li>ขนาดไฟล์รูปไม่เกิน 30 Mb</li>
            </ul>
          </div>
          }
        </div>
      </div>
      <div class="col-12 col-lg-7">
        @if (isView) {
        <div class="d-flex flex-wrap justify-content-end">
          @if (dataManage?.action?.delete) {
          <button matRipple (click)="confirmDelete(dataManage)" class="btn btn-outline-danger me-2">
            <span class="icon material-symbols-outlined fill">delete</span>
            ลบข้อมูล
          </button>
          } @if (dataManage?.action?.edit) {
          <button
            matRipple
            class="btn btn-primary"
            [routerLink]="['/product/edit/' + dataManage.id]">
            <span class="icon material-symbols-outlined fill">edit</span>
            แก้ไขข้อมูล
          </button>
          }
        </div>
        } @else {
        <div class="title-blue">ข้อมูลสินค้า</div>
        }

        <div class="row mt-3">
          <div class="col-12 col-lg-6">
            <label
              class="form-label {{ isView && 'neutral-500'}}"
              [class.text-danger]="form.controls['code'].touched && form.controls['code'].invalid">
              รหัสสินค้า @if (!isView) {
              <span class="text-danger">*</span>
              }
            </label>

            @if (!isView) {
            <app-input [control]="form.controls['code']" (onChange)="validateCustom()">
              @if (form.controls['code'].hasError('required')) {
              <ng-container error>กรุณากรอกข้อมูล</ng-container>
              } @else if (form.controls['code'].hasError('maxlength')) {
              <ng-container error>กรุณากรอกข้อมูลไม่เกิน 15 ตัวอักษร</ng-container>
              } @else if (form.controls['code'].hasError('duplicate')) {
              <ng-container error>รหัสสินค้าซ้ำกัน</ng-container>
              } @else if (form.controls['code'].hasError('formApi')) {
              <ng-container error>{{ form.controls['code'].errors?.['formApi']}}</ng-container>
              }
            </app-input>
            } @else {
            <div class="label-view">{{ dataManage?.code }}</div>
            }
          </div>

          @if(service.companyId == COMPANY_ID.BHB){
          <div class="col-12 col-lg-6">
            <label class="form-label {{ isView && 'neutral-500'}}"> รหัสสินค้าจริง </label>

            @if (!isView) {
            <app-input [control]="form.controls['real_code']">
              @if (form.controls['code'].hasError('formApi')) {
              <ng-container error>{{ form.controls['code'].errors?.['formApi']}}</ng-container>
              } @else if (form.controls['real_code'].hasError('maxlength')) {
              <ng-container error>กรุณากรอกข้อมูลไม่เกิน 250 ตัวอักษร</ng-container>
              }
            </app-input>
            } @else {
            <div class="label-view">{{ dataManage?.real_code || '-' }}</div>
            }
          </div>
          } @if(service.companyId == COMPANY_ID.PANIA){
          <div class="col-12 col-lg-6">
            <label class="form-label {{ isView && 'neutral-500'}}"> บาร์โค้ด </label>

            @if (!isView) {
            <app-input [control]="form.controls['barcode']">
              @if (form.controls['barcode'].hasError('formApi')) {
              <ng-container error>{{ form.controls['barcode'].errors?.['formApi']}}</ng-container>
              } @else if (form.controls['barcode'].hasError('maxlength')) {
              <ng-container error>กรุณากรอกข้อมูลไม่เกิน 250 ตัวอักษร</ng-container>
              } @else if (form.controls['barcode'].hasError('formApi')) {
              <ng-container error>{{ form.controls['barcode'].errors?.['formApi']}}</ng-container>
              }
            </app-input>
            } @else {
            <div class="label-view">{{ dataManage?.barcode || '-' }}</div>
            }
          </div>
          }

          <div class="col-12 col-lg-6">
            <label
              class="form-label {{ isView && 'neutral-500'}}"
              [class.text-danger]="form.controls['name'].touched && form.controls['name'].invalid">
              ชื่อสินค้า @if (!isView) {
              <span class="text-danger">*</span>
              }
            </label>

            @if (!isView) {
            <app-input [control]="form.controls['name']">
              @if (form.controls['name'].hasError('required')) {
              <ng-container error>กรุณากรอกข้อมูล</ng-container>
              } @else if (form.controls['name'].hasError('maxlength')) {
              <ng-container error>กรุณากรอกข้อมูลไม่เกิน 250 ตัวอักษร</ng-container>
              } @else if (form.controls['name'].hasError('formApi')) {
              <ng-container error>{{ form.controls['name'].errors?.['formApi']}}</ng-container>
              }
            </app-input>
            } @else {
            <div class="label-view">
              <app-text-tooltip> {{ dataManage?.name }} </app-text-tooltip>
            </div>
            }
          </div>

          @if(service.companyId == COMPANY_ID.PANIA){
          <div class="col-12 col-lg-6">
            <label class="form-label {{ isView && 'neutral-500'}}"> คำอธิบายชื่อสินค้า </label>

            @if (!isView) {
            <app-input [control]="form.controls['detail']">
              @if (form.controls['detail'].hasError('required')) {
              <ng-container error>กรุณากรอกข้อมูล</ng-container>
              } @else if (form.controls['detail'].hasError('maxlength')) {
              <ng-container error>กรุณากรอกข้อมูลไม่เกิน 250 ตัวอักษร</ng-container>
              } @else if (form.controls['detail'].hasError('formApi')) {
              <ng-container error>{{ form.controls['detail'].errors?.['formApi']}}</ng-container>
              }
            </app-input>
            } @else {
            <div class="label-view">{{ dataManage?.detail }}</div>
            }
          </div>
          }

          <div class="col-12 col-lg-6">
            <label class="form-label {{ isView && 'neutral-500'}}"> หมวดหมู่ </label>
            @if (!isView) {
            <app-select
              [control]="form.controls['product_category_id']"
              [datas]="list.gategory"
              fieldValue="id"
              [search]="['name']"
              placeholder="เลือก"
              empty="ไม่มีหมวดหมู่"
              (selectionChange)="selectCategory($event)">
              <ng-template #optionRef let-option> {{ option.name }} </ng-template>
              @if (form.controls['product_category_id'].hasError('required')) {
              <ng-container error>กรุณาเลือกข้อมูล</ng-container>
              } @else if (form.controls['product_category_id'].hasError('formApi')) {
              <ng-container error
                >{{ form.controls['product_category_id'].errors?.['formApi']}}</ng-container
              >
              }
            </app-select>
            } @else {
            <div class="label-view">{{ dataManage?.product_category?.name || '-' }}</div>
            }
          </div>
          <div class="col-12 col-lg-6">
            <label class="form-label {{ isView && 'neutral-500'}}"> หมวดหมู่ย่อย </label>
            @if (!isView) {
            <app-select-sub-category
              [controlSubCategory]="form.controls['product_sub_category_id']"
              [controlSubSubCategory]="form.controls['product_sub_sub_category_id']"
              [datas]="subCategories"
              [search]="['name']"
              placeholder="หมวดหมู่ย่อย / หมวดหมู่ย่อยรอง"
              empty="ไม่มีหมวดหมู่ย่อย">
              @if (form.controls['product_sub_category_id'].hasError('required')) {
              <ng-container error>กรุณาเลือกข้อมูล</ng-container>
              } @else if (form.controls['product_sub_category_id'].hasError('formApi')) {
              <ng-container error
                >{{ form.controls['product_sub_category_id'].errors?.['formApi']}}</ng-container
              >
              }
            </app-select-sub-category>
            } @else {
            <div class="label-view">
              @if (dataManage?.product_sub_category?.name) {
              <ng-container>
                @if (dataManage.product_sub_category.parent_id) {
                <ng-container>
                  {{ dataManage.product_sub_category.parent_sub_category?.name + ' / ' +
                  dataManage.product_sub_category.name }}
                </ng-container>
                } @else {
                <ng-container> {{ dataManage.product_sub_category.name }} </ng-container>
                }
              </ng-container>
              } @else { - }
            </div>
            }
          </div>

          @if(service.companyId == COMPANY_ID.BHB){
          <div class="col-12 col-lg-6">
            <label class="form-label {{ isView && 'neutral-500'}}"> แบรนด์ </label>
            @if (!isView) {
            <app-select
              [control]="form.controls['product_brand_id']"
              [datas]="list.brand"
              fieldValue="id"
              [search]="['name']"
              placeholder="เลือก"
              empty="ไม่มีแบรนด์">
              <ng-template #optionRef let-option> {{ option.name }} </ng-template>
              @if (form.controls['product_brand_id'].hasError('required')) {
              <ng-container error>กรุณาเลือกข้อมูล</ng-container>
              } @else if (form.controls['product_brand_id'].hasError('formApi')) {
              <ng-container error
                >{{ form.controls['product_brand_id'].errors?.['formApi']}}</ng-container
              >
              }
            </app-select>
            } @else {
            <div class="label-view">{{ dataManage?.product_brand?.name || '-' }}</div>
            }
          </div>
          }
          <div class="col-12 col-lg-6">
            <label class="form-label {{ isView && 'neutral-500'}}"> สี </label>
            @if (!isView) {
            <div class="d-flex gap-2 w-full">
              <div class="flex-1">
                <app-input [control]="form.controls['color']">
                  @if (form.controls['color'].hasError('maxlength')) {
                  <ng-container error>กรุณากรอกข้อมูลไม่เกิน 15 ตัวอักษร</ng-container>
                  }
                </app-input>
              </div>

              <input
                type="color"
                class="input-color"
                [value]="form.controls['color_code'].value"
                (change)="form.controls['color_code'].setValue($any($event.target).value)" />
            </div>
            } @else {
            <div class="label-view">{{ dataManage?.color || '-' }}</div>
            }
          </div>
          <div class="col-12 col-lg-6">
            <label class="form-label {{ isView && 'neutral-500'}}"> ขนาด </label>
            @if (!isView) {
            <app-input [control]="form.controls['size']">
              @if (form.controls['size'].hasError('maxlength')) {
              <ng-container error>กรุณากรอกข้อมูลไม่เกิน 15 ตัวอักษร</ng-container>
              }
            </app-input>
            } @else {
            <div class="label-view">{{ dataManage?.size || '-' }}</div>
            }
          </div>
          <div class="col-12 col-lg-6">
            <label
              class="form-label {{ isView && 'neutral-500'}}"
              [class.text-danger]="form.controls['selling_price'].touched && form.controls['selling_price'].invalid">
              ราคาขาย @if (!isView) {
              <span class="text-danger">*</span>
              }
            </label>
            @if (!isView) {
            <app-input-number
              [control]="form.controls['selling_price']"
              placeholder="0.00"
              [min]="0"
              unit="บาท"
              (onInput)="calcMemberPrice()"
              (onChange)="calcMemberPrice()">
              @if (form.controls['selling_price'].hasError('required')) {
              <ng-container error>กรุณากรอกข้อมูล</ng-container>
              } @else if (form.controls['selling_price'].hasError('max')) {
              <ng-container error>กรุณากรอกไม่เกิน 1,000,000,000</ng-container>
              } @else if (form.controls['selling_price'].hasError('formApi')) {
              <ng-container error
                >{{ form.controls['selling_price'].errors?.['formApi']}}</ng-container
              >
              }
            </app-input-number>
            } @else {
            <div class="title-blue label-view">
              {{ formatPrice(dataManage?.selling_price || 0) }} บาท
            </div>
            }
          </div>

          <div class="col-12 col-lg-6">
            <div class="row">
              <div class="col-5">
                <label class="form-label {{ isView && 'neutral-500'}}"> เปอร์เซ็นต์ที่ลด </label>
                @if (!isView) {
                <app-input-number
                  [control]="form.controls['percent_discount']"
                  placeholder="0.00"
                  [min]="0"
                  [max]="99"
                  [decimal]="0"
                  unit="%"
                  (onInput)="calcMemberPrice()"
                  (onChange)="calcMemberPrice()">
                  @if (form.controls['percent_discount'].hasError('required')) {
                  <ng-container error>กรุณากรอกข้อมูล</ng-container>
                  } @else if (form.controls['percent_discount'].hasError('max')) {
                  <ng-container error>กรุณากรอกไม่เกิน 1,000,000,000</ng-container>
                  } @else if (form.controls['percent_discount'].hasError('formApi')) {
                  <ng-container error
                    >{{ form.controls['percent_discount'].errors?.['formApi']}}</ng-container
                  >
                  }
                </app-input-number>
                } @else {
                <div class="title-blue label-view">
                  {{ formatPrice(dataManage?.percent_discount || 0) }} บาท
                </div>
                }
              </div>
              <div class="col-7">
                <label class="form-label {{ isView && 'neutral-500'}}"> ราคาสมาชิก </label>
                @if (!isView) {
                <app-input-number
                  [control]="form.controls['member_price']"
                  placeholder="0.00"
                  [min]="0"
                  unit="บาท"
                  [disabled]="true">
                  @if (form.controls['member_price'].hasError('required')) {
                  <ng-container error>กรุณากรอกข้อมูล</ng-container>
                  } @else if (form.controls['member_price'].hasError('max')) {
                  <ng-container error>กรุณากรอกไม่เกิน 1,000,000,000</ng-container>
                  } @else if (form.controls['member_price'].hasError('formApi')) {
                  <ng-container error
                    >{{ form.controls['member_price'].errors?.['formApi']}}</ng-container
                  >
                  }
                </app-input-number>
                } @else {
                <div class="title-blue label-view">
                  {{ formatPrice(dataManage?.member_price || 0) }} บาท
                </div>
                }
              </div>
            </div>
          </div>

          <div class="col-12 col-lg-6">
            <label class="form-label {{ isView && 'neutral-500'}}">
              รายละเอียดสินค้า (ภาษาไทย)
            </label>

            @if (!isView) {
            <app-text-area [control]="form.controls['description']">
              @if (form.controls['description'].hasError('maxlength')) {
              <ng-container error>กรุณากรอกข้อมูลไม่เกิน 1000 ตัวอักษร</ng-container>
              } @else if (form.controls['description'].hasError('formApi')) {
              <ng-container error
                >{{ form.controls['description'].errors?.['formApi']}}</ng-container
              >
              }
            </app-text-area>
            } @else {
            <div class="label-view">{{ dataManage?.description || '-' }}</div>
            }
          </div>

          <div class="col-12 col-lg-6">
            <label class="form-label {{ isView && 'neutral-500'}}">
              รายละเอียดสินค้า (ภาษาอังกฤษ)
            </label>

            @if (!isView) {
            <app-text-area [control]="form.controls['description_en']">
              @if (form.controls['description_en'].hasError('maxlength')) {
              <ng-container error>กรุณากรอกข้อมูลไม่เกิน 1000 ตัวอักษร</ng-container>
              } @else if (form.controls['description_en'].hasError('formApi')) {
              <ng-container error
                >{{ form.controls['description_en'].errors?.['formApi']}}</ng-container
              >
              }
            </app-text-area>
            } @else {
            <div class="label-view">{{ dataManage?.description_en || '-' }}</div>
            }
          </div>

          @if(service.companyId == COMPANY_ID.BHB){
          <div class="col-12 col-lg-6">
            <label class="form-label {{ isView && 'neutral-500'}}"> บาร์โค้ด </label>

            @if (!isView) {
            <app-input [control]="form.controls['barcode']">
              @if (form.controls['barcode'].hasError('maxlength')) {
              <ng-container error>กรุณากรอกข้อมูลไม่เกิน 100 ตัวอักษร</ng-container>
              } @else if (form.controls['barcode'].hasError('formApi')) {
              <ng-container error>{{ form.controls['barcode'].errors?.['formApi']}}</ng-container>
              } @else if (form.controls['size'].hasError('maxlength')) {
              <ng-container error>กรุณากรอกข้อมูลไม่เกิน 250 ตัวอักษร</ng-container>
              } @else if (form.controls['barcode'].hasError('formApi')) {
              <ng-container error>{{ form.controls['barcode'].errors?.['formApi']}}</ng-container>
              }
            </app-input>
            } @else {
            <div class="label-view">{{ dataManage?.barcode || '-' }}</div>
            }
          </div>
          }
        </div>

        <div class="title-blue mt-3">รายละเอียดเพิ่มเติม</div>
        <div class="row mt-3">
          <div class="col-12 col-lg-6">
            <label class="form-label {{ isView && 'neutral-500'}}"> สินค้าสำหรับ </label>
            @if (!isView) {
            <app-select
              [control]="form.controls['gender_id']"
              [datas]="list.gender"
              fieldValue="id"
              placeholder="เลือก">
              <ng-template #optionRef let-option> {{ option.name }} </ng-template>
            </app-select>
            } @else {
            <div class="label-view">{{ dataManage?.gender?.name || '-' }}</div>
            }
          </div>
          <div class="col-12 col-lg-6">
            <label class="form-label {{ isView && 'neutral-500'}}"> แท็กสินค้า </label>
            @if (!isView) {
            <app-input-tag [control]="form.controls['tags']"></app-input-tag>
            } @else {
            <div class="d-flex flex-wrap gap-2">
              @for (item of dataManage?.tags; track $index) {
              <label class="tag-name"> {{ item.tag.name }} </label>
              }
              <!-- ~ -->
              @if (!dataManage?.tags?.length) { - }
            </div>
            }
          </div>
          <div class="col-12 col-lg-6">
            <label class="form-label {{ isView && 'neutral-500'}}"> คู่มือขนาดสินค้า </label>
            @if (!isView) {
            <app-input
              [control]="form.controls['cms_size_guide_name']"
              [disabled]="true"></app-input>
            } @else {
            <div class="label-view">{{ form.controls['cms_size_guide_name'].value || '-' }}</div>
            }
          </div>

          @if(service.companyId == COMPANY_ID.PANIA){
          <div class="col-12 col-lg-6">
            <label class="form-label {{ isView && 'neutral-500'}}"> การวัดขนาดสินค้า </label>
            @if (!isView) {
            <app-input
              [control]="form.controls['cms_size_measurement_name']"
              [disabled]="true"></app-input>
            } @else {
            <div class="label-view">
              {{ form.controls['cms_size_measurement_name'].value || '-' }}
            </div>
            }
          </div>
          }
        </div>

        <div class="title-blue mt-3">การร่วมรายการ</div>
        <div class="mt-3">
          @if (!isView) {
          <label class="form-label {{ isView && 'neutral-500'}}">
            สินค้าเข้าร่วมรายการสะสมกะรัต
          </label>
          <div [formGroup]="form">
            <mat-checkbox formControlName="is_carat">เข้าร่วมรายการ</mat-checkbox>
          </div>
          } @else {
          <div class="label-view">
            <div class="d-flex align-items-center">
              @if (dataManage?.is_carat) {
              <span class="icon material-symbols-outlined fill green me-2">check_circle</span>
              สินค้าเข้าร่วมรายการสะสมกะรัต } @else {
              <span class="icon material-symbols-outlined fill red me-2">cancel</span>
              สินค้าไม่เข้าร่วมรายการสะสมกะรัต }
            </div>
          </div>
          }
        </div>

        <div class="title-blue mt-4">การใช้งาน</div>
        <div class="row">
          <div class="col-12 col-lg-6">
            <div class="mt-2">
              <label class="form-label {{ isView && 'neutral-500'}}"> สถานะการใช้งาน </label>
              <div>
                @if (!isView) {
                <mat-slide-toggle
                  hideIcon="true"
                  color="primary"
                  class="slide-toggle"
                  [formControl]="form.controls.is_active">
                  ใช้งาน
                </mat-slide-toggle>
                }@else {
                <div class="label-view">
                  <app-status-user [status]="dataManage?.is_active ? 'active' : 'inactive'">
                    {{ form.controls['is_active'].value ? 'ใช้งาน' : 'ไม่ใช้งาน' }}
                  </app-status-user>
                </div>
                }
              </div>
            </div>
          </div>

          @if(service.companyId == COMPANY_ID.BHB){
          <div class="col-12 col-lg-6">
            <div class="mt-2">
              <label class="form-label {{ isView && 'neutral-500'}}">
                สถานะการนำสินค้าขึ้นเว็บ
              </label>
              <div>
                @if (!isView) {
                <mat-slide-toggle
                  hideIcon="true"
                  color="primary"
                  class="slide-toggle"
                  [formControl]="form.controls.is_launch">
                  นำขึ้นเว็บ
                </mat-slide-toggle>
                }@else {
                <div class="label-view">
                  <app-status-user [status]="dataManage?.is_launch ? 'active' : 'inactive'">
                    นำขึ้นเว็บ
                  </app-status-user>
                </div>
                }
              </div>
            </div>
          </div>
          }
        </div>

        <div class="mt-4"></div>
        <div class="d-flex flex-wrap gap-3">
          <div class="card-relate" (click)="openModalAddModel()">
            <div class="title">โมเดล</div>

            <div class="d-flex align-items-center gap-2 color--brand-blue-700--f p-2 text-nowrap">
              @if (isView) {
              <u> ดูข้อมูล </u>
              } @else {
              <!-- ~ -->
              @if (form.controls.file_model.value || form.controls.model_size.value) {
              <span class="icon icon-brand-blue-700 material-symbols-outlined fill">edit</span>
              แก้ไขข้อมูล } @else {
              <span class="icon icon-brand-blue-700 material-symbols-outlined fill">add_circle</span
              >เพิ่มข้อมูล }
              <!-- ~ -->
              }
            </div>
          </div>
          <div class="card-relate" (click)="isRelatePage.show = true;isRelatePage.page = 1">
            <div class="title">Complete the look</div>

            <div class="d-flex align-items-center gap-2 color--brand-blue-700--f p-2 text-nowrap">
              @if (isView) {
              <u> ดูข้อมูล </u>
              } @else {
              <!-- ~ -->
              @if (product_complete_looks.length) {
              <span class="icon icon-brand-blue-700 material-symbols-outlined fill">edit</span>
              แก้ไขข้อมูล ({{ product_complete_looks.length }}) } @else {
              <span class="icon icon-brand-blue-700 material-symbols-outlined fill">add_circle</span
              >เพิ่มข้อมูล }
              <!-- ~ -->
              }
            </div>
          </div>
          <div class="card-relate" (click)="isRelatePage.show = true;isRelatePage.page = 2">
            <div class="title">You may like color</div>

            <div class="d-flex align-items-center gap-2 color--brand-blue-700--f p-2 text-nowrap">
              @if (isView) {
              <u> ดูข้อมูล </u>
              } @else {
              <!-- ~ -->
              @if (product_may_like_colors.length) {
              <span class="icon icon-brand-blue-700 material-symbols-outlined fill">edit</span>
              แก้ไขข้อมูล ({{ product_may_like_colors.length }}) } @else {
              <span class="icon icon-brand-blue-700 material-symbols-outlined fill">add_circle</span
              >เพิ่มข้อมูล }
              <!-- ~ -->
              }
            </div>
          </div>
          <div class="card-relate" (click)="isRelatePage.show = true;isRelatePage.page = 3">
            <div class="title">You may like style</div>

            <div class="d-flex align-items-center gap-2 color--brand-blue-700--f p-2 text-nowrap">
              @if (isView) {
              <u> ดูข้อมูล </u>
              } @else {
              <!-- ~ -->
              @if (product_may_like_styles.length) {
              <span class="icon icon-brand-blue-700 material-symbols-outlined fill">edit</span>
              แก้ไขข้อมูล ({{ product_may_like_styles.length }}) } @else {
              <span class="icon icon-brand-blue-700 material-symbols-outlined fill">add_circle</span
              >เพิ่มข้อมูล }
              <!-- ~ -->
              }
            </div>
          </div>
        </div>
      </div>
    </div>

    @if (isCreate) {
    <div class="line"></div>
    <div class="title-blue">ข้อมูลสต๊อกสินค้า</div>
    <div class="row mt-3">
      <div class="col-12 col-lg-4">
        <label class="form-label"> สต๊อก </label>
        <app-input-number
          [control]="form.controls['stock']"
          placeholder="0"
          [min]="0"
          [decimal]="0">
          @if (form.controls['stock'].hasError('max')) {
          <ng-container error>กรุณากรอกไม่เกิน 1,000,000</ng-container>
          }@else if (form.controls['stock'].hasError('formApi')) {
          <ng-container error>{{ form.controls['stock'].errors?.['formApi']}}</ng-container>
          }
        </app-input-number>
      </div>
      <div class="col-12 col-lg-4">
        <label class="form-label"> ผู้ขาย </label>
        <app-select
          [control]="form.controls['supplier_id']"
          [datas]="list.supplier"
          fieldValue="id"
          [search]="['name']"
          placeholder="เลือก"
          empty="ไม่มีผู้ขาย">
          <ng-template #optionRef let-option> {{ option.name }} </ng-template>
          @if (form.controls['supplier_id'].hasError('formApi')) {
          <ng-container error>{{ form.controls['supplier_id'].errors?.['formApi']}}</ng-container>
          }
        </app-select>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-4">
        <label class="form-label"> ต้นทุนต่อหน่วย </label>
        <app-input-number
          [control]="form.controls['cost_price']"
          placeholder="0.00"
          [min]="0"
          unit="บาท">
          @if (form.controls['cost_price'].hasError('max')) {
          <ng-container error>กรุณากรอกไม่เกิน 1,000,000,000</ng-container>
          }@else if (form.controls['cost_price'].hasError('formApi')) {
          <ng-container error>{{ form.controls['cost_price'].errors?.['formApi']}}</ng-container>
          }
        </app-input-number>
      </div>
      <div class="col-12 col-lg-4">
        <label class="form-label"> ต้นทุนรวม </label>
        <app-input
          [value]="formatPrice(totalCostPrice() || 0)"
          placeholder="0.00"
          unit="บาท"
          [disabled]="true"></app-input>
      </div>
    </div>
    }
    <div class="line"></div>
    <div class="title-blue">สินค้าย่อย</div>
    @if (isCreate || (isEdit && this.dataManage && !this.dataManage.details.length)) {
    <div [formGroup]="form">
      <mat-checkbox formControlName="is_sub_product" (change)="changeIsSubProduct($event)"
        >เพิ่มสินค้าย่อย</mat-checkbox
      >
    </div>
    } @if (form.controls['is_sub_product'].value) {

    <div class="card-relate mt-3" (click)="openModalAddColor()">
      <div class="title">ข้อมูลสีสินค้า</div>

      <div class="d-flex align-items-center gap-2 color--brand-blue-700--f p-2 text-nowrap">
        @if (isView) {
        <u> ดูข้อมูล </u>
        } @else {
        <!-- ~ -->
        @if (product_colors.length) {
        <span class="icon icon-brand-blue-700 material-symbols-outlined fill">edit</span>
        แก้ไขข้อมูล } @else {
        <span class="icon icon-brand-blue-700 material-symbols-outlined fill">add_circle</span
        >เพิ่มข้อมูล }
        <!-- ~ -->
        }
      </div>
    </div>

    <div class="d-flex gap-2 align-items-center mt-3">
      <span class="icon-16 color--yellow-500--f material-symbols-outlined fill">info</span>
      <span class="color--neutral-500--f">สินค้าย่อยลำดับแรกจะเป็นสินค้าโปรโมตหน้าเว็บ</span>
    </div>

    <div class="table-responsive-detail mt-3">
      <table class="table table-fixed-column table-detail">
        <thead>
          <tr>
            @if (!isView) {
            <th width="65">ลำดับ</th>
            }
            <th width="90">รูปสินค้า</th>
            <th width="210">รหัสสินค้า @if (!isView) {<span class="text-danger">*</span>}</th>
            @if(service.companyId == COMPANY_ID.BHB){
            <th width="210">รหัสสินค้าจริง</th>
            }
            <th width="340">ชื่อสินค้า @if (!isView) {<span class="text-danger">*</span>}</th>
            <th width="250">
              <div class="d-flex align-items-center">
                สี @if (!isView) {<span class="text-danger mx-2"> * </span>
                <span
                  class="icon icon-blue material-symbols-outlined fill color--neutral-400 cursor-pointer"
                  matTooltip="กรุณาตั้งค่าข้อมูลสีสินค้า"
                  matTooltipPosition="above"
                  matTooltipClass="custom-tooltip"
                  >info </span
                >}
              </div>
            </th>
            <th width="140">ขนาด @if (!isView) {<span class="text-danger">*</span>}</th>
            <th width="140">ราคาขาย (บาท) @if (!isView) {<span class="text-danger">*</span>}</th>
            <th width="200">ราคาสมาชิก (บาท)</th>
            @if (!isView) {
            <!-- ~ -->
            @if (isCreate) {
            <th width="100">สต๊อก</th>
            <th width="160">ต้นทุนต่อหน่วย (บาท)</th>
            <th width="150">ต้นทุนรวม (บาท)</th>
            } }
            <th width="200">บาร์โค้ด</th>
            <th width="180">You may like color</th>

            <!-- ~ -->
            @if (!isView) {
            <th width="80"></th>
            }
            <!-- ~ -->
            @if (!isView && sub_product_list.controls.length > 1) {
            <th width="80"></th>
            }
          </tr>
        </thead>
        <tbody
          id="all"
          cdkDropList
          [cdkDropListData]="sub_product_list.controls"
          (cdkDropListDropped)="drop($event)"
          [cdkDropListDisabled]="isView"
          class="drag-list mt-3 w-100">
          @for (item of sub_product_list.controls; track item; let index = $index;) {
          <tr class="drag-box mb-2" [class.tr-border-bottom-highlight]="index == 0">
            @if (!isView) {
            <td width="65">
              <!-- <div class="pb-3">{{index + 1}}</div> -->
              <div class="pre-box">
                <button
                  matRipple
                  cdkDragHandle
                  cdkDrag
                  [cdkDragData]="item"
                  class="btn btn-transparent-outline me-1">
                  <span class="icon icon-dark-grey material-symbols-outlined fill">
                    drag_indicator
                  </span>
                </button>
              </div>
            </td>
            }
            <td width="90">
              @if (isView) {
              <div class="image-product has-image" (click)="openModalUploadImage(item)">
                <img class="img-table" [src]="item.value.cover?.url || imgStockDefault" />
              </div>
              } @else if (!!item.value.cover) {
              <div class="image-product has-image" (click)="openModalUploadImage(item)">
                <img class="img-table" [src]="item.value.cover.url" />
              </div>
              } @else {
              <div class="image-product" (click)="openModalUploadImage(item)">
                <span class="icon icon-blue material-symbols-outlined fill">add_circle</span>
              </div>
              }
            </td>
            <td width="210">
              @if (!isView) {
              <app-input [control]="item.controls['code']" (onChange)="validateCustom()">
                @if (item.controls['code'].hasError('required')) {
                <ng-container error>กรุณากรอกข้อมูล</ng-container>
                } @else if (item.controls['code'].hasError('maxlength')) {
                <ng-container error>กรุณากรอกข้อมูลไม่เกิน 15 ตัวอักษร</ng-container>
                } @else if (item.controls['code'].hasError('duplicate')) {
                <ng-container error>รหัสสินค้าซ้ำกัน</ng-container>
                } @else if (item.controls['code'].hasError('formApi')) {
                <ng-container error>{{ item.controls['code'].errors?.['formApi']}}</ng-container>
                }
              </app-input>
              } @else { {{ item.value.code }} }
            </td>
            @if(service.companyId == COMPANY_ID.BHB){
            <td>
              @if (!isView) {
              <app-input [control]="item.controls['real_code']" (onChange)="validateCustom()">
                @if (item.controls['real_code'].hasError('formApi')) {
                <ng-container error>
                  {{ item.controls['real_code'].errors?.['formApi']}}
                </ng-container>
                } @else if (item.controls['real_code'].hasError('maxlength')) {
                <ng-container error>กรุณากรอกข้อมูลไม่เกิน 250 ตัวอักษร</ng-container>
                }
              </app-input>
              } @else { {{ item.value.real_code || '-' }} }
            </td>
            }
            <td width="340">
              @if (!isView) {
              <app-input [control]="item.controls['name']">
                @if (item.controls['name'].hasError('required')) {
                <ng-container error>กรุณากรอกข้อมูล</ng-container>
                } @else if (item.controls['name'].hasError('maxlength')) {
                <ng-container error>กรุณากรอกข้อมูลไม่เกิน 250 ตัวอักษร</ng-container>
                } @else if (item.controls['name'].hasError('formApi')) {
                <ng-container error>{{ item.controls['name'].errors?.['formApi']}}</ng-container>
                }
              </app-input>
              } @else { {{ item.value.name }} }
            </td>
            <td width="250">
              @if (!isView) {
              <app-select
                [control]="item.controls['product_color_uuid']"
                [datas]="product_colors"
                fieldValue="uuid"
                placeholder="เลือก"
                empty="ไม่มีข้อมูล">
                <ng-template #optionRef let-option> {{ option.color }} </ng-template>
                @if (item.controls['product_color_uuid'].hasError('required')) {
                <ng-container error>กรุณาเลือกข้อมูล</ng-container>
                } @else if (item.controls['product_color_uuid'].hasError('formApi')) {
                <ng-container error
                  >{{ item.controls['product_color_uuid'].errors?.['formApi']}}</ng-container
                >
                }
              </app-select>
              } @else { {{ item.value.color || '-' }} }
            </td>
            <td width="110">
              @if (!isView) {
              <app-input [control]="item.controls['size']">
                @if (item.controls['size'].hasError('required')) {
                <ng-container error>กรุณากรอกข้อมูล</ng-container>
                } @else if (item.controls['size'].hasError('maxlength')) {
                <ng-container error>กรุณากรอกข้อมูลไม่เกิน 15 ตัวอักษร</ng-container>
                } @else if (item.controls['size'].hasError('formApi')) {
                <ng-container error>{{ item.controls['size'].errors?.['formApi']}}</ng-container>
                }
              </app-input>
              } @else { {{ item.value.size || '-' }} }
            </td>
            <td width="140">
              @if (!isView) {
              <app-input-number [control]="item.controls['selling_price']" [min]="0">
                @if (item.controls['selling_price'].hasError('required')) {
                <ng-container error>กรุณากรอกข้อมูล</ng-container>
                } @else if (item.controls['selling_price'].hasError('max')) {
                <ng-container error>กรุณากรอกไม่เกิน 1,000,000,000</ng-container>
                } @else if (item.controls['selling_price'].hasError('formApi')) {
                <ng-container error
                  >{{ item.controls['selling_price'].errors?.['formApi']}}</ng-container
                >
                }
              </app-input-number>
              } @else {
              <div class="title-blue">{{ formatPrice(item.value.selling_price || 0) }}</div>
              }
            </td>
            <!-- ราคาสมาชิก  -->
            <td width="200">
              @if (!isView) {
              <app-input-number [control]="item.controls['member_price']">
                @if (item.controls['member_price'].hasError('required')) {
                <ng-container error>กรุณากรอกข้อมูล</ng-container>
                } @else if (item.controls['member_price'].hasError('max')) {
                <ng-container error>กรุณากรอกไม่เกิน 1,000,000,000</ng-container>
                } @else if (item.controls['member_price'].hasError('formApi')) {
                <ng-container error
                  >{{ item.controls['member_price'].errors?.['formApi']}}</ng-container
                >
                }
              </app-input-number>
              } @else {
              <div class="title-blue">{{ formatPrice(item.value.member_price || 0) }}</div>
              }
            </td>
            @if (!isView) {
            <!-- ~ -->
            @if (isCreate) {
            <td width="100">
              <app-input-number [control]="item.controls['stock']" [min]="0">
                @if (item.controls['stock'].hasError('max')) {
                <ng-container error>กรุณากรอกไม่เกิน 1,000,000</ng-container>
                } @else if (item.controls['stock'].hasError('formApi')) {
                <ng-container error>{{ item.controls['stock'].errors?.['formApi']}}</ng-container>
                }
              </app-input-number>
            </td>
            <td width="160">
              <app-input-number [control]="item.controls['cost_price']" [min]="0">
                @if (item.controls['cost_price'].hasError('max')) {
                <ng-container error>กรุณากรอกไม่เกิน 1,000,000,000</ng-container>
                } @else if (item.controls['cost_price'].hasError('formApi')) {
                <ng-container error
                  >{{ item.controls['cost_price'].errors?.['formApi']}}</ng-container
                >
                }
              </app-input-number>
            </td>
            <td width="150">
              <app-input
                [value]="formatPrice(totalCostPriceDetail(index))"
                [disabled]="true"></app-input>
            </td>
            } }

            <td width="200">
              @if (!isView) {
              <app-input [control]="item.controls['barcode']">
                @if (item.controls['barcode'].hasError('maxlength')) {
                <ng-container error>กรุณากรอกข้อมูลไม่เกิน 250 ตัวอักษร</ng-container>
                } @else if (item.controls['barcode'].hasError('formApi')) {
                <ng-container error>{{ item.controls['barcode'].errors?.['formApi']}}</ng-container>
                }
              </app-input>
              } @else { {{ item.value.barcode || '-' }} }
            </td>
            <td width="180">
              <button
                matRipple
                class="btn btn-outline-secondary py-1 mb-3"
                (click)="openDetailMayLikeColors(item)">
                @if (isView) { ดูข้อมูล } @else {
                <!-- ~ -->
                @if (item.controls['product_may_like_colors'].value?.length) {
                <span class="icon icon-brand-blue-700 material-symbols-outlined fill">edit</span>
                แก้ไขข้อมูล ({{ item.controls['product_may_like_colors'].value?.length }}) } @else {
                <span class="icon icon-brand-blue-700 material-symbols-outlined fill"
                  >add_circle</span
                >เพิ่มข้อมูล }
                <!-- ~ -->
                }
              </button>
            </td>

            @if (!isView) {
            <td width="80">
              <button
                matRipple
                class="btn btn-transparent-outline py-1 mb-3"
                (click)="duplicateSubProduct(item, index)">
                <span class="icon icon-brand-blue-700 material-symbols-outlined fill"
                  >content_copy</span
                >
              </button>
            </td>
            }
            <!-- ~ -->
            @if (!isView && sub_product_list.controls.length > 1) {
            <td width="80">
              @if (index != 0) {
              <button
                matRipple
                class="btn btn-transparent-outline py-1 mb-3"
                (click)="delSubProduct(item, index)">
                <span class="icon icon-20 material-symbols-outlined fill text-danger">delete</span>
              </button>
              }
            </td>
            }
          </tr>
          }
        </tbody>
      </table>
    </div>
    @if (!isView) {
    <button matRipple class="btn btn-outline-secondary mt-3" (click)="addSubProduct()">
      <span class="icon material-symbols-outlined fill">add_circle</span>
      เพิ่มสินค้าย่อย
    </button>
    }
    <!-- ~ -->
    }
    <!-- ~ -->
    @if (!isView && profile.permissions.checkAccess([APP_PERMISSION.PRODUCT], [ACTION_ACCESS.CREATE,
    ACTION_ACCESS.UPDATE])) {
    <div class="footer-button-wrapper d-flex justify-content-end mt-3">
      <button matRipple class="btn btn-outline-secondary btn-md" (click)="toList()">ยกเลิก</button>
      <button matRipple class="btn btn-primary btn-md" (click)="onConfirm()">บันทึก</button>
    </div>
    }
  </div>

  @if(isView && dataManage) {
  <app-product-stock
    [product]="dataManage"
    [imageCover]="imageCover"
    (done)="productStockLog.getProductStockLogList()"></app-product-stock>

  <app-product-seller-list [product]="dataManage"></app-product-seller-list>

  <app-product-stock-log #productStockLog [product]="dataManage"></app-product-stock-log>

  <app-product-return-log #productReturnLog [product]="dataManage"></app-product-return-log>
  } @if (isView) {
  <div class="line mt-4"></div>
  <div class="d-flex justify-content-end mt-3">
    <button matRipple class="btn btn-outline-secondary min-w-100px" [routerLink]="['/product']">
      กลับสู่หน้าสินค้า
    </button>
  </div>
  } }@else{
  <!-- ~ -->
  @if (isRelatePage.page == 1) {
  <app-complete-the-look
    [productId]="dataManage?.id"
    [isEdit]="isEdit || isCreate"
    [product_complete_looks]="product_complete_looks"
    (onClose)="isRelatePage.show = $event"
    (onConfirm)="product_complete_looks = $event" />
  } @else if (isRelatePage.page == 2) {
  <app-you-may-like-color
    [productId]="dataManage?.id"
    [isEdit]="isEdit || isCreate"
    [product_may_like_colors]="product_may_like_colors"
    [color]="form.controls.color.value"
    (onClose)="isRelatePage.show = $event"
    (onConfirm)="product_may_like_colors = $event" />
  } @else if (isRelatePage.page == 3) {
  <app-you-may-like-style
    [productId]="dataManage?.id"
    [isEdit]="isEdit || isCreate"
    [product_may_like_styles]="product_may_like_styles"
    (onClose)="isRelatePage.show = $event"
    (onConfirm)="product_may_like_styles = $event" />
  } @else if (isRelatePage.page == 4) {
  <app-you-may-like-color
    [productId]="dataManage?.id"
    [isEdit]="isEdit || isCreate"
    [product_may_like_colors]="product_detail_may_like_colors?.value || []"
    [color]="product_detail_color"
    (onClose)="isRelatePage.show = $event"
    (onConfirm)="product_detail_may_like_colors?.setValue($event)" />
  }
  <!-- ~ -->
  }
</div>
