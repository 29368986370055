<div class="app-header">
  <div class="box-header d-flex align-items-center justify-content-between gap-4">
    <div class="d-flex align-items-center gap-4">
      <div class="d-flex">
        <img src="/assets/preview-bhb/logos/logo.svg" class="logo" />
      </div>

      @for (item of genders; track $index) {
      <div class="text-base-regular cursor-pointer color--Primary-Black">
        {{ item['name_' + lang] }}
      </div>
      }

      <div class="box-search d-flex align-items-center gap-2">
        <img src="/assets/preview-bhb/icons/icon-search-gray.svg" width="20" height="20" />
        <span> {{ lang== 'en' ? 'Search' : 'ค้นหา' }} </span>
      </div>
    </div>

    <div class="d-flex align-items-center gap-3">
      <div class="d-flex align-items-center cursor-pointer pb-1 position-relative">
        <!-- <span class="s-badge">3</span> -->
        <img src="/assets/preview-bhb/icons/icon-favorite.svg" width="20" height="20" />
      </div>
      <div class="d-flex align-items-center cursor-pointer pb-1 position-relative">
        <!-- <span class="s-badge">3</span> -->
        <img src="/assets/preview-bhb/icons/icon-bag.svg" width="20" height="20" />
      </div>

      <div class="line-y"></div>

      <div class="text-base-regular cursor-pointer">
        {{lang == 'th' ? 'สกุลเงิน' : 'Currency'}}: ฿ | THB
      </div>

      <div class="lang d-flex align-items-center gap-1 cursor-pointer">
        <img
          src="/assets/preview-bhb/images/lang-th.png"
          width="20"
          height="20"
          [style]="{ 'margin-top': '-3px' }" />
        <span class="text-uppercase">TH</span>
      </div>

      @if (isLogin) {
      <div class="d-flex align-items-center gap-2">
        <div class="d-flex align-items-center gap-2 cursor-pointer">
          <img
            src="/assets/preview-bhb/icons/icon-user.svg"
            width="20"
            height="20"
            [style]="{ 'margin-bottom': '2px' }" />
          <span class="text-base-ragular"> Araya </span>
        </div>
        <label class="tag-tier"> {{lang == 'th' ? 'สมาชิก' : 'Member'}} </label>
      </div>
      } @else {
      <button class="button-logout-bhb">{{lang == 'th' ? 'ออกจากระบบ' : 'Log in'}}</button>
      }
    </div>
  </div>

  <div class="sub-header d-flex align-items-center gap-3">
    <div class="text-base-regular router-link color--Error-Dark">Sales</div>
    <div class="text-base-regular router-link color--Primary-Black">
      {{lang == 'th' ? 'แบรนด์' : 'Brand'}}
    </div>
    @for (item of categories; track $index) {
    <div class="text-base-regular router-link color--Primary-Black">{{ item['name_' + lang] }}</div>
    }
  </div>
</div>
