<div mat-dialog-title class="dialog-title">
  <div class="dialog-title-head">{{data.title}}</div>
  <button class="btn btn-transparent btn-icon" (click)="onClose()">
    <img src="../assets/images/icons/icon-close.svg" />
  </button>
</div>

<mat-dialog-content>
  <div class="modal-content">
    <div class="color--neutral-950 font-14">ระบุเหตุผล</div>
    <app-text-area [control]="reason" maxlength="300">
      @if (reason.hasError('required')) {
      <ng-container error>กรุณากรอกข้อมูล</ng-container>
      } @else if (reason.hasError('formApi')) {
      <ng-container error>{{ reason.errors?.['formApi']}}</ng-container>
      }
    </app-text-area>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="footer-button-wrapper d-flex justify-content-end w-100">
    <button matRipple class="btn btn-outline-danger btn-md" (click)="onClose()">ยกเลิก</button>
    <button matRipple class="btn btn-danger btn-md" (click)="onConfirm()">ลบ</button>
  </div>
</mat-dialog-actions>
