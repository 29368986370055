import { Component, OnInit } from '@angular/core'
import { FormControl } from '@angular/forms'
import { MatBadgeModule } from '@angular/material/badge'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatDialog } from '@angular/material/dialog'
import { MatIconModule } from '@angular/material/icon'
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatTabsModule } from '@angular/material/tabs'
import { MatTooltipModule } from '@angular/material/tooltip'
import { ActivatedRoute, Router } from '@angular/router'
import { CheckboxAllTableComponent } from '../../components/checkbox-table/checkbox-all-table.component'
import { InputNumberComponent } from '../../components/input-number/input-number'
import { ModalDeleteComponent } from '../../components/modal-delete/modal-delete'
import { ModalDownloadFileComponent } from '../../components/modal-download-file/modal-download-file'
import { ModalErrorComponent } from '../../components/modal-error/modal-error'
import { ModalLeaveComponent } from '../../components/modal-leave/modal-leave'
import { SelectSortComponent } from '../../components/select-sort/select-sort'
import { CustomSnackBar } from '../../components/snackbar/snackbar'
import { ACTION_ACCESS, APP_PERMISSION, FilterTableProduct, Loading, Profile } from '../../globals'
import {
  Ascending,
  COMPANY_ID,
  DownloadFileBlob,
  Moment,
  Pagination,
  channelId,
  formatPrice,
  formatPriceAutoDecimal,
  productColorSizeText,
  sortList,
} from '../../helpers'
import { imports } from '../../imports'
import { ProductService } from '../../services'
import { Service } from '../../services/service'
import { ModalFilterProductComponent } from './modal-filter-product/modal-filter-product'
import { ModalFilterWishListComponent } from './modal-filter-wish-list/modal-filter-wish-list'
import { ModalUploadFileComponent } from './modal-upload-file/modal-upload-file'
import { ModalAddStockComponent } from './product-detail/product-stock/modal-add-stock/modal-add-stock'
import { SearchFilterComponent } from './search-filter/search-filter'
import { TabProductsDiscountComponent } from './tab-products-discount/tab-products-discount'
import { TabProductsFlashSalesComponent } from './tab-products-flash-sales/tab-products-flash-sales'
import { TabProductsWishListComponent } from './tab-products-wish-list/tab-products-wish-list'
import { CheckboxTableComponent } from '../../components/checkbox-table/checkbox-table.component'
import { ModalInvalidProeductToWebComponent } from './modal-invalid-proeduct-to-web copy/modal-invalid-proeduct-to-web'
import { ModalSuccessProeductToWebComponent } from './modal-success-proeduct-to-web/modal-success-proeduct-to-web'

@Component({
  selector: 'app-product',
  standalone: true,
  imports: [
    ...imports,
    CheckboxTableComponent,
    CheckboxAllTableComponent,
    MatPaginatorModule,
    MatTabsModule,
    SelectSortComponent,
    MatTooltipModule,
    MatIconModule,
    InputNumberComponent,
    TabProductsDiscountComponent,
    TabProductsFlashSalesComponent,
    TabProductsWishListComponent,
    MatBadgeModule,
    SearchFilterComponent,
    MatCheckboxModule,
  ],
  templateUrl: './product.html',
  styleUrls: ['./product.scss'],
})
export class ProductComponent implements OnInit {
  readonly APP_PERMISSION = APP_PERMISSION
  readonly ACTION_ACCESS = ACTION_ACCESS
  readonly Moment = Moment
  readonly formatPrice = formatPrice
  readonly formatPriceAutoDecimal = formatPriceAutoDecimal
  readonly channelId = channelId
  readonly COMPANY_ID = COMPANY_ID
  readonly productColorSizeText = productColorSizeText

  tab = 0
  sortControl = new FormControl(sortList[0].value)
  readonly paginationProduct = new Pagination({
    sortName: sortList[0].value.sortName,
    sortBy: sortList[0].value.sortBy as 'asc' | 'desc',
  })
  rerender = false
  pageNumber = new FormControl(1)
  productList: any[] = []
  itemChecked = new Set<any>()
  itemCheckedData = new Map()

  //สินค้าส่วนลด
  itemCheckedDiscount = new Set<any>()
  itemCheckedDataDiscount = new Map()
  onSearchDiscount = false

  //สินค้า Flash Sales
  itemCheckedFlashSales = new Set<any>()
  itemCheckedDataFlashSales = new Map()
  onSearchFlashSales = false

  // Wist List
  itemCheckedWistList = new Set<any>()
  itemCheckedDataWistList = new Map()
  onSearchWistList = false
  notificationWishList = false
  showFilterMinStock = false
  isFilterMinStock = false
  readonly min_stock = 2

  list = {
    sort: sortList,
  }

  get checkboxValues() {
    return [...this.itemChecked.values()]
  }

  get checkboxLaunch() {
    return this.checkboxValues.filter((value: string) => value && value.includes('is_launch=false'))
  }

  get checkboxUnNew() {
    return this.checkboxValues.filter((value: string) => value && value.includes('is_new=true'))
  }

  get checkboxNew() {
    return this.checkboxValues.filter((value: string) => value && value.includes('is_new=false'))
  }

  constructor(
    public profile: Profile,
    public router: Router,
    public route: ActivatedRoute,
    public dialog: MatDialog,
    public loading: Loading,
    public customSnackBar: CustomSnackBar,
    public productService: ProductService,
    public service: Service,
    public filterTableProduct: FilterTableProduct
  ) {}

  ngOnInit(): void {
    this.itemChecked.values()
    this.route.queryParams.subscribe((params: any) => {
      if (params.tab) {
        this.tab = params.tab
      }
    })
    if (this.tab == 0) {
      this.getProductList()
    }

    this.getNotificationWishList()
  }

  onChangePage(reset = false) {
    if (this.pageNumber.value) {
      const page = this.pageNumber.value - 1
      if (this.paginationProduct.data.pageIndex != page) {
        this.paginationProduct.data.pageIndex = page
        this.getProductList()
      }
    } else if (reset) {
      this.pageNumber.setValue(this.paginationProduct.data.pageIndex + 1)
      this.rerender = true
      setTimeout(() => {
        this.rerender = false
      })
    }
  }

  checkFilterMinStock(dataFilter: any) {
    const payload = {
      filter: {
        min_stock: this.min_stock,
        ...dataFilter.filter,
      },
    }

    return new Promise(rev => {
      this.loading.start()
      this.productService.checkMinStock(payload).subscribe((res: any) => {
        this.showFilterMinStock = !!res.data
        if (!this.showFilterMinStock) {
          this.isFilterMinStock = false
        }
        this.loading.stop()

        rev(null)
      })
    })
  }

  async getProductList() {
    const dataFilter = this.filterTableProduct.getData()
    if (this.isFilterMinStock) {
      await this.checkFilterMinStock(dataFilter)
    } else {
      this.checkFilterMinStock(dataFilter)
    }

    const pagination = this.paginationProduct.get()
    if (this.isFilterMinStock) {
      dataFilter.filter.min_stock = this.min_stock
    }
    const payload = {
      ...pagination,
      ...dataFilter,
    }

    this.loading.start()
    this.productService.getProductList(payload).subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          this.productList = res.data.records.map((product: any) => {
            Ascending(product.details, 'code')

            return {
              ...product,
              checkboxValue: `${product.id},is_launch=${product.is_launch},is_new=${product.is_new}`,
              isShowStock: !!(
                !product.details.length ||
                this.getStock(product, channelId.online)?.book ||
                this.getStock(product, channelId.shopee)?.book ||
                this.getStock(product, channelId.lazada)?.book
              ),
            }
          })
          if (!res.data.records.length && this.paginationProduct.page > 1) {
            this.paginationProduct.reset(res.data.total_page - 1)
            this.getProductList()
          } else {
            this.paginationProduct.setFromResponse(res.data)
            this.pageNumber.setValue(res.data.page)
          }
        } else {
          this.customSnackBar.fail()
        }
      }
      this.loading.stop()
    })
  }

  getNotificationWishList() {
    this.loading.start()
    this.productService.getNotificationWishList().subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          this.notificationWishList = res?.data
        } else {
          this.customSnackBar.fail()
        }
      }
      this.loading.stop()
    })
  }

  setItemCheckedData(checked: boolean, datas: any[]) {
    datas.forEach(item => {
      if (checked) {
        this.itemCheckedData.set(item.id, {
          id: item.id,
          is_order: item.is_order,
        })
      } else {
        this.itemCheckedData.delete(item.id)
      }
    })
  }

  confirmDelete() {
    let itemSize =
      this.tab == 0
        ? this.itemCheckedData.size
        : this.tab == 1
        ? this.itemCheckedDataDiscount.size
        : this.tab == 2
        ? this.itemCheckedDataFlashSales.size
        : ''
    const dialogRefDelete = this.dialog.open(ModalDeleteComponent, {
      data: {
        detail: `คุณต้องการลบสินค้าทั้งหมดที่เลือก ${itemSize} รายการใช่หรือไม่`,
      },
    })

    dialogRefDelete.afterClosed().subscribe(result => {
      if (result) {
        if (this.tab == 0) {
          this.deleteProduct()
        } else if (this.tab == 1) {
          this.deleteProductDiscount()
        } else if (this.tab == 2) {
          this.deleteProductFlashSales()
        }
      }
    })

    return dialogRefDelete
  }

  deleteProduct() {
    const payload = {
      ids: [...this.itemCheckedData.values()].map(item => item.id),
    }

    this.loading.start()
    this.productService.deleteProductBulk(payload).subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          this.customSnackBar.success('ลบข้อมูลสำเร็จ')
          this.itemChecked.clear()
          this.itemCheckedData.clear()
          this.getProductList()
        } else {
          this.dialog.open(ModalErrorComponent, {
            data: {
              title: 'ไม่สามารถลบสินค้าได้',
              detail: res.message,
            },
          })
        }
      }
      this.loading.stop()
    })
  }

  deleteProductDiscount() {
    const payload = {
      ids: [...this.itemCheckedDataDiscount.values()].map(item => item.id),
    }
    this.loading.start()
    this.productService.deleteBulkProductDiscounts(payload).subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          this.onSearchDiscount = true
          this.customSnackBar.success('ลบข้อมูลสำเร็จ')
          this.itemCheckedDiscount.clear()
          this.itemCheckedDataDiscount.clear()
          setTimeout(() => {
            this.onSearchDiscount = false
          }, 500)
        } else {
          this.dialog.open(ModalErrorComponent, {
            data: {
              title: 'ไม่สามารถลบสินค้าได้',
              detail: res.message,
            },
          })
        }
      }
      this.loading.stop()
    })
  }

  deleteProductFlashSales() {
    const payload = {
      ids: [...this.itemCheckedDataFlashSales.values()].map(item => item.id),
    }
    this.loading.start()
    this.productService.deleteBulkProductFlashSales(payload).subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          this.onSearchFlashSales = true
          this.customSnackBar.success('ลบข้อมูลสำเร็จ')
          this.itemCheckedFlashSales.clear()
          this.itemCheckedDataFlashSales.clear()
          setTimeout(() => {
            this.onSearchFlashSales = false
          }, 500)
        } else {
          this.dialog.open(ModalErrorComponent, {
            data: {
              title: 'ไม่สามารถลบสินค้าได้',
              detail: res.message,
            },
          })
        }
      }
      this.loading.stop()
    })
  }

  getStock(item: any, channelId: any) {
    return item.channels.find((d: any) => d.id == channelId)
  }

  onSort() {
    this.paginationProduct.setFromPaginator({
      pageIndex: 0,
      ...(this.sortControl.value || {}),
    })
    this.getProductList()
  }

  onSearch() {
    if (this.tab == 0) {
      this.paginationProduct.reset()
      this.getProductList()
      this.itemChecked.clear()
    } else if (this.tab == 1) {
      this.onSearchDiscount = true
      this.itemCheckedDiscount.clear()
    } else if (this.tab == 2) {
      this.onSearchFlashSales = true
      this.itemCheckedFlashSales.clear()
    } else if (this.tab == 3) {
      this.onSearchWistList = true
      this.itemCheckedWistList.clear()
    }
  }

  openModalAddStock(title: string, product: any): void {
    const dialogRef = this.dialog.open(ModalAddStockComponent, {
      data: {
        title,
        product,
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getProductList()
      }
    })
  }

  openModalCancel(): void {
    const dialogCancel = this.dialog.open(ModalLeaveComponent, {
      data: {},
    })

    dialogCancel.afterClosed().subscribe(result => {
      if (result) {
        this.router.navigate(['/setting/promotion/view'])
      }
    })
  }

  openModalDownloadFile(title: string, detail: any) {
    const dialogRef = this.dialog.open(ModalDownloadFileComponent, {
      data: {
        title,
        detail,
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.downloadFile()
      }
    })

    return dialogRef
  }

  getCheckboxValueId(checkboxValue: string) {
    return parseInt(checkboxValue.split(',')[0])
  }

  downloadFile() {
    const pagination = this.paginationProduct.get()
    const dataFilter = this.filterTableProduct.getData()
    dataFilter.filter.ids =
      this.itemChecked.size > 0
        ? this.checkboxValues.map((checkboxValue: string) => this.getCheckboxValueId(checkboxValue))
        : undefined
    if (this.isFilterMinStock) {
      dataFilter.filter.min_stock = this.min_stock
    }

    const payload = {
      sort_by: pagination.sort_by,
      sort_name: pagination.sort_name,
      ...dataFilter,
    }

    this.loading.start()
    this.productService.exportProduct(payload).subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          DownloadFileBlob(res.body, `products.xlsx`)
          this.itemChecked.clear()
          this.customSnackBar.success('ดาวน์โหลดข้อมูลสำเร็จ')
        } else {
          this.customSnackBar.fail('ดาวน์โหลดข้อมูลไม่สำเร็จ')
        }
      }
      this.loading.stop()
    })
  }

  openModalUploadFile(title: string): void {
    const dialogRef = this.dialog.open(ModalUploadFileComponent, {
      disableClose: true,
      maxWidth: '90vw',
      maxHeight: '90vh',
      data: {
        title,
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getProductList()
      }
    })
  }

  changeTab($tabIndex: any) {
    this.tab = $tabIndex
    this.navigateToTab(this.tab)
    this.filterTableProduct.reset()
    this.onSearchDiscount = false
    this.onSearchFlashSales = false
    this.onSearchWistList = false
    if (this.tab == 0) {
      this.getProductList()
    }
    this.getNotificationWishList()
  }

  getCover(item: any) {
    return item.files.find((d: any) => d.is_cover)
  }

  navigateToTab(tab: number) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        tab: tab,
      },
      skipLocationChange: false,
    })
  }

  // tab discount product
  setItemCheckedDiscount(data: any) {
    if (data.type == 'set') {
      this.itemCheckedDataDiscount.set(data.item.id, {
        id: data.item.id,
        is_order: data.item.is_order,
      })
    } else {
      this.itemCheckedDataDiscount.delete(data.item.id)
    }
  }

  // tab Flash Sales
  setItemCheckedFlashSales(data: any) {
    if (data.type == 'set') {
      this.itemCheckedDataFlashSales.set(data.item.id, {
        id: data.item.id,
        is_order: data.item.is_order,
      })
    } else {
      this.itemCheckedDataFlashSales.delete(data.item.id)
    }
  }

  // tab Wist List
  setItemCheckedWistList(data: any) {
    if (data.type == 'set') {
      this.itemCheckedDataWistList.set(data.item.id, {
        id: data.item.id,
        is_order: data.item.is_order,
      })
    } else {
      this.itemCheckedDataWistList.delete(data.item.id)
    }
  }

  async openModalFilterProduct() {
    const dialogRef = this.dialog.open(ModalFilterProductComponent, {
      data: {
        title: 'ตัวกรอง',
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getProductList()
        this.itemChecked.clear()
      }
    })
  }

  async openModalFilterWishList() {
    const dialogRef = this.dialog.open(ModalFilterWishListComponent, {
      data: {
        title: 'ตัวกรอง',
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.onSearchWistList = true
        this.itemCheckedWistList.clear()
      }
    })
  }

  updateProductNew(action: boolean) {
    const payload = {
      is_new: action,
      ids: (action ? this.checkboxNew : this.checkboxUnNew).map((checkboxValue: string) =>
        this.getCheckboxValueId(checkboxValue)
      ),
    }
    this.loading.start()
    this.productService.updateIsNew(payload).subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          this.customSnackBar.success('บันทีกข้อมูลสำเร็จ')
          this.getProductList()
          this.itemCheckedData.clear()
          this.itemChecked.clear()
        } else {
          this.customSnackBar.fail()
        }
      }
      this.loading.stop()
    })
  }

  productToWeb(): void {
    const payload = {
      is_launch: true,
      ids: this.checkboxLaunch.map((checkboxValue: string) =>
        this.getCheckboxValueId(checkboxValue)
      ),
    }
    this.loading.start()
    this.productService.isLaunch(payload).subscribe(res => {
      if (!res.is_error) {
        this.dialog.open(ModalSuccessProeductToWebComponent, {
          disableClose: true,
          width: '572px',
          data: {
            count: payload.ids.length,
          },
        })
        this.getProductList()
        this.itemCheckedData.clear()
        this.itemChecked.clear()
      } else {
        this.dialog.open(ModalInvalidProeductToWebComponent, {
          disableClose: true,
          width: '572px',
          maxWidth: '90vw',
          maxHeight: '90vh',
          data: {
            errors: res.errors || [],
          },
        })
      }
      this.loading.stop()
    })
  }
}
