<div>
  <mat-form-field appearance="outline" class="input">
    <input
      matInput
      [formControl]="formControl"
      placeholder="{{placeholder || ''}}"
      [type]="!showPassword ? 'password' : 'text'"
      (input)="onInput.emit()"
      (keydown.enter)="onEnter.emit()" />

    <button
      type="button"
      mat-icon-button
      matSuffix
      (click)="showPassword = !showPassword"
      [attr.aria-label]="'Hide password'"
      [attr.aria-pressed]="!showPassword"
      class="eyes-close">
      <img
        alt="Icon Eye"
        src="/assets/images/icons/{{
        !showPassword ? 'icon-eye.svg' : 'icon-eye-close.svg'
      }}" />
    </button>

    @if (control.invalid) {
    <mat-error>
      <ng-content select="[error]"></ng-content>
    </mat-error>
    }
  </mat-form-field>
</div>
