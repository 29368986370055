import { Component, Input, Output, EventEmitter, PLATFORM_ID, Inject } from '@angular/core'
import { Router } from '@angular/router'
import { forkJoin } from 'rxjs'
import { CMSMenuService } from '../../../services/cms-menu.service'
import { Loading } from '../../../globals'

@Component({
  selector: 'app-top-menu[theme]',
  templateUrl: './top-menu.html',
  styleUrl: './top-menu.scss',
  standalone: true,
  imports: [],
})
export class TopMenuComponent {
  @Input() theme!: 'black' | 'white'
  @Input() lang: any
  @Input() isMenus: boolean = false
  @Input() menus: any[] = [
    { name_th: 'เสื้อผ้า', name_en: 'Clothes' },
    { name_th: 'รองเท้า', name_en: 'Shoes' },
    { name_th: 'กระเป๋า', name_en: 'Bag' },
    { name_th: 'เครื่องประดับ', name_en: 'Accessories' },
  ]

  defaultMenu: any = {
    campaign: {
      name_th: 'แคมเปญ',
      name_en: 'Campaign',
    },
    explore: {
      name_th: 'สำรวจสินค้า',
      name_en: 'Explore',
    },
    sales: {
      name_th: 'ลดราคา',
      name_en: 'Sales',
    },
  }

  curencyData() {}

  constructor(public loading: Loading, public cmsMenuService: CMSMenuService) {}

  ngOnInit(): void {
    this.getMenu()
  }

  getMenu() {
    const apis = [this.cmsMenuService.getMenuList()]
    this.loading.start()
    forkJoin(apis).subscribe(([resMenuList]) => {
      if (!resMenuList.is_error) {
        let menuList = []
        for (const f of resMenuList.data) {
          if (f.is_default) {
            menuList.push(this.defaultMenu[f.code])
          } else {
            if (f?.product_category) {
              menuList.push({
                name_th: f?.product_category?.name_th,
                name_en: f?.product_category?.name_en,
              })
            }
          }
        }
        this.menus = menuList
      }

      this.loading.stop()
    })
  }
}
