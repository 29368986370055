<div mat-dialog-title class="dialog-title">
  <div class="dialog-title-head">โมเดล</div>
  <button class="btn btn-transparent btn-icon" (click)="onClose()">
    <img src="../assets/images/icons/icon-close.svg" />
  </button>
</div>

<mat-dialog-content>
  <div class="modal-content">
    <div class="row">
      @if (profile.companyId == COMPANY_ID.PANIA) {
        <div class="col-12 col-md-6">
          <label class="form-label">ภาพโมเดล</label>
          <!-- <app-input value="" /> -->
  
          <div class="section-upload-container">
            <div class="remark">(ขนาดรูปภาพ : 130px x 220px / ไม่เกิน 5 Mb)</div>
  
            <div class="upload-file-wrapper mb-1">
              <app-upload-file-flexible
                [type]="data.isEdit?'edit':'view'"
                [maxImageSize]="5"
                [imgOnly]="true"
                [logoDefault]="form.controls['file_model'].value?.url"
                (uploadData)="form.controls['file_model'].setValue($event?.[0])" />
            </div>
          </div>
        </div>
      }

      <div class="col-12 col-md-6">
        <div class="row">
          <div class="col-12">
            <label class="form-label">โมเดลใช้สินค้าขนาด</label>
            @if (data.isEdit) {
            <app-input [control]="form.controls['model_size']" />
            }@else {
            <div class="label-view mb-4">{{ form.controls['model_size'].value || '-' }}</div>
            }
          </div>

          <div class="col-12">
            <label class="form-label">ส่วนสูง</label>
            @if (data.isEdit) {
            <mat-radio-group
              [formControl]="form.controls['model_unit']"
              class="radio-button-wrapper d-flex align-items-center">
              <mat-radio-button [value]="1" [disabled]="!data.isEdit"
                >เซนติเมตร (ซม.)</mat-radio-button
              >
              <mat-radio-button [value]="2" [disabled]="!data.isEdit">ฟุต</mat-radio-button>
            </mat-radio-group>

            <app-input-number
              [control]="form.controls['tall']"
              placeholder="0"
              [min]="0"
              [unit]="form.controls['model_unit'].value == 1 ? 'ซม.' : 'ฟุต'">
            </app-input-number>
            }@else{
            <div class="label-view mb-4">
              @if (form.controls['tall'].value) {
              <ng-container>
                {{ form.controls['tall'].value }} {{ (form.controls['model_unit'].value == 1 ? 'ซม.'
                : 'ฟุต') }}
              </ng-container>

              } @else {-}
            </div>
            }
          </div>

          <div class="col-12">
            <label class="form-label">น้ำหนัก</label>
            @if (data.isEdit) {
            <app-input-number
              [control]="form.controls['weight']"
              placeholder="0"
              [min]="0"
              unit="กก.">
            </app-input-number>
            } @else {
            <div class="label-view mb-4">
              @if (form.controls['weight'].value) {
              <ng-container> {{ form.controls['weight'].value || '-' }} กก. </ng-container>
              } @else {-}
            </div>
            }
          </div>

          <div class="col-12">
            <label class="form-label">สัดส่วน</label>
            @if (data.isEdit) {
            <app-input [control]="form.controls['shape']" placeholder="รายละเอียด" />
            } @else {
            <div class="label-view mb-4">{{ form.controls['shape'].value || '-' }}</div>
            }
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="footer-button-wrapper d-flex justify-content-end w-100">
    @if (data.isEdit) {
    <button matRipple class="btn btn-outline-secondary btn-md" (click)="onClose()">ยกเลิก</button>
    <button matRipple class="btn btn-primary btn-md" (click)="onConfirm()">บันทึก</button>
    } @else {
    <button matRipple class="btn btn-primary btn-md" (click)="onClose()">ปิด</button>
    }
  </div>
</mat-dialog-actions>
