import { Component, Inject } from '@angular/core'
import { MatCheckboxModule } from '@angular/material/checkbox'
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog'
import { CustomSnackBar } from '../../../components/snackbar/snackbar'
import { FilterTableProduct, Profile } from '../../../globals'
import { imports } from '../../../imports'
import { status_product_ids_list, status_stock_ids_list } from '../filter-data'
import { COMPANY_ID } from '../../../helpers'

@Component({
  selector: 'app-modal-filter-product',
  templateUrl: './modal-filter-product.html',
  styleUrls: ['./modal-filter-product.scss'],
  standalone: true,
  imports: [...imports, MatDialogTitle, MatDialogContent, MatDialogActions, MatCheckboxModule],
})
export class ModalFilterProductComponent {
  readonly status_stock_ids_list = status_stock_ids_list
  readonly status_product_ids_list = status_product_ids_list
  readonly COMPANY_ID = COMPANY_ID

  constructor(
    public dialogRef: MatDialogRef<ModalFilterProductComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public customSnackBar: CustomSnackBar,
    public filterTableProduct: FilterTableProduct,
    public profile: Profile
  ) {
    this.filterTableProduct.initData()
  }

  onClose(): void {
    this.dialogRef.close()
  }

  onReset(): void {
    this.filterTableProduct.resetModalFilter()
  }

  onConfirm(): void {
    this.filterTableProduct.confirm()
    this.dialogRef.close(true)
  }
}
