<mat-form-field
  appearance="outline"
  class="select-custom-arrow {{ type == 'select-bank' ? 'select-bank' : 'select' }} {{ remove_bottom && 'remove-bottom' }}">
  <mat-select
    #select
    [formControl]="formControlCategory"
    [placeholder]="placeholder"
    [multiple]="multiple"
    [hideSingleSelectionIndicator]="true"
    (selectionChange)="selectionChange.emit($event)"
    [placeholder]="placeholder"
    (opened)="inputSearch.focus()"
    (closed)="searchText.set('');handleClose()">
    <mat-select-trigger>
      <div class="d-flex gap-1" [ngStyle]="{ 'margin-top': '1px' }">
        {{ subCategory?.name }}{{ subSubCategory ? '/' + subSubCategory.name : '' }}
      </div>
    </mat-select-trigger>

    <div class="search-select" [class.d-none]="!search.length">
      <input
        type="text"
        [(ngModel)]="searchText"
        class="form-control input w-100"
        placeholder="ค้นหา"
        (keydown)="$event.stopPropagation()"
        #inputSearch />
    </div>

    @if (datas.length) {
    <!-- ~ -->
    @if (subTempCategory) {
    <div matRipple class="mat-mdc-option mdc-list-item d-flex gap-1" (click)="closeSubSelect()">
      <img src="/assets/images/icons/icon-caret-left.svg" />
      <span> {{ subTempCategory.name }} </span>
    </div>
    <ng-container>
      @for (option of subTempDatas; track option) {
      <mat-option [value]="option.id" [class.d-none]="true"> {{ option.name }} </mat-option>
      <div
        matRipple
        class="mat-mdc-option mdc-list-item"
        [class.mdc-list-item--selected]="isSubSubActive(option)"
        [class.d-none]="isNoSearch(option)"
        (click)="handleSelectSubItem(option, select)">
        <span class="mdc-list-item__primary-text"> {{ option.name }} </span>
      </div>
      }
    </ng-container>
    }
    <ng-container>
      <mat-option
        [value]="optionNone.id"
        [class.d-none]="isNoSearch(optionNone) || !!subTempCategory"
        (onSelectionChange)="controlSubSubCategory.reset()">
        {{ optionNone.name }}
      </mat-option>
      @for (option of datas; track option) {
      <mat-option [value]="option.id" [class.d-none]="true"> {{ option.name }} </mat-option>
      <div
        matRipple
        class="mat-mdc-option mdc-list-item"
        [class.mdc-list-item--selected]="isSubActive(option)"
        [class.d-none]="isNoSearch(option) || subTempCategory"
        (click)="handleSelectItem(option, select)">
        <div class="d-flex justify-content-between w-100">
          <span class="mdc-list-item__primary-text"> {{ option.name }} </span>
          <img
            class="icon-invert"
            src="/assets/images/icons/icon-caret-right.svg"
            *ngIf="isSubSub(option.id)" />
        </div>
      </div>
      }
    </ng-container>
    <!-- ~ -->
    } @else {
    <mat-option [disabled]="true" class="option-customer-item text-center select-not-found">
      {{ empty }}
    </mat-option>
    }
  </mat-select>

  @if (controlSubCategory.invalid || controlSubSubCategory.invalid) {
  <mat-error>
    <ng-content select="[error]"></ng-content>
  </mat-error>
  }

  <div matSuffix class="select-suffix d-flex align-items-center">
    <img src="/assets/images/icons/icon-select.svg" />
  </div>
</mat-form-field>
