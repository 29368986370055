import { animate, state, style, transition, trigger } from '@angular/animations'
import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'

@Component({
  selector: 'app-spinner-custom[show]',
  standalone: true,
  imports: [MatProgressSpinnerModule],
  templateUrl: './custom-spinner.component.html',
  styleUrls: ['./custom-spinner.component.scss'],
  animations: [
    trigger('openClose', [
      // ...
      state(
        'open',
        style({
          opacity: 1,
        })
      ),
      state(
        'closed',
        style({
          opacity: 0,
        })
      ),
      transition('* => *', animate('0.3s ease')),
    ]),
  ],
})
export class SpinnerCustomComponent implements OnInit {
  @Input() show = false
  @Input() diameter: number = 50
  @Input() value: number = 10
  @Input() inline = false
  @Input() size = '65px'
  @Input() borderWidth = '6px'

  ngOnInit(): void {}
}
