<div class="search-wrapper">
  <mat-form-field appearance="outline" class="input remove-bottom">
    <button type="button" mat-icon-button matPrefix class="btn btn-transparent btn-icon ps-3">
      <span class="icon icon-grey material-symbols-outlined fill">search</span>
    </button>

    <input
      matInput
      type="search"
      name="search"
      enterkeyhint="search"
      autocomplete="off"
      aria-autocomplete="list"
      [formControl]="formControl"
      placeholder="{{placeholder || ''}}"
      (keydown.enter)="onEnter.emit()" />

    @if (formControl.value) {
    <button
      type="button"
      mat-icon-button
      matSuffix
      class="btn btn-transparent btn-icon"
      (click)="close()">
      <span class="icon icon-dark-grey material-symbols-outlined fill">close</span>
    </button>
    } @if (control.invalid) {
    <mat-error>
      <ng-content select="[error]"></ng-content>
    </mat-error>
    }
  </mat-form-field>
</div>
