import { Component, EventEmitter, Input, Output } from '@angular/core'
import { CommonModule } from '@angular/common'
import { getLocalStorage, Pagination } from '../../helpers'

@Component({
  selector: 'app-sort-by',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './sort-by.html',
  styleUrls: ['./sort-by.scss'],
})
export class SortByComponent {
  @Input() pagination!: Pagination
  @Input() key = ''
  @Input() reverse = false
  @Input() divClass = ''

  @Output() onChange = new EventEmitter()

  companyId: any

  ngOnInit(): void {
    this.companyId = getLocalStorage('company-id')
  }

  isActive(sortBy: 'asc' | 'desc') {
    return (
      this.pagination &&
      (this.pagination.data.sortName != this.key || this.pagination.data.sortBy == sortBy)
    )
  }

  clickSort() {
    if (this.pagination) {
      const data = this.pagination.onSort(this.key, this.reverse)
      this.onChange.emit(data)
    }
  }
}
