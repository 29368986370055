<div class="page-content">
  <div class="breadcrumb-wrapper">
    <div class="breadcrumb-text breadcrumb-link">ตั้งค่า</div>
    <div class="breadcrumb-separator">/</div>
    @if(isView) {
    <div class="breadcrumb-text">ข้อมูลบริษัท</div>
    } @else if(isEdit) {
    <div class="breadcrumb-text breadcrumb-link color--brand-blue--f" (click)="toView()">
      ข้อมูลบริษัท
    </div>
    <div class="breadcrumb-separator">/</div>
    <div class="breadcrumb-text">แก้ไขข้อมูลบริษัท</div>
    }
  </div>

  <div class="title-wrapper">ข้อมูลบริษัท</div>

  @if (dataManage) {
  <div class="page-content-inner">
    <div class="row mb-3">
      <div class="col-6 col-sm-4">
        <div class="d-flex align-items-center h-100">
          <div class="title-blue">รายละเอียดบริษัท</div>
        </div>
      </div>
      <div class="col-6 col-sm-8">
        <div class="d-flex align-items-center justify-content-end">
          @if (isView && profile.permissions.checkAccess([APP_PERMISSION.COMPANY],
          [ACTION_ACCESS.UPDATE])) {
          <button
            matRipple
            class="btn btn-primary min-w-120px btn-edit-pc"
            [routerLink]="['/setting/company-manage/edit']">
            แก้ไขข้อมูล
          </button>
          }
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-4">
        <div class="my-2">
          <app-upload-image-profile
            class="my-sm-3 mb-2"
            [type]="isView ? 'view' : 'edit'"
            [logoDefault]="dataManage.logo_url"
            [imageDefault]="dataManage.logo_url"
            (upload)="form.controls['logo_id'].setValue($event?.id || null)"
            [isRemark]="true"></app-upload-image-profile>
        </div>
      </div>
      <div class="col-12 col-sm-8">
        <div class="row mt-2">
          <div class="col-12">
            <label
              class="form-label {{ isView && 'neutral-500'}}"
              [class.text-danger]="form.controls['code'].touched && form.controls['code'].invalid">
              รหัสบริษัท @if (isEdit) {
              <span class="text-danger">*</span>
              }
            </label>
            @if (isEdit) {
            <app-input [control]="form.controls['code']">
              @if (form.controls['code'].hasError('required')) {
              <ng-container error>กรุณากรอกข้อมูล</ng-container>
              } @else if (form.controls['code'].hasError('maxlength')) {
              <ng-container error>กรุณากรอกข้อมูลไม่เกิน 10 ตัวอักษร</ng-container>
              }
            </app-input>
            } @else {
            <div class="label-view">
              <app-text-tooltip> {{ dataManage.code || '-' }} </app-text-tooltip>
            </div>
            }
          </div>

          <div class="col-12 col-sm-6">
            <label
              class="form-label {{ isView && 'neutral-500'}}"
              [class.text-danger]="form.controls['name'].touched && form.controls['name'].invalid">
              ชื่อบริษัทภาษาไทย @if (isEdit) {
              <span class="text-danger">*</span>
              }
            </label>

            @if (isEdit) {
            <app-input [control]="form.controls['name']">
              @if (form.controls['name'].hasError('required')) {
              <ng-container error>กรุณากรอกข้อมูล</ng-container>
              } @else if (form.controls['name'].hasError('maxlength')) {
              <ng-container error>กรุณากรอกข้อมูลไม่เกิน 100 ตัวอักษร</ng-container>
              } @else if (form.controls['name'].hasError('patternCha')) {
              <ng-container error>ชื่อบริษัทภาษาไทยไม่ถูกต้อง</ng-container>
              }
            </app-input>
            } @else {
            <div class="label-view">
              <app-text-tooltip> {{ dataManage.name || '-' }} </app-text-tooltip>
            </div>
            }
          </div>

          <div class="col-12 col-sm-6">
            <label
              class="form-label {{ isView && 'neutral-500'}}"
              [class.text-danger]="form.controls['name_en'].touched && form.controls['name_en'].invalid">
              ชื่อบริษัทภาษาอังกฤษ @if (isEdit) {
              <span class="text-danger">*</span>
              }
            </label>

            @if (isEdit) {
            <app-input [control]="form.controls['name_en']">
              @if (form.controls['name_en'].hasError('required')) {
              <ng-container error>กรุณากรอกข้อมูล</ng-container>
              } @else if (form.controls['name_en'].hasError('maxlength')) {
              <ng-container error>กรุณากรอกข้อมูลไม่เกิน 100 ตัวอักษร</ng-container>
              } @else if (form.controls['name_en'].hasError('patternCha')) {
              <ng-container error>ชื่อบริษัทภาษาอังกฤษไม่ถูกต้อง</ng-container>
              }
            </app-input>
            } @else {
            <div class="label-view">
              <app-text-tooltip> {{ dataManage.name_en || '-' }} </app-text-tooltip>
            </div>
            }
          </div>

          <div class="col-12 col-sm-6">
            <label
              class="form-label {{ isView && 'neutral-500'}}"
              [class.text-danger]="form.controls['address'].touched && form.controls['address'].invalid">
              ที่อยู่บริษัทภาษาไทย @if (isEdit) {
              <span class="text-danger">*</span>
              }
            </label>

            @if (isEdit) {
            <app-text-area [control]="form.controls['address']" remainingText="250" maxlength="250">
              @if (form.controls['address'].hasError('required')) {
              <ng-container error>กรุณากรอกข้อมูล</ng-container>
              } @else if (form.controls['address'].hasError('pattern')) {
              <ng-container error>ที่อยู่บริษัทภาษาไทยไม่ถูกต้อง</ng-container>
              }
            </app-text-area>
            } @else {
            <div class="label-view text-pre-line">
              <app-text-tooltip> {{ dataManage.address || '-' }} </app-text-tooltip>
            </div>
            }
          </div>

          <div class="col-12 col-sm-6">
            <label
              class="form-label {{ isView && 'neutral-500'}}"
              [class.text-danger]="form.controls['address_en'].touched && form.controls['address_en'].invalid">
              ที่อยู่บริษัทภาษาอังกฤษ @if (isEdit) {
              <span class="text-danger">*</span>
              }
            </label>

            @if (isEdit) {
            <app-text-area
              [control]="form.controls['address_en']"
              remainingText="250"
              maxlength="250">
              @if (form.controls['address_en'].hasError('required')) {
              <ng-container error>กรุณากรอกข้อมูล</ng-container>
              } @else if (form.controls['address_en'].hasError('pattern')) {
              <ng-container error>ที่อยู่บริษัทภาษาอังกฤษไม่ถูกต้อง</ng-container>
              }
            </app-text-area>
            } @else {
            <div class="label-view text-pre-line">
              <app-text-tooltip> {{ dataManage.address_en || '-' }} </app-text-tooltip>
            </div>
            }
          </div>

          <div class="col-12">
            <label
              class="form-label {{ isView && 'neutral-500'}}"
              [class.text-danger]="form.controls['tax_id'].touched && form.controls['tax_id'].invalid">
              Tax ID / หมายเลขประจำตัวผู้เสียภาษี @if (isEdit) {
              <span class="text-danger">*</span>
              }
            </label>

            @if (isEdit) {
            <app-input [control]="form.controls['tax_id']">
              @if (form.controls['tax_id'].hasError('required')) {
              <ng-container error>กรุณากรอกข้อมูล</ng-container>
              } @else if (form.controls['tax_id'].hasError('pattern')) {
              <ng-container error>Tax ID ไม่ถูกต้อง</ng-container>
              }
            </app-input>
            } @else {
            <div class="label-view">{{ dataManage.tax_id || '-' }}</div>
            }
          </div>

          <div class="col-12 col-sm-6">
            <label
              class="form-label {{ isView && 'neutral-500'}}"
              [class.text-danger]="form.controls['telephone'].touched && form.controls['telephone'].invalid">
              เบอร์ติดต่อ
            </label>

            @if (isEdit) {
            <app-input [control]="form.controls['telephone']">
              @if (form.controls['telephone'].hasError('required')) {
              <ng-container error>กรุณากรอกข้อมูล</ng-container>
              } @else if (form.controls['telephone'].hasError('maxlength')) {
              <ng-container error>กรุณากรอกข้อมูลไม่เกิน 100 ตัวอักษร</ng-container>
              } @else if (form.controls['telephone'].hasError('pattern')) {
              <ng-container error>เบอร์โทรศัพท์ไม่ถูกต้อง</ng-container>
              }
            </app-input>
            } @else {
            <div class="label-view">{{ dataManage.telephone }}</div>
            }
          </div>

          <div class="col-12 col-sm-6">
            <label
              class="form-label {{ isView && 'neutral-500'}}"
              [class.text-danger]="form.controls['fax'].touched && form.controls['fax'].invalid">
              เบอร์แฟกซ์
            </label>

            @if (isEdit) {
            <app-input [control]="form.controls['fax']">
              @if (form.controls['fax'].hasError('required')) {
              <ng-container error>กรุณากรอกข้อมูล</ng-container>
              } @else if (form.controls['fax'].hasError('pattern')) {
              <ng-container error>เบอร์แฟกซ์ไม่ถูกต้อง</ng-container>
              }
            </app-input>
            } @else {
            <div class="label-view">{{ dataManage.fax || '-' }}</div>
            }
          </div>

          <div class="col-12">
            <label
              class="form-label {{ isView && 'neutral-500'}}"
              [class.text-danger]="form.controls['email'].touched && form.controls['email'].invalid">
              อีเมล
            </label>

            @if (isEdit) {
            <app-input [control]="form.controls['email']">
              @if (form.controls['email'].hasError('pattern')) {
              <ng-container error>รูปแบบอีเมลไม่ถูกต้อง</ng-container>
              } @else if (form.controls['email'].hasError('maxlength')) {
              <ng-container error>กรุณากรอกข้อมูลไม่เกิน 100 ตัวอักษร</ng-container>
              }
            </app-input>
            } @else {
            <div class="label-view">
              <app-text-tooltip> {{ dataManage.email || '-' }} </app-text-tooltip>
            </div>
            }
          </div>
        </div>

        <div class="title-blue my-3">ที่อยู่จัดส่ง</div>
        <div class="row">
          <div class="col-12 col-sm-6">
            <label
              class="form-label {{ isView && 'neutral-500'}}"
              [class.text-danger]="form.controls['sender_name'].touched && form.controls['sender_name'].invalid">
              ชื่อผู้ส่ง @if (isEdit) {
              <span class="text-danger">*</span>
              }
            </label>

            @if (isEdit) {
            <app-input [control]="form.controls['sender_name']">
              @if (form.controls['sender_name'].hasError('required')) {
              <ng-container error>กรุณากรอกข้อมูล</ng-container>
              } @else if (form.controls['sender_name'].hasError('maxlength')) {
              <ng-container error>กรุณากรอกข้อมูลไม่เกิน 100 ตัวอักษร</ng-container>
              }
            </app-input>
            } @else {
            <div class="label-view">
              <app-text-tooltip>{{ dataManage.sender_name || '-' }}</app-text-tooltip>
            </div>
            }
          </div>
          <div class="col-12 col-sm-6">
            <label
              class="form-label {{ isView && 'neutral-500'}}"
              [class.text-danger]="form.controls['sender_telephone'].touched && form.controls['sender_telephone'].invalid">
              เบอร์ผู้ส่ง @if (isEdit) {
              <span class="text-danger">*</span>
              }
            </label>

            @if (isEdit) {
            <app-input [control]="form.controls['sender_telephone']">
              @if (form.controls['sender_telephone'].hasError('required')) {
              <ng-container error>กรุณากรอกข้อมูล</ng-container>
              } @else if (form.controls['sender_telephone'].hasError('maxlength')) {
              <ng-container error>กรุณากรอกข้อมูลไม่เกิน 100 ตัวอักษร</ng-container>
              } @else if (form.controls['sender_telephone'].hasError('pattern')) {
              <ng-container error>เบอร์โทรศัพท์ไม่ถูกต้อง</ng-container>
              }
            </app-input>
            } @else {
            <div class="label-view">{{ dataManage.sender_telephone || '-' }}</div>
            }
          </div>
          <div class="col-12">
            <label
              class="form-label {{ isView && 'neutral-500'}}"
              [class.text-danger]="form.controls['sender_address'].touched && form.controls['sender_address'].invalid">
              ที่อยู่ผู้ส่ง @if (isEdit) {
              <span class="text-danger">*</span>
              }
            </label>

            @if (isEdit) {
            <app-text-area
              [control]="form.controls['sender_address']"
              remainingText="250"
              maxlength="250">
              @if (form.controls['sender_address'].hasError('required')) {
              <ng-container error>กรุณากรอกข้อมูล</ng-container>
              }
            </app-text-area>
            } @else {
            <div class="label-view">
              <app-text-tooltip>{{ dataManage.sender_address || '-' }}</app-text-tooltip>
            </div>
            }
          </div>
        </div>

        @if (isEdit) {
        <div class="d-flex justify-content-end mt-3">
          <button matRipple class="btn btn-outline-primary min-w-100px" (click)="toView()">
            ยกเลิก
          </button>
          <button matRipple class="btn btn-primary ms-2 min-w-100px" (click)="confirmSave()">
            บันทึก
          </button>
        </div>
        }
      </div>
    </div>
  </div>
  }
</div>
