<div mat-dialog-title class="dialog-title">
  <div class="dialog-title-head">{{data.title}}</div>
  <button class="btn btn-transparent btn-icon" (click)="onClose()">
    <img src="../assets/images/icons/icon-close.svg" />
  </button>
</div>

<mat-dialog-content>
  <div class="modal-content">
    @if (dataManage) {
    <div class="order-detail-wrapper">
      <div class="order-number">เลขที่ออเดอร์ {{ dataManage.order_no }}</div>
      <div class="detail-wrapper">
        <div class="detail-text">
          ชื่อลูกค้า: {{ showCustomerName(dataManage.customer_name, dataManage.customer_last_name)
          }}
        </div>
        <div class="detail-text">เบอร์โทรศัพท์: {{ dataManage.telephone }}</div>
      </div>
    </div>

    <table class="table table-fixed-column table-detail">
      <colgroup>
        <col width="350" />
        <col width="75" />
        <col width="140" />
        <col width="140" />
        @if (dataManage.is_multi_delivery) {
        <col width="275" />
        }
      </colgroup>
      <thead>
        <tr>
          <th>สินค้า</th>
          <th class="text-end">จำนวน</th>
          <th class="text-end">ราคา (บาท)</th>
          <th class="text-end">รวม (บาท)</th>
          @if (dataManage.is_multi_delivery) {
          <th>ที่อยู่จัดส่ง</th>
          }
        </tr>
      </thead>

      <tbody>
        @for (item of products; track $index) {
        <tr>
          <td>
            <div class="product-wrapper d-flex align-items-center">
              <img
                src="{{ item.image?.file?.thumbnail || imgDefault }}"
                width="60"
                height="60"
                alt="Product" />
              <div class="product-detail-wrapper d-flex flex-column">
                <div>{{ item.product_name }}</div>
                <div class="neutral-500">{{ item.product_code }}</div>
                @if (profile.permissions.checkAccess([APP_PERMISSION.PRODUCT],
                [ACTION_ACCESS.VIEW])) {
                <a [href]="'/product/view/' + item.product_id" target="_blank" class="view-product">
                  ดูสินค้า
                </a>
                }
              </div>
            </div>
          </td>
          <td class="text-end">{{ formatPriceAutoDecimal(item.quantity) }}</td>
          <td class="text-end">{{ formatPrice(item.price) }}</td>
          <td class="text-end">{{ formatPrice(item.total_price) }}</td>
          @if (dataManage.is_multi_delivery) {
          <td>
            @if (item.delivery_address) {
            <!-- ~ -->
            {{ item.delivery_address.name }}<br />{{ item.delivery_address.telephone }}<br />{{
            item.delivery_address.address}}
            <!-- ~ -->
            }
          </td>
          }
        </tr>
        }
      </tbody>
    </table>
    }
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="footer-button-wrapper d-flex justify-content-end w-100">
    <button matRipple class="btn btn-primary btn-md" (click)="onClose()">ปิด</button>
  </div>
</mat-dialog-actions>
