<div class="page-content">
  <div class="breadcrumb-wrapper">
    <div class="breadcrumb-text breadcrumb-link">ตั้งค่า</div>
    <div class="breadcrumb-separator">/</div>
    <div class="breadcrumb-text">จัดการเนื้อหา (CMS)</div>
    <div class="breadcrumb-separator">/</div>
    <div class="breadcrumb-text">คำถามที่พบบ่อย</div>
  </div>

  <div class="title-wrapper">คำถามที่พบบ่อย</div>

  <div class="page-content-inner d-flex flex-column">
    <div class="d-flex justify-content-end">
      @if (profile.permissions.checkAccess([APP_PERMISSION.CMS], [ACTION_ACCESS.CREATE])) {
      <button class="btn btn-primary w-fit mb-3" [routerLink]="'/setting/cms/faqs/create'">
        <span class="icon material-symbols-outlined fill"> add_circle </span>
        เพิ่มหมวดหมู่คำถาม
      </button>
      }
    </div>
    <div class="table-responsive table-main-wrapper">
      <table class="table table-fixed-column table-detail">
        <thead>
          <tr>
            <th width="300">หมวดหมู่</th>
            <th width="140">จำนวนคำถาม</th>
            <th width="140">สถานะ</th>
            <th width="220">อัปเดตโดย</th>
            <th width="220">วันที่อัปเดต</th>
            <th width="120" class="fixed box-shadow-left">จัดการ</th>
          </tr>
        </thead>
        <tbody class="mt-3 w-100">
          @for (item of faqsList; track $index;) {
          <tr class="drag-box mb-2">
            <td width="300">
              <app-text-tooltip>{{ item.name_th }}</app-text-tooltip>
            </td>
            <td width="140">{{ item.details.length }}</td>
            <td width="140">
              <app-status [status]="item?.is_public ? 'public' : 'not_public'">
                {{item?.is_public ? 'เผยแพร่แล้ว' : 'ยังไม่เผยแพร่'}}
              </app-status>
            </td>
            <td width="220">{{ item?.updated_by?.full_name || '-' }}</td>
            <td width="220">{{ Moment(item?.updated_at).format('DD-MM-YYYY, HH:mm') }}</td>
            <td width="120" class="fixed box-shadow-left">
              <div class="w-100 d-flex justify-content-center gap-2">
                <button
                  matRipple
                  class="btn btn-outline-secondary btn-icon"
                  [routerLink]="'/setting/cms/faqs/edit/'+ item.id">
                  <span class="icon material-symbols-outlined fill">edit</span>
                </button>
                <!-- @if (item?.action?.delete) {} -->
                <button
                  matRipple
                  class="btn btn-outline-danger btn-icon"
                  (click)="confirmDelete(item.id)">
                  <span class="icon material-symbols-outlined fill">delete</span>
                </button>
              </div>
            </td>
          </tr>
          }
        </tbody>
      </table>

      @if(!faqsList.length) {
      <div class="data-not-found-wrapper">ไม่พบข้อมูล กรุณาเพิ่มหมวดหมู่คำถาม</div>
      }
    </div>
    <!-- Pagination -->
    @if (faqsList.length) {
    <div class="pagination-wrapper">
      <mat-paginator
        [pageIndex]="paginationFaqs.data.pageIndex"
        [length]="paginationFaqs.data.length"
        [pageSize]="paginationFaqs.data.pageSize"
        [pageSizeOptions]="paginationFaqs.data.pageSizeOptions"
        aria-label="Select page"
        (page)="paginationFaqs.setFromPaginator($event); getCmsFaqsList()"
        aria-label="Select page">
      </mat-paginator>
    </div>
    }
  </div>
</div>
