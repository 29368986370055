import { Injectable } from '@angular/core'
import { __searchAndFitler } from './_mix'
import { FormControl, FormGroup } from '@angular/forms'
import { Moment, ORDER_ID, convertDateToApi } from '../../helpers'
import { Loading } from '../loading'
import { CustomSnackBar } from '../../components/snackbar/snackbar'

@Injectable({ providedIn: 'root' })
export class FilterTableHistoryOrderDeleted extends __searchAndFitler {
  readonly list = {
    status_ids: <any[]>[
      { id: `${ORDER_ID.CREATE},${ORDER_ID.VERIFY}`, text: 'รอชำระเงิน' },
      { id: `${ORDER_ID.PAID}`, text: 'ชำระเงินแล้ว' },
      { id: `${ORDER_ID.PRINTED}`, text: 'พิมพ์แล้ว' },
      { id: `${ORDER_ID.SHIPMENT}`, text: 'ส่งสินค้าแล้ว' },
    ],
  }

  form = new FormGroup({
    start_date: new FormControl(''),
    end_date: new FormControl(''),
  })

  get isFilter() {
    const data = this.getData()
    return data.filter && Object.keys(data.filter).length
  }

  get startEndDateFormat() {
    return [this.form.controls['start_date'].value, this.form.controls['end_date'].value]
      .reduce((ds: any[], d: any) => {
        if (d) {
          ds.push(Moment(d).format('DD/MM/YYYY'))
        }
        return ds
      }, [])
      .join(' - ')
  }

  constructor(public loading: Loading, public customSnackBar: CustomSnackBar) {
    const filter = {
      status_ids: [],
    }
    super('/history/order-deleted', filter)

    this._initForm(this.form)
  }

  initData() {}

  isStatusIdChecked(id: any) {
    return this.filter['status_ids'].some((val: any) => val === id)
  }

  statusIdChange(id: any) {
    const status_ids = this.filter['status_ids']
    if (status_ids.includes(id)) {
      status_ids.splice(status_ids.indexOf(id), 1)
    } else {
      status_ids.push(id)
    }
  }

  removeStatusId(id: any) {
    this.statusIdChange(id)
  }

  removeStartEndDate() {
    this.form.controls['start_date'].reset()
    this.form.controls['end_date'].reset()
  }

  getData() {
    const filter: any = {}
    const data: any = {
      filter,
    }

    const status_ids = this.filter['status_ids']
    if (status_ids.length) {
      filter.status_ids = status_ids.reduce((ids: number[], str: string) => {
        return [...ids, ...str.split(',').map((idr: string) => parseInt(idr))]
      }, [])
    }

    const value = this.form.getRawValue()
    if (value.start_date) {
      filter.start_date = convertDateToApi(value.start_date)
    }
    if (value.end_date) {
      filter.end_date = convertDateToApi(value.end_date, 'day')
    }

    const searchValue = this.search.value
    if (searchValue) {
      data.search = searchValue
    }

    return data
  }
}
