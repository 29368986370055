<div class="page-content-inner mt-3">
  <div class="top-content-wrapper align-items-center flex-wrap mb-3">
    <div class="top-content-left"><div class="title-blue">ประวัติการคืนสินค้า</div></div>
    <div class="top-content-right">
      @if (profile.permissions.checkAccess([APP_PERMISSION.PRODUCT], [ACTION_ACCESS.VIEW])) {
      <button
        matRipple
        (click)="openModalExportProductReturnLog('ดาวน์โหลดไฟล์สินค้า', { message: 'คุณต้องการดาวน์โหลดไฟล์ประวัติการคืนสินค้าที่เลือกใช่หรือไม่' })"
        class="btn btn-outline-secondary min-w-120px">
        <span class="icon material-symbols-outlined fill">download_2</span>
        ส่งออกไฟล์
      </button>
      }
    </div>
  </div>

  <div class="table-responsive-detail">
    <table class="table table-fixed-column table-detail">
      <thead>
        <tr class="tr-vertical-top">
          <th width="120">เลขที่ออเดอร์</th>
          <th width="250">ชื่อสินค้า</th>
          <th width="100" class="text-center">จำนวน</th>
          <th width="170">วันที่คืนสินค้า</th>
          <th width="200">โดย</th>
        </tr>
      </thead>
      <tbody>
        @for (item of productReturnLogList; track $index) {
        <tr class="tr-vertical-top">
          <td>{{ item.order_no }}</td>
          <td colspan="2">
            <table class="w-100">
              <colgroup>
                <col width="250" />
                <col width="100" />
              </colgroup>

              <tbody>
                <tr class="tr-sub-product">
                  <td>
                    <app-text-tooltip> {{ item.return_product_name }} </app-text-tooltip>
                  </td>
                  <td class="text-center">{{ formatPriceAutoDecimal(item.return_quantity) }}</td>
                </tr>
                <!-- <tr class="tr-sub-product">
                  <td>{{ item.origin_product_name }}</td>
                  <td class="text-center">{{ formatPriceAutoDecimal(item.origin_quantity) }}</td>
                </tr> -->
              </tbody>
            </table>
          </td>
          <td>{{ Moment(item.created_at).format('DD-MM-YYYY HH:mm') }}</td>
          <td>{{ item.created_by?.full_name }}</td>
        </tr>
        }
      </tbody>
    </table>
    @if(!productReturnLogList.length) {
    <div class="data-not-found-wrapper">ไม่พบข้อมูล</div>
    }
  </div>

  <!-- Pagination -->
  @if (productReturnLogList.length) {
  <div class="pagination-wrapper">
    <mat-paginator
      [pageIndex]="paginationProductReturnLog.data.pageIndex"
      [length]="paginationProductReturnLog.data.length"
      [pageSize]="paginationProductReturnLog.data.pageSize"
      [pageSizeOptions]="paginationProductReturnLog.data.pageSizeOptions"
      (page)="paginationProductReturnLog.setFromPaginator($event);getProductReturnLogList()"
      aria-label="Select page">
    </mat-paginator>
  </div>
  }
</div>
