import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core'
import { Router } from '@angular/router'
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms'
import { imports } from '../../../imports'
import { InputComponent } from '../../../components/input/input'
import { TextareaComponent } from '../../../components/text-area/text-area'
import {
  EmailReg,
  ValidateForm,
  setErrorForm,
  ValidateArrayForm,
  Moment,
  convertDateToApi,
  Pagination,
  sortFlashSalesList
} from '../../../helpers'
import { UploadImageProfileComponent } from '../../../components/upload-image-profile/upload-image-profile'
import { ModalLeaveComponent } from '../../../components/modal-leave/modal-leave'
import { MatDialog } from '@angular/material/dialog'
import { CompanyProfileService, CaratService, ProductService } from '../../../services'
import { CustomSnackBar } from '../../../components/snackbar/snackbar'
import { ACTION_ACCESS, APP_PERMISSION, Loading, Profile } from '../../../globals'
import { forkJoin } from 'rxjs'
import { ModalConfirmComponent } from '../../../components/modal-confirm/modal-confirm'
import { InputNumberComponent } from '../../../components/input-number/input-number'
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
  CdkDrag,
  CdkDropList,
} from '@angular/cdk/drag-drop'
import { DatePickerComponent } from '../../../components/date-picker/date-picker'
import { SelectSortComponent } from '../../../components/select-sort/select-sort'
import { CheckboxAllTableComponent } from '../../../components/checkbox-table/checkbox-all-table.component'
import { CheckboxTableComponent } from '../../../components/checkbox-table/checkbox-table.component'
import { ModalDeleteComponent } from '../../../components/modal-delete/modal-delete'

@Component({
  selector: 'app-tab-products-flash-sales',
  standalone: true,
  imports: [
    ...imports,
    InputComponent,
    TextareaComponent,
    UploadImageProfileComponent,
    ModalLeaveComponent,
    InputNumberComponent,
    DatePickerComponent,
    CdkDrag,
    CdkDropList,
    SelectSortComponent,
    CheckboxAllTableComponent,
    CheckboxTableComponent
  ],
  templateUrl: './tab-products-flash-sales.html',
  styleUrls: ['./tab-products-flash-sales.scss'],
})
export class TabProductsFlashSalesComponent implements OnInit {
  @Output() setItemCheckedFlashSales = new EventEmitter()
  @Output() onSearch = new EventEmitter()
  @Input() onSearchFlashSales: boolean = false
  @Input() filterTableProduct: any

  readonly APP_PERMISSION = APP_PERMISSION
  readonly ACTION_ACCESS = ACTION_ACCESS
  readonly Moment = Moment

  isEdit: boolean = false
  isView: boolean = false

  sortControl = new FormControl(sortFlashSalesList[0].value)
  readonly paginationProduct = new Pagination({
    sortName: sortFlashSalesList[0].value.sortName,
    sortBy: sortFlashSalesList[0].value.sortBy as 'asc' | 'desc',
  })
  rerender = false
  pageNumber = new FormControl(1)
  productList: any[] = []
  itemChecked = new Set<any>()
  itemCheckedData = new Map()

  list = {
    sort: sortFlashSalesList,
  }

  constructor(
    public profile: Profile,
    public router: Router,
    public dialog: MatDialog,
    public customSnackBar: CustomSnackBar,
    public loading: Loading,
    public companyProfileService: CompanyProfileService,
    public ProductService: ProductService,
  ) {}

  ngOnInit(): void {
    this.getFlashSalesProductList()
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log('changes', changes);
    
    if (changes['onSearchFlashSales']) {
      this.getFlashSalesProductList()
    }
  }

  getFlashSalesProductList() {
    const pagination = this.paginationProduct.get()
    const dataFilter = this.filterTableProduct.getData()
    const payload = {
      ...pagination,
      ...dataFilter,
    }

    this.loading.start()
    this.ProductService.getProductFlashSalesList(payload).subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          this.productList = res.data.records
          this.paginationProduct.setFromResponse(res.data)
          this.pageNumber.setValue(res.data.page)

          this.onSearch.emit()
        } else {
          this.customSnackBar.fail()
        }
      }
      this.loading.stop()
    })
  }

  onSort() {
    this.paginationProduct.setFromPaginator({
      pageIndex: 0,
      ...(this.sortControl.value || {}),
    })
    this.getFlashSalesProductList()
  }


  setItemCheckedData(checked: boolean, datas: any[]) {
    datas.forEach(item => {
      if (checked) {
        this.itemCheckedData.set(item.id, {
          id: item.id,
          is_order: item.is_order,
        })
        this.setItemCheckedFlashSales.emit({item, type: 'set'})
      } else {
        this.itemCheckedData.delete(item.id)
        this.setItemCheckedFlashSales.emit({item, type: 'delete'})
      }
    })
  }

  confirmDelete(item: any) {
    const dialogRefDelete = this.dialog.open(ModalDeleteComponent, {
      data: {
        detail: `คุณต้องการลบแคมเปญ “${item.name}” ใช่หรือไม่`,
      },
    })

    dialogRefDelete.afterClosed().subscribe(result => {
      if (result) {
        this.ProductService.deleteProductFlashSales(item.id).subscribe((res: any) => {
          if (!res.is_error) {
            this.customSnackBar.success('ลบข้อมูลสำเร็จ')
            this.getFlashSalesProductList()
          } else {
            this.customSnackBar.fail('ลบข้อมูลไม่สำเร็จ')
          }
          this.loading.stop()
        })
      }
    })

    return dialogRefDelete
  }
}
