import { Component, Inject, Injectable } from '@angular/core'
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog'
import { MatButtonModule } from '@angular/material/button'
import { FormsModule } from '@angular/forms'
import { MatInputModule } from '@angular/material/input'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatTabsModule } from '@angular/material/tabs'
import { MatIconModule } from '@angular/material/icon'
import { MatRipple } from '@angular/material/core'
import { TopMenuComponent } from '../../../../components/preview/top-menu/top-menu'
import { FileService, ProductService } from '../../../../services'
import { Loading } from '../../../../globals'
import { CustomSnackBar } from '../../../../components/snackbar/snackbar'
import { CardProductComponent } from '../../../../components/preview/card-product/card-product'
import { ScrollSildeComponent } from '../../../../components/preview/scroll-silde/scroll-silde'
import { VideoPlayerComponent } from '../../../../components/preview/video-player/video-player'
import { FooterComponent } from '../../../../components/preview/footer/footer'
import { DomSanitizer } from '@angular/platform-browser'
import { readHTML } from '../../../../helpers'

@Component({
  selector: 'app-modal-preview',
  templateUrl: './modal-preview.html',
  styleUrls: ['./modal-preview.scss'],
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatTabsModule,
    MatIconModule,
    MatRipple,
    TopMenuComponent,
    CardProductComponent,
    ScrollSildeComponent,
    VideoPlayerComponent,
    FooterComponent,
  ],
})
export class ModalPreviewAbountUsComponent {
  readonly readHTML = readHTML

  file_url: any

  constructor(
    public dialogRef: MatDialogRef<ModalPreviewAbountUsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public fileService: FileService,
    public loading: Loading,
    public customSnackBar: CustomSnackBar,
    public sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    // console.log(this.data)
    this.setData()
  }

  getFile(id: any, call: Function) {
    this.loading.start()
    this.fileService.signUrl(id).subscribe(res => {
      if (!res.is_error) {
        call(res)
      } else {
        this.customSnackBar.fail()
      }
      this.loading.stop()
    })
  }

  setData() {
    this.getFile(this.data.formValue.file, (res: any) => {
      this.file_url = res.data
    })
  }

  onClose(): void {
    this.dialogRef.close()
  }
}
