import { Component, Inject, Input, PLATFORM_ID } from '@angular/core'

@Component({
  selector: 'app-product-dot-color[fill][active]',
  templateUrl: './product-dot-color.html',
  styleUrl: './product-dot-color.scss',
  standalone: true,
  imports: [],
})
export class ProductDotColorComponent {
  @Input() active = false
  @Input() fill = '#A20909'

  constructor() {}

  ngOnInit(): void {}
}
