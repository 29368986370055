import { Component, ViewChild } from '@angular/core'
import { MatTabsModule } from '@angular/material/tabs'

import { ActivatedRoute, Router } from '@angular/router'
import { MatDialog } from '@angular/material/dialog'
import { ModalLeaveComponent } from '../../components/modal-leave/modal-leave'
import { ACTION_ACCESS, APP_PERMISSION, Profile } from '../../globals'
import { TabCustomerComponent } from '../customer-manage/tabs/tab-customer/tab-customer'
import { TabSupplierComponent } from '../customer-manage/tabs/tab-supplier/tab-supplier'
import { imports } from '../../imports'
import { SelectComponent } from '../../components/select/select'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { HistoryOrderComponent } from './order/order'
import { ProductInOrderComponent } from './product-in-order/product-in-order'
import { ProductReturnComponent } from './product-return/product-return'
import { ExpenseItemComponent } from './expense-item/expense-item'
import { RestockingComponent } from './restocking/restocking'
import { HistoryPaymentComponent } from './payment/payment'

@Component({
  selector: 'app-history',
  standalone: true,
  imports: [
    ...imports,
    MatTabsModule,
    ModalLeaveComponent,
    TabCustomerComponent,
    TabSupplierComponent,
    SelectComponent,
    HistoryOrderComponent,
    ProductInOrderComponent,
    ProductReturnComponent,
    ExpenseItemComponent,
    RestockingComponent,
    HistoryPaymentComponent,
  ],
  templateUrl: './history.html',
  styleUrls: ['./history.scss'],
})
export class HistoryComponent {
  @ViewChild('historyRef') historyRef?: any

  readonly APP_PERMISSION = APP_PERMISSION
  readonly ACTION_ACCESS = ACTION_ACCESS

  tab = 0

  form = new FormGroup({
    history: new FormControl(1),
  })

  list = {
    history: [
      { value: 1, text: 'ออเดอร์' },
      { value: 2, text: 'สินค้าในออเดอร์' },
      { value: 3, text: 'รายการคืนสินค้า' },
      { value: 4, text: 'รายการค่าใช้จ่าย' },
      { value: 5, text: 'การเพิ่มสต๊อกสินค้า' },
      { value: 6, text: 'การชำระเงิน' },
    ],
  }
  subTitle: any = {
    1: ['ข้อมูลทั่วไป', 'ใบกำกับภาษี', 'รายการที่ลบ', 'ยกเลิกออเดอร์'],
    4: ['ข้อมูลทั่วไป', 'รายงานค่าใช้จ่ายจากสินค้าชำรุด'],
  }

  constructor(
    public route: ActivatedRoute,
    public Router: Router,
    public dialog: MatDialog,
    public profile: Profile
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params: any) => {
      if (params.tab) {
        this.tab = params.tab
      }
    })

    this.form.controls.history.valueChanges.subscribe(() => {
      this.navigateToTab(0)
    })
  }

  changeTab($tabIndex: any) {
    this.tab = $tabIndex
    this.navigateToTab(this.tab)
  }

  navigateToTab(tab: number) {
    this.Router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        tab: tab,
      },
      skipLocationChange: false,
    })
  }
}
