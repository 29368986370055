<div class="page-main-content">
  <div class="breadcrumb-wrapper">
    <div class="breadcrumb-text">ผู้ติดต่อ</div>
    <div class="breadcrumb-separator">/</div>
    <div class="breadcrumb-text breadcrumb-link color--brand-blue--f" (click)="toList()">
      ข้อมูลผู้ขาย
    </div>
    <div class="breadcrumb-separator">/</div>
    @if(isCreate) {
    <div class="breadcrumb-text">เพิ่มผู้ขาย</div>
    } @else if(isView) {
    <div class="breadcrumb-text">รายละเอียดผู้ขาย</div>
    } @else if(isEdit) {
    <div class="breadcrumb-text breadcrumb-link color--brand-blue--f" (click)="toView()">
      รายละเอียดผู้ขาย
    </div>
    <div class="breadcrumb-separator">/</div>
    <div class="breadcrumb-text">แก้ไขผู้ขาย</div>
    }
  </div>

  @if(!isView) {
  <div class="title-wrapper">{{ isCreate ? 'เพิ่มผู้ขาย' : 'แก้ไขผู้ขาย' }}</div>
  <div class="page-content-inner">
    <div class="detail-form-wrapper container-fluid gx-0">
      <div class="row">
        <div class="col-12 col-sm-8">
          <div class="col-12 mb-3">
            <span class="title-blue">ข้อมูลผู้ขาย</span>
          </div>

          <div class="row">
            <div class="col-12 col-lg-6">
              <label
                class="form-label"
                [class.text-danger]="form.controls['code'].touched && form.controls['code'].invalid">
                รหัสผู้ขาย <span class="text-danger">*</span>
              </label>

              <app-input [control]="form.controls['code']">
                @if (form.controls['code'].hasError('required')) {
                <ng-container error>กรุณากรอกข้อมูล</ng-container>
                } @else if (form.controls['code'].hasError('maxlength')) {
                <ng-container error>กรุณากรอกข้อมูลไม่เกิน 10 ตัวอักษร</ng-container>
                } @else if (form.controls['code'].hasError('formApi')) {
                <ng-container error>{{ form.controls['code'].errors?.['formApi']}}</ng-container>
                }
              </app-input>
            </div>

            <div class="col-12 col-lg-6">
              <label
                class="form-label"
                [class.text-danger]="form.controls['name'].touched && form.controls['name'].invalid">
                ชื่อผู้ขาย <span class="text-danger">*</span>
              </label>

              <app-input [control]="form.controls['name']">
                @if (form.controls['name'].hasError('required')) {
                <ng-container error>กรุณากรอกข้อมูล</ng-container>
                } @else if (form.controls['name'].hasError('maxlength')) {
                <ng-container error>กรุณากรอกข้อมูลไม่เกิน 100 ตัวอักษร</ng-container>
                } @else if (form.controls['name'].hasError('formApi')) {
                <ng-container error>{{ form.controls['name'].errors?.['formApi']}}</ng-container>
                }
              </app-input>
            </div>

            <div class="col-12 col-lg-6">
              <label
                class="form-label"
                [class.text-danger]="form.controls['tax_id'].touched && form.controls['tax_id'].invalid">
                เลขประจำตัวผู้เสียภาษี
              </label>

              <app-input [control]="form.controls['tax_id']">
                @if (form.controls['tax_id'].hasError('pattern')) {
                <ng-container error>เลขประจำตัวผู้เสียภาษีไม่ถูกต้อง</ng-container>
                } @else if (form.controls['tax_id'].hasError('formApi')) {
                <ng-container error>{{ form.controls['tax_id'].errors?.['formApi']}}</ng-container>
                }
              </app-input>
            </div>

            <div class="col-12 col-lg-6">
              <label
                class="form-label"
                [class.text-danger]="form.controls['address'].touched && form.controls['address'].invalid">
                ที่อยู่ผู้ขาย
              </label>
              <app-text-area
                [control]="form.controls['address']"
                remainingText="250"
                maxlength="250">
              </app-text-area>
            </div>
          </div>

          <div class="col-12 mb-3">
            <span class="title-blue">ข้อมูลติดต่อ</span>
          </div>

          <div class="row">
            <div class="col-12 col-lg-6">
              <label
                class="form-label"
                [class.text-danger]="form.controls['telephone'].touched && form.controls['telephone'].invalid">
                เบอร์โทรศัพท์
              </label>

              <app-input [control]="form.controls['telephone']">
                @if (form.controls['telephone'].hasError('pattern')) {
                <ng-container error>เบอร์โทรศัพท์ไม่ถูกต้อง</ng-container>
                } @else if (form.controls['telephone'].hasError('maxlength')) {
                <ng-container error>กรุณากรอกข้อมูลไม่เกิน 100 ตัวอักษร</ng-container>
                } @else if (form.controls['telephone'].hasError('formApi')) {
                <ng-container error
                  >{{ form.controls['telephone'].errors?.['formApi']}}</ng-container
                >
                }
              </app-input>
            </div>

            <div class="col-12 col-lg-6">
              <label
                class="form-label"
                [class.text-danger]="form.controls['email'].touched && form.controls['email'].invalid">
                อีเมล
              </label>

              <app-input [control]="form.controls['email']">
                @if (form.controls['email'].hasError('pattern')) {
                <ng-container error>รูปแบบอีเมลไม่ถูกต้อง</ng-container>
                } @else if (form.controls['email'].hasError('maxlength')) {
                <ng-container error>กรุณากรอกข้อมูลไม่เกิน 100 ตัวอักษร</ng-container>
                } @else if (form.controls['email'].hasError('formApi')) {
                <ng-container error>{{ form.controls['email'].errors?.['formApi']}}</ng-container>
                }
              </app-input>
            </div>
          </div>

          <div class="col-12 mb-3">
            <span class="title-blue">อื่นๆ</span>
          </div>

          <div class="col-12">
            <label class="form-label"> ไฟล์แนบ </label>

            <div class="upload-button-wrapper d-flex align-items-center">
              <button matRipple class="btn btn-outline-secondary" (click)="inputUpload.click()">
                <span class="icon material-symbols-outlined fill">add_circle</span>
                อัปโหลด
              </button>

              <input
                type="file"
                #inputUpload
                multiple
                class="d-none"
                (change)="uploadFile($event)" />

              <div class="upload-info-text">สูงสุด 3 ไฟล์ ขนาดไฟล์ไม่เกิน 10 Mb</div>
            </div>

            @if (errorUpload) {
            <div class="text-danger font-12 my-1">{{ errorUpload }}</div>
            }

            <div class="upload-file-name-wrapper">
              @for (f of files; track $index) {
              <div class="upload-file-item-wrapper">
                <div class="upload-file-wrapper">
                  <span class="icon-16 icon-grey material-symbols-outlined fill">attach_file</span>
                  <div class="upload-file-name">{{ f.name }}</div>
                </div>

                <button matRipple class="btn btn-icon" (click)="delFile($index)">
                  <span class="icon material-symbols-outlined fill">delete</span>
                </button>
              </div>
              }

              <!-- <div class="upload-file-error">เกิดข้อผิดพลาด กรุณาอัปโหลดไฟล์อีกครั้ง</div> -->
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-4">
          <div class="col-12 mb-3">
            <span class="title-blue">ข้อมูลธนาคาร</span>
          </div>

          <div class="col-12">
            <label
              class="form-label"
              [class.text-danger]="form.controls['bank_id'].touched && form.controls['bank_id'].invalid">
              ชื่อธนาคาร
            </label>
            <app-select
              [control]="form.controls['bank_id']"
              [datas]="list.bank"
              fieldValue="id"
              [search]="['name']"
              placeholder="เลือก">
              <ng-template #optionRef let-option>
                <div class="d-flex align-items-center gap-2">
                  <img
                    [alt]="option.name"
                    src="/assets/images/logos/banks/{{ option.code }}.png"
                    width="20" />
                  {{ option.name }}
                </div>
              </ng-template>
              <!-- @if (form.controls['bank_id'].hasError('required')) {
              <ng-container error>กรุณาเลือกข้อมูล</ng-container>
              } -->
            </app-select>
          </div>

          <div class="col-12">
            <label
              class="form-label"
              [class.text-danger]="form.controls['bank_account'].touched && form.controls['bank_account'].invalid">
              เลขที่บัญชีธนาคาร
            </label>
            <app-input [control]="form.controls['bank_account']">
              @if (form.controls['bank_account'].hasError('pattern')) {
              <ng-container error>เลขที่บัญชีไม่ถูกต้อง</ng-container>
              }@else if (form.controls['bank_account'].hasError('formApi')) {
              <ng-container error
                >{{ form.controls['bank_account'].errors?.['formApi']}}</ng-container
              >
              }
            </app-input>
          </div>

          <div class="col-12">
            <label
              class="form-label"
              [class.text-danger]="form.controls['bank_account_name'].touched && form.controls['bank_account_name'].invalid">
              ชื่อบัญชีธนาคาร
            </label>
            <app-input [control]="form.controls['bank_account_name']">
              @if (form.controls['bank_account_name'].hasError('maxlength')) {
              <ng-container error>กรุณากรอกข้อมูลไม่เกิน 100 ตัวอักษร</ng-container>
              } @else if (form.controls['bank_account_name'].hasError('formApi')) {
              <ng-container error
                >{{ form.controls['bank_account_name'].errors?.['formApi']}}</ng-container
              >
              }
            </app-input>
          </div>

          <div class="col-12">
            <label
              class="form-label"
              [class.text-danger]="form.controls['branch_code'].touched && form.controls['branch_code'].invalid">
              รหัสสาขา
            </label>
            <app-input [control]="form.controls['branch_code']">
              @if (form.controls['branch_code'].hasError('pattern')) {
              <ng-container error>กรุณากรอกตัวเลข (0-9) เท่านั้น</ng-container>
              } @else if (form.controls['branch_code'].hasError('maxlength')) {
              <ng-container error>กรุณากรอกข้อมูลไม่เกิน 50 ตัวอักษร</ng-container>
              } @else if (form.controls['branch_code'].hasError('formApi')) {
              <ng-container error
                >{{ form.controls['branch_code'].errors?.['formApi']}}</ng-container
              >
              }</app-input
            >
          </div>

          <div class="col-12">
            <label
              class="form-label"
              [class.text-danger]="form.controls['branch_name'].touched && form.controls['branch_name'].invalid">
              ชื่อสาขา
            </label>
            <app-input [control]="form.controls['branch_name']">
              @if (form.controls['branch_name'].hasError('maxlength')) {
              <ng-container error>กรุณากรอกข้อมูลไม่เกิน 100 ตัวอักษร</ng-container>
              } @else if (form.controls['branch_name'].hasError('formApi')) {
              <ng-container error
                >{{ form.controls['branch_name'].errors?.['formApi']}}</ng-container
              >
              }</app-input
            >
          </div>

          <div class="col-12 mb-3">
            <span class="title-blue">การใช้งาน</span>
          </div>

          <div class="col-12">
            <label class="form-label"> สถานะการใช้งาน </label>

            <div>
              <mat-slide-toggle
                hideIcon="true"
                color="primary"
                class="slide-toggle"
                [formControl]="form.controls.is_active">
                ใช้งาน
              </mat-slide-toggle>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-button-wrapper d-flex justify-content-end">
      <button matRipple class="btn btn-outline-secondary btn-md" (click)="toList()">ยกเลิก</button>
      <button matRipple class="btn btn-primary btn-md" (click)="onConfirm()">บันทึก</button>
    </div>
  </div>
  } @else {
  <div class="title-wrapper">รายละเอียดผู้ขาย</div>
  <div class="page-content-inner">
    <div class="top-content-wrapper align-items-center flex-wrap mb-3">
      <div class="top-content-left"><div class="title-blue">รายละเอียดผู้ขาย</div></div>
      <div class="top-content-right">
        @if (profile.permissions.checkAccess([APP_PERMISSION.SUPPLIER], [ACTION_ACCESS.DELETE])) {
        <button matRipple (click)="confirmDelete()" class="btn btn-outline-danger">
          <span class="icon material-symbols-outlined fill">delete</span>
          ลบข้อมูล
        </button>
        }
        <!-- ~ -->
        @if (profile.permissions.checkAccess([APP_PERMISSION.SUPPLIER], [ACTION_ACCESS.UPDATE])) {
        <button
          matRipple
          class="btn btn-primary"
          [routerLink]="['/supplier/edit/' + dataManage?.id]">
          <span class="icon material-symbols-outlined fill">edit</span>
          แก้ไขข้อมูล
        </button>
        }
      </div>
    </div>
    @if (dataManage) {
    <div class="detail-form-wrapper container-fluid gx-0">
      <div class="row">
        <div class="col-12 col-sm-8">
          <div class="col-12 mb-3">
            <span class="title-blue">ข้อมูลผู้ขาย</span>
          </div>

          <div class="row">
            <div class="col-12 col-lg-6 mb-3">
              <label class="form-label neutral-500"> ชื่อผู้ขาย </label>
              <div class="view-data-text">
                <app-text-tooltip> {{ dataManage.name || '-' }} </app-text-tooltip>
              </div>
            </div>

            <div class="col-12 col-lg-6 mb-3">
              <label class="form-label neutral-500"> รหัสผู้ขาย </label>
              <div class="view-data-text">{{ dataManage.code }}</div>
            </div>

            <div class="col-12 col-lg-6 mb-3">
              <label class="form-label neutral-500"> ที่อยู่ผู้ขาย </label>
              <div class="view-data-text text-pre-line">
                <app-text-tooltip> {{ dataManage.address || '-' }} </app-text-tooltip>
              </div>
            </div>
          </div>

          <div class="col-12 mb-3">
            <span class="title-blue">ข้อมูลติดต่อ</span>
          </div>

          <div class="row">
            <div class="col-12 col-lg-6 mb-3">
              <label class="form-label neutral-500"> เบอร์โทรศัพท์ </label>
              <div class="d-flex align-items-center gap-2">
                <span class="icon-16 icon-dark material-symbols-outlined fill">call</span>
                <div class="view-data-text">{{ dataManage.telephone }}</div>
              </div>
            </div>

            <div class="col-12 col-lg-6 mb-3">
              <label class="form-label neutral-500"> อีเมล </label>
              <div class="d-flex align-items-center gap-2">
                <span class="icon-16 icon-dark material-symbols-outlined fill">mail</span>
                <div class="view-data-text">
                  <app-text-tooltip> {{ dataManage.email || '-' }} </app-text-tooltip>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 my-3">
            <span class="title-blue">ข้อมูลธนาคาร</span>
          </div>

          <div class="row">
            <div class="col-12 col-lg-6 mb-3">
              <label class="form-label neutral-500"> ชื่อธนาคาร </label>
              <div class="d-flex align-items-center gap-2">
                @if (dataManage.bank_id) {
                <img
                  alt="K Bank"
                  src="/assets/images/logos/banks/{{ dataManage.bank.code }}.png"
                  width="20" />
                }
                <div class="view-data-text">{{ dataManage.bank.name }}</div>
              </div>
            </div>

            <div class="col-12 col-lg-6 mb-3">
              <label class="form-label neutral-500"> เลขที่บัญชีธนาคาร </label>
              <div class="view-data-text">{{ dataManage.bank_account }}</div>
            </div>

            <div class="col-12 col-lg-6 mb-3">
              <label class="form-label neutral-500"> ชื่อบัญชีธนาคาร </label>
              <div class="view-data-text">
                <app-text-tooltip> {{ dataManage.bank_account_name || '-' }} </app-text-tooltip>
              </div>
            </div>

            <div class="col-12 col-lg-6 mb-3">
              <label class="form-label neutral-500"> รหัสสาขา </label>
              <div class="view-data-text">
                <app-text-tooltip> {{ dataManage.branch_code || '-' }} </app-text-tooltip>
              </div>
            </div>

            <div class="col-12 col-lg-6 mb-3">
              <label class="form-label neutral-500"> ชื่อสาขา </label>
              <div class="view-data-text">
                <app-text-tooltip> {{ dataManage.branch_name || '-' }} </app-text-tooltip>
              </div>
            </div>
          </div>

          <div class="col-12 my-3">
            <span class="title-blue">อื่นๆ</span>
          </div>

          <div class="row">
            <div class="col-12">
              <label class="form-label neutral-500"> ไฟล์แนบ </label>

              <div class="upload-file-name-wrapper view-file-name mt-0">
                @for (f of dataManage.files; track $index) {
                <div class="upload-file-item-wrapper">
                  <div class="upload-file-wrapper">
                    <span class="icon-16 icon-grey material-symbols-outlined fill"
                      >attach_file</span
                    >
                    <div class="upload-file-name">{{ f.file.name }}</div>
                  </div>

                  <div class="d-flex align-items-center gap-2">
                    <!-- <a [href]="f.file.thumbnail" target="_blank">
                      <button matRipple class="btn btn-transparent btn-text-primary">
                        ดูข้อมูล
                      </button>
                    </a>
                    <div class="separator">|</div> -->
                    <!-- <a [href]="f.file.thumbnail" [download]="f.file.name"> -->
                    <button
                      matRipple
                      class="btn btn-transparent btn-text-primary"
                      (click)="downloadFile(f.file)">
                      ดาวน์โหลด
                    </button>
                    <!-- </a> -->
                  </div>
                </div>
                }
              </div>
            </div>
          </div>

          <div class="col-12 my-3">
            <span class="title-blue">การใช้งาน</span>
          </div>

          <div class="row">
            <div class="col-12 col-lg-6 mb-3">
              <label class="form-label neutral-500"> สถานะการใช้งาน </label>
              <app-status-user [status]="dataManage.is_active ? 'active' : 'inactive'">
                {{ dataManage.is_active ? 'ใช้งาน' : 'ไม่ใช้งาน' }}
              </app-status-user>
            </div>
          </div>
        </div>
      </div>
    </div>
    }

    <div class="footer-button-wrapper d-flex justify-content-end">
      <button matRipple class="btn btn-outline-secondary min-w-125px" (click)="toList()">
        กลับสู่หน้าข้อมูลผู้ขาย
      </button>
    </div>
  </div>
  }
</div>
