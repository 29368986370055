import { Component, Inject, Input, PLATFORM_ID } from '@angular/core'
import { RouterModule } from '@angular/router'
import { COMPANY_ID, formatPrice } from '../../../helpers'
import { ProductDotColorComponent } from '../product-dot-color/product-dot-color'
import { defaultImage, ProductData } from '../../../helpers/preview/product'
import { Service } from '../../../services/service'

@Component({
  selector: 'app-card-product-bnh',
  templateUrl: './card-product-bnh.html',
  styleUrl: './card-product-bnh.scss',
  standalone: true,
  imports: [],
})
export class CardProductBnhComponent {
  @Input() productData!: ProductData

  readonly formatPrice = formatPrice

  get defaultUrl() {
    return '/assets/images/default-image-product.png'
  }

  get imageSrc() {
    return this.productData.colorDetail?.product_color?.file?.url
    // return this.colorDetails[this.index]?.product_color?.file?.url;
  }

  constructor(private service: Service) {}

  ngOnInit(): void {}
}
