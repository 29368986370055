import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core'
import { MatFormFieldModule, MatSuffix } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import 'moment/locale/th'
import 'moment/locale/en-gb'
import { AbstractControl, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms'
import { CommonModule } from '@angular/common'
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu'

const times = {
  HH: <string[]>[],
  mm: <string[]>[],
}

for (let index = 0; index < 24; index++) {
  times.HH.push(`0${index}`.slice(-2))
}

for (let index = 0; index < 60; index++) {
  times.mm.push(`0${index}`.slice(-2))
}

@Component({
  selector: 'app-time-picker[control]',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    FormsModule,
    MatSuffix,
    MatInputModule,
    MatMenuModule,
  ],
  templateUrl: './time-picker.html',
  styleUrls: ['./time-picker.scss'],
})
export class TimePickerComponent implements OnInit {
  @ViewChild('boxHours') boxHours!: ElementRef
  @ViewChild('boxMinute') boxMinute!: ElementRef

  @Input() control!: FormControl | AbstractControl
  @Input() placeholder = '00:00'
  @Input() label = ''
  @Input() disabled = false
  @Input() min: any
  @Input() max: any
  @Output() onChange = new EventEmitter()

  get formControl() {
    if (this.control instanceof AbstractControl) {
      return this.control as FormControl
    }
    return this.control
  }

  times = times

  get time() {
    const [HH, mm] = (this.formControl.value || '').split(':')
    return {
      HH,
      mm,
    }
  }

  constructor() {}

  ngOnInit() {}

  onOpen() {
    for (const element of this.boxHours.nativeElement.children) {
      if (element.classList.contains('active')) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' })
        break
      }
    }

    for (const element of this.boxMinute.nativeElement.children) {
      if (element.classList.contains('active')) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' })
        break
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['disabled']) {
      if (changes['disabled'].currentValue) {
        this.control.disable()
      } else {
        this.control.enable()
      }
    }
  }

  setTime(HH: string, mm: string) {
    this.formControl.setValue(`${HH || '00'}:${mm || '00'}:${mm == '59' ? '59' : '00'}`)
  }
}
