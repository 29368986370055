export const status_product_ids_list = [
    {
        id: 1,
        name: 'สินค้าใหม่',
    },
    {
        id: 2,
        name: 'สินค้าลดราคา',
    },
    {
        id: 3,
        name: 'สินค้า Flash Sales',
    }
]

export const status_stock_ids_list = [
    {
        id: 1,
        name: 'สต๊อกเท่ากัน',
    },
    {
        id: 2,
        name: 'สต๊อกรออัปเดต',
    }
]

export const status_ids_list = [
    {
        id: 1,
        name: 'รอสินค้า',
    },
    {
        id: 2,
        name: 'มีสินค้า',
    },
    {
        id: 3,
        name: 'รอชำระเงิน',
    }
]

