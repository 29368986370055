<div mat-dialog-title class="dialog-title">
  <div class="dialog-title-head">{{data.title}}</div>
  <button class="btn btn-transparent btn-icon" (click)="onClose()">
    <img src="../assets/images/icons/icon-close.svg" />
  </button>
</div>

<mat-dialog-content>
  <div class="modal-content">
    @if(!productList.length) {
    <div class="detail-list-wrapper">
      <ul class="detail-list">
        <li>
          ดาวน์โหลดตัวอย่างการกรอกข้อมูลสินค้าได้ที่นี่
          <span class="template-link" (click)="downloadTemplate()">template.xls</span>
        </li>
        <li>ระบบยอมรับเฉพาะไฟล์ Excel (.xlsx) เท่านั้น</li>
        <li>จำกัดขนาดไฟล์ไม่เกิน 10 MB</li>
        <li>ห้ามกรอกรหัสสินค้าซ้ำกับข้อมูลในระบบ</li>
        <li>ตรวจสอบข้อมูลที่เว้นว่าง หรือไม่มีข้อมูลให้ระบุเป็น “ - ”</li>
        <li>การกรอกสินค้าย่อยหลายรายการ ให้กรอกข้อมูลสินค้าหลักเหมือนกัน</li>
      </ul>
    </div>

    <input
      id="upload"
      type="file"
      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      class="d-none"
      #fileUpload
      (change)="onChangeUpload(fileUpload)" />

    @if(!file) {
    <label
      for="upload"
      class="upload-file-wrapper"
      (dragover)="dragOver($event)"
      (drop)="drop($event)"
      [class.border-danger]="fileErrorMessage">
      <div class="upload-file-text-wrapper h-100">
        <span class="icon material-symbols-outlined icon-upload">drive_folder_upload</span>
        <div class="upload-file-text">ลากวาง หรือ เลือกไฟล์อัปโหลด</div>
        <div class="file-type">.xlsx</div>
      </div>
    </label>
    @if (fileErrorMessage) {
    <div class="mt-1 text-danger text-error">{{ fileErrorMessage }}</div>
    } } @else { @if(uploadError) {
    <div class="upload-file-wrapper upload-error-section">
      <div class="upload-file-text-wrapper">
        <div class="xlsx-icon">
          <img alt="icon" src="/assets/images/icons/icon-excel.svg" />
        </div>
        <div class="file-name-wrapper">
          <div class="file-name">{{ file.name }}</div>
          <button class="btn btn-transparent btn-icon" (click)="resetUpload()">
            <img alt="icon" src="/assets/images/icons/icon-close-circle-red.svg" />
          </button>
        </div>

        <div class="upload-error-text-wrapper">
          <div class="upload-error-text">
            รูปแบบการอัปโหลดไม่ถูกต้อง ตรวจสอบข้อมูลและอัปโหลดใหม่อีกครั้ง
          </div>
          <div class="upload-error-text">Format Error:</div>
          <div class="error-text-row-wrapper">
            @for (err of uploadError; track $index) {
            <div class="error-text-row">
              <!-- <div class="error-row">แถว: 33 คอลัมน์:</div> -->
              <div class="error-info">{{ err.message }}</div>
            </div>
            }
          </div>
        </div>
      </div>
    </div>

    <div class="btn-upload-wrapper">
      <button class="btn btn-outline-secondary btn-upload" (click)="fileUpload.click()">
        อัปโหลดไฟล์ใหม่
      </button>
    </div>
    } @else {
    <div class="upload-file-wrapper file-name-section">
      <div class="upload-file-text-wrapper h-100">
        <div class="xlsx-icon">
          <img alt="icon" src="/assets/images/icons/icon-excel.svg" />
        </div>
        <div class="file-name-wrapper">
          <div class="file-name">{{ file.name }}</div>
          <button class="btn btn-transparent btn-icon" (click)="resetUpload()">
            <span class="icon material-symbols-outlined fill icon-cancel">cancel</span>
          </button>
        </div>
      </div>
    </div>
    } } } @else {
    <div class="table-section-wrapper">
      <div class="table-responsive">
        <table class="table table-fixed-column">
          <colgroup>
            <col width="180" />
            <col width="300" />
            <col width="120" />
            <col width="120" />
            <col width="110" />
            <col width="110" />
            <col width="140" />
            <col width="180" />
            <col width="180" />
            <col width="300" />
            <col width="100" />
            <col width="180" />
            <col width="180" />
            <col width="130" />
            <col width="100" />
            <col width="150" />
            <col width="160" />
            <col width="150" />
            <col width="180" />
            <col width="300" />
            <col width="110" />
            <col width="110" />
            <col width="140" />
            <col width="180" />
            <col width="100" />
            <col width="160" />
            <col width="150" />
            <col width="180" />
          </colgroup>

          <thead>
            <tr class="tr-upload-file">
              <th class="th-blue text-center" colspan="18">สินค้าหลัก</th>
              <th class="th-orange text-center" colspan="10">สินค้าย่อย</th>
            </tr>
            <tr class="tr-upload-file">
              <th>รหัสสินค้า</th>
              <th>ชื่อสินค้า</th>
              <th>หมวดหมู่</th>
              <th>แบรนด์</th>
              <th>สี</th>
              <th>ขนาด</th>
              <th>ราคาขาย (บาท)</th>
              <th>เปอร์เซ็นต์ที่ลด</th>
              <th>บาร์โค้ด</th>
              <th>รายละเอียดสินค้า</th>
              <th>เพศ</th>
              <th>แท็กสินค้า</th>
              <th>เข้าร่วมรายการสะสมกะรัต</th>
              <th>ใช้งาน / ไม่ใช้งาน</th>
              <th>สต๊อก</th>
              <th>ผู้ขาย</th>
              <th>ต้นทุนต่อหน่วย (บาท)</th>
              <th>ต้นทุนรวม (บาท)</th>

              <th>รหัสสินค้า</th>
              <th>ชื่อสินค้า</th>
              <th>สี</th>
              <th>ขนาด</th>
              <th>ราคาขาย (บาท)</th>
              <th>ราคาสมาชิก (บาท)</th>
              <th>สต๊อก</th>
              <th>ต้นทุนต่อหน่วย (บาท)</th>
              <th>ต้นทุนรวม (บาท)</th>
              <th>บาร์โค้ด</th>
            </tr>
          </thead>

          <tbody>
            @for (item of pageList; track $index) {
            <tr class="tr-upload-file">
              <td>{{ item.product_code || '-' }}</td>
              <td>{{ item.product_name || '-' }}</td>
              <td>{{ item.product_category || '-' }}</td>
              <td>{{ item.product_brand || '-' }}</td>
              <td>{{ item.product_color || '-' }}</td>
              <td>{{ item.product_size || '-' }}</td>
              <td>{{ formatPrice(item.product_selling_price) }}</td>
              <td>{{ item.product_percent_discount ? formatNoDecimal(item.product_percent_discount) : '-' }}</td>
              <td>{{ item.product_barcode || '-' }}</td>
              <td>{{ item.product_detail || '-' }}</td>
              <td>{{ gender.get(item.product_gender) || '-' }}</td>
              <td>{{ item.product_tags || '-' }}</td>
              <td>{{ item.product_is_carat }}</td>
              <td>{{ item.product_is_active }}</td>
              <td>{{ formatPriceAutoDecimal(item.product_stock) }}</td>
              <td>{{ item.product_supplier || '-' }}</td>
              <td>{{ formatPrice(item.product_cost) }}</td>
              <td>{{ formatPrice(item.product_total_cost) }}</td>

              <td>{{ item.detail_code || '-' }}</td>
              <td>{{ item.detail_name || '-' }}</td>
              <td>{{ item.detail_color || '-' }}</td>
              <td>{{ item.detail_size || '-' }}</td>
              <td>
                {{ item.detail_selling_price ? formatPrice(item.detail_selling_price) : '-' }}
              </td>
              <td>
                {{ item.detail_member_price ? formatPrice(item.detail_member_price) : '-' }}
              </td>
              <td>{{ item.detail_stock ? formatPriceAutoDecimal(item.detail_stock) : '-' }}</td>
              <td>{{ item.detail_cost ? formatPrice(item.detail_cost) : '-' }}</td>
              <td>{{ item.detail_total_cost ? formatPrice(item.detail_total_cost) : '-' }}</td>
              <td>{{ item.detail_barcode || '-' }}</td>
            </tr>
            }
          </tbody>
        </table>
      </div>

      <!-- Pagination -->
      @if (productList.length) {
      <div class="pagination-wrapper">
        <mat-paginator
          [pageIndex]="paginationProduct.data.pageIndex"
          [length]="paginationProduct.data.length"
          [pageSize]="paginationProduct.data.pageSize"
          [pageSizeOptions]="paginationProduct.data.pageSizeOptions"
          (page)="paginationProduct.setFromPaginator($event)"
          aria-label="Select page">
        </mat-paginator>
      </div>
      }
    </div>
    }
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="footer-button-wrapper d-flex justify-content-end w-100">
    <button matRipple class="btn btn-outline-secondary btn-md" (click)="onClose()">ยกเลิก</button>
    @if (!productList.length) {
    <button
      matRipple
      class="btn btn-primary btn-md"
      [disabled]="!file || uploadError"
      (click)="checkImport()">
      ตรวจสอบ
    </button>
    } @else {
    <button matRipple class="btn btn-primary btn-md" (click)="onSaveUpload()">บันทึก</button>
    }
  </div>
</mat-dialog-actions>
