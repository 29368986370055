@if (show && !inline) {
<div class="mask-spinner">&nbsp;</div>
}
<div [@openClose]="show ? 'open' : 'closed'" class="overlay-spinner" [class.inline]="inline">
  <div class="d-flex w-100 h-100 justify-content-center align-items-center flex-column text-center">
    @if (inline) {
    <div
      class="loader"
      [style]="{
        width: size,
        height: size,
        borderWidth
      }"></div>
    } @else {
    <div class="loader"></div>
    }
    <!-- <mat-spinner color="accent" strokeWidth="5" [diameter]="70"></mat-spinner> -->
  </div>
</div>
