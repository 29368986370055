import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core'
import { Router } from '@angular/router'
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms'
import { imports } from '../../../imports'
import { InputComponent } from '../../../components/input/input'
import { TextareaComponent } from '../../../components/text-area/text-area'
import {
  EmailReg,
  ValidateForm,
  setErrorForm,
  ValidateArrayForm,
  Moment,
  convertDateToApi,
  Pagination,
  sortList,
  sortWishList,
  productImage,
} from '../../../helpers'
import { UploadImageProfileComponent } from '../../../components/upload-image-profile/upload-image-profile'
import { ModalLeaveComponent } from '../../../components/modal-leave/modal-leave'
import { MatDialog } from '@angular/material/dialog'
import { CompanyProfileService, CaratService, ProductService } from '../../../services'
import { CustomSnackBar } from '../../../components/snackbar/snackbar'
import { ACTION_ACCESS, APP_PERMISSION, Loading, Profile } from '../../../globals'
import { forkJoin } from 'rxjs'
import { ModalConfirmComponent } from '../../../components/modal-confirm/modal-confirm'
import { InputNumberComponent } from '../../../components/input-number/input-number'
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
  CdkDrag,
  CdkDropList,
} from '@angular/cdk/drag-drop'
import { DatePickerComponent } from '../../../components/date-picker/date-picker'
import { SelectSortComponent } from '../../../components/select-sort/select-sort'
import { CheckboxAllTableComponent } from '../../../components/checkbox-table/checkbox-all-table.component'
import { CheckboxTableComponent } from '../../../components/checkbox-table/checkbox-table.component'
import { SortByComponent } from '../../../components/sort-by/sort-by'
import { StatusUserComponent } from '../../../components/status-user/status-user'
import { ModalDeleteComponent } from '../../../components/modal-delete/modal-delete'

@Component({
  selector: 'app-tab-products-wish-list',
  standalone: true,
  imports: [
    ...imports,
    InputComponent,
    TextareaComponent,
    UploadImageProfileComponent,
    ModalLeaveComponent,
    InputNumberComponent,
    DatePickerComponent,
    CdkDrag,
    CdkDropList,
    SelectSortComponent,
    CheckboxAllTableComponent,
    CheckboxTableComponent,
    SortByComponent,
    StatusUserComponent,
  ],
  templateUrl: './tab-products-wish-list.html',
  styleUrls: ['./tab-products-wish-list.scss'],
})
export class TabProductsWishListComponent implements OnInit {
  @Output() setItemCheckedWistList = new EventEmitter()
  @Output() onSearch = new EventEmitter()
  @Input() onSearchWistList: boolean = false
  @Input() filterTableProduct: any

  readonly APP_PERMISSION = APP_PERMISSION
  readonly ACTION_ACCESS = ACTION_ACCESS
  readonly Moment = Moment

  isEdit: boolean = false
  isView: boolean = false

  sortControl = new FormControl(sortWishList[0].value)
  readonly paginationProduct = new Pagination({
    sortName: sortWishList[0].value.sortName,
    sortBy: sortWishList[0].value.sortBy as 'asc' | 'desc',
  })
  rerender = false
  pageNumber = new FormControl(1)
  productList: any[] = []
  itemChecked = new Set<any>()
  itemCheckedCancel = new Map()
  itemCheckedSendMail = new Map()

  list = {
    sort: sortWishList,
  }

  constructor(
    public profile: Profile,
    public router: Router,
    public dialog: MatDialog,
    public customSnackBar: CustomSnackBar,
    public loading: Loading,
    public companyProfileService: CompanyProfileService,
    public ProductService: ProductService
  ) {}

  ngOnInit(): void {
    this.getWishList()
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes['onSearchWistList'] &&
      changes['onSearchWistList'].currentValue &&
      !changes['onSearchWistList'].firstChange
    ) {
      this.getWishList()
    }
  }

  getWishList() {
    const pagination = this.paginationProduct.get()
    const dataFilter = this.filterTableProduct.getDataWishList()
    const payload = {
      ...pagination,
      ...dataFilter,
    }

    this.loading.start()
    this.ProductService.getProductWishList(payload).subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          this.productList = res.data.records.map((d: any) => {
            return {
              ...d,
              productUse: d.product_detail_id ? d.product_detail : d.product,
              image: productImage(d.product, d.product_detail),
            }
          })
          this.paginationProduct.setFromResponse(res.data)
          this.pageNumber.setValue(res.data.page)

          this.onSearch.emit()
        } else {
          this.customSnackBar.fail()
        }
      }
      this.loading.stop()
    })
  }

  onSort() {
    this.paginationProduct.setFromPaginator({
      pageIndex: 0,
      ...(this.sortControl.value || {}),
    })
    this.getWishList()
  }

  setItemCheckedData(checked: boolean, datas: any[]) {
    datas.forEach(item => {
      if (checked) {
        if (!item.is_receive_email) {
          this.itemCheckedCancel.set(item.id, {
            id: item.id,
            is_order: item.is_order,
          })
        }

        if (!item.is_receive_email && item.status_type == 2) {
          this.itemCheckedSendMail.set(item.id, {
            id: item.id,
            is_order: item.is_order,
          })
        }
      } else {
        this.itemCheckedCancel.delete(item.id)
        this.itemCheckedSendMail.delete(item.id)
      }
    })
  }

  getCover(item: any) {
    if (item) {
      return item?.files?.find((d: any) => d.is_cover)?.file.url
    } else {
      return false
    }
  }

  sendEmail(id: any, multi: boolean) {
    const payload = {
      filter: {
        ids: multi ? [...this.itemCheckedSendMail.values()].map(item => item.id) : [id],
      },
    }

    this.loading.start()
    this.ProductService.sendMail(payload).subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          for (const id of payload.filter.ids) {
            this.itemChecked.delete(id)
            this.itemCheckedSendMail.delete(id)
            this.itemCheckedCancel.delete(id)
          }
          this.customSnackBar.success('ส่งอีเมลสำเร็จ')
          this.getWishList()
        } else {
          this.customSnackBar.fail()
        }
      }
      this.loading.stop()
    })
  }

  confirmDelete(data: any, multi: boolean) {
    const dialogRefDelete = this.dialog.open(ModalDeleteComponent, {
      data: {
        detail: `คุณต้องการยกเลิกรายการจอง ${
          multi ? data.size + ' รายการ' : '“' + data.product.name + '”'
        } ใช่หรือไม่`,
        title: 'ยืนยันการยกเลิก',
        confirm: 'ยกเลิกจอง',
      },
    })

    dialogRefDelete.afterClosed().subscribe(result => {
      if (result) {
        this.cancelWishList(data.id, multi)
      }
    })

    return dialogRefDelete
  }

  cancelWishList(id: any, multi: boolean) {
    const payload = {
      filter: {
        ids: multi ? [...this.itemCheckedCancel.values()].map(item => item.id) : [id],
      },
    }

    this.loading.start()
    this.ProductService.cancelWishList(payload).subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          for (const id of payload.filter.ids) {
            this.itemCheckedCancel.delete(id)
          }
          this.customSnackBar.success('ยกเลิกสำเร็จ')
          this.getWishList()
        } else {
          this.customSnackBar.fail()
        }
      }
      this.loading.stop()
    })
  }
}
