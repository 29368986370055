import { Component, Inject, Input, PLATFORM_ID, ViewChild } from '@angular/core'
import { PRODUCT_VIEW } from '../../../helpers/preview/product'

@Component({
  selector: 'app-header-bhb',
  templateUrl: './header.html',
  styleUrl: './header.scss',
  standalone: true,
  imports: [],
})
export class HeaderBHBComponent {
  readonly PRODUCT_VIEW = PRODUCT_VIEW

  @Input() lang: 'en' | 'th' = 'en'
  @Input() isLogin = false

  genders: any[] = [
    { name_th: 'ผู้หญิง', name_en: 'Women' },
    { name_th: 'ผู้ชาย', name_en: 'Men' },
    { name_th: 'เด็ก', name_en: 'Kids' },
  ]
  categories: any[] = [
    { name_th: 'กระเป๋า', name_en: 'Bag' },
    { name_th: 'เสื้อผ้า', name_en: 'Clothes' },
    { name_th: 'รองเท้า', name_en: 'Shoes' },
    { name_th: 'เครื่องประดับ', name_en: 'Accessories' },
    { name_th: 'แว่นกันแดด', name_en: 'Sunglasses' },
  ]

  constructor() {}

  ngOnInit(): void {}
}
