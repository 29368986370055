import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { imports } from '../../../imports'
import { ACTION_ACCESS, APP_PERMISSION, Profile } from '../../../globals'
import { CMSHomePaniaComponent } from './pania/cms-home'
import { CMSHomeBHBComponent } from './bhb/cms-home'
import { COMPANY_ID } from '../../../helpers'

@Component({
  selector: 'app-cms-home',
  standalone: true,
  imports: [...imports, CMSHomeBHBComponent, CMSHomePaniaComponent],
  templateUrl: './cms-home.html',
  styleUrls: ['./cms-home.scss'],
})
export class CMSHomeComponent implements OnInit {
  readonly APP_PERMISSION = APP_PERMISSION
  readonly ACTION_ACCESS = ACTION_ACCESS
  readonly COMPANY_ID = COMPANY_ID

  constructor(public profile: Profile, public router: Router) {}

  ngOnInit(): void {}
}
