<div mat-dialog-title class="dialog-title">
  <div class="dialog-title-head">เลือกหมวดหมู่สินค้า</div>
  <button class="btn btn-transparent btn-icon" (click)="onClose()">
    <img src="../assets/images/icons/icon-close.svg" />
  </button>
</div>

<mat-dialog-content>
  <div class="modal-content py-2">
    <div class="row mb-3">
      <div class="col-12 col-sm-6">
        <app-input-search
          [control]="formSearch.controls['search_name']"
          placeholder="ค้นหา"
          (onEnter)="onSearch()" />
      </div>
    </div>

    <div class="table-responsive-detail">
      <table class="table table-fixed-column">
        <thead>
          <tr>
            <th width="50"></th>
            <th width="200">หมวดหมู่สินค้า</th>
            <th class="product-item-head">รายการสินค้า</th>
          </tr>
        </thead>
        <tbody>
          @for (item of categoryList; track $index) {
          <tr>
            <td>
              <div class="radio-button-wrapper">
                <mat-radio-button
                  [value]="item.id"
                  (change)="changeCategory(item.id, item.name)"></mat-radio-button>
              </div>
            </td>
            <td>{{ item.name }}</td>
            <td>{{ item.product_count }}</td>
          </tr>
          }
        </tbody>
      </table>

      @if(!categoryList.length) {
      <div class="data-not-found-wrapper flex-column align-items-center">
        <div>ไม่พบสินค้า</div>
      </div>
      }
    </div>

    @if (categoryList.length) {
    <div class="pagination-wrapper d-flex flex-wrap gap-2">
      <mat-paginator
        [pageIndex]="pagination.data.pageIndex"
        [length]="pagination.data.length"
        [pageSize]="pagination.data.pageSize"
        [pageSizeOptions]="pagination.data.pageSizeOptions"
        (page)="pagination.setFromPaginator($event);getCategoryList()"
        aria-label="Select page">
      </mat-paginator>
    </div>
    }
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="footer-button-wrapper d-flex justify-content-end w-100">
    <button class="btn btn-outline-secondary btn-md" matRipple (click)="onClose()">ยกเลิก</button>
    <button class="btn btn-primary btn-md" matRipple (click)="onConfirm()">ยืนยัน</button>
  </div>
</mat-dialog-actions>
