<mat-form-field
  appearance="outline"
  class="input cursor-pointer"
  [matMenuTriggerFor]="menu"
  #menuTrigger
  (click)="onOpen()"
>
  <input
    matInput
    [formControl]="formControl"
    [placeholder]="placeholder"
    readonly
    class="cursor-pointer"
  />
  @if (formControl.value) {
  <div class="mdc-text-field__input .mat-mdc-form-field-input-control custom-mask">
    <div>{{ time.HH }}:{{ time.mm }}</div>
  </div>
  }
  <div matSuffix class="suffix">
    <img src="/assets/images/icons/icon-time-picker.svg" />
  </div>

  @if (control.invalid) {
  <mat-error>
    <ng-content select="[error]"></ng-content>
  </mat-error>
  }
</mat-form-field>

<mat-menu #menu="matMenu" backdropClass="time-picker-backdrop" class="time-picker-menu-wrapper">
  <div class="d-flex" (click)="$event.stopPropagation();">
    <div class="box-time" #boxHours>
      @for (item of times.HH; track $index) {
      <div
        matRipple
        class="mat-menu-item text-center"
        [class.active]="item == time.HH"
        (click)="setTime(item, time.mm); onChange.emit()"
      >
        {{ item }}
      </div>
      }
    </div>
    <div class="box-time" #boxMinute>
      @for (item of times.mm; track $index) {
      <div
        matRipple
        class="mat-menu-item text-center"
        [class.active]="item == time.mm"
        (click)="setTime(time.HH, item); onChange.emit()"
      >
        {{ item }}
      </div>
      }
    </div>
  </div>
</mat-menu>
