<div class="box-footer mt-5">
  <div class="footer-munu">
    <div class="text-label">{{lang == 'th' ? 'บริษัท' : 'Company'}}</div>
    <a class="text-menu">{{lang == 'th' ? 'แบรนด์ของเรา' : 'Our Brand'}} </a>
  </div>
  <div class="footer-munu">
    <div class="text-label">{{lang == 'th' ? 'บัญชีของฉัน' : 'My Account'}}</div>
    <div class="text-menu">{{lang == 'th' ? 'โปรไฟล์ของฉัน' : 'My Profile'}}</div>
    <div class="text-menu">{{lang == 'th' ? 'ระดับของฉัน' : 'My Tier'}}</div>
    <div class="text-menu">{{lang == 'th' ? 'คูปองของฉัน' : 'My Coupon'}}</div>
    <a class="text-menu">{{lang == 'th' ? 'การติดตาม' : 'Tracking'}}</a>
  </div>
  <div class="footer-munu">
    <div class="text-label">{{lang == 'th' ? 'ความช่วยเหลือ' : 'Help'}}</div>
    <a class="text-menu">{{lang == 'th' ? 'นโยบายความเป็นส่วนตัว' : 'Privacy & Policy'}} </a>
    <a class="text-menu">{{lang == 'th' ? 'ข้อกำหนดและเงื่อนไข' : 'Term & Condition'}} </a>
    <a class="text-menu">{{lang == 'th' ? 'คำถามที่พบบ่อย' : 'FAQs'}} </a>
  </div>
  <div class="footer-munu">
    <div class="text-label">{{lang == 'th' ? 'ติดต่อเรา' : 'Contact Us'}}</div>
    <div class="d-flex flex-wrap gap-3 md:gap-4">
      <img src="/assets/preview/icons/icon-line-black.svg" alt="line" class="footer-icon" />
      <img src="/assets/preview/icons/icon-facebook-black.svg" alt="facebook" class="footer-icon" />
      <img
        src="/assets/preview/icons/icon-instragram-black.svg"
        alt="instragram"
        class="footer-icon" />
      <img src="/assets/preview/icons/icon-tiktok-black.svg" alt="tiktok" class="footer-icon" />
    </div>
  </div>
</div>

<div class="bottom-footer text-center">
  {{lang == 'th' ? '© ลิขสิทธิ์ 2021 Brandname Hunter สงวนลิขสิทธิ์ทุกประการ' : '© Copyright 2021
  Brandname Hunter All Rights Reserved.'}}
</div>
