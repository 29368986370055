import { Component, Input, OnInit } from '@angular/core'
import { RouterOutlet } from '@angular/router'
import { imports } from '../../../../imports'
import { MatTableModule } from '@angular/material/table'
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms'
import { InputNumberComponent } from '../../../../components/input-number/input-number'

@Component({
  selector: 'app-table-size[size_xs][size_ys]',
  standalone: true,
  imports: [...imports, MatTableModule, InputNumberComponent],
  templateUrl: './table-size.html',
  styleUrls: ['./table-size.scss'],
})
export class TableSizeComponent implements OnInit {
  @Input() isCreate: boolean = false
  @Input() isEdit: boolean = false
  @Input() size_xs!: FormArray<type__size_xs>
  @Input() size_ys!: FormArray<type__size_ys>
  @Input() head_name!: FormControl

  displayedColumns: string[] = ['x0', 'x1']
  displayedHead: string[] = ['', '']
  displayedFields: string[] = ['x0', 'x1']
  columnSelect = -1
  tableArray = new FormArray([
    new FormGroup({
      x0: new FormControl(''),
      x1: new FormControl(''),
    }),
  ])
  dataSource = this.tableArray.controls

  get columns() {
    return this.size_ys.value || []
  }

  constructor() {}

  ngOnInit() {
    this.InitData()
    // this.size_xs.valueChanges.subscribe(() => {
    //   this.InitData()
    // })
  }

  InitData() {
    if (this.isCreate) {
      this.addRow()
      this.addColumn()
    }
  }

  selectColumn(index: number, inputField: any) {
    this.columnSelect = index
    setTimeout(() => {
      inputField.focus()
    })
  }

  changeColumnName(index: any, columnTitle: any) {
    if (columnTitle) {
      const oldName = this.displayedFields[index]
      const columnName = columnTitle.replace(/ /g, '').toLowerCase()
      if (columnName != oldName) {
        this.tableArray.controls.forEach((group: FormGroup) => {
          group.addControl(columnName, new FormControl(group.value[oldName]))
          group.removeControl(oldName)
        })
        this.displayedHead.splice(index, 1, columnTitle)
        this.displayedColumns.splice(index, 1, columnName)
        this.displayedFields.splice(index, 1, columnName)
      }
      this.columnSelect = -1
    }
  }

  deleteRow(index: number) {
    this.size_xs.removeAt(index)
  }

  addRow() {
    const size_values = new FormArray<type__size_xs_value>([])

    for (const _ of this.size_ys.controls) {
      size_values.push(
        new FormGroup({
          value: new FormControl('', [Validators.required]),
        })
      )
    }

    this.size_xs.push(
      new FormGroup({
        name: new FormControl('', [Validators.required]),
        size_values,
      })
    )
    // console.log(this.size_xs)
  }

  addColumn() {
    this.size_ys.push(
      new FormGroup({
        name: new FormControl('', [Validators.required]),
      })
    )

    for (const f of this.size_xs.controls) {
      f.controls.size_values.push(
        new FormGroup({
          value: new FormControl('', [Validators.required]),
        })
      )
    }
    // console.log(this.size_ys)
  }

  deleteColumn(index: number) {
    this.size_ys.removeAt(index)

    for (const f of this.size_xs.controls) {
      f.controls.size_values.removeAt(index)
    }
  }
}

export type type__size_xs_value = FormGroup<{ value: FormControl }>

export type type__size_xs = FormGroup<{
  name: FormControl<string | null>
  size_values: FormArray<type__size_xs_value>
}>

export type type__size_ys = FormGroup<{
  name: FormControl<string | null>
}>
