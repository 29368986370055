<div mat-dialog-title class="dialog-title">
  <div class="dialog-title-head">
    {{ data.view == 'create' ? 'ข้อมูลการชำระเงิน' : data.view == 'verify' ? 'หลักฐานการโอนเงิน' :
    'ข้อมูลการโอนเงิน' }}
  </div>
  <button class="btn btn-transparent btn-icon" (click)="onClose()">
    <img src="../assets/images/icons/icon-close.svg" />
  </button>
</div>

<mat-dialog-content>
  <div class="modal-content">
    <div class="payment-slip-wrapper">
      @if (data.view == 'paid') {
      <div class="d-flex flex-column align-items-center gap-2">
        <img
          src="/assets/images/icons/img-payment-success.svg"
          width="128"
          height="100"
          alt="Payment Success" />
        <div class="text-bold color--green-600">ชำระเงินเรียบร้อยแล้ว</div>
      </div>

      <div class="d-flex flex-column gap-1">
        <div class="text-bold color--neutral-950">เลขที่ออเดอร์ {{ data.order.order_no }}</div>
        <div class="d-flex align-items-center gap-2">
          <div class="color--neutral-950">ชื่อลูกค้า:</div>
          <div class="color--neutral-950">
            {{ showCustomerName(data.order.customer_name, data.order.customer_last_name) }}
          </div>
        </div>
      </div>

      <div class="line2 mb-0"></div>
      }
      <!-- ~ -->
      @if (order && payment && order.total <= 0) {
      <div>
        <div class="d-flex gap-2">
          <img src="assets/images/icons/icon-payment-check.svg" />
          <div>
            ชำระเงินด้วยส่วนลดรวม {{ formatPrice(payment.credit_code_price +
            payment.carat_code_price + payment.discount_code_price) }} บาท
          </div>
        </div>
        <div class="d-flex gap-2 mt-1">
          <div>วันเวลาที่ชำระ:</div>
          <div>
            {{ payment.created_at ? Moment(payment.created_at).format('DD-MM-YYYY, HH:mm') : '' }}
          </div>
        </div>
      </div>

      <div class="line2"></div>
      }

      <div class="d-flex flex-column gap-3">
        @if (payment) {
        <!-- ~ -->
        @if (payment.details) {
        <!-- ~ -->
        @for (paymentDetail of payment.details; track $index) {
        <!-- ~ -->
        @if (paymentDetail.file?.url) {
        <div class="box-qr-img">
          <img [src]="paymentDetail.file.url" />
        </div>
        }

        <div class="d-flex flex-column gap-2">
          @for (err of paymentDetail.errors; track $index) {
          <div class="box-log-error d-flex align-items-center gap-2 mt-2">
            <img src="/assets/images/icons/icon-payyment-log-error.svg" alt="ic" />
            {{ err }}
          </div>
          }
        </div>

        <div class="row gx-2 gy-2">
          <div class="col-12 col-sm-4">ช่องทางการชำระเงิน</div>
          <div class="col-12 col-sm-8">
            @if (paymentDetail.payment_type == 1) { โอนเงินผ่านธนาคาร @if
            (paymentDetail.bank_account) {
            <!-- ~ -->
            <div class="d-inline-block">
              <div class="d-flex align-items-center flex-wrap">
                <span>(บัญชีร้านค้า:</span>
                @if (paymentDetail.bank_account?.bank?.code != BANK_NONE.CODE) {
                <img
                  src="/assets/images/logos/banks/{{ paymentDetail.bank_account?.bank?.code }}.png"
                  width="20"
                  height="20"
                  class="ms-1 me-1"
                  [style]="{ 'margin-top': '-3px' }" />
                }
                <span> {{ paymentDetail.bank_account.account_number }}) </span>
              </div>
            </div>
            }
            <!-- ~ -->
            } @else if (paymentDetail.payment_type == 2) { เงินสด } @else if
            (paymentDetail.payment_type == 3) { Credit / Debit Card } @else if
            (paymentDetail.payment_type == 4) { QR Payment }
          </div>
          <div class="col-12 col-sm-4">ยอดชำระ</div>
          <div class="col-12 col-sm-8">{{ formatPrice(paymentDetail?.payment_amount) }} บาท</div>
          <div class="col-12 col-sm-4">วันเวลาที่ชำระ</div>
          <div class="col-12 col-sm-8">
            {{ Moment(paymentDetail.payment_date).format('DD-MM-YYYY, HH:mm') }}
          </div>
          <div class="col-12 col-sm-4">สถานะการชำระ</div>
          <div class="col-12 col-sm-8">
            @if (!paymentDetail.is_paid && !paymentDetail.is_paid_success) {
            <span class="paid-pending">รอชำระเงิน</span>
            } @else if (paymentDetail.is_paid && !paymentDetail.is_paid_success) {
            <span class="paid-fail">ไม่สำเร็จ</span>
            } @else {
            <span class="paid-success">สำเร็จ</span>
            }
          </div>
        </div>

        @if (paymentDetail.is_paid && !paymentDetail.is_paid_success) {
        <div>
          <button
            matRipple
            class="btn btn-outline-danger button-del"
            [style]="{ 'min-width': 'max-content' }"
            (click)="confirmDelete(paymentDetail)">
            <span class="icon material-symbols-outlined fill">delete</span>
            ลบข้อมูลการชำระเงิน
          </button>
        </div>
        }

        <div class="line2 mb-1"></div>
        <!-- ~ -->
        }
        <!-- ~ -->
        }
        <!-- ~ -->
        }
        <!-- ~ -->
        @for (item of arrayPayments.controls; track item; let index = $index) {
        <div class="title2">ช่องทางการชำระเงิน</div>

        <div class="d-flex gap-2 justify-content-between align-items-center">
          <mat-radio-group
            [formControl]="item.controls['payment']"
            class="d-flex flex-wrap gap-2 align-items-center"
            (change)="resetPayment(item)">
            <div
              class="radio-button-wrapper box-radio {{ item.value['payment'] == 1 && 'active' }} me-2">
              <mat-radio-button [value]="1"> โอนเงินผ่านธนาคาร </mat-radio-button>
            </div>
            <div
              class="radio-button-wrapper box-radio {{ item.value['payment'] == 2 && 'active' }}">
              <mat-radio-button [value]="2"> เงินสด </mat-radio-button>
            </div>
          </mat-radio-group>

          <button
            matRipple
            class="btn btn-outline-danger button-del"
            [style]="{ 'min-width': 'max-content' }"
            (click)="delPayment(index)">
            <span class="icon material-symbols-outlined fill">delete</span>
            ลบ
          </button>
        </div>

        @if (item.value['payment'] == 1) {
        <div>
          <div class="mb-2">ชำระเงินโดยการโอนเงินเข้าบัญชีธนาคาร</div>
          <div class="box-select-slip">
            <app-select
              [control]="item.controls['bank_account_id']"
              [datas]="list.bank_account"
              fieldValue="id"
              placeholder="เลือก"
              type="select-bank">
              <ng-template #optionRef let-option>
                <div class="d-flex align-items-center">
                  @if (option.bank_id) {
                  <img
                    [alt]="option.bank.name"
                    src="/assets/images/logos/banks/{{ option.bank.code }}.png"
                    width="32" />
                  }
                  <div class="ms-2">
                    <b>{{ option.account_name }}</b>
                    <div>เลขที่บัญชี {{ option.account_number}}</div>
                  </div>
                </div>
              </ng-template>

              @if (item.controls['bank_account_id'].hasError('required')) {
              <ng-container error>กรุณาเลือกข้อมูล</ng-container>
              }
            </app-select>
          </div>

          <div class="title2">หลักฐานการโอนเงิน</div>

          <div class="row mt-2 font-14">
            <div
              class="col-12 col-sm-12"
              [class.text-danger]="item.controls['file_id'].touched && item.controls['file_id'].invalid">
              หลักฐานการโอนเงิน <span class="text-danger">*</span>
            </div>

            <div class="col-12 mt-2 mb-3">
              <app-upload-image-profile
                [isPublic]="true"
                [type]="'edit'"
                [logoDefault]="''"
                (upload)="item.controls['file_id'].setValue($event?.id || null)"
                (onDelete)="item.controls['file_id'].reset()"></app-upload-image-profile>

              @if (item.controls['file_id'].touched &&
              item.controls['file_id'].hasError('required')) {
              <div class="text-danger font-12 mt-1">กรุณาเพิ่มรูปภาพ</div>
              }
            </div>

            <div class="col-12 col-sm-12">
              <label
                class="form-label"
                [class.text-danger]="item.controls['bank_id'].touched && item.controls['bank_id'].invalid">
                ธนาคารผู้โอน <span class="text-danger">*</span>
              </label>
              <app-select
                [control]="item.controls['bank_id']"
                [datas]="list.bank"
                fieldValue="id"
                placeholder="เลือก">
                <ng-template #optionRef let-option>
                  <div class="d-flex align-items-center gap-2">
                    @if (option.code != BANK_NONE.CODE) {
                    <img
                      [alt]="option.name"
                      src="/assets/images/logos/banks/{{ option.code }}.png"
                      width="20" />
                    } {{ option.name }}
                  </div>
                </ng-template>

                @if (item.controls['bank_id'].touched &&
                item.controls['bank_id'].hasError('required')) {
                <ng-container error>กรุณาเลือกข้อมูล</ng-container>
                }
              </app-select>
            </div>

            <div class="col-12 col-sm-12">
              <label
                class="form-label"
                [class.text-danger]="item.controls['payment_amount'].touched && item.controls['payment_amount'].invalid">
                ยอดโอน <span class="text-danger">*</span>
              </label>
              <app-input-number
                [control]="item.controls['payment_amount']"
                unit="บาท"
                placeholder="0.00">
                @if (item.controls['payment_amount'].hasError('required')) {
                <ng-container error>กรุณากรอกข้อมูล</ng-container>
                }
              </app-input-number>
            </div>

            <div class="col-12 col-sm-6">
              <label class="form-label"> วันที่โอนเงิน </label>
              <app-date-picker [control]="item.controls['date']" [max]="now">
                @if (item.controls['date'].hasError('required')) {
                <ng-container error>กรุณาเลือกข้อมูล</ng-container>
                }
              </app-date-picker>
            </div>

            <div class="col-12 col-sm-6">
              <label class="form-label"> เวลาที่โอนเงิน </label>
              <app-time-picker [control]="item.controls['time']">
                @if (item.controls['time'].hasError('required')) {
                <ng-container error>กรุณาเลือกข้อมูล</ng-container>
                }
              </app-time-picker>
            </div>
            <div class="line2"></div>
          </div>
        </div>
        } @else if (item.value['payment'] == 2) {
        <div class="col-12 col-sm-12">
          <label
            class="form-label"
            [class.text-danger]="item.controls['payment_amount'].touched && item.controls['payment_amount'].invalid">
            ยอดชำระ <span class="text-danger">*</span>
          </label>
          <app-input-number
            [control]="item.controls['payment_amount']"
            unit="บาท"
            placeholder="0.00">
            @if (item.controls['payment_amount'].hasError('required')) {
            <ng-container error>กรุณากรอกข้อมูล</ng-container>
            }
          </app-input-number>
        </div>
        <div class="line2"></div>
        }
        <!-- ~ -->
        }
        <!-- ~ -->
        @if (isAddPayment) {
        <div>
          <button matRipple class="btn btn-outline-secondary" (click)="addPayment()">
            <span class="icon material-symbols-outlined fill">edit</span>
            เพิ่มการชำระเงินหลายช่องทาง
          </button>
        </div>
        }
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="d-flex">
  <!-- <div class="footer-button-wrapper d-flex justify-content-end w-100">
    <button matRipple class="btn btn-primary btn-md" (click)="onClose()">ปิด</button>
  </div> -->

  <div class="footer-button-wrapper d-flex justify-content-end w-100">
    @if (order?.status_id == ORDER_ID.CANCEL || order?.status_id == ORDER_ID.SHIPMENT) {
    <button matRipple class="btn btn-primary btn-md" (click)="onClose()">ปิด</button>
    } @else {
    <button matRipple class="btn btn-outline-secondary btn-md" (click)="onClose()">ยกเลิก</button>
    <button
      matRipple
      class="btn btn-primary btn-md"
      (click)="onSave()"
      [disabled]="!arrayPayments.value.length && !isDeletePayment">
      บันทึก
    </button>
    }
  </div>
</mat-dialog-actions>
