<div mat-dialog-title class="dialog-title">
  <div class="dialog-title-head">{{data.title}}</div>
  <button class="btn btn-transparent btn-icon" (click)="onClose()">
    <img src="../assets/images/icons/icon-close.svg" />
  </button>
</div>

<mat-dialog-content>
  <div class="modal-content">
    <div class="row">
      <div class="col-12 col-sm-6">
        <div class="filter-item-wrapper">
          <div class="filter-title">วันที่โอนเงิน</div>
          <app-date-picker-range
            [controlStr]="filterTableCustomerOrder.form.controls['start_payment_date']"
            [controlEnd]="filterTableCustomerOrder.form.controls['end_payment_date']"
            [max]="now"></app-date-picker-range>
        </div>
      </div>
      <div class="col-12 col-sm-6">
        <div class="filter-item-wrapper">
          <div class="filter-title">ธนาคาร</div>
          <app-select
            [control]="filterTableCustomerOrder.form.controls['bank_ids']"
            [datas]="filterTableCustomerOrder.list.banks"
            fieldValue="id"
            placeholder="เลือก"
            [multiple]="true">
            <ng-template #optionRef let-option> {{ option.name }} </ng-template>
          </app-select>
        </div>
      </div>
      <div class="col-12 col-sm-6">
        <div class="filter-item-wrapper">
          <div class="filter-title">วันที่ปิดรายการ</div>
          <app-date-picker-range
            [controlStr]="filterTableCustomerOrder.form.controls['start_closed_date']"
            [controlEnd]="filterTableCustomerOrder.form.controls['end_closed_date']"></app-date-picker-range>
        </div>
      </div>
      <div class="col-12 col-sm-6">
        <div class="filter-item-wrapper">
          <div class="filter-title">เลขพัสดุ</div>
          <app-select
            [control]="filterTableCustomerOrder.form.controls['trackings']"
            [datas]="filterTableCustomerOrder.list.trackings"
            fieldValue="value"
            placeholder="เลือก"
            [multiple]="true">
            <ng-template #optionRef let-option> {{ option.value }} </ng-template>
          </app-select>
        </div>
      </div>
      <div class="col-12 col-sm-6">
        <div class="filter-item-wrapper">
          <div class="filter-title">สถานะออเดอร์</div>
          <app-select
            [control]="filterTableCustomerOrder.form.controls['status_ids']"
            [datas]="filterTableCustomerOrder.list.statuses"
            fieldValue="id"
            placeholder="เลือก"
            [multiple]="true">
            <ng-template #optionRef let-option> {{ option.name }} </ng-template>
          </app-select>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="footer-button-wrapper d-flex justify-content-end w-100">
    <button matRipple class="btn btn-outline-secondary btn-md" (click)="onReset()">ล้างค่า</button>
    <button matRipple class="btn btn-primary btn-md" (click)="onConfirm()">นำไปใช้</button>
  </div>
</mat-dialog-actions>
