@if (order) {
<div class="page-main-content">
  <div class="container-log-wrapper">
    <div>
      <div class="title-wrapper">
        {{ order.order_no }}: {{ showCustomerName(order.customer_name, order.customer_last_name) }}
        logs
      </div>

      <div class="page-content-inner">
        <div class="table-responsive table-main-wrapper">
          <table class="table table-fixed-column" [style]="{ 'min-width': '750px' }">
            <thead>
              <tr>
                <th width="100">การกระทำ</th>
                <th width="180">โดย</th>
                <th width="150">วันที่</th>
                <th>สิ่งที่เปลี่ยน</th>
              </tr>
            </thead>

            <tbody>
              @for (item of logList; track item) {
              <tr>
                <td>{{ item.action || '-' }}</td>
                <td>{{ item.created_by?.full_name || '-' }}</td>
                <td>
                  {{ item.created_at ? Moment(item.created_at).format('DD-MM-YYYY HH:mm') : '-' }}
                </td>
                <td>
                  <span class="color--brand-blue-700 text-type-name"
                    >{{ item.log_type_name || '-' }}</span
                  >
                  <!-- @if (item.status_id == ORDER_ID.VERIFY && item.file_id) {
                  <br />
                  <span
                    (click)="openModalSlip('หลักฐานการโอนเงิน', item)"
                    class="color--brand-blue cursor-pointer">
                    หลักฐานการโอนเงิน
                  </span>
                  } -->
                  <pre [innerHTML]="item.logs"></pre>
                </td>
              </tr>
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div>
      <div class="title-wrapper">หลักฐานการโอนเงิน</div>

      <div class="d-flex flex-column gap-3">
        @for (item of urlSlips; track item) {
        <div class="box-qr-img">
          <img [src]="item" />
        </div>
        }
      </div>
    </div>
  </div>
</div>
}
