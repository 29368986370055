import { FormControl, FormGroup } from '@angular/forms'
import {
  getSessionStorage,
  removeSessionStorage,
  setSessionStorage,
} from '../../helpers/local-storage'
import { inject, Inject } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'

interface DataFilter {
  [k: string]: any
}

export class __searchAndFitler {
  readonly router = inject(Router)

  #resetFilter = '{}'
  #resetForm = '{}'
  #defaultFilter: string
  search = new FormControl('')
  #form = new FormGroup({})

  saveStorage = true

  get keyFilter() {
    return this.key + '_filter'
  }

  get keySearch() {
    return this.key + '_search'
  }

  get keyForm() {
    return this.key + '_form'
  }

  constructor(readonly key: string, public filter: DataFilter) {
    this.#resetFilter = JSON.stringify(filter)
    const oldFilter = getSessionStorage(this.keyFilter)
    if (oldFilter) {
      this.setFilter(JSON.parse(oldFilter))
    }
    this.#defaultFilter = this.#resetFilter

    const oldSearch = getSessionStorage(this.keySearch)
    if (oldSearch) {
      this.search.setValue(oldSearch)
    }

    this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        if (!e.url.includes(key)) {
          this.clear()
        }
      }
    })
  }

  _initForm(form: FormGroup) {
    const value = form.getRawValue()
    this.#resetForm = JSON.stringify(value)

    const oldForm = getSessionStorage(this.keyForm)
    if (oldForm) {
      try {
        for (const key in oldForm) {
          oldForm[key] = oldForm[key] || value[key] || ''
        }
        form.reset(oldForm)
      } catch (e) {}
    }

    this.#form = form
  }

  setFilter(filter: DataFilter) {
    for (const key in this.filter) {
      if (key in filter) {
        this.filter[key] = filter[key]
      }
    }
  }

  setForm(form: FormGroup, oldValue: any) {
    const value = form.getRawValue()
    const newValue: any = {}
    try {
      for (const key in oldValue) {
        if (key in value) {
          newValue[key] = oldValue[key] || ''
        }
      }
    } catch (e) {}

    form.reset(newValue)
  }

  clear() {
    this.#defaultFilter = this.#resetFilter
    this.reset()
  }

  reset() {
    this.resetModalFilter()
    removeSessionStorage(this.keyFilter)
    removeSessionStorage(this.keySearch)
    removeSessionStorage(this.keyForm)
  }

  resetModalFilter() {
    this.setFilter(JSON.parse(this.#resetFilter))
    this.search.reset()
    this.#form.reset(JSON.parse(this.#resetForm))
  }

  close() {
    this.setFilter(JSON.parse(this.#defaultFilter))
    this.setForm(this.#form, getSessionStorage(this.keyForm))
  }

  confirm() {
    const filterString = JSON.stringify(this.filter)
    this.#defaultFilter = filterString

    if (this.saveStorage) {
      setSessionStorage(this.keyFilter, filterString)
      setSessionStorage(this.keySearch, this.search.value)
      setSessionStorage(this.keyForm, this.#form.getRawValue())
    }
  }
}
