import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core'
import { Router } from '@angular/router'
import { MatDialog } from '@angular/material/dialog'
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms'
import { MatPaginator } from '@angular/material/paginator'
import { InputNumberComponent } from '../../../../../components/input-number/input-number'
import { imports } from '../../../../../imports'
import { ACTION_ACCESS, APP_PERMISSION, Loading, Profile } from '../../../../../globals'
import {
  Moment,
  Pagination,
  formatPrice,
  formatPriceAutoDecimal,
  setErrorForm,
  validateField,
} from '../../../../../helpers'
import { CustomSnackBar } from '../../../../../components/snackbar/snackbar'
import { ProductStockService } from '../../../../../services'
import { ModalAddStockComponent } from '../modal-add-stock/modal-add-stock'
import { ModalAdjustStockComponent } from '../modal-adjust-stock/modal-adjust-stock'
import { MatTooltipModule } from '@angular/material/tooltip'

@Component({
  selector: 'app-product-stock-list',
  standalone: true,
  imports: [
    ...imports,
    InputNumberComponent,
    ModalAddStockComponent,
    MatPaginator,
    MatTooltipModule,
  ],
  templateUrl: './product-stock-list.html',
  styleUrls: ['./product-stock-list.scss'],
})
export class ProductStockListComponent implements OnInit {
  readonly APP_PERMISSION = APP_PERMISSION
  readonly ACTION_ACCESS = ACTION_ACCESS
  readonly formatPrice = formatPrice
  readonly formatPriceAutoDecimal = formatPriceAutoDecimal
  readonly Moment = Moment

  @Input() product: any
  @Input() product_detail: any
  @Input() resetStockList: number = 0

  @Output() onResetList = new EventEmitter()

  readonly paginationProductStock = new Pagination({
    sortBy: 'desc',
  })
  productStockList: any[] = []
  imgStockDefault = '../../../../assets/images/icons/img-default-table.svg'

  productStockLogList: any[] = []

  get products() {
    return this.product.details.length ? this.product.details : [this.product]
  }

  constructor(
    public profile: Profile,
    public router: Router,
    public dialog: MatDialog,
    public loading: Loading,
    public customSnackBar: CustomSnackBar,
    public productStockService: ProductStockService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['resetStockList']) {
      this.getProductStockList()
    }
  }

  ngOnInit(): void {}

  getProductStockList() {
    const pagination = this.paginationProductStock.get()
    const payload = {
      ...pagination,
      filter: {
        product_id: this.product.id,
        product_detail_id: this.product_detail.details ? null : this.product_detail.id,
      },
    }

    // console.log(payload)
    // return

    this.loading.start()
    this.productStockService.getProductStockList(payload).subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          this.productStockList = res.data.records.map((data: any) => {
            return {
              data,
              isEdit: false,
              stock: new FormControl(data.stock, [Validators.required]),
              cost_price: new FormControl(data.cost_price, [
                Validators.required,
                Validators.max(1000000000),
              ]),
            }
          })
          this.paginationProductStock.setFromResponse(res.data)
        } else {
          this.customSnackBar.fail()
        }
      }
      this.loading.stop()
    })
  }

  openModalAdjustStock(title: string, item: any): void {
    const dialogRef = this.dialog.open(ModalAdjustStockComponent, {
      data: {
        title,
        item,
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getProductStockList()
        this.onResetList.emit()
      }
    })
  }

  onSaveCostPrice(item: any): void {
    validateField(item.cost_price)
    if (!item.cost_price.valid) return

    const cost_price = item.cost_price.value
    const payload = {
      stock_id: item.data.stock_id,
      supplier_id: item.data.supplier_id,
      product_id: item.data.product_id,
      product_detail_id: item.data.product_detail_id,
      cost_price,
      stock: item.data.stock,
      total_cost_price: cost_price * item.data.stock,
    }

    // return console.log(payload)

    this.loading.start()
    this.productStockService.updateProductStock(item.data.id, payload).subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          this.customSnackBar.success('บันทึกข้อมูลสำเร็จ')
          this.getProductStockList()
          this.onResetList.emit()
        } else {
          if (res.errors.length) {
            item.cost_price.setErrors({
              formApi: res.errors[0].message,
            })
          } else {
            this.customSnackBar.failSave(res.message)
          }
        }
      }
      this.loading.stop()
    })
  }

  updateToWeb(id: any) {
    this.loading.start()
    this.productStockService.updateToWeb(id, {}).subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          this.customSnackBar.success('บันทึกข้อมูลสำเร็จ')
          this.getProductStockList()
          this.onResetList.emit()
        } else {
          this.customSnackBar.fail('บันทึกข้อมูลไม่สำเร็จ')
        }
      }
      this.loading.stop()
    })
  }
}
