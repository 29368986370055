import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms'
import { imports } from '../../../imports'
import { InputComponent } from '../../../components/input/input'
import { TextareaComponent } from '../../../components/text-area/text-area'
import {
  EmailReg,
  ValidateForm,
  setErrorForm,
  ValidateArrayForm,
  Moment,
  convertDateToApi,
  Pagination,
  sortList,
  formatPrice,
  mergeDateAndTime,
  validateField,
  COMPANY_ID,
  productKey,
  getDifferenceProductKey,
  productImage,
} from '../../../helpers'
import { UploadImageProfileComponent } from '../../../components/upload-image-profile/upload-image-profile'
import { ModalLeaveComponent } from '../../../components/modal-leave/modal-leave'
import { MatDialog } from '@angular/material/dialog'
import { CompanyProfileService, CaratService, ProductService } from '../../../services'
import { CustomSnackBar } from '../../../components/snackbar/snackbar'
import { ACTION_ACCESS, APP_PERMISSION, Loading, Profile } from '../../../globals'
import { forkJoin } from 'rxjs'
import { ModalConfirmComponent } from '../../../components/modal-confirm/modal-confirm'
import { InputNumberComponent } from '../../../components/input-number/input-number'
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
  CdkDrag,
  CdkDropList,
} from '@angular/cdk/drag-drop'
import { DatePickerComponent } from '../../../components/date-picker/date-picker'
import { SelectSortComponent } from '../../../components/select-sort/select-sort'
import { CheckboxAllTableComponent } from '../../../components/checkbox-table/checkbox-all-table.component'
import { CheckboxTableComponent } from '../../../components/checkbox-table/checkbox-table.component'
import { ModalDeleteComponent } from '../../../components/modal-delete/modal-delete'
import { ModalSelectProductMultipleComponent } from '../../../components/modal-select-product-multiple/modal-select-product-multiple'
import { Service } from '../../../services/service'
import { ModalErrorComponent } from '../../../components/modal-error/modal-error'

@Component({
  selector: 'app-products-discount-detail',
  standalone: true,
  imports: [
    ...imports,
    InputComponent,
    TextareaComponent,
    UploadImageProfileComponent,
    ModalLeaveComponent,
    InputNumberComponent,
    DatePickerComponent,
    CdkDrag,
    CdkDropList,
    SelectSortComponent,
    CheckboxAllTableComponent,
    CheckboxTableComponent,
  ],
  templateUrl: './products-discount-detail.html',
  styleUrls: ['./products-discount-detail.scss'],
})
export class ProductsDiscountDetailComponent implements OnInit {
  readonly COMPANY_ID = COMPANY_ID
  readonly APP_PERMISSION = APP_PERMISSION
  readonly ACTION_ACCESS = ACTION_ACCESS
  readonly Moment = Moment
  readonly formatPrice = formatPrice
  readonly mergeDateAndTime = mergeDateAndTime

  itemChecked = new Set<any>()
  itemCheckedData = new Map()

  isEdit: boolean = false
  isView: boolean = false
  isCreate: boolean = false
  dataManage: any

  readonly paginationProduct = new Pagination({
    sortName: sortList[0].value.sortName,
    sortBy: sortList[0].value.sortBy as 'asc' | 'desc',
  })

  percent_all = new FormControl('', [Validators.max(99), Validators.min(1)])

  form = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.maxLength(100)]),
    date_start: new FormControl('', [Validators.required]),
    time_start: new FormControl('', [Validators.required]),
    date_end: new FormControl('', [Validators.required]),
    time_end: new FormControl('', [Validators.required]),

    products: new FormArray<FormGroup>(
      []
      // [
      //   (control: AbstractControl) => {
      //     if (this.productCount(control) < 1) {
      //       return {
      //         minLength: true,
      //       }
      //     }

      //     return null
      //   },
      // ]
    ),
  })

  productCount(productControl: FormControl | AbstractControl) {
    return (productControl.value || []).reduce((products: any[], value: any) => {
      if (!products.includes(value.product_id)) {
        products.push(value.product_id)
      }
      return products
    }, []).length
  }

  get product_list() {
    return this.form.get('products') as FormArray<FormGroup>
  }

  get pageList() {
    return this.product_list.controls.filter((d: any, i: number) => {
      return i >= this.paginationProduct.indexData.min && i <= this.paginationProduct.indexData.max
    })
  }

  constructor(
    public profile: Profile,
    public router: Router,
    public route: ActivatedRoute,
    public dialog: MatDialog,
    public customSnackBar: CustomSnackBar,
    public loading: Loading,
    public companyProfileService: CompanyProfileService,
    public ProductService: ProductService,
    public service: Service
  ) {}

  ngOnInit(): void {
    if (this.router.url.includes('view')) {
      this.isView = true
    }
    if (this.router.url.includes('edit')) {
      this.isEdit = true
    }
    if (this.router.url.includes('create')) {
      this.isCreate = true
    }

    this.route.params.subscribe(params => {
      this.initData(params['id'])
    })
  }

  initData(id: any) {
    if (!id) return

    const apis = [this.ProductService.getProductDiscounts(id)]

    this.loading.start()
    forkJoin(apis).subscribe(([resProduct]: any) => {
      if (resProduct) {
        if (!resProduct.is_error) {
          this.dataManage = resProduct.data

          let time_start = ''
          let time_end = ''
          if (this.dataManage.start_datetime) {
            time_start = Moment(this.dataManage.start_datetime).format('HH:mm:ss')
          }
          if (this.dataManage.end_datetime) {
            time_end = Moment(this.dataManage.end_datetime).format('HH:mm:ss')
          }

          this.form.patchValue({
            name: this.dataManage.name,
            date_start: this.dataManage.start_datetime,
            time_start: time_start,
            date_end: this.dataManage.end_datetime,
            time_end: time_end,
          })

          if (this.dataManage.details.length > 0) {
            for (let index = 0; index < this.dataManage.details.length; index++) {
              const data = this.dataManage.details[index]
              let newData = new FormGroup({})
              for (const property in data) {
                newData.addControl(property, new FormControl(data[property] || ''))
              }

              newData.addControl(
                'key',
                new FormControl(productKey(data.product_id, data.product_detail_id))
              )
              newData.addControl('file', new FormControl(data?.files?.[0] || null))
              newData.addControl('selling_price', new FormControl(data?.normal_price || 0))
              newData.addControl(
                'percent_discount',
                new FormControl(data?.percent_discount || '', [
                  Validators.required,
                  Validators.max(100),
                ])
              )
              newData.addControl('discount_price', new FormControl(data?.discount_price || 0))
              newData.addControl(
                'normal_discount_price',
                new FormControl(data?.normal_price - data?.discount_price || 0)
              )

              this.product_list.push(newData)
              this.paginationProduct.setFromResponse({
                page: 1,
                total_record: this.product_list.value.length,
              })
            }
          }
        } else {
          this.customSnackBar.fail()
        }
      }
      this.loading.stop()
    })
  }

  isSaved = false
  openModalCancel(resolveFromRouter: (value: boolean) => void): void {
    if (this.isEdit && this.form.dirty && !this.isSaved) {
      const dialogCancel = this.dialog.open(ModalLeaveComponent, {
        data: {},
      })

      dialogCancel.afterClosed().subscribe(result => {
        resolveFromRouter(!!result)
      })
    } else {
      resolveFromRouter(true)
    }
  }

  toView(id = this.dataManage.id) {
    this.router.navigate(['/product/discount/view/' + id])
  }

  toEdit(id = this.dataManage.id) {
    this.router.navigate(['/product/discount/edit/' + id])
  }

  toList() {
    this.router.navigate(['/product'], {
      queryParams: {
        tab: 1,
      },
    })
  }

  changePercentAll() {
    validateField(this.percent_all)
    if (!this.percent_all.valid) {
      return
    }
    let value_percent = Number(this.percent_all.value)
    let percent = parseFloat((value_percent / 100).toString())
    this.product_list.controls.map((item: any) => {
      let discount = percent * item.value.selling_price
      item.patchValue({
        percent_discount: this.percent_all.value,
        discount_price: Number(discount.toFixed(2)),
        normal_discount_price: Number((item.value.selling_price - discount).toFixed(2)),
      })
    })
    this.product_list.updateValueAndValidity()
  }

  changePercent(item: any, event: any) {
    let value_percent = item.controls['percent_discount'].value || 0
    let percent = Number((value_percent / 100).toString())
    let discount = percent * item.value.selling_price
    const discount_price = parseFloat(discount.toFixed(2))

    item.patchValue({
      discount_price,
      normal_discount_price: parseFloat((item.value.selling_price - discount_price).toFixed(2)),
    })

    if (value_percent > 99) {
      item.get('percent_discount')?.setValidators([Validators.required, Validators.max(99)])
      item.get('percent_discount')?.updateValueAndValidity()
      item.patchValue({
        percent_discount: value_percent,
      })
    }
    this.product_list.updateValueAndValidity()
    this.form.updateValueAndValidity()
  }

  changeDiscount(item: any, event: any) {
    let value_discount = item.controls['discount_price'].value || 0
    let percent = parseFloat(((value_discount / item.value.selling_price) * 100).toFixed(2))

    item.get('discount_price')?.setValidators([Validators.required, Validators.min(1)])
    item.get('discount_price')?.updateValueAndValidity()

    const discount_price = parseFloat(value_discount.toFixed(2))
    item.patchValue({
      discount_price,
      normal_discount_price: parseFloat((item.value.selling_price - discount_price).toFixed(2)),
      percent_discount: percent,
    })

    if (value_discount >= item.value.selling_price) {
      item.get('discount_price')?.setValidators([Validators.required, this.validatePrice])
      item.get('discount_price')?.updateValueAndValidity()
    }

    let percent99 = Number(99 / 100)
    if (value_discount > item.value.selling_price * percent99) {
      item.get('discount_price')?.setValidators([Validators.required, this.validatePrice99])
      item.get('discount_price')?.updateValueAndValidity()
    }
    this.product_list.updateValueAndValidity()
    this.form.updateValueAndValidity()
  }

  get validatePrice(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value) {
        return { validate_price: true }
      }
      return null
    }
  }

  get validatePrice99(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value) {
        return { validate_price_99: true }
      }
      return null
    }
  }

  addProduct(data: any = {}) {
    let newData = new FormGroup({})

    for (const property in data) {
      newData.addControl(property, new FormControl(data[property] || ''))
    }

    newData.addControl('key', new FormControl(productKey(data.product_id, data.product_detail_id)))
    newData.addControl('id', new FormControl(null))
    newData.addControl('file', new FormControl(data?.file || productImage(data)))
    newData.addControl('member_price', new FormControl(data?.member_price || 0))
    newData.addControl(
      'percent_discount',
      new FormControl(data?.percent_discount || '', [Validators.required, Validators.max(99)])
    )
    newData.addControl(
      'discount_price',
      new FormControl(data?.discount_price || '', [Validators.required, Validators.min(1)])
    )
    newData.addControl(
      'normal_discount_price',
      new FormControl(data?.normal_discount_price || data?.selling_price || 0)
    )
    // this.product_list.push(newData)
    return newData
  }

  openProduct() {
    const dialogRefProduct = this.dialog.open(ModalSelectProductMultipleComponent, {
      data: {
        product_list: this.product_list.value,
        filter_not_flash_sale: true,
      },
    })
    dialogRefProduct.afterClosed().subscribe(result => {
      if (result) {
        if (result.length > 0) {
          let difference = getDifferenceProductKey(result, this.product_list.value)
          difference.map((item: any) => {
            item.id = null
            item.file = item.file?.file

            this.product_list.push(this.addProduct(item))
          })
          this.paginationProduct.setFromResponse({
            page: 1,
            total_record: this.product_list.value.length,
          })
        }
      }
    })
    return dialogRefProduct
  }

  deleteProduct(item?: any) {
    if (item) {
      this.product_list.removeAt(this.product_list.value.findIndex(d => d.key == item.key))
      this.itemChecked.delete(item.key)
      this.itemCheckedData.delete(item.key)
    } else {
      this.itemCheckedData.forEach((item, key) => {
        let i = this.product_list.value.findIndex(d => d.key == key)
        this.product_list.removeAt(i)
        this.itemCheckedData.delete(key)
        this.itemChecked.delete(key)
      })
    }
  }

  setItemCheckedData(checked: boolean, datas: any[]) {
    datas.forEach(item => {
      if (checked) {
        this.itemCheckedData.set(item.key, {
          id: item.id,
          is_order: item.is_order,
        })
        this.itemChecked.add(item.key)
      } else {
        this.itemCheckedData.delete(item.key)
        this.itemChecked.delete(item.key)
      }
    })
  }

  checkProduct() {
    let check = false
    let list = this.product_list.controls
    // console.log('this.product_list.value', this.product_list.value, list);

    for (let index = 0; index < list.length; index++) {
      const p = list[index]
      if (!p.controls['discount_price'].value) {
        p.get('discount_price')?.setValidators([Validators.required])
        p.get('discount_price')?.updateValueAndValidity()

        return (check = true)
      }
      if (p.controls['discount_price'].value >= p.controls['selling_price'].value) {
        p.get('discount_price')?.setValidators([Validators.required, this.validatePrice])
        p.get('discount_price')?.updateValueAndValidity()

        return (check = true)
      }
      if (!p.controls['percent_discount'].value) {
        p.get('percent_discount')?.setValidators([Validators.required])
        p.get('percent_discount')?.updateValueAndValidity()

        return (check = true)
      }
      if (p.controls['percent_discount'].value > 99) {
        p.get('percent_discount')?.setValidators([Validators.required, Validators.max(99)])
        p.get('percent_discount')?.updateValueAndValidity()

        return (check = true)
      }
    }
    // console.log('check', check)
    return check
  }

  onSave() {
    const value = this.form.getRawValue()
    let start = new Date(mergeDateAndTime(value.date_start, value.time_start))
    let end = new Date(mergeDateAndTime(value.date_end, value.time_end))
    if (end < start) {
      this.form.controls['date_end'].setErrors({ error_end: true })
      this.form.controls['time_end'].setErrors({ error_end: true })
    }
    if (this.productCount(this.form.controls['products']) < 1) {
      this.form.controls['products'].setErrors({ minLength: true })
    }
    ValidateForm(this.form)
    // console.log('this.checkProduct()', this.checkProduct())

    if (!this.form.valid || this.checkProduct()) {
      return
    }

    let payload = {
      name: value.name,
      start_datetime: convertDateToApi(mergeDateAndTime(value.date_start, value.time_start)),
      end_datetime: convertDateToApi(mergeDateAndTime(value.date_end, value.time_end)),
      details: (value.products || []).map((d: any, i: number) => {
        return {
          id: d.id || null,
          product_id: d.product_id || null,
          product_detail_id: d.product_detail_id || null,
          seq: i + 1,
          percent_discount: d.percent_discount || null,
          normal_discount_price: d.normal_discount_price || null,
          discount_price: d.discount_price || null,
          product_category_id: d.product_category_id || null,
          product_sub_category_id: d.product_sub_category_id || null,
        }
      }),
    }

    // console.log('payload', payload)

    const API = this.isEdit
      ? this.ProductService.updateProductDiscounts(this.dataManage.id, payload)
      : this.ProductService.addProductDiscounts(payload)

    this.loading.start()
    API.subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          this.customSnackBar.success('บันทึกข้อมูลสำเร็จ')
          this.isSaved = true
          if (this.isCreate) {
            this.toList()
          } else {
            this.toView(this.dataManage.id)
          }
        } else {
          // if (res.errors[0]?.field) {
          //   setErrorForm(this.form, res.errors)
          // } else {
          //   this.customSnackBar.failSave(res.message)
          // }
          if (res.errors) {
            this.dialog.open(ModalErrorComponent, {
              data: {
                title: 'ไม่สามารถบันทึกข้อมูลได้',
                detail: res.message,
              },
            })
          }
        }
      }
      this.loading.stop()
    })
  }

  onCancel() {
    const dialogRefConfirm = this.dialog.open(ModalLeaveComponent, {
      data: {},
    })

    dialogRefConfirm.afterClosed().subscribe(result => {
      if (result) {
        this.toList()
      }
    })
  }
}
