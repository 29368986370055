<div mat-dialog-title class="dialog-title">
  <div class="dialog-title-head">ข้อมูลสีสินค้า</div>
  <button class="btn btn-transparent btn-icon" (click)="onClose()">
    <img src="../assets/images/icons/icon-close.svg" />
  </button>
</div>

<mat-dialog-content>
  <div class="modal-content">
    <div class="table-responsive-detail">
      <table class="table table-fixed-column table-detail">
        <thead>
          <tr>
            <th width="80">ลำดับ</th>
            <th width="300">ชื่อสี @if (!data.isView) {<span class="text-danger">*</span>}</th>
            <th width="100">สี</th>
            @if(service.companyId == COMPANY_ID.PANIA){
            <th width="150">รูป @if (!data.isView) {<span class="text-danger">*</span>}</th>
            }
            <th width="90"></th>
          </tr>
        </thead>
        <tbody>
          @for (item of form.controls; track item; let index = $index;) {
          <tr>
            <td>
              <div class="pb-3">{{ index + 1 }}</div>
            </td>
            <td>
              @if (!data.isView) {
              <div class="mt-3">
                <app-input [control]="item.controls['color']">
                  @if (item.controls['color'].hasError('required')) {
                  <ng-container error>กรุณากรอกข้อมูล</ng-container>
                  } @else if (item.controls['color'].hasError('maxlength')) {
                  <ng-container error>กรุณากรอกข้อมูลไม่เกิน 15 ตัวอักษร</ng-container>
                  } @else if (item.controls['color'].hasError('duplicate')) {
                  <ng-container error>ชื่อสีซ้ำกัน</ng-container>
                  } @else if (item.controls['color'].hasError('formApi')) {
                  <ng-container error>{{ item.controls['color'].errors?.['formApi']}}</ng-container>
                  }
                </app-input>
              </div>
              } @else { {{ item.value.color }} }
            </td>
            <td style="vertical-align: top">
              @if (!data.isView) {
              <input
                type="color"
                class="input-color mt-3"
                [value]="item.value.color_code"
                (change)="item.controls.color_code.setValue($any($event.target).value)" />
              } @else {
              <div
                class="box-color"
                [ngStyle]="{'background-color': item.value.color_code || '#000000'}"></div>
              }
            </td>
            @if(service.companyId == COMPANY_ID.PANIA){
            <td>
              <app-upload-image-table
                [type]="data.isView ? 'view' : 'edit'"
                [logoDefault]="item.controls['file'].value?.url"
                [isRequired]="item.controls['file'].touched && item.controls['file'].invalid"
                (upload)="item.controls['file'].setValue($event?.[0] || null)"></app-upload-image-table>
            </td>
            }
            <td>
              @if (index != 0 && !data.isView) {
              <button
                matRipple
                class="btn btn-transparent-outline py-1 mb-3"
                (click)="delColor(index)">
                <span class="icon icon-20 material-symbols-outlined fill text-danger">delete</span>
              </button>
              }
            </td>
          </tr>

          }
        </tbody>
      </table>
    </div>

    @if (!data.isView) {
    <div class="w-20">
      <button matRipple class="btn btn-outline-secondary min-w-100px mb-3" (click)="addColor({})">
        <span class="icon material-symbols-outlined fill">add_circle</span>
        เพิ่มสี
      </button>
    </div>
    }
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="footer-button-wrapper d-flex justify-content-end w-100">
    @if (!data.isView) {
    <button matRipple class="btn btn-outline-secondary btn-md" (click)="onClose()">ยกเลิก</button>
    <button matRipple class="btn btn-primary btn-md" (click)="onConfirm()">บันทึก</button>
    } @else {
    <button matRipple class="btn btn-primary btn-md" (click)="onClose()">ปิด</button>
    }
  </div>
</mat-dialog-actions>
