<div class="page-content page-carat">
  <div class="breadcrumb-wrapper">
    <div class="breadcrumb-text breadcrumb-link">ตั้งค่า</div>
    <div class="breadcrumb-separator">/</div>
    <div class="breadcrumb-text">ตั้งค่ากะรัต</div>
    @if (isEdit) {
    <div class="breadcrumb-separator">/</div>
    <div class="breadcrumb-text">แก้ไข</div>
    }
  </div>

  <div class="title-wrapper">ตั้งค่ากะรัต</div>

  <div class="page-content-inner d-flex flex-column">
    <div class="d-flex justify-content-between align-items-center">
      <div class="title-blue">กำหนดยอดสั่งซื้อเป็นกะรัต</div>
      @if (isView && profile.permissions.checkAccess([APP_PERMISSION.CARATS], [ACTION_ACCESS.CREATE,
      ACTION_ACCESS.UPDATE])) {
      <button matRipple class="btn btn-primary min-w-120px" [routerLink]="['/setting/carats/edit']">
        แก้ไขข้อมูล
      </button>
      }
    </div>
    <div class="page-content-inner-body d-flex flex-column flex-1">
      <div class="flex-1">
        <div class="box-row-carat mt-3">
          <div class="pre-box align-items-center">&nbsp;</div>
          <div class="w-100 row-carat">
            <div class="input-carat">
              <label class="form-label"> กะรัต </label>
              <app-input [control]="form.controls['carat']" [disabled]="true"></app-input>
            </div>
            <div class="btn-pc">
              <label class="form-label">&nbsp;</label>
              <div class="font40">=</div>
            </div>
            <div class="row-input">
              <div class="input-carat">
                <label
                  class="form-label"
                  [class.text-danger]="form.controls['total_order_amount'].touched && form.controls['total_order_amount'].invalid">
                  จำนวนการสั่งซื้อ @if (isEdit) { <span class="text-danger">*</span> }
                </label>
                <app-input-number
                  [control]="form.controls['total_order_amount']"
                  unit="บาท"
                  [disabled]="isView"
                  [autoDecimal]="isView">
                  @if (form.controls['total_order_amount'].hasError('required')) {
                  <ng-container error>กรุณากรอกข้อมูล</ng-container>
                  }
                </app-input-number>
              </div>
              <div class="input-carat">
                <label
                  class="form-label"
                  [class.text-danger]="form.controls['order_start_date'].touched && form.controls['order_start_date'].invalid">
                  วันที่เริ่มต้น @if (isEdit) { <span class="text-danger">*</span> }
                </label>

                <app-date-picker
                  [control]="form.controls['order_start_date']"
                  placeholder="วว/ดด/ปปปป"
                  [disabled]="isView">
                  @if (form.controls['order_start_date'].hasError('required')) {
                  <ng-container error>กรุณากรอกข้อมูล</ng-container>
                  } @else if (form.controls['order_start_date'].hasError('formApi')) {
                  <ng-container error>
                    {{ form.controls['order_start_date'].errors?.['formApi']}}
                  </ng-container>
                  }
                </app-date-picker>
              </div>
              <div class="input-carat">
                <label
                  class="form-label"
                  [class.text-danger]="form.controls['order_end_date'].touched && form.controls['order_end_date'].invalid">
                  วันที่สิ้นสุด
                </label>

                <app-date-picker
                  [control]="form.controls['order_end_date']"
                  placeholder="วว/ดด/ปปปป"
                  [min]="form.controls['order_start_date'].value"
                  [disabled]="isView"
                  [showClear]="true">
                  @if (form.controls['order_end_date'].hasError('formApi')) {
                  <ng-container error>
                    {{ form.controls['order_end_date'].errors?.['formApi']}}
                  </ng-container>
                  } @else if (form.controls['order_end_date'].hasError('matDatepickerMin')) {
                  <ng-container error> วันที่สิ้นสุดต้องมากกว่าวันที่เริ่มต้น </ng-container>
                  }
                </app-date-picker>
              </div>
            </div>
          </div>
        </div>

        <div class="title-blue">กำหนดยอดมูลค่ากะรัตขั้นต่ำ</div>
        <div class="box-row-carat mt-3">
          <div class="pre-box align-items-center">&nbsp;</div>
          <div class="w-100 row-carat">
            <div class="input-carat">
              <label class="form-label"> กะรัต </label>
              <app-input [control]="form.controls['carat']" [disabled]="true"></app-input>
            </div>
            <div class="btn-pc">
              <label class="form-label">&nbsp;</label>
              <div class="font40">=</div>
            </div>
            <div class="row-input">
              <div class="input-carat">
                <label
                  class="form-label"
                  [class.text-danger]="form.controls['value_amount'].touched && form.controls['value_amount'].invalid">
                  มูลค่ากะรัต @if (isEdit) { <span class="text-danger">*</span> }
                </label>
                <app-input-number
                  [control]="form.controls['value_amount']"
                  unit="บาท"
                  [disabled]="isView"
                  [autoDecimal]="isView">
                  @if (form.controls['value_amount'].hasError('required')) {
                  <ng-container error>กรุณากรอกข้อมูล</ng-container>
                  }
                </app-input-number>
              </div>
              <div class="input-carat"></div>
              <div class="input-carat"></div>
            </div>
          </div>
        </div>

        <div class="title-blue">กำหนดระดับกะรัตเป็นส่วนลด</div>
        <div
          id="all"
          cdkDropList
          [cdkDropListData]="discounts.controls"
          (cdkDropListDropped)="drop($event)"
          class="drag-list mt-3">
          @for (item of discounts.controls; track item; let i = $index) {
          <div class="box-row-carat drag-box mb-2">
            <div class="pre-box">
              <label class="form-label">&nbsp;</label>
              @if (isEdit) {
              <button
                class="btn btn-transparent-outline me-1"
                matRipple
                cdkDragHandle
                cdkDrag
                [cdkDragData]="item">
                <span class="icon icon-dark-grey material-symbols-outlined fill">
                  drag_indicator
                </span>
              </button>
              } @else { &nbsp; }
            </div>

            <div class="w-100 row-carat">
              <div class="d-flex">
                <div class="input-carat">
                  <label
                    class="form-label"
                    [class.text-danger]="item.controls['carat'].touched && item.controls['carat'].invalid">
                    กะรัต @if (isEdit) { <span class="text-danger">*</span> }
                  </label>
                  <app-input-number
                    [control]="item.controls['carat']"
                    [disabled]="isView"
                    (onInput)="checkValidate()">
                    @if (item.controls['carat'].hasError('required')) {
                    <ng-container error>กรุณากรอกข้อมูล</ng-container>
                    } @else if (item.controls['carat'].hasError('min')) {
                    <ng-container error>จำนวนกะรัตต้องมากกว่า 0</ng-container>
                    } @else if (item.controls['carat'].hasError('unique')) {
                    <ng-container error>จำนวนกะรัตต้องไม่ซ้ำกัน</ng-container>
                    } @else if (item.controls['carat'].hasError('max_nam')) {
                    <ng-container error>โปรดกำหนดค่าให้น้อยกว่าค่าถัดไป</ng-container>
                    }
                  </app-input-number>
                </div>
              </div>

              <div class="btn-pc">
                <label class="form-label">&nbsp;</label>
                <div class="font40">=</div>
              </div>
              <div class="input-carat">
                <label
                  class="form-label"
                  [class.text-danger]="item.controls['discount_price'].touched && item.controls['discount_price'].invalid">
                  จำนวนส่วนลด @if (isEdit) { <span class="text-danger">*</span> }
                </label>

                <app-input-number
                  [control]="item.controls['discount_price']"
                  unit="บาท"
                  [disabled]="isView"
                  [autoDecimal]="isView"
                  (onInput)="checkValidate()">
                  @if (item.controls['discount_price'].hasError('required')) {
                  <ng-container error>กรุณากรอกข้อมูล</ng-container>
                  } @else if (item.controls['discount_price'].hasError('min')) {
                  <ng-container error>จำนวนส่วนลดต้องมากกว่า 0</ng-container>
                  } @else if (item.controls['discount_price'].hasError('unique')) {
                  <ng-container error>จำนวนส่วนลดต้องไม่ซ้ำกัน</ng-container>
                  } @else if (item.controls['discount_price'].hasError('max_nam')) {
                  <ng-container error>โปรดกำหนดค่าให้น้อยกว่าค่าถัดไป</ng-container>
                  }
                </app-input-number>
              </div>
            </div>

            @if (isEdit && profile.permissions.checkAccess([APP_PERMISSION.CARATS],
            [ACTION_ACCESS.UPDATE])) {
            <div>
              <label class="form-label">&nbsp;</label>
              <button class="btn btn-transparent-outline" matRipple (click)="delCaratDiscount(i)">
                <span matRipple class="icon icon-red material-symbols-outlined fill"> delete </span>
              </button>
            </div>
            }
          </div>
          }
        </div>

        @if(isEdit && profile.permissions.checkAccess([APP_PERMISSION.CARATS],
        [ACTION_ACCESS.CREATE, ACTION_ACCESS.UPDATE])) {
        <button
          matRipple
          class="btn btn-outline-secondary min-w-100px mt-2"
          (click)="addCaratDiscount({})">
          เพิ่มเงื่อนไขแลกส่วนลด
        </button>
        }
      </div>

      <div class="footer-min-height mt-3 {{ isEdit && 'line'}}"></div>
      @if (isEdit && profile.permissions.checkAccess([APP_PERMISSION.CARATS], [ACTION_ACCESS.CREATE,
      ACTION_ACCESS.UPDATE])) {
      <div class="d-flex justify-content-end mt-3">
        <button matRipple class="btn btn-outline-primary min-w-100px" (click)="toView()">
          ยกเลิก
        </button>
        <button matRipple class="btn btn-primary ms-2 min-w-100px" (click)="confirmSave()">
          บันทึก
        </button>
      </div>
      }
    </div>
  </div>
</div>
