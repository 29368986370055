import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms'
import { imports } from '../../../imports'
import { InputComponent } from '../../../components/input/input'
import { TextareaComponent } from '../../../components/text-area/text-area'
import {
  EmailReg,
  ValidateForm,
  setErrorForm,
  ValidateArrayForm,
  Moment,
  convertDateToApi,
  Pagination,
  formatPriceAutoDecimal,
  COMPANY_ID,
} from '../../../helpers'
import { UploadImageProfileComponent } from '../../../components/upload-image-profile/upload-image-profile'
import { ModalLeaveComponent } from '../../../components/modal-leave/modal-leave'
import { MatDialog } from '@angular/material/dialog'
import { CompanyProfileService, CaratService } from '../../../services'
import { CustomSnackBar } from '../../../components/snackbar/snackbar'
import { ACTION_ACCESS, APP_PERMISSION, Loading, Profile } from '../../../globals'
import { forkJoin } from 'rxjs'
import { ModalConfirmComponent } from '../../../components/modal-confirm/modal-confirm'
import { InputNumberComponent } from '../../../components/input-number/input-number'
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
  CdkDrag,
  CdkDropList,
} from '@angular/cdk/drag-drop'
import { DatePickerComponent } from '../../../components/date-picker/date-picker'
import { MatTabsModule } from '@angular/material/tabs'
import { CMSSizeService } from '../../../services/cms-size.service'
import { CMSSizeMeasureService } from '../../../services/cms-size-measure.service'
import { ModalDeleteComponent } from '../../../components/modal-delete/modal-delete'
import { MatPaginatorModule } from '@angular/material/paginator'
import { Service } from '../../../services/service'

@Component({
  selector: 'app-carats',
  standalone: true,
  imports: [
    ...imports,
    InputComponent,
    TextareaComponent,
    UploadImageProfileComponent,
    ModalLeaveComponent,
    InputNumberComponent,
    DatePickerComponent,
    CdkDrag,
    CdkDropList,
    MatTabsModule,
    MatPaginatorModule
  ],
  templateUrl: './cms-product-details.html',
  styleUrls: ['./cms-product-details.scss'],
})
export class CMSProductDetailsComponent implements OnInit {
  readonly COMPANY_ID = COMPANY_ID
  readonly APP_PERMISSION = APP_PERMISSION
  readonly ACTION_ACCESS = ACTION_ACCESS
  readonly formatPriceAutoDecimal = formatPriceAutoDecimal
  readonly Moment = Moment

  // mobile = window.matchMedia('(max-width: 1000px)')

  isEdit: boolean = false
  isView: boolean = false
  dataManage: any
  id: any = 1
  tab: number = 0

  paginationSize = new Pagination({})
  sizeList: any[] = []

  paginationMeasure = new Pagination({})
  measureList: any[] = []

  constructor(
    public profile: Profile,
    public router: Router,
    public route: ActivatedRoute,
    public dialog: MatDialog,
    public customSnackBar: CustomSnackBar,
    public loading: Loading,
    public companyProfileService: CompanyProfileService,
    public cmsSizeService: CMSSizeService,
    public cmsSizeMeasureService: CMSSizeMeasureService,
    public service: Service,
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params: any) => {
      if (params.tab) {
        this.tab = params.tab
      }
    })
    this.getList()
  }

  changeTab($tabIndex: any) {
    this.tab = $tabIndex
    this.navigateToTab(this.tab)
    this.getList()
  }

  navigateToTab(tab: number) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        tab: tab,
      },
      skipLocationChange: false,
    })
  }

  getList() {
    if (this.tab == 0) {
      this.getSizeList()
    } else {
      this.getSizeMeasureList()
    }
  }

  getSizeList() {
    const pagination = this.paginationSize.get()
    const payload = {
      ...pagination,
    }

    this.loading.start()
    this.cmsSizeService.getSizeList(payload).subscribe(res => {
      if (!res.is_error) {
        this.sizeList = res.data.records
        this.paginationSize.setFromResponse(res.data)
      } else {
        this.customSnackBar.fail()
      }
      this.loading.stop()
    })
  }

  getSizeMeasureList() {
    const pagination = this.paginationMeasure.get()
    const payload = {
      ...pagination,
    }

    this.loading.start()
    this.cmsSizeMeasureService.getSizeMeasureList(payload).subscribe(res => {
      if (!res.is_error) {
        this.measureList = res.data.records
        this.paginationMeasure.setFromResponse(res.data)
      } else {
        this.customSnackBar.fail()
      }
      this.loading.stop()
    })
  }

  confirmDeleteSize(item: any) {
    const dialogRefDelete = this.dialog.open(ModalDeleteComponent, {
      data: {
        detail: `คุณต้องการลบรายการนี้ใช่หรือไม่`,
      },
    })

    dialogRefDelete.afterClosed().subscribe(result => {
      if (result) {
        this.deleteSize(item)
      }
    })

    return dialogRefDelete
  }

  deleteSize(item: any) {
    this.loading.start()
    this.cmsSizeService.deleteSize(item.id).subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          this.customSnackBar.success('ลบข้อมูลสำเร็จ')
          this.getSizeList()
        } else {
          this.customSnackBar.fail(res.message || 'ลบข้อมูลไม่สำเร็จ')
        }
      }
      this.loading.stop()
    })
  }

  confirmDeleteMeasure(item: any) {
    const dialogRefDelete = this.dialog.open(ModalDeleteComponent, {
      data: {
        detail: `คุณต้องการลบรายการนี้ใช่หรือไม่`,
      },
    })

    dialogRefDelete.afterClosed().subscribe(result => {
      if (result) {
        this.deleteMeasure(item)
      }
    })

    return dialogRefDelete
  }

  deleteMeasure(item: any) {
    this.loading.start()
    this.cmsSizeMeasureService.deleteSizeMeasure(item.id).subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          this.customSnackBar.success('ลบข้อมูลสำเร็จ')
          this.getSizeMeasureList()
        } else {
          this.customSnackBar.fail(res.message || 'ลบข้อมูลไม่สำเร็จ')
        }
      }
      this.loading.stop()
    })
  }

  setPage($event: any) {
    if (this.tab == 1) {
      this.paginationMeasure.setFromPaginator($event); 
      this.getSizeMeasureList()
    } else {
      this.paginationSize.setFromPaginator($event); 
      this.getSizeList()
    }
  }
}
