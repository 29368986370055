import { Component, Inject } from '@angular/core'
import { MatCheckboxModule } from '@angular/material/checkbox'
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
} from '@angular/material/dialog'
import { MatPaginatorModule } from '@angular/material/paginator'
import { imports } from '../../../imports'
import { formatPriceAutoDecimal } from '../../../helpers'

@Component({
  selector: 'app-modal-success-proeduct-to-web',
  templateUrl: './modal-success-proeduct-to-web.html',
  styleUrls: ['./modal-success-proeduct-to-web.scss'],
  standalone: true,
  imports: [...imports, MatDialogContent, MatDialogActions, MatCheckboxModule, MatPaginatorModule],
})
export class ModalSuccessProeductToWebComponent {
  readonly formatPriceAutoDecimal = formatPriceAutoDecimal

  constructor(
    public dialogRef: MatDialogRef<ModalSuccessProeductToWebComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {}

  onClose(): void {
    this.dialogRef.close()
  }
}
