<div class="top-content-wrapper align-items-center flex-wrap mb-3">
  <div class="top-content-left">
    <div class="title-blue">Wish List</div>
  </div>
  <div class="top-content-right">
    <button
      matRipple
      class="btn btn-outline-danger"
      [disabled]="itemCheckedCancel.size < 1"
      (click)="confirmDelete(itemCheckedCancel , true)">
      ยกเลิกจอง @if (itemCheckedCancel.size > 0) { ({{ itemCheckedCancel.size }}) }
    </button>

    <button
      matRipple
      class="btn btn-outline-secondary"
      [disabled]="itemCheckedSendMail.size < 1"
      (click)="sendEmail(itemCheckedSendMail , true)">
      <span class="icon material-symbols-outlined fill">email</span>
      ส่งอีเมล @if (itemCheckedSendMail.size > 0) { ({{ itemCheckedSendMail.size }}) }
    </button>
    <app-select-sort
      [control]="sortControl"
      [datas]="list.sort"
      fieldValue="value"
      placeholder="เลือก"
      (selectionChange)="onSort()">
      <ng-template #triggerRef let-option> {{ option?.text }}</ng-template>
      <ng-template #optionRef let-option> {{ option?.text }}</ng-template>
    </app-select-sort>
  </div>
</div>

<div class="table-responsive table-main-tabs-wrapper">
  <table class="table table-fixed-column">
    <thead>
      <tr>
        <th width="70">
          <app-checkbox-all-table
            [checkedValue]="itemChecked"
            [datas]="productList"
            fieldValue="id"
            (onChange)="setItemCheckedData($event, productList)"></app-checkbox-all-table>
        </th>
        <th width="360">รายการสินค้า</th>
        <th width="120" class="text-center">สต๊อกคงเหลือ</th>
        <th width="100" class="text-center">Wish List</th>
        <th width="250">อีเมลติดต่อ</th>
        <th width="150">ผู้จอง</th>
        <th width="150">วันและเวลาที่จอง</th>
        <th width="150">
          <app-sort-by
            key="status_type"
            [pagination]="paginationProduct"
            (onChange)="getWishList()">
            สถานะการจอง
          </app-sort-by>
        </th>
        <th width="180">อัปเดตล่าสุด</th>
        <th width="180">อัปเดตโดย</th>
        <th width="240" class="fixed box-shadow-left text-center">จัดการ</th>
      </tr>
    </thead>

    <tbody>
      @for (item of productList; track $index) {
      <tr>
        <!-- select  -->
        <td>
          <app-checkbox-table
            [checkedValue]="itemChecked"
            [value]="item.id"
            (onChange)="setItemCheckedData($event, [item])"></app-checkbox-table>
        </td>

        <!-- รายการสินค้า -->
        <td>
          <div class="d-flex gap-3">
            <img
              width="90"
              height="90"
              class="img-product"
              [src]="item.image?.file?.thumbnail || '/assets/images/default-product.svg'" />
            <div>
              <div>
                <app-text-tooltip>{{item?.productUse?.name || '-' }}</app-text-tooltip>
              </div>
              <div>รหัสสินค้า: {{item?.productUse?.code || '-' }}</div>
              <div class="color--neutral-500">
                {{item?.productUse?.color || '-'}}, {{item?.productUse?.size || '-'}}
              </div>
            </div>
          </div>
        </td>

        <!-- สต๊อกคงเหลือ -->
        <td class="text-center">{{ item.product_stock }}</td>

        <!-- Wish List  -->
        <td class="text-center">{{ item.quantity_reserved }}</td>

        <!-- อีเมลติดต่อ -->
        <td>
          <app-text-tooltip>{{ item.email }} </app-text-tooltip>
        </td>

        <!-- ผู้จอง  -->
        <td>
          <app-text-tooltip>
            {{ item.customer == null ? 'ผู้เยี่ยมชม' : item.customer.name + ' ' +
            item.customer.last_name }}
          </app-text-tooltip>
        </td>

        <!-- วันและเวลาที่จอง -->
        <td>
          {{ item.date_reserved ? Moment(item.date_reserved).format('DD-MM-YYYY, HH:mm') : '-' }}
        </td>

        <!-- สถานะการจอง -->
        <td>
          <app-status-user [status]="item.status_type == 2 ? 'active' :'inactive' ">
            {{ item.status_name }}
          </app-status-user>
        </td>

        <!-- อัปเดตล่าสุด  -->
        <td>{{ item.updated_at ? Moment(item.updated_at).format('DD-MM-YYYY, HH:mm') : '-' }}</td>

        <!-- อัปเดตโดย -->
        <td>
          <app-text-tooltip>{{ item?.updated_by?.full_name || '-' }} </app-text-tooltip>
        </td>

        <!-- action  -->
        <td class="fixed box-shadow-left">
          <div class="action-button-wrapper justify-content-start">
            @if (!item.is_receive_email && item.status_type == 2) {
            <button matRipple class="btn btn-outline-secondary" (click)="sendEmail(item.id, false)">
              <span class="icon material-symbols-outlined fill">email</span>
              ส่งอีเมล
            </button>
            } @if (item.is_receive_email) {
            <button matRipple class="btn btn-outline-secondary" disabled>
              <span class="icon material-symbols-outlined fill">email</span>
              ส่งอีเมลแล้ว
            </button>
            } @if (!item.is_receive_email) {
            <button matRipple class="btn btn-outline-danger" (click)="confirmDelete(item,false)">
              ยกเลิกจอง
            </button>
            }
          </div>
        </td>
      </tr>
      }
    </tbody>
  </table>

  @if(!productList.length) {
  <div class="data-not-found-wrapper">ไม่พบสินค้า</div>
  }
</div>

<!-- Pagination -->
@if (productList.length) {
<div class="pagination-wrapper">
  <mat-paginator
    [pageIndex]="paginationProduct.data.pageIndex"
    [length]="paginationProduct.data.length"
    [pageSize]="paginationProduct.data.pageSize"
    [pageSizeOptions]="paginationProduct.data.pageSizeOptions"
    aria-label="Select page"
    (page)="paginationProduct.setFromPaginator($event); getWishList()">
  </mat-paginator>
</div>
}
