import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms'
import { imports } from '../../../../imports'
import { InputComponent } from '../../../../components/input/input'
import { TextareaComponent } from '../../../../components/text-area/text-area'
import {
  EmailReg,
  ValidateForm,
  setErrorForm,
  ValidateArrayForm,
  Moment,
  convertDateToApi,
} from '../../../../helpers'
import { UploadImageProfileComponent } from '../../../../components/upload-image-profile/upload-image-profile'
import { ModalLeaveComponent } from '../../../../components/modal-leave/modal-leave'
import { MatDialog } from '@angular/material/dialog'
import { CompanyProfileService, CaratService } from '../../../../services'
import { CustomSnackBar } from '../../../../components/snackbar/snackbar'
import { ACTION_ACCESS, APP_PERMISSION, Loading, Profile } from '../../../../globals'
import { forkJoin } from 'rxjs'
import { ModalConfirmComponent } from '../../../../components/modal-confirm/modal-confirm'
import { InputNumberComponent } from '../../../../components/input-number/input-number'
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
  CdkDrag,
  CdkDropList,
} from '@angular/cdk/drag-drop'
import { DatePickerComponent } from '../../../../components/date-picker/date-picker'
import { UploadFileFlexibleComponent } from '../../../../components/upload-file-flexible/upload-file-flexible'
import { SelectAddLinkComponent } from '../../../../components/select-add-link/select-add-link'
import { ModalPreviewComponent } from '../../../../components/modal-preview/modal-preview'
import { CMSFaqsService } from '../../../../services/cms-faqs.service'
import { StatusComponent } from '../../../../components/status/status'

@Component({
  selector: 'app-faqs-detail',
  standalone: true,
  imports: [
    ...imports,
    UploadImageProfileComponent,
    ModalLeaveComponent,
    InputNumberComponent,
    DatePickerComponent,
    CdkDrag,
    CdkDropList,
    UploadFileFlexibleComponent,
    SelectAddLinkComponent,
    StatusComponent
  ],
  templateUrl: './faqs-detail.html',
  styleUrls: ['./faqs-detail.scss'],
})
export class FAQSDetailComponent implements OnInit {
  readonly APP_PERMISSION = APP_PERMISSION
  readonly ACTION_ACCESS = ACTION_ACCESS
  readonly Moment = Moment

  // mobile = window.matchMedia('(max-width: 1000px)')

  isCreate: boolean = false
  isEdit: boolean = false
  isView: boolean = false
  dataManage: any
  faq_id: any = null

  details = new FormArray([
    new FormGroup({
      id: new FormControl(null),
      question_th: new FormControl('', Validators.required),
      question_en: new FormControl('', Validators.required),
      answer_th: new FormControl('', Validators.required),
      answer_en: new FormControl('', Validators.required),
    }),
  ])
  form = new FormGroup({
    name_th: new FormControl('', [Validators.required, Validators.maxLength(255)]),
    name_en: new FormControl('', [Validators.required, Validators.maxLength(255)]),
    details: this.details,
  })

  submenu = [true]

  constructor(
    public profile: Profile,
    public router: Router,
    public route: ActivatedRoute,
    public dialog: MatDialog,
    public customSnackBar: CustomSnackBar,
    public loading: Loading,
    public companyProfileService: CompanyProfileService,
    public CMSFaqsService: CMSFaqsService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params: any) => {
      this.faq_id = params['id']
    })
    if (this.router.url.includes('view')) {
      this.isView = true
      this.initData()
    }
    if (this.router.url.includes('edit')) {
      this.isEdit = true
      this.initData()
    }
    if (this.router.url.includes('create')) {
      this.isCreate = true
    }
  }

  initData() {
    this.loading.start()
    this.CMSFaqsService.getCmsFaqsById(this.faq_id).subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          this.dataManage = res.data

          this.form.patchValue({
            name_th: res.data.name_th,
            name_en: res.data.name_en,
          })

          this.details.clear()
          this.submenu = []
          if (res?.data?.details?.length > 0) {
            res?.data?.details.map((item: any, i: any) => {
              this.submenu.push(true)
              this.details.push(
                new FormGroup({
                  id: new FormControl(item.id || null),
                  question_th: new FormControl(item.question_th),
                  question_en: new FormControl(item.question_en),
                  answer_th: new FormControl(item.answer_th),
                  answer_en: new FormControl(item.answer_en),
                })
              )
            })
          }

          console.log('this.form', this.details, this.form)
        } else {
          this.customSnackBar.fail(res.message)
        }
      }

      this.loading.stop()
    })
  }

  addQuestion() {
    this.submenu.push(true)
    this.details.push(
      new FormGroup({
        id: new FormControl(null),
        question_th: new FormControl('', Validators.required),
        question_en: new FormControl('', Validators.required),
        answer_th: new FormControl('', Validators.required),
        answer_en: new FormControl('', Validators.required),
      })
    )
  }

  toggleSubMenu(i: any) {
    this.submenu[i] = !this.submenu[i]
  }

  delQuestion(index: any) {
    this.details.removeAt(index)
  }

  onSave(type: any) {
    ValidateForm(this.form)
    if (this.form.invalid) {
      return
    }

    let details = this.form.controls['details'].value.map((item: any) => {
      return { ...item, id: item.id || undefined, cms_faq_id: this.faq_id ? Number(this.faq_id) : undefined }
    })

    let payload = {
      name_th: this.form.value.name_th,
      name_en: this.form.value.name_en,
      is_public: type == 'public' ? true : false,
      details: details,
    }
    // return console.log(payload)

    const API = this.isCreate
      ? this.CMSFaqsService.createCmsFaqs(payload)
      : this.CMSFaqsService.updateCmsFaqs(this.faq_id, payload)

    this.loading.start()
    API.subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          this.customSnackBar.success(type == 'public' ? 'เผยแพร่สำเร็จ' : 'บันทึกร่างสำเร็จ')
          this.router.navigate(['/setting/cms/faqs'])
        } else {
          if (res.errors[0]?.field) {
            setErrorForm(this.form, res.errors)
            this.customSnackBar.failSave(type == 'public' ? 'เผยแพร่ไม่สำเร็จ' : 'บันทึกร่างไม่สำเร็จ')
          } else {
            this.customSnackBar.failSave(type == 'public' ? 'เผยแพร่ไม่สำเร็จ' : 'บันทึกร่างไม่สำเร็จ')
          }
        }
      }
      this.loading.stop()
    })
  }

  onCancel() {
    const dialogRefConfirm = this.dialog.open(ModalLeaveComponent, {
      data: {},
    })

    dialogRefConfirm.afterClosed().subscribe(result => {
      if (result) {
        this.router.navigate(['/setting/cms/faqs'])
      }
    })
  }
}
