import { Component, Inject, Input, PLATFORM_ID } from '@angular/core'
import { RouterModule } from '@angular/router'
import { COMPANY_ID, formatPrice } from '../../../helpers'
import { ProductDotColorComponent } from '../product-dot-color/product-dot-color'
import { defaultImage, ProductData } from '../../../helpers/preview/product'
import { Service } from '../../../services/service'

@Component({
  selector: 'app-card-product',
  templateUrl: './card-product.html',
  styleUrl: './card-product.scss',
  standalone: true,
  imports: [ProductDotColorComponent],
})
export class CardProductComponent {
  @Input() productData!: ProductData

  readonly formatPrice = formatPrice
  readonly defaultImagePania = defaultImage

  get defaultUrl() {
    if (this.service.companyId == COMPANY_ID.BHB) {
      return this.defaultImagePania
    } else {
      return this.defaultImagePania
    }
  }

  get imageSrc() {
    return this.productData.colorDetail?.product_color?.file?.url
    // return this.colorDetails[this.index]?.product_color?.file?.url;
  }

  constructor(private service: Service) {}

  ngOnInit(): void {}
}
