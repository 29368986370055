import { Component, EventEmitter, Injectable, Input, OnInit, Output, inject } from '@angular/core'
import { MatFormFieldModule, MatSuffix } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatSelectModule } from '@angular/material/select'
import { DatePickerComponent } from '../date-picker/date-picker'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'

import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms'
import { SelectComponent } from '../select/select'
import { MatRipple } from '@angular/material/core'
import { CustomSnackBar } from '../snackbar/snackbar'
import { Loading } from '../../globals'
import { FileService } from '../../services'

@Component({
  selector: 'app-upload-image-table',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatSuffix,
    DatePickerComponent,
    SelectComponent,
    ReactiveFormsModule,
    FormsModule,
    MatRipple,
  ],
  templateUrl: './upload-image-table.html',
  styleUrls: ['./upload-image-table.scss'],
})
export class UploadImageTableComponent implements OnInit {
  @Input() type: string = 'view'
  @Input() logoDefault = ''
  @Output() showChange = new EventEmitter<boolean>()
  @Output() upload = new EventEmitter<any>()
  @Output() onOpen = new EventEmitter()
  @Output() onClose = new EventEmitter()

  @Input() preview: string = 'square'
  @Input() isRequired: boolean = false

  readonly acceptFileTypes = ['image/png', 'image/jpeg', 'image/jpg']

  uuid = crypto.randomUUID()
  imageSrc: any
  is_error: boolean = false

  get logoUrl() {
    return this.imageSrc || this.logoDefault
  }

  get imgUrl() {
    return this.logoUrl || '/assets/images/default-image-product-more.png'
  }

  get error() {
    return this.is_error || this.isRequired
  }

  constructor(
    public customSnackBar: CustomSnackBar,
    public loading: Loading,
    public fileService: FileService
  ) {}

  ngOnInit(): void {}

  // ------------upload file-------------------

  onChangeUpload(fileUpload: any) {
    const file = fileUpload.files[0]
    fileUpload.value = ''
    if (file) {
      //check file
      if (!this.isValidType(file.type)) {
        this.customSnackBar.fail('ไฟล์ต้องเป็นนามสกุล png, jpeg, jpg')
        return
      }
      if (!this.isValidSize(file.size)) {
        this.customSnackBar.fail('ไฟล์ต้องไม่เกิน 30mb')
        return
      }

      this.loading.start()
      this.fileService.upload(file).subscribe((res: any) => {
        if (res) {
          if (!res.is_error) {
            this.upload.emit(res.data)
            this.imageSrc = res.data[0].url
          } else {
            this.customSnackBar.fail()
          }
        }
        this.loading.stop()
      })
    }
  }

  isValidType(fileType: any) {
    return this.acceptFileTypes.some((type: any) => {
      return type === fileType
    })
  }

  isValidSize(fileSize: number) {
    return fileSize < 30 * Math.pow(1024, 2)
  }

  isDelete() {
    this.upload.emit([])
    if (this.imageSrc) {
      this.imageSrc = ''
    } else {
      this.logoDefault = ''
    }
  }

  dragOver(event: Event) {
    event.preventDefault()
  }

  drop(event: any) {
    event.preventDefault()
    this.onChangeUpload(event.dataTransfer)
  }
}
