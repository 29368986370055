<div mat-dialog-title class="dialog-title">
  <div class="dialog-title-head">{{data.title}}</div>
  <button class="btn btn-transparent btn-icon" (click)="onClose()">
    <img src="../assets/images/icons/icon-close.svg" />
  </button>
</div>

<mat-dialog-content>
  <div class="modal-content">
    <div class="filter-item-wrapper mb-2">
      <div class="filter-title">วันที่สร้างออเดอร์</div>
      <div class="col-12 col-sm-6">
        <app-date-picker-range
          [controlStr]="filterTableOrder.form.controls['start_date']"
          [controlEnd]="filterTableOrder.form.controls['end_date']"
          [max]="maxDate"></app-date-picker-range>
      </div>
    </div>

    <div class="filter-item-wrapper">
      <div class="filter-title">ช่องทางโซเชียล</div>

      <div class="filter-checkbox-wrapper checkbox-two-column">
        @for (item of filterTableOrder.list.channel; track $index) {
        <mat-checkbox
          class="filter-checkbox"
          [checked]="filterTableOrder.isChannelChecked(item.id)"
          (change)="filterTableOrder.channelChange(item.id)">
          @if (channelIcon[item.id]) {
          <div class="d-flex align-items-center gap-2">
            <img
              src="/assets/images/icons/icon-social-{{ channelIcon[item.id] }}.svg"
              width="20"
              height="21"
              [alt]="item.name" />
            {{ item.name }}
          </div>
          } @else { {{ item.name }} }
        </mat-checkbox>
        }
      </div>
    </div>

    <div class="filter-item-wrapper">
      <div class="filter-title">การจัดส่ง</div>

      <div class="filter-checkbox-wrapper checkbox-two-column">
        @for (item of filterTableOrder.list.transport; track $index) {
        <mat-checkbox
          class="filter-checkbox"
          [checked]="filterTableOrder.isTransportChecked(item.id)"
          (change)="filterTableOrder.transportChange(item.id)">
          {{ item.name }}
        </mat-checkbox>
        }
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="footer-button-wrapper d-flex justify-content-end w-100">
    <button matRipple class="btn btn-outline-secondary btn-md" (click)="onReset()">ล้างค่า</button>
    <button matRipple class="btn btn-primary btn-md" (click)="onConfirm()">นำไปใช้</button>
  </div>
</mat-dialog-actions>
