import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms'
import { imports } from '../../../imports'
import { InputComponent } from '../../../components/input/input'
import { TextareaComponent } from '../../../components/text-area/text-area'
import {
  EmailReg,
  ValidateForm,
  setErrorForm,
  ValidateArrayForm,
  Moment,
  convertDateToApi,
  Ascending,
  readHTML,
  COMPANY_ID,
} from '../../../helpers'
import { UploadImageProfileComponent } from '../../../components/upload-image-profile/upload-image-profile'
import { ModalLeaveComponent } from '../../../components/modal-leave/modal-leave'
import { MatDialog } from '@angular/material/dialog'
import { CompanyProfileService, CaratService, TierService } from '../../../services'
import { CustomSnackBar } from '../../../components/snackbar/snackbar'
import { ACTION_ACCESS, APP_PERMISSION, Loading, Profile } from '../../../globals'
import { forkJoin } from 'rxjs'
import { ModalConfirmComponent } from '../../../components/modal-confirm/modal-confirm'
import { InputNumberComponent } from '../../../components/input-number/input-number'
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
  CdkDrag,
  CdkDropList,
} from '@angular/cdk/drag-drop'
import { DatePickerComponent } from '../../../components/date-picker/date-picker'
import { UploadFileFlexibleComponent } from '../../../components/upload-file-flexible/upload-file-flexible'
import { SelectAddLinkComponent } from '../../../components/select-add-link/select-add-link'
import { ModalPreviewComponent } from '../../../components/modal-preview/modal-preview'
import { MatMenuModule } from '@angular/material/menu'
import { StatusComponent } from '../../../components/status/status'
import { DomSanitizer } from '@angular/platform-browser'
import { CMSTierService } from '../../../services/cms-tier.service'
import { ModalPreviewTierComponent } from './modal-preview/modal-preview'
import { ModalPreviewTierBHBComponent } from './modal-preview-bhb/modal-preview'

@Component({
  selector: 'app-cms-tier',
  standalone: true,
  imports: [
    ...imports,
    InputComponent,
    TextareaComponent,
    UploadImageProfileComponent,
    ModalLeaveComponent,
    InputNumberComponent,
    DatePickerComponent,
    CdkDrag,
    CdkDropList,
    UploadFileFlexibleComponent,
    SelectAddLinkComponent,
    MatMenuModule,
    StatusComponent,
  ],
  templateUrl: './cms-tier.html',
  styleUrls: ['./cms-tier.scss'],
})
export class CMSTierComponent implements OnInit {
  readonly APP_PERMISSION = APP_PERMISSION
  readonly ACTION_ACCESS = ACTION_ACCESS
  readonly Moment = Moment
  readonly readHTML = readHTML

  // mobile = window.matchMedia('(max-width: 1000px)')

  isEdit: boolean = false
  isView: boolean = false
  isCreate: boolean = false

  dataManage: any
  selectionIndex: number = 0
  tab: number = 0
  is_public: boolean = false

  tierList: any[] = []
  tierId: any = null

  form = new FormGroup({
    detail_th: new FormControl(''),
    detail_en: new FormControl(''),
  })

  constructor(
    public profile: Profile,
    public router: Router,
    public route: ActivatedRoute,
    public dialog: MatDialog,
    public customSnackBar: CustomSnackBar,
    public loading: Loading,
    public CMSTierService: CMSTierService,
    public sanitizer: DomSanitizer,
    public TierService: TierService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params: any) => {
      if (params.id) {
        this.tierId = params.id
      }

      if (this.router.url.includes('view')) {
        this.isView = true
      }
      if (this.router.url.includes('edit')) {
        this.isEdit = true
      }

      this.profile.getProfile()
      this.initData()
    })
  }

  initData() {
    this.loading.start()
    this.TierService.getTierList({}).subscribe(res => {
      if (res) {
        if (!res.is_error) {
          this.tierList = Ascending(res.data || [], 'min_amount')
          let data = res?.data

          if (!this.tierId) {
            this.tierId = data?.[0]?.id
          }

          if (this.tierId) {
            this.getCmsTierById(this.tierId)
          }
        } else {
          this.customSnackBar.fail(res.message)
        }
      }
      this.loading.stop()
    })
  }

  getCmsTierById(id: any) {
    this.loading.start()
    this.CMSTierService.getCmsTierById(id).subscribe(res => {
      if (res) {
        if (!res.is_error) {
          let data = res.data
          if (data.id) {
            this.dataManage = data
            this.form.setValue({
              detail_th: data.detail_th,
              detail_en: data.detail_en,
            })
          } else {
            this.isCreate = true
          }
        }
        this.loading.stop()
      }
    })
  }

  changeTab($tabIndex: any) {
    this.tab = $tabIndex
  }

  setSelection(id: any) {
    this.form.reset()
    this.dataManage = null
    this.isCreate = false

    this.tierId = id
    this.router.navigate([`/setting/cms/tier/${this.isView ? 'view' : 'edit'}`], {
      queryParams: { id: this.tierId },
    })
  }

  openPreview() {
    const tier = this.tierList.find(t => t.id == this.tierId)
    const index = this.tierList.indexOf(tier)
    const nextTier = this.tierList[index + 1]

    this.dialog.open(
      this.profile.companyId == COMPANY_ID.BHB
        ? ModalPreviewTierBHBComponent
        : ModalPreviewTierComponent,
      {
        width: '1200px',
        data: {
          lang: this.tab == 0 ? 'th' : 'en',
          tier: nextTier ? tier || tier : this.tierList[index - 1],
          nextTier: nextTier || tier,
          formValue: this.form.getRawValue(),
        },
      }
    )
  }

  onSave(type: any) {
    let payload = {
      tier_id: Number(this.tierId),
      detail_th: this.form.value.detail_th,
      detail_en: this.form.value.detail_en,
      is_public: type == 'public' ? true : false,
    }
    // console.log(payload)

    const API = this.isCreate
      ? this.CMSTierService.createCmsTier(payload)
      : this.CMSTierService.updateCmsTier(this.dataManage.id, payload)

    this.loading.start()
    API.subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          this.customSnackBar.success(type == 'public' ? 'เผยแพร่สำเร็จ' : 'บันทึกร่างสำเร็จ')
          this.router.navigate(['/setting/cms/tier/view'], {
            queryParams: { id: this.tierId },
          })
        } else {
          this.customSnackBar.failSave(
            type == 'public' ? 'เผยแพร่ไม่สำเร็จ' : 'บันทึกร่างไม่สำเร็จ'
          )
        }
      }
      this.loading.stop()
    })
  }

  onCancel() {
    const dialogRefConfirm = this.dialog.open(ModalLeaveComponent, {
      data: {},
    })

    dialogRefConfirm.afterClosed().subscribe(result => {
      if (result) {
        this.router.navigate(['/setting/cms/tier/view'], { queryParams: { id: this.tierId } })
      }
    })
  }
}
