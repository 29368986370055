<div class="page-content">
  <div class="breadcrumb-wrapper">
    <div class="breadcrumb-text breadcrumb-link">ตั้งค่า</div>
    <div class="breadcrumb-separator">/</div>
    <div class="breadcrumb-text">ตั้งค่าสกุลเงิน</div>
  </div>

  <div class="title-wrapper">ตั้งค่าสกุลเงิน</div>

  <div class="page-content-inner d-flex flex-column pb-0">
    <div class="title-with-update w-100 d-flex justify-content-between">
      <div class="title-blue">กำหนดสกุลเงิน</div>
      <div class="update-text mt-1 d-flex align-items-center gap-1">
        <img width="20" height="20" src="assets/images/icons/icon-refresh-update.svg" />
        อัปเดตล่าสุด: {{ currencyList[0] ? Moment(currencyList[0]?.updated_at).format('DD-MM-YYYY,
        HH:mm') : '-' }}
      </div>
    </div>

    <div class="currency-container">
      <div class="currency-th">
        <label class="form-label"> {{ 'THB' }} </label>
        <div class="d-flex">
          <app-input [value]="1" />
          <span class="icon material-symbols-outlined fill pt-2 px-3">equal</span>
        </div>
      </div>

      <div class="currency-wrapper">
        @for (item of currencyList; track $index) {
        <div class="currency">
          <label class="form-label"> {{ currencyList[$index]?.currency }} </label>
          <app-input [value]="currencyList[$index]?.exchange_rate" [disabled]="true" />
        </div>
        } @empty {
        <div class="currency">
          <label class="form-label"> - </label>
          <app-input [value]="" [disabled]="true" />
        </div>
        }
      </div>
    </div>
  </div>
</div>
