<div class="title-wrapper">You may like color</div>

<div class="page-content-inner mb-3">
  <div class="wrapper">
    <div class="d-flex justify-content-between align-items-center">
      <div class="title-blue">รายการสินค้า ({{ listProduct.length }})</div>

      @if (isEdit) {
      <button
        matRipple
        class="btn btn-outline-danger"
        [disabled]="!itemChecked.size"
        (click)="deleteItem()">
        <span class="icon material-symbols-outlined fill me-1">delete</span>
        ลบข้อมูล
      </button>
      }
    </div>

    <div class="table-responsive-detail mt-3">
      <table class="table table-fixed-column">
        <thead>
          <tr>
            @if (isEdit) {
            <th width="50">
              <app-checkbox-all-table
                [checkedValue]="itemChecked"
                [datas]="listProduct"
                fieldValue="key"
                [disabled]="!listProduct.length" />
            </th>
            }
            <th width="90"></th>
            <th width="180">รหัสสินค้า</th>
            <th width="350">ชื่อสินค้า</th>
            <th width="260" class="text-end">ราคา (บาท)</th>
            @if (isEdit) {
            <th width="260"></th>
            }
          </tr>
        </thead>
        <tbody>
          @for (item of pageList; track item) {
          <tr>
            @if (isEdit) {
            <td>
              <app-checkbox-table [checkedValue]="itemChecked" [value]="item.key" />
            </td>
            }
            <td>
              <img
                class="img-table"
                [src]="item.image?.file?.thumbnail || item.files?.[0]?.file?.thumbnail ||  '/assets/images/icons/img-default-table.svg'" />
            </td>
            <td>{{ item.code }}</td>
            <td>
              <div>
                <div>{{ item.name }}</div>
                <div class="product-detail">
                  @if (item.product_color) {
                  <div
                    class="tag-color"
                    [ngStyle]="{'background-color': item.product_color?.color_code || '#000000'}"></div>
                  }
                  <div>{{item.color}} , {{ item.size }}</div>
                </div>
              </div>
            </td>
            <td class="text-end">{{ formatPrice(item.selling_price) }}</td>
            @if (isEdit) {
            <td>
              <div class="d-flex justify-content-center">
                <button matRipple class="btn btn-outline-danger" (click)="deleteItem([item.key])">
                  <span class="icon material-symbols-outlined fill">delete</span>
                </button>
              </div>
            </td>
            }
          </tr>
          }
        </tbody>
      </table>

      @if(!listProduct.length) {
      <div class="data-not-found-wrapper flex-column align-items-center">
        <div>ไม่พบสินค้า @if(isEdit) { กรุณาเลือกสินค้า }</div>
        @if(isEdit) {
        <button matRipple class="btn btn-primary mt-3" (click)="addProduct()">
          <span class="icon material-symbols-outlined fill me-1">add_circle</span>
          เลือกสินค้า
        </button>
        }
      </div>
      }
    </div>

    @if (listProduct.length) {
    <div class="pagination-wrapper d-flex flex-wrap gap-2">
      <mat-paginator
        [pageIndex]="pagination.data.pageIndex"
        [length]="pagination.data.length"
        [pageSize]="pagination.data.pageSize"
        [pageSizeOptions]="pagination.data.pageSizeOptions"
        (page)="pagination.setFromPaginator($event);"
        aria-label="Select page">
      </mat-paginator>
    </div>
    }

    <!-- ~ -->
    @if(listProduct.length && isEdit) {
    <button matRipple class="btn btn-primary mt-4" (click)="addProduct()">
      <span class="icon material-symbols-outlined fill me-1">add_circle</span>
      เลือกสินค้า
    </button>
    }
  </div>

  <div class="page-footer-btn">
    <div class="d-flex flex-wrap justify-content-between gap-2">
      <div class="d-flex flex-wrap gap-2"></div>

      <div class="d-flex flex-wrap gap-2">
        <button
          matRipple
          class="btn btn-outline-secondary min-w-100px"
          (click)="onClose.emit(false)">
          ยกเลิก
        </button>
        @if (isEdit) {
        <button matRipple class="btn btn-primary min-w-100px" (click)="confirm()">บันทึก</button>
        }
      </div>
    </div>
  </div>
</div>
