<mat-dialog-content>
  <div class="modal-content">
    <button class="btn btn-transparent btn-icon button-close" (click)="onClose()">
      <img src="../assets/images/icons/icon-close.svg" />
    </button>

    <div>
      <div class="d-flex align-items-center justify-content-center flex-wrap gap-2">
        <span class="title"> ช้อปสินค้าแบรนด์ดังเพิ่มเติมได้บน </span>
        <label class="tag-name">
          @if (data.companyId == COMPANY_ID.BHB) {
          <img src="/assets/images/bhb-logo-link.png" height="16" />
          } @else {
          <img src="/assets/images/pania-logo-link.svg" height="16" />
          }
        </label>
      </div>
      <div class="des mt-1">พรีออเดอร์ สินค้าพร้อมส่ง ราคาพิเศษ</div>
    </div>
    <div class="box-images">
      @for (item of products; track $index) {
      <div>
        <div class="box-image">
          @defer { @if (item.image) {
          <img [src]="item.image?.file?.url" />
          } @else {
          <img [src]="defaultImage" />
          } } @placeholder {
          <div class="bg--neutral6 w-100 h-100"></div>
          }
        </div>
        <div class="name mt-2">
          <app-text-tooltip> {{ item.name || '-' }} </app-text-tooltip>
        </div>
      </div>
      } @empty {
      <div>
        <div class="box-image"></div>
      </div>
      }
    </div>
    <div class="d-flex justify-content-center mt-3">
      <a [href]="data.webLink" target="_blank" class="a-to-link">
        <button class="btn btn-primary btn-md button-to-link" matRipple>ช้อปสินค้า</button>
      </a>
    </div>
  </div>
</mat-dialog-content>
