import { Component, Inject, OnInit } from '@angular/core'
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog'
import { MatInputModule } from '@angular/material/input'
import { imports } from '../../../../imports'
import { CustomSnackBar } from '../../../../components/snackbar/snackbar'
import { ModalConfirmComponent } from '../../../../components/modal-confirm/modal-confirm'
import { ModalLeaveComponent } from '../../../../components/modal-leave/modal-leave'
import { InputComponent } from '../../../../components/input/input'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { Loading } from '../../../../globals'
import { BANK_NONE, BANK_TYPE_ID, ValidateForm, setErrorForm } from '../../../../helpers'
import { SelectComponent } from '../../../../components/select/select'
import { MasterService, PaymentChannelService } from '../../../../services'
import { forkJoin } from 'rxjs'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { UploadImageComponent } from '../upload-image/upload-image'

@Component({
  selector: 'app-modal-bank',
  templateUrl: './modal-bank.html',
  styleUrls: ['./modal-bank.scss'],
  standalone: true,
  imports: [
    ...imports,
    MatInputModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    ModalConfirmComponent,
    ModalLeaveComponent,
    InputComponent,
    MatSlideToggleModule,
    SelectComponent,
    MatCheckboxModule,
    UploadImageComponent,
  ],
})
export class ModalBankComponent implements OnInit {
  form = new FormGroup({
    bank_id: new FormControl('', [Validators.required]),
    account_name: new FormControl('', [Validators.required, Validators.maxLength(100)]),
    branch_name: new FormControl('', [Validators.maxLength(100)]),
    branch_code: new FormControl('', [Validators.pattern(/^[0-9]+$/), Validators.maxLength(100)]),
    account_number: new FormControl('', [
      Validators.required,
      Validators.pattern(/^[0-9]{10,14}$/),
    ]),
    type_id: new FormControl(BANK_TYPE_ID.SAVING, [Validators.required]),
    is_active: new FormControl(true),
    is_primary: new FormControl(false),
    qr_id: new FormControl(null),
  })

  list = {
    bank: [],
    bankType: [],
  }

  dataManage: any

  constructor(
    public dialogRef: MatDialogRef<ModalBankComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public customSnackBar: CustomSnackBar,
    public loading: Loading,
    public paymentChannelService: PaymentChannelService,
    public masterService: MasterService
  ) {}

  ngOnInit(): void {
    this.initData()
  }

  initData() {
    const apis = [this.masterService.getBanks(), this.masterService.getBankType()]

    if (this.data.type == 'edit') {
      apis.push(this.paymentChannelService.getPaymentChannel(this.data.dataManage.id))
    } else {
      if (this.data.is_main_default) {
        this.form.controls.is_primary.setValue(true)
        this.form.controls.is_active.disable()
        this.form.controls.is_primary.disable()
        this.form.controls.is_active.disable()
      }
    }

    this.loading.start()
    forkJoin(apis).subscribe(([resBank, resBankType, resPaymentChannel]: any) => {
      if (resBank) {
        if (!resBank.is_error) {
          this.list.bank = resBank.data.filter((b: any) => b.code != BANK_NONE.CODE)
        } else {
          this.customSnackBar.fail(resBank.message)
        }
      }

      if (resBankType) {
        if (!resBankType.is_error) {
          this.list.bankType = resBankType.data
        } else {
          this.customSnackBar.fail(resBankType.message)
        }
      }

      if (resPaymentChannel) {
        if (!resPaymentChannel.is_error) {
          this.dataManage = resPaymentChannel.data

          this.form.reset({
            bank_id: this.dataManage.bank_id,
            account_name: this.dataManage.account_name,
            branch_name: this.dataManage.branch_name,
            branch_code: this.dataManage.branch_code,
            account_number: this.dataManage.account_number,
            type_id: this.dataManage.type_id,
            is_active: this.dataManage.is_active,
            is_primary: this.dataManage.is_primary || false,
            qr_id: this.dataManage.qr_id,
          })
          if (this.form.controls.is_primary.value) {
            this.form.controls.is_primary.disable()
            this.form.controls.is_active.disable()
          }
        } else {
          this.customSnackBar.fail(resPaymentChannel.message)
        }
      }

      this.loading.stop()
    })
  }

  changePrimary() {
    if (this.form.controls.is_primary.value) {
      this.form.controls.is_active.setValue(true)
      this.form.controls.is_active.disable()
    } else {
      this.form.controls.is_active.enable()
    }
  }

  onClose() {
    if (this.data.type == 'edit' && this.form.dirty) {
      const dialogRef = this.dialog.open(ModalLeaveComponent, {
        data: {
          data: true,
        },
      })

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.dialogRef.close()
        }
      })
    } else {
      this.dialogRef.close()
    }
  }

  onConfirm(): void {
    ValidateForm(this.form)
    if (!this.form.valid) return

    if (this.data.type == 'edit') {
      this.openModalConfirm()
    } else {
      this.onSave()
    }
  }

  openModalConfirm() {
    const dialogRefConfirm = this.dialog.open(ModalConfirmComponent, {
      data: {},
    })

    dialogRefConfirm.afterClosed().subscribe(result => {
      if (result) {
        this.onSave()
      }
    })

    return dialogRefConfirm
  }

  onSave(): void {
    const isEdit = this.data.type == 'edit'

    const value = this.form.getRawValue()
    const payload = {
      bank_id: value.bank_id,
      account_name: value.account_name,
      branch_name: value.branch_name,
      branch_code: value.branch_code,
      account_number: value.account_number,
      type_id: value.type_id,
      is_active: value.is_active,
      is_primary: value.is_primary,
      qr_id: value.qr_id,
    }

    // return console.log(payload)

    const api = isEdit
      ? this.paymentChannelService.updatePaymentChannel(this.data.dataManage.id, payload)
      : this.paymentChannelService.addPaymentChannel(payload)
    this.loading.start()
    api.subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          this.customSnackBar.success('บันทึกข้อมูลสำเร็จ')
          this.dialogRef.close(true)
        } else {
          if (res.errors[0]?.field) {
            setErrorForm(this.form, res.errors)
          } else {
            this.customSnackBar.failSave(res.message)
          }
        }
      }
      this.loading.stop()
    })
  }
}
