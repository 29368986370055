<div class="scroll-slide" [class.layout-1]="layout == 1" [class.layout-2]="layout == 2">
  <button
    class="button-move button-prev"
    [class.show]="isPrev"
    [class.hide-icon-disabled]="hideIconDisabled"
    [disabled]="layout == 2 && !isPrev"
    (click)="onMove('prev')">
    <img src="/assets/preview/icons/icon-scroll-next.svg" width="24" height="24" />
  </button>

  <div #divscroll class="box-scroll">
    <div #content class="content">
      <ng-content></ng-content>
    </div>
  </div>

  <button
    class="button-move button-next"
    [class.show]="isNext"
    [class.hide-icon-disabled]="hideIconDisabled"
    [disabled]="layout == 2 && !isNext"
    (click)="onMove('next')">
    <img src="/assets/preview/icons/icon-scroll-next.svg" width="24" height="24" />
  </button>
</div>
