import { Component, Inject } from '@angular/core'
import { MatCheckboxModule } from '@angular/material/checkbox'
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog'
import { DatePickerRangeComponent } from '../../../../../components/date-picker-range/date-picker-range'
import { CustomSnackBar } from '../../../../../components/snackbar/snackbar'
import { FilterTableHistoryOrderDeleted } from '../../../../../globals'
import { imports } from '../../../../../imports'

@Component({
  selector: 'app-modal-filter-history-order',
  templateUrl: './modal-filter.html',
  styleUrls: ['./modal-filter.scss'],
  standalone: true,
  imports: [
    ...imports,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatCheckboxModule,
    DatePickerRangeComponent,
  ],
})
export class ModalFilterComponent {
  constructor(
    public dialogRef: MatDialogRef<ModalFilterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public customSnackBar: CustomSnackBar,
    public filterTableHistoryOrderDeleted: FilterTableHistoryOrderDeleted
  ) {}

  ngOnInit(): void {
    this.filterTableHistoryOrderDeleted.initData()
  }

  onClose(): void {
    this.dialogRef.close()
  }

  onReset(): void {
    this.filterTableHistoryOrderDeleted.resetModalFilter()
  }

  onConfirm(): void {
    this.filterTableHistoryOrderDeleted.confirm()
    this.dialogRef.close(true)
  }
}
