@if (Router.url != '/') {
<mat-drawer-container class="drawer-container" [hasBackdrop]="true">
  <mat-drawer #drawer [mode]="'over'">
    @if (isShowMenu) {
    <app-nav-menu></app-nav-menu>
    }
  </mat-drawer>

  <mat-drawer-content>
    <!-- % -->
    @if (isShowHeader) {
    <app-header></app-header>
    }

    <router-outlet></router-outlet>
  </mat-drawer-content>
</mat-drawer-container>
}
<app-spinner-custom [show]="Loading.isLoading"></app-spinner-custom>
