import { Injectable } from '@angular/core'
import { CustomSnackBar } from '../../components/snackbar/snackbar'
import { Loading } from '../loading'
import { __searchAndFitler } from './_mix'
import { FormControl, FormGroup } from '@angular/forms'
import { convertDateToApi, Moment, ORDER_ID } from '../../helpers'

@Injectable({ providedIn: 'root' })
export class FilterTableHistoryOrderCanceled extends __searchAndFitler {
  readonly list = {
    status_ids: <any[]>[
      { id: 1, text: 'สินค้าหมด' },
      { id: 2, text: 'เกินเวลาชำระเงิน' },
    ],
  }

  form = new FormGroup({
    start_cancel_date: new FormControl(''),
    end_cancel_date: new FormControl(''),
  })

  get isFilter() {
    const data = this.getData()
    return data.filter && Object.keys(data.filter).length
  }

  get startEndDateFormat() {
    return [this.form.controls['start_cancel_date'].value, this.form.controls['end_cancel_date'].value]
      .reduce((ds: any[], d: any) => {
        if (d) {
          ds.push(Moment(d).format('DD/MM/YYYY'))
        }
        return ds
      }, [])
      .join(' - ')
  }

  constructor(public loading: Loading, public customSnackBar: CustomSnackBar) {
    const filter = {
      cancel_statuses: [],
    }
    super('/history/order/canceled', filter)

    this._initForm(this.form)
  }

  initData() {}

  isStatusIdChecked(id: any) {
    return this.filter['cancel_statuses'].some((val: any) => val === id)
  }

  statusIdChange(id: any) {
    const cancel_statuses = this.filter['cancel_statuses']
    if (cancel_statuses.includes(id)) {
      cancel_statuses.splice(cancel_statuses.indexOf(id), 1)
    } else {
      cancel_statuses.push(id)
    }
  }

  removeStatusId(id: any) {
    this.statusIdChange(id)
  }

  removeStartEndDate() {
    this.form.controls['start_cancel_date'].reset()
    this.form.controls['end_cancel_date'].reset()
  }

  getData() {
    const filter: any = {}
    const data: any = {
      filter,
    }

    const value = this.form.getRawValue()
    if (value.start_cancel_date) {
      filter.start_cancel_date = convertDateToApi(value.start_cancel_date)
    }
    if (value.end_cancel_date) {
      filter.end_cancel_date = convertDateToApi(value.end_cancel_date, 'day')
    }
    if (this.filter['cancel_statuses'].length) {
      filter.cancel_statuses = this.filter['cancel_statuses']
    }

    const searchValue = this.search.value
    if (searchValue) {
      data.search = searchValue
    }

    return data
  }
}
