import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { Service } from './service'

@Injectable({
  providedIn: 'root',
})
export class CMSTierService {
  constructor(private service: Service) {}
  private pathCMSBenefits = '/v1/cms/benefits'

  getCmsTierById(id: any): Observable<any> {
    return this.service.get(`${this.pathCMSBenefits}/tiers/${id}`)
  }

  createCmsTier(payload: any): Observable<any> {
    return this.service.post(`${this.pathCMSBenefits}`, payload)
  }

  updateCmsTier(id: any, payload: any): Observable<any> {
    return this.service.put(`${this.pathCMSBenefits}/${id}`, payload)
  }

}
