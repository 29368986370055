<div mat-dialog-title class="dialog-title">
  <div class="dialog-title-head">ดูตัวอย่าง</div>
  <button class="btn btn-transparent btn-icon" (click)="onClose()">
    <img src="../assets/images/icons/icon-close.svg" />
  </button>
</div>

<mat-dialog-content>
  <div class="modal-content py-2">
    <div class="page-top-content">
      <div class="box-content">
        <app-top-menu theme="black" [lang]="data.lang"></app-top-menu>
      </div>
    </div>

    @if (policies) {
    <div class="text-center mt-3 mb-3">
      <h1 class="p-title">
        {{ policies.is_privacy_policy ? data.lang == 'th'? 'นโยบายความเป็นส่วนตัว':'Privacy &
        Policy' : data.lang == 'th'? 'ข้อกำหนดและเงื่อนไข': 'Term & Condition' }}
      </h1>
    </div>

    <div class="px-4 mx-auto pb-3 mb-3 p-detail">
      <div
        [innerHtml]="
          sanitizer.bypassSecurityTrustHtml(readHTML(policies['detail_' + data.lang] || ''))"></div>
    </div>
    }

    <app-footer [lang]="data.lang"></app-footer>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="footer-button-wrapper d-flex justify-content-end w-100">
    <button class="btn btn-primary btn-md" matRipple (click)="onClose()">กลับ</button>
  </div>
</mat-dialog-actions>
