<div class="menu-profile-wrapper mt-2 hidden md:flex">
  <div class="menu-profile">
    <div class="menu-profile-item">{{ lang == 'en' ? 'My Profile' : 'โปรไฟล์ของฉัน' }}</div>
    <div class="menu-profile-item active">{{ lang == 'en' ? 'My Tier' : 'ระดับของฉัน' }}</div>
    <div class="menu-profile-item">{{ lang == 'en' ? 'My Favourites' : 'รายการโปรดของฉัน' }}</div>
    <div class="menu-profile-item">{{ lang == 'en' ? 'My Wish List' : 'รายการที่อยากได้' }}</div>
    <div class="menu-profile-item">{{ lang == 'en' ? 'My Coupons' : 'คูปองของฉัน' }}</div>
    <div class="menu-profile-item">{{ lang == 'en' ? 'My Order' : 'คำสั่งซื้อของฉัน' }}</div>
    <div class="menu-profile-item">
      {{ lang == 'en' ? 'Shipping Address' : 'ที่อยู่สำหรับจัดส่ง' }}
    </div>
  </div>

  <div>
    <button class="logout">
      <span class="text-base-regular color--Primary-Black--f flex align-items-center gap-2">
        <img src="/assets/preview-bhb/icons/icon-sign-out-circle.svg" alt="icon-sign-out" />
        {{ lang == 'en' ? 'Log out' : 'ออกจากระบบ' }}
      </span>
    </button>
  </div>
</div>
