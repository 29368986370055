import { Component, EventEmitter, Input, Output } from '@angular/core'
import { MatSuffix } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatSelectModule } from '@angular/material/select'
import { MatDialog } from '@angular/material/dialog'
import { ReactiveFormsModule } from '@angular/forms'

import { FilterTableCustomer, FilterTableOrder, Loading } from '../../../../app/globals'
import { RoleService } from '../../../../app/services'
import { CustomSnackBar } from '../../../components/snackbar/snackbar'
import { ModalFilterComponent } from '../modal-filter/modal-filter'
import { InputSearchScanComponent } from '../input-search-scan/input-search-scan'
import { imports } from '../../../imports'
import { Moment } from '../../../helpers'

@Component({
  selector: 'app-search-filter-order',
  standalone: true,
  imports: [
    ...imports,
    MatInputModule,
    MatSelectModule,
    MatSuffix,
    ReactiveFormsModule,
    ModalFilterComponent,
    InputSearchScanComponent,
  ],
  templateUrl: './search-filter.html',
  styleUrls: ['./search-filter.scss'],
})
export class SearchFilterComponent {
  @Input() placeholder = ''
  @Output() onConfirm = new EventEmitter()

  constructor(
    public dialog: MatDialog,
    public roleService: RoleService,
    public loading: Loading,
    public customSnackBar: CustomSnackBar,
    public filterTableOrder: FilterTableOrder
  ) {}

  ngOnInit(): void {}

  async openModalFilter() {
    const dialogRef = this.dialog.open(ModalFilterComponent, {
      data: {
        title: 'ตัวกรอง',
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.onConfirm.emit()
      } else {
        this.filterTableOrder.close()
      }
    })
  }

  afterFilter() {
    this.filterTableOrder.confirm()
    this.onConfirm.emit()
  }
}
