import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms'
import { imports } from '../../../../imports'
import { InputComponent } from '../../../../components/input/input'
import { TextareaComponent } from '../../../../components/text-area/text-area'
import {
  EmailReg,
  ValidateForm,
  setErrorForm,
  ValidateArrayForm,
  Moment,
  convertDateToApi,
  Ascending,
  productImage,
} from '../../../../helpers'
import { UploadImageProfileComponent } from '../../../../components/upload-image-profile/upload-image-profile'
import { ModalLeaveComponent } from '../../../../components/modal-leave/modal-leave'
import { MatDialog } from '@angular/material/dialog'
import { CompanyProfileService, CaratService } from '../../../../services'
import { CustomSnackBar } from '../../../../components/snackbar/snackbar'
import { ACTION_ACCESS, APP_PERMISSION, Loading, Profile } from '../../../../globals'
import { forkJoin } from 'rxjs'
import { ModalConfirmComponent } from '../../../../components/modal-confirm/modal-confirm'
import { InputNumberComponent } from '../../../../components/input-number/input-number'
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
  CdkDrag,
  CdkDropList,
} from '@angular/cdk/drag-drop'
import { DatePickerComponent } from '../../../../components/date-picker/date-picker'
import { UploadFileFlexibleComponent } from '../../../../components/upload-file-flexible/upload-file-flexible'
import { RadioLayoutComponent } from '../../../../components/radio-layout/radio-layout'
import { MatRadioModule } from '@angular/material/radio'
import { SelectComponent } from '../../../../components/select/select'
import { SectionProductComponent } from './section-product/section-product'
import { ModalManageHomeComponent } from './modal-manage-home/modal-manage-home'
import { ModalSelectProductSingleComponent } from '../../../../components/modal-select-product-single/modal-select-product-single'
import { ModalSelectCategoryProductComponent } from '../../../../components/modal-select-category-product/modal-select-category-product'
import { ModalAddURLComponent } from '../../../../components/modal-add-url/modal-add-url'
import { SelectAddLinkComponent } from '../../../../components/select-add-link/select-add-link'
import { ModalPreviewComponent } from '../../../../components/modal-preview/modal-preview'
import { CMSMainService } from '../../../../services/cms-main.service'
import { LINK_LIST_ID, linkTextView } from '../../../../helpers/link'
import { CMSMenuService } from '../../../../services/cms-menu.service'
import { ModalPreviewMainComponent } from './modal-preview/modal-preview'
import { StatusComponent } from '../../../../components/status/status'

@Component({
  selector: 'app-cms-home-pania',
  standalone: true,
  imports: [
    ...imports,
    InputComponent,
    TextareaComponent,
    UploadImageProfileComponent,
    ModalLeaveComponent,
    InputNumberComponent,
    DatePickerComponent,
    CdkDrag,
    CdkDropList,
    UploadFileFlexibleComponent,
    RadioLayoutComponent,
    MatRadioModule,
    SelectComponent,
    CdkDrag,
    CdkDropList,
    SectionProductComponent,
    SelectAddLinkComponent,
    StatusComponent,
  ],
  templateUrl: './cms-home.html',
  styleUrls: ['./cms-home.scss'],
})
export class CMSHomePaniaComponent implements OnInit {
  readonly APP_PERMISSION = APP_PERMISSION
  readonly ACTION_ACCESS = ACTION_ACCESS
  readonly Moment = Moment
  readonly linkTextView = linkTextView

  // mobile = window.matchMedia('(max-width: 1000px)')

  isEdit: boolean = false
  isView: boolean = false
  dataManage: any
  reset: any

  cms_pania_link_menus = new FormArray<FormGroup>([
    new FormGroup({
      link_name: new FormControl(''),
      cms_link_list_id: new FormControl(),
      product_id: new FormControl(),
      product_detail_id: new FormControl(),
      product_category_id: new FormControl(),
      link_path: new FormControl(),
      product_name: new FormControl(),
      product_category_name: new FormControl(),
    }),
    new FormGroup({
      link_name: new FormControl(''),
      cms_link_list_id: new FormControl(),
      product_id: new FormControl(),
      product_detail_id: new FormControl(),
      product_category_id: new FormControl(),
      link_path: new FormControl(),
      product_name: new FormControl(),
      product_category_name: new FormControl(),
    }),
    new FormGroup({
      link_name: new FormControl(''),
      cms_link_list_id: new FormControl(),
      product_id: new FormControl(),
      product_detail_id: new FormControl(),
      product_category_id: new FormControl(),
      link_path: new FormControl(),
      product_name: new FormControl(),
      product_category_name: new FormControl(),
    }),
  ])

  formSectionProduct = new FormGroup({
    id: new FormControl(null),
    is_two_image: new FormControl('two'),
    product_category_id: new FormControl(null),
    cms_pania_main_content_details: new FormArray<FormGroup>([]),
    value_cms_pania_main_content_details: new FormControl([]),
    cms_pania_main_content_products: new FormControl(
      [],
      [
        (control: AbstractControl) => {
          if (this.productCount(control) < 4) {
            return {
              minLength: true,
            }
          }

          return null
        },
      ]
    ),
  })

  sectionProductArr = new FormArray([this.formSectionProduct])

  form = new FormGroup({
    is_content_white: new FormControl(true),
    content_th: new FormControl(),
    content_en: new FormControl(),
    content_description_th: new FormControl(),
    content_description_en: new FormControl(),
    file_banner_id: new FormControl(null, [Validators.required]),
    cms_link_list_id: new FormControl(),
    product_id: new FormControl(),
    product_detail_id: new FormControl(),
    product_category_id: new FormControl(),
    product_name: new FormControl(),
    product_category_name: new FormControl(),
    link_path: new FormControl(),
    cms_pania_link_menus: this.cms_pania_link_menus,
    cms_pania_main_contents: this.sectionProductArr,
  })

  constructor(
    public profile: Profile,
    public router: Router,
    public dialog: MatDialog,
    public customSnackBar: CustomSnackBar,
    public loading: Loading,
    public companyProfileService: CompanyProfileService,
    public cmsMainService: CMSMainService
  ) {}

  ngOnInit(): void {
    if (this.router.url.includes('view')) {
      this.isView = true
    }
    if (this.router.url.includes('edit')) {
      this.isEdit = true
    }
    this.initData()
    // this.openModalSelectProductSingle()
  }

  initData() {
    const apis = [this.cmsMainService.getCmsMain()]

    // this.sectionProductArr.clear()
    this.loading.start()
    forkJoin(apis).subscribe(([resMain]: any) => {
      if (!resMain.is_error) {
        if (resMain.data.id) {
          this.dataManage = resMain.data

          this.form.reset({
            is_content_white: this.dataManage.is_content_white,
            content_th: this.dataManage.content_th,
            content_en: this.dataManage.content_en,
            content_description_th: this.dataManage.content_description_th,
            content_description_en: this.dataManage.content_description_en,
            file_banner_id: this.dataManage.file_banner_id,
            cms_link_list_id: this.dataManage.cms_link_list_id ?? null,
            product_id: this.dataManage.product_id,
            product_detail_id: this.dataManage.product_detail_id,
            product_category_id: this.dataManage.product_category_id,
            link_path: this.dataManage.link_path,
            product_name: this.dataManage.product?.name,
            product_category_name: this.dataManage.product_category?.name,
          })

          Ascending(this.dataManage.cms_pania_link_menus, 'seq')
          ;(this.dataManage.cms_pania_link_menus || []).forEach((item: any, i: number) => {
            const f = this.cms_pania_link_menus.controls[i]
            f.setValue({
              link_name: item.link_name,
              cms_link_list_id: item.cms_link_list_id ?? null,
              product_id: item.product_id || null,
              product_detail_id: item.product_detail_id || null,
              product_category_id: item.product_category_id || null,
              link_path: item.link_path || null,
              product_name: item?.product?.name || null,
              product_category_name: item?.product_category?.name || null,
            })
          })

          this.sectionProductArr.clear()
          if (this.dataManage.cms_pania_main_contents?.length) {
            for (const value of this.dataManage.cms_pania_main_contents) {
              this.addSection(value)
            }
          } else {
            this.addSection()
          }
        } else if (!resMain.data.id) {
          this.router.navigate(['/setting/cms/home/edit'])
        }
      } else {
        this.customSnackBar.fail()
      }

      this.loading.stop()
    })
  }

  drop(event: CdkDragDrop<any[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex)
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      )
    }
    // this.checkValidate()
    this.form.markAllAsTouched()
  }

  addSection(value: any = { is_two_image: true }) {
    const cms_pania_main_content_details = new FormArray<FormGroup>([])
    if (value.cms_pania_main_content_details?.length) {
      for (const v of value.cms_pania_main_content_details) {
        cms_pania_main_content_details.push(
          new FormGroup({
            file_id: new FormControl(v.file_id || null),
            file_url: new FormControl(v.file?.url || null),
            cms_link_list_id: new FormControl(v.cms_link_list_id ?? null),
            product_id: new FormControl(v.product_id || null),
            product_detail_id: new FormControl(v.product_detail_id || null),
            product_category_id: new FormControl(v.product_category_id || null),
            link_path: new FormControl(v.link_path || null),
            product_name: new FormControl((v.product_detail || v.product)?.name || ''),
            product_category_name: new FormControl(v.product_category?.name || ''),
          })
        )
      }
    }

    // console.log('cms_pania_main_content_details', cms_pania_main_content_details)

    let cms_pania_main_content_products: any = []
    if (value.cms_pania_main_content_products?.length) {
      cms_pania_main_content_products = Ascending(
        value.cms_pania_main_content_products,
        'seq'
      ).reduce((produtcs: any[], item: any) => {
        produtcs.push({
          ...item,
          ...(item.product_detail_id ? item.product_detail : item.product),
          image: productImage(item.product, item.product_detail),
          seq: item.seq,
        })

        return produtcs
      }, [])
    }

    // console.log('cms_pania_main_content_products', cms_pania_main_content_products)

    let newForm = new FormGroup({
      id: new FormControl(null),
      is_two_image: new FormControl(value.is_two_image ? 'two' : 'one'),
      product_category_id: new FormControl(value.product_category_id),
      cms_pania_main_content_details,
      value_cms_pania_main_content_details: new FormControl(value.cms_pania_main_content_details),
      cms_pania_main_content_products: new FormControl(cms_pania_main_content_products, [
        (control: AbstractControl) => {
          if (this.productCount(control) < 4) {
            return {
              minLength: true,
            }
          }

          return null
        },
      ]),
    })
    this.sectionProductArr.push(newForm)
    // console.log('value', this.sectionProductArr)
  }

  deleteSection(index: number) {
    this.sectionProductArr.removeAt(index)
  }

  openModalManageHome() {
    this.dialog.open(ModalManageHomeComponent, {
      data: {},
    })
  }

  setDefault() {
    this.form.reset({
      is_content_white: true,
    })
    this.sectionProductArr.push(
      new FormGroup({
        id: new FormControl(null),
        is_two_image: new FormControl('two'),
        product_category_id: new FormControl(null),
        cms_pania_main_content_details: new FormArray<FormGroup>([]),
        value_cms_pania_main_content_details: new FormControl([]),
        cms_pania_main_content_products: new FormControl(
          [],
          [
            (control: AbstractControl) => {
              if (this.productCount(control) < 4) {
                return {
                  minLength: true,
                }
              }

              return null
            },
          ]
        ),
      })
    )

    for (const f of this.cms_pania_link_menus.controls) {
      f.reset()
    }
  }

  productCount(productControl: FormControl | AbstractControl) {
    return (productControl.value || []).reduce((products: any[], value: any) => {
      if (!products.includes(value.product_id)) {
        products.push(value.product_id)
      }
      return products
    }, []).length
  }

  onReset() {
    this.sectionProductArr.clear()
    this.setDefault()
    this.reset = crypto.randomUUID()
    // this.initData()
  }

  onCancel() {
    const dialogRefConfirm = this.dialog.open(ModalLeaveComponent, {
      data: {},
    })

    dialogRefConfirm.afterClosed().subscribe(result => {
      if (result) {
        this.router.navigate(['/setting/cms/home/view'])
      }
    })
  }

  openPreview() {
    if (!this.checkValidate()) return

    this.dialog.open(ModalPreviewMainComponent, {
      width: '1200px',
      data: {
        // dataManage: this.dataManage,
        formValue: this.form.getRawValue(),
      },
    })
  }

  checkValidate() {
    ValidateForm(this.form)
    let arr_invalid = false
    for (const c of this.form.controls.cms_pania_main_contents.controls) {
      ValidateArrayForm(c.controls['cms_pania_main_content_details'])

      if (!c.controls['cms_pania_main_content_details'].valid) {
        arr_invalid = true
      }
    }
    if (!this.form.valid) return
    if (arr_invalid) return

    return true
  }

  onSave(is_public: boolean) {
    if (!this.checkValidate()) return

    const formValue = this.form.getRawValue()
    // console.log(formValue)
    const payload = {
      id: this.dataManage?.id || null,
      is_content_white: formValue.is_content_white,
      content_th: formValue.content_th,
      content_en: formValue.content_en,
      content_description_th: formValue.content_description_th,
      content_description_en: formValue.content_description_en,
      file_banner_id: formValue.file_banner_id,
      is_public: is_public,
      cms_link_list_id: formValue.cms_link_list_id ?? null,
      product_id: formValue.product_id,
      product_detail_id: formValue.product_detail_id,
      product_category_id: formValue.product_category_id,
      link_path: formValue.link_path,
      cms_pania_link_menus: formValue.cms_pania_link_menus.map((value: any, seq) => {
        return {
          seq: seq + 1,
          link_name: value.link_name,
          cms_link_list_id: value.cms_link_list_id ?? null,
          product_id: value.product_id,
          product_detail_id: value.product_detail_id,
          product_category_id: value.product_category_id,
          link_path: value.link_path,
          is_page_campaign: value.cms_link_list_id == LINK_LIST_ID.CAMPAIGN,
          is_page_collection: value.cms_link_list_id == LINK_LIST_ID.COLLECTION,
        }
      }),
      cms_pania_main_contents: formValue.cms_pania_main_contents.map(c => {
        return {
          id: c.id,
          is_two_image: c.is_two_image == 'one' ? false : true,
          // cms_link_list_id: null,
          // product_id: null,
          // product_detail_id: null,
          product_category_id: c.product_category_id,
          // link_path: null,
          // is_page_campaign: false,
          // is_page_collection: false,
          cms_pania_main_content_details: c.cms_pania_main_content_details.map((d: any) => {
            return {
              file_id: d.file_id,
              cms_link_list_id: d.cms_link_list_id ?? null,
              product_id: d.product_id || null,
              product_detail_id: d.product_detail_id || null,
              product_category_id: d.product_category_id || null,
              link_path: d.link_path || null,
              is_page_campaign: d.cms_link_list_id == LINK_LIST_ID.CAMPAIGN,
              is_page_collection: d.cms_link_list_id == LINK_LIST_ID.COLLECTION,
            }
          }),
          cms_pania_main_content_products: c.cms_pania_main_content_products?.map((p: any, seq) => {
            return {
              seq: seq + 1,
              product_id: p.product_id,
              product_detail_id: p.product_detail_id,
            }
          }),
        }
      }),
    }
    console.log(payload)

    this.loading.start()
    this.cmsMainService.updateCmsMain(payload).subscribe(res => {
      if (!res.is_error) {
        this.customSnackBar.success(is_public ? 'เผยแพร่สำเร็จ' : 'บันทึกร่างสำเร็จ')
        this.router.navigate(['/setting/cms/home/view'])
      } else {
        this.customSnackBar.failSave(is_public ? 'เผยแพร่ไม่สำเร็จ' : 'บันทึกร่างไม่สำเร็จ')
      }
      this.loading.stop()
    })
  }
}
