<div mat-dialog-title class="dialog-title">
  <div class="dialog-title-head">ดูตัวอย่าง</div>
  <button class="btn btn-transparent btn-icon" (click)="onClose()">
    <img src="../assets/images/icons/icon-close.svg" />
  </button>
</div>

<mat-dialog-content>
  <div class="modal-content py-2">
    <div class="page-top-content">
      <div class="box-content">
        <app-top-menu theme="black"></app-top-menu>
      </div>
    </div>

    @if (file_main_banner) {
    <div class="banner-one-image">
      @if (file_main_banner.mime_type.includes('image')) {
      <img class="banner-image" [src]="file_main_banner.url" alt="banner-campaign" />
      }@else {
      <video
        class="banner-image"
        [src]="file_main_banner.url"
        autoplay
        muted
        loop
        [controls]="false"
        playsinline
        webkit-playsinline></video>
      }
    </div>
    }
    <!-- ~ -->
    @if (file_sub_banner_one) {
    <div class="image-text">
      <img class="image-text" [src]="file_sub_banner_one.url" alt="image-campaign-text-2" />
    </div>
    }
    <!-- ~ -->
    <div class="card-products">
      <!-- ~ -->
      @for (item of cms_pania_campaign_product_lists; track $index) {
      <app-card-product [productData]="item"></app-card-product>
      }
    </div>
    <!-- ~ -->
    @if (file_sub_banner_two) {
    <div class="image-text">
      <img class="image-text" [src]="file_sub_banner_two.url" alt="image-campaign-text-2" />
    </div>
    }
    <!-- ~ -->
    @if (cms_pania_campaign_products.length) {
    <div class="banner-two-image mb-4">
      @for (item of cms_pania_campaign_products; track $index) {

      <div class="image-banner-two">
        @if (item.file?.mime_type?.includes('image')) {
        <img [src]="item.file?.url" alt="image-campaign-1" />
        }@else {
        <video
          [src]="item.file?.url"
          autoplay
          muted
          loop
          [controls]="false"
          playsinline
          webkit-playsinline></video>
        }
      </div>
      }
    </div>
    }

    <app-footer></app-footer>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="footer-button-wrapper d-flex justify-content-end w-100">
    <button class="btn btn-primary btn-md" matRipple (click)="onClose()">กลับ</button>
  </div>
</mat-dialog-actions>
