<div class="page-content">
  <div class="breadcrumb-wrapper">
    <div class="breadcrumb-text breadcrumb-link">ตั้งค่า</div>
    <div class="breadcrumb-separator">/</div>
    <div class="breadcrumb-text">ข้อมูลโปรโมชั่น</div>
  </div>

  <div class="title-wrapper">โค้ดส่วนลด</div>

  <div class="page-content-inner">
    <div class="top-content-wrapper">
      <div class="top-content-left">
        <app-search-filter-promotion
          placeholder="ค้นหา"
          (onConfirm)="paginationPromotion.reset();getPromotionList();itemChecked.clear()"></app-search-filter-promotion>
      </div>
      <div class="top-content-right mb-3">
        @if (profile.permissions.checkAccess([APP_PERMISSION.PROMOTION], [ACTION_ACCESS.VIEW])) {
        <button
          matRipple
          class="btn btn-outline-secondary"
          (click)="openModalDownloadFile('ดาวน์โหลดไฟล์รายชื่อ', { message: 'คุณต้องการดาวน์โหลดไฟล์รายชื่อที่เลือกใช่หรือไม่' })">
          <span class="icon material-symbols-outlined fill">download_2</span>
          ส่งออกไฟล์
        </button>
        }
        <!-- ~ -->
        @if (profile.permissions.checkAccess([APP_PERMISSION.PROMOTION], [ACTION_ACCESS.CREATE])) {
        <button
          matRipple
          class="btn btn-primary"
          (click)="openModalPromotion('เพิ่มรหัสส่วนลด', 'add')">
          <span class="icon material-symbols-outlined fill">add_circle</span>
          เพิ่มรหัสส่วนลด
        </button>
        }
      </div>
    </div>

    <div class="table-responsive table-main-tabs-wrapper">
      <table class="table table-fixed-column">
        <thead>
          <tr>
            @if (profile.permissions.checkAccess([APP_PERMISSION.PROMOTION], [ACTION_ACCESS.VIEW]))
            {
            <th width="70">
              <app-checkbox-all-table
                [checkedValue]="itemChecked"
                [datas]="promotionList"
                fieldValue="id"></app-checkbox-all-table>
            </th>
            }
            <th width="200">รหัสโปรโมชั่น</th>
            <th width="200">ชื่อโปรโมชั่น (ภาษาไทย)</th>
            <th width="200">ชื่อโปรโมชั่น (ภาษาอังกฤษ)</th>
            <th width="300">คำอธิบาย</th>
            <th width="135">มูลค่าส่วนลด</th>
            <th width="135">มูลค่าขั้นต่ำ</th>
            <th width="135" class="text-center">จำนวนโค้ดส่วนลด</th>
            <th width="150">จำนวนส่วนลดต่อคน</th>
            <th width="135">กลุ่มลูกค้า</th>
            <th width="200">สินค้าย่อย</th>
            <th width="135">วันที่เริ่มต้น</th>
            <th width="135">วันที่สิ้นสุด</th>
            <th width="112">สถานะ</th>
            <th width="150">อัปเดตโดย</th>
            <th width="170">อัปเดตล่าสุด</th>
            <th width="120" class="fixed box-shadow-left">จัดการ</th>
          </tr>
        </thead>

        <tbody>
          @for (item of promotionList; track $index) {
          <tr>
            @if (profile.permissions.checkAccess([APP_PERMISSION.PROMOTION], [ACTION_ACCESS.VIEW]))
            {
            <td>
              <app-checkbox-table
                [checkedValue]="itemChecked"
                [value]="item.id"></app-checkbox-table>
            </td>
            }
            <td>{{ item.code }}</td>
            <td>
              <app-text-tooltip> {{ item.name || '-' }} </app-text-tooltip>
            </td>
            <td>
              <app-text-tooltip> {{ item.name_en || '-' }} </app-text-tooltip>
            </td>
            <td>
              <app-text-tooltip> {{ item.description || '-' }} </app-text-tooltip>
            </td>
            <td>{{ formatPriceAutoDecimal(item.value) }} {{ item.is_percent ? '%' :'บาท' }}</td>
            <td>{{ formatPriceAutoDecimal(item.min_amount) }} บาท</td>
            <td class="text-center">
              {{ item.is_limit ? formatPriceAutoDecimal(item.total_code) : 'ไม่จำกัด' }}
            </td>
            <td class="text-center">
              {{ item.is_limit ? formatPriceAutoDecimal(item.code_per_person) : '-' }}
            </td>
            <td>
              <app-text-tooltip> {{ item.tierNames || '-' }} </app-text-tooltip>
            </td>
            <td>
              <span class="color--brand-blue--f cursor-pointer" (click)="openModalProduct(item)">
                {{ formatPriceAutoDecimal(item.total_product) }} รายการ
              </span>
            </td>
            <td>
              {{ item.effective_date_from ? Moment(item.effective_date_from).format('DD/MM/YYYY') :
              '-' }}
            </td>
            <td>
              {{ item.effective_date_to ? Moment(item.effective_date_to).format('DD/MM/YYYY') : '-'
              }}
            </td>
            <td>
              <app-status-user
                [status]="item.isExpired ? 'expired' : item.is_active ? 'active' : 'inactive'">
                {{ item.isExpired ? 'หมดอายุ' : item.is_active ? 'ใช้งาน' : 'ไม่ใช้งาน' }}
              </app-status-user>
            </td>
            <td>{{item.updated_by ? item.updated_by.full_name : ''}}</td>
            <td>
              {{ item.updated_at ? Moment(item.updated_at).format('DD-MM-YYYY, HH:mm') : '' }}
            </td>
            <td class="fixed box-shadow-left">
              <div class="d-flex justify-content-center align-items-center">
                @if (item.action.edit) {
                <button
                  (click)="openModalPromotion('แก้ไขรหัสส่วนลด', 'edit', item)"
                  matRipple
                  matTooltip="แก้ไข"
                  matTooltipPosition="above"
                  class="btn btn-outline-secondary btn-icon">
                  <span class="icon material-symbols-outlined fill">edit</span>
                </button>
                }
              </div>
            </td>
          </tr>
          }
        </tbody>
      </table>

      @if(!promotionList.length) {
      <div class="data-not-found-wrapper">ไม่พบโค้ดส่วนลด กรุณาเพิ่มโค้ดส่วนลด</div>
      }
    </div>

    <!-- Pagination -->
    @if (promotionList.length) {
    <div class="pagination-wrapper">
      <mat-paginator
        [pageIndex]="paginationPromotion.data.pageIndex"
        [length]="paginationPromotion.data.length"
        [pageSize]="paginationPromotion.data.pageSize"
        [pageSizeOptions]="paginationPromotion.data.pageSizeOptions"
        aria-label="Select page"
        (page)="paginationPromotion.setFromPaginator($event);getPromotionList()">
      </mat-paginator>
    </div>
    }
  </div>
</div>
