import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms'
import { imports } from '../../../../imports'
import { InputComponent } from '../../../../components/input/input'
import { TextareaComponent } from '../../../../components/text-area/text-area'
import {
  EmailReg,
  ValidateForm,
  setErrorForm,
  ValidateArrayForm,
  Moment,
  convertDateToApi,
  Pagination,
  formatPriceAutoDecimal,
  formatPrice,
  productKey,
} from '../../../../helpers'
import { UploadImageProfileComponent } from '../../../../components/upload-image-profile/upload-image-profile'
import { ModalLeaveComponent } from '../../../../components/modal-leave/modal-leave'
import { MatDialog } from '@angular/material/dialog'
import { CompanyProfileService, CaratService } from '../../../../services'
import { CustomSnackBar } from '../../../../components/snackbar/snackbar'
import { ACTION_ACCESS, APP_PERMISSION, Loading, Profile } from '../../../../globals'
import { forkJoin } from 'rxjs'
import { ModalConfirmComponent } from '../../../../components/modal-confirm/modal-confirm'
import { InputNumberComponent } from '../../../../components/input-number/input-number'
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
  CdkDrag,
  CdkDropList,
} from '@angular/cdk/drag-drop'
import { DatePickerComponent } from '../../../../components/date-picker/date-picker'
import { SearchComponent } from '../../../../components/search/search'
import { MatPaginator } from '@angular/material/paginator'
import { CheckboxTableComponent } from '../../../../components/checkbox-table/checkbox-table.component'
import { CheckboxAllTableComponent } from '../../../../components/checkbox-table/checkbox-all-table.component'
import { MatTooltipModule } from '@angular/material/tooltip'
import { ModalSelectProductMultipleComponent } from '../../../../components/modal-select-product-multiple/modal-select-product-multiple'
import { ModalDeleteComponent } from '../../../../components/modal-delete/modal-delete'
import { MatRadioButton, MatRadioGroup } from '@angular/material/radio'
import {
  TableSizeComponent,
  type__size_xs,
  type__size_xs_value,
  type__size_ys,
} from '../table-size/table-size'
import { CMSSizeService } from '../../../../services/cms-size.service'

@Component({
  selector: 'app-carats',
  standalone: true,
  imports: [
    ...imports,
    InputComponent,
    TextareaComponent,
    UploadImageProfileComponent,
    ModalLeaveComponent,
    InputNumberComponent,
    DatePickerComponent,
    CdkDrag,
    CdkDropList,
    SearchComponent,
    MatPaginator,
    CheckboxAllTableComponent,
    CheckboxTableComponent,
    MatTooltipModule,
    MatRadioButton,
    MatRadioGroup,
    TableSizeComponent,
  ],
  templateUrl: './manual.html',
  styleUrls: ['./manual.scss'],
})
export class SizeGuideManualComponent implements OnInit {
  readonly APP_PERMISSION = APP_PERMISSION
  readonly ACTION_ACCESS = ACTION_ACCESS
  readonly formatPrice = formatPrice
  readonly formatPriceAutoDecimal = formatPriceAutoDecimal

  search = new FormControl('')
  seatchText = ''
  readonly paginationManual = new Pagination({
    sortBy: 'desc',
  })
  itemChecked = new Set<any>()

  rerender = false
  pageNumber = new FormControl(1)

  isEdit: boolean = false
  isView: boolean = false
  isCreate: boolean = false
  dataManage: any

  form = new FormGroup({
    name: new FormControl('', [Validators.required]),
    subject_th: new FormControl(''),
    subject_en: new FormControl(''),
    description_th: new FormControl(''),
    description_en: new FormControl(''),
    is_centimeter: new FormControl(true),
    product_lists: new FormControl<any[]>([]),
    size_xs: new FormArray<type__size_xs>([]),
    size_ys: new FormArray<type__size_ys>([]),
    head_name: new FormControl('', [Validators.required]),
  })

  get products() {
    return this.form.controls['product_lists'].value || []
  }

  get pageList() {
    return this.products
      .filter((d: any) => {
        if (this.seatchText) {
          return (
            d.code.toLowerCase().includes(this.seatchText) ||
            d.name.toLowerCase().includes(this.seatchText)
          )
        }

        return true
      })
      .filter((d: any, i: number) => {
        if (this.seatchText) {
        }
        return i >= this.paginationManual.indexData.min && i <= this.paginationManual.indexData.max
      })
  }

  constructor(
    public profile: Profile,
    public router: Router,
    public dialog: MatDialog,
    public customSnackBar: CustomSnackBar,
    public loading: Loading,
    public cmsSizeService: CMSSizeService,
    public route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    if (this.router.url.includes('view')) {
      this.isView = true
    }
    if (this.router.url.includes('edit')) {
      this.isEdit = true
    }
    if (this.router.url.includes('create')) {
      this.isCreate = true
    }
    this.route.params.subscribe(params => {
      this.initData(params['id'])
    })
  }

  initData(id: any) {
    if (!id) return

    const apis = [this.cmsSizeService.getSizeById(id)]

    this.loading.start()
    forkJoin(apis).subscribe(([resSize]: any) => {
      if (resSize) {
        if (!resSize.is_error) {
          this.dataManage = resSize.data

          this.form.reset({
            name: this.dataManage.name,
            subject_th: this.dataManage.subject_th,
            subject_en: this.dataManage.subject_en,
            description_th: this.dataManage.description_th,
            description_en: this.dataManage.description_en,
            is_centimeter: this.dataManage.is_centimeter,
            head_name: this.dataManage.head_name,
            product_lists: (this.dataManage.product_lists || []).map((d: any) => {
              return {
                key: productKey(d.product_id, d.product_detail_id),
                product_id: d.product_id || null,
                product_detail_id: d.product_detail_id || null,
                ...(d.product_detail || d.product),
              }
            }),
          })

          this.paginationManual.setFromResponse({
            page: 1,
            total_record: this.dataManage.product_lists?.length || [],
          })

          const size_xs = this.dataManage.size_xs || []
          const size_ys = this.dataManage.size_ys || []

          if (size_xs.length && size_ys.length) {
            this.form.controls.size_xs.clear()
            for (const value of size_xs) {
              const size_values = new FormArray<type__size_xs_value>([])

              for (const v of value.size_values) {
                size_values.push(
                  new FormGroup({
                    value: new FormControl(v.value || null, [Validators.required]),
                  })
                )
              }

              this.form.controls.size_xs.push(
                new FormGroup({
                  name: new FormControl(value.name || '', [Validators.required]),
                  size_values,
                })
              )
            }

            const size_ys = this.dataManage.size_ys || []
            for (const value of size_ys) {
              this.form.controls.size_ys.push(
                new FormGroup({
                  name: new FormControl(value.name || '', [Validators.required]),
                })
              )
            }
          }
        } else {
          this.customSnackBar.fail()
        }
      }
      this.loading.stop()
    })
  }

  onSearch() {
    this.seatchText = (this.search.value || '').toLowerCase()
  }

  onChangePage(reset = false) {
    if (this.pageNumber.value) {
      const page = this.pageNumber.value - 1
      if (this.paginationManual.data.pageIndex != page) {
        this.paginationManual.data.pageIndex = page
        // this.getProductList()
      }
    } else if (reset) {
      this.pageNumber.setValue(this.paginationManual.data.pageIndex + 1)
      this.rerender = true
      setTimeout(() => {
        this.rerender = false
      })
    }
  }

  addProduct() {
    const dialogRef = this.dialog.open(ModalSelectProductMultipleComponent, {
      data: {
        product_list: this.form.controls.product_lists.value,
        is_size_guide: false,
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const newProducts = result.filter(
          (np: any) =>
            !this.products.some(
              (p: any) =>
                p.product_id == np.product_id && p.product_detail_id == np.product_detail_id
            )
        )

        const products = [...this.products, ...newProducts]

        this.form.controls.product_lists.setValue(products)

        this.paginationManual.setFromResponse({
          page: 1,
          total_record: products.length,
        })
      }
    })
  }

  selectKeys() {
    return [...this.itemChecked.values()]
  }

  deleteProduct(keys: any[]) {
    for (const key of keys) {
      this.itemChecked.delete(key)

      const values = this.form.controls.product_lists.value || []
      const item = values.find(d => d.key == key)
      if (item) {
        values.splice(values.indexOf(item), 1)
      }
      this.form.controls.product_lists.reset(values)
    }

    this.paginationManual.setFromResponse({
      page: 1,
      total_record: (this.form.controls.product_lists.value || []).length,
    })
  }

  onCancel() {
    const dialogRefConfirm = this.dialog.open(ModalLeaveComponent, {
      data: {},
    })

    dialogRefConfirm.afterClosed().subscribe(result => {
      if (result) {
        this.router.navigate(['/setting/cms/product-details'])
      }
    })
  }

  onSave() {
    ValidateForm(this.form)
    let invalid = !this.form.valid

    for (const size_xs of this.form.controls.size_xs.controls) {
      ValidateForm(size_xs)
      if (!size_xs.valid) {
        invalid = true
      }

      for (const size_value of size_xs.controls.size_values.controls) {
        ValidateForm(size_value)
        if (!size_value.valid) {
          invalid = true
        }
      }
    }

    for (const size_ys of this.form.controls.size_ys.controls) {
      ValidateForm(size_ys)
      if (!size_ys.valid) {
        invalid = true
      }
    }

    // console.log({ invalid })

    if (invalid) return

    const formValue = this.form.getRawValue()
    // console.log(formValue)
    const payload = {
      name: formValue.name,
      subject_th: formValue.subject_th,
      subject_en: formValue.subject_en,
      description_th: formValue.description_th,
      description_en: formValue.description_en,
      is_centimeter: formValue.is_centimeter,
      product_lists: (formValue.product_lists || []).map(v => {
        return {
          product_id: v.product_id,
          product_detail_id: v.product_detail_id,
        }
      }),
      head_name: formValue.head_name,
      size_xs: formValue.size_xs.map(v => {
        return {
          name: v.name,
          size_values: v.size_values.map(vv => {
            return {
              value: vv.value,
            }
          }),
        }
      }),
      size_ys: formValue.size_ys.map(v => {
        return {
          name: v.name,
        }
      }),
    }
    // console.log(payload)
    // return

    const api = this.isEdit
      ? this.cmsSizeService.updateSize(this.dataManage.id, payload)
      : this.cmsSizeService.createSize(payload)

    this.loading.start()
    api.subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          this.customSnackBar.success('บันทึกข้อมูลสำเร็จ')
          this.router.navigate(['/setting/cms/product-details'])
        } else {
          this.customSnackBar.failSave()
        }
      }
      this.loading.stop()
    })
  }
}
