import { Component, Inject, Injectable } from '@angular/core'
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog'
import { MatButtonModule } from '@angular/material/button'
import { FormsModule } from '@angular/forms'
import { MatInputModule } from '@angular/material/input'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatTabsModule } from '@angular/material/tabs'
import { MatIconModule } from '@angular/material/icon'
import { MatRipple } from '@angular/material/core'
import { TopMenuComponent } from '../../../../components/preview/top-menu/top-menu'
import { FileService, ProductService } from '../../../../services'
import { Loading } from '../../../../globals'
import { CustomSnackBar } from '../../../../components/snackbar/snackbar'
import { CardProductComponent } from '../../../../components/preview/card-product/card-product'
import { ScrollSildeComponent } from '../../../../components/preview/scroll-silde/scroll-silde'
import { VideoPlayerComponent } from '../../../../components/preview/video-player/video-player'
import { FooterComponent } from '../../../../components/preview/footer/footer'

@Component({
  selector: 'app-modal-preview',
  templateUrl: './modal-preview.html',
  styleUrls: ['./modal-preview.scss'],
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatTabsModule,
    MatIconModule,
    MatRipple,
    TopMenuComponent,
    CardProductComponent,
    ScrollSildeComponent,
    VideoPlayerComponent,
    FooterComponent,
  ],
})
export class ModalPreviewCollectionComponent {
  collections: any[] = []

  constructor(
    public dialogRef: MatDialogRef<ModalPreviewCollectionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public fileService: FileService,
    public loading: Loading,
    public customSnackBar: CustomSnackBar,
    public productService: ProductService
  ) {}

  ngOnInit(): void {
    // console.log(this.data)
    this.setData()
    document.addEventListener('fullscreenchange', () => {
      document.exitFullscreen()
    })
  }

  getFile(id: any, call: Function) {
    this.loading.start()
    this.fileService.getFile(id).subscribe(res => {
      if (!res.is_error) {
        call(res)
      } else {
        this.customSnackBar.fail()
      }
      this.loading.stop()
    })
  }

  setData() {
    this.collections = (this.data.collections || []).map((item: any) => {
      item.images = item.images.reduce((images: any[], im: any) => {
        if (im.file_id) {
          if (!im.file_url) {
            this.getFile(im.file_id, (res: any) => {
              im.file = res.data
            })
          }
          images.push(im)
        }

        return images
      }, [])

      return item
    })
    // console.log(this.collections)
  }

  onClose(): void {
    this.dialogRef.close()
  }
}
