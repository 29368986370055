import { CommonModule } from '@angular/common'
import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  model,
} from '@angular/core'
import { AbstractControl, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatFormFieldModule, MatSuffix } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatSelectModule } from '@angular/material/select'
import { SpinnerCustomComponent } from '../spinner/custom-spinner.component'

@Component({
  selector: 'app-select-search-async[control][datas][fieldValue]',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    FormsModule,
    MatSuffix,
    MatSelectModule,
    MatInputModule,
    SpinnerCustomComponent,
  ],
  templateUrl: './select-search-async.html',
  styleUrls: ['./select-search-async.scss'],
})
export class SelectSearchAsyncComponent implements OnInit {
  @ContentChild('triggerRef') triggerRef: any
  @ContentChild('optionRef') optionRef: any
  @Input() control!: FormControl | AbstractControl
  @Input() placeholder = ''
  @Input() label = ''
  @Input() datas!: any[]
  @Input() fieldValue!: any
  @Input() disabled = false
  @Input() multiple = false
  @Input() type = ''
  @Input() remove_bottom = false
  readonly searchText = model('')
  @Input() search: string[] = []
  @Input() empty = 'ไม่พบข้อมูล'
  @Input() disabledNotAllOption = false
  @Input() loading = false

  @Output() selectionChange = new EventEmitter()
  @Output() onSelectionChange = new EventEmitter()
  @Output() onSearch = new EventEmitter()
  @Output() onOpen = new EventEmitter()
  // @Input('eng')
  // eng?: TemplateRef<any>;
  @ViewChild('select') select: any

  get formControl() {
    if (this.control instanceof AbstractControl) {
      return this.control as FormControl
    }
    return this.control
  }

  get values() {
    let value = this.formControl.value
    if (value) {
      if (this.multiple) {
        return value
      }
      return [value]
    }
    return []
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['disabled']) {
      if (changes['disabled'].currentValue) {
        this.control.disable()
      } else {
        this.control.enable()
      }
    }
    if (changes['datas']) {
    }
  }

  compareWith = (option: any, op2: any) => {
    return option[this.fieldValue] == op2?.[this.fieldValue]
  }

  isNoOption(option: any) {
    return !this.datas.some(d => d[this.fieldValue] == option[this.fieldValue])
  }

  #keyCountSearch = 0
  handleSearch() {
    this.#keyCountSearch++
    const keyCountSearch = this.#keyCountSearch
    setTimeout(() => {
      if (keyCountSearch == this.#keyCountSearch) {
        this.onSearch.emit(this.searchText())
      }
    }, 700)
  }
}
