<div class="page-main-content">
  <div class="top-content-wrapper flex-wrap mb-3 gap-3">
    <div class="top-content-left align-items-start gap-3">
      <div class="title-wrapper mt-1">สินค้า</div>
      <app-search-filter-product
        placeholder="ค้นหา"
        (onConfirm)="onSearch();"
        [tab]="tab"></app-search-filter-product>
    </div>
    <div class="top-content-right">
      <!-- สินค้าทั่วไป -->
      @if (tab == 0) { @if (profile.permissions.checkAccess([APP_PERMISSION.PRODUCT],
      [ACTION_ACCESS.DELETE])) {
      <button
        matRipple
        class="btn btn-outline-danger"
        [disabled]="itemCheckedData.size < 1"
        (click)="confirmDelete()">
        <span class="icon material-symbols-outlined fill">delete</span>
        ลบข้อมูล @if (itemCheckedData.size > 0) { ({{ itemCheckedData.size }}) }
      </button>
      }
      <!-- ~ -->
      @if (profile.permissions.checkAccess([APP_PERMISSION.PRODUCT], [ACTION_ACCESS.VIEW])) {
      <button
        matRipple
        (click)="openModalDownloadFile('ดาวน์โหลดไฟล์สินค้า', { message: 'คุณต้องการดาวน์โหลดไฟล์สินค้าที่เลือกใช่หรือไม่' })"
        class="btn btn-outline-secondary">
        <span class="icon material-symbols-outlined fill">download_2</span>
        ส่งออกไฟล์
      </button>
      }
      <!-- ~ -->
      @if (profile.permissions.checkAccess([APP_PERMISSION.PRODUCT], [ACTION_ACCESS.CREATE])) {
      <button
        matRipple
        (click)="openModalUploadFile('นำเข้าสินค้า')"
        class="btn btn-outline-secondary">
        <span class="icon material-symbols-outlined fill">upload_2</span>
        นำเข้าสินค้า
      </button>
      }
      <!-- ~ -->
      @if (profile.permissions.checkAccess([APP_PERMISSION.PRODUCT], [ACTION_ACCESS.CREATE])) {
      <button matRipple [routerLink]="['/product/create']" class="btn btn-primary">
        <span class="icon material-symbols-outlined fill">add_circle</span>
        เพิ่มสินค้า
      </button>
      } }

      <!-- สินค้าลดราคา -->
      @if (tab == 1) { @if (profile.permissions.checkAccess([APP_PERMISSION.PRODUCT],
      [ACTION_ACCESS.DELETE])) {
      <button
        matRipple
        class="btn btn-outline-danger"
        [disabled]="itemCheckedDataDiscount.size < 1"
        (click)="confirmDelete()">
        <span class="icon material-symbols-outlined fill">delete</span>
        ลบข้อมูล @if (itemCheckedDataDiscount.size > 0) { ({{ itemCheckedDataDiscount.size }}) }
      </button>
      } @if (profile.permissions.checkAccess([APP_PERMISSION.PRODUCT], [ACTION_ACCESS.CREATE])) {
      <button matRipple [routerLink]="['/product/discount/create']" class="btn btn-primary">
        <span class="icon material-symbols-outlined fill">add_circle</span>
        เพิ่มสินค้า
      </button>
      } }

      <!-- สินค้า flash sales -->
      @if (tab == 2) { @if (profile.permissions.checkAccess([APP_PERMISSION.PRODUCT],
      [ACTION_ACCESS.DELETE])) {
      <button
        matRipple
        class="btn btn-outline-danger"
        [disabled]="itemCheckedDataFlashSales.size < 1"
        (click)="confirmDelete()">
        <span class="icon material-symbols-outlined fill">delete</span>
        ลบข้อมูล @if (itemCheckedDataFlashSales.size > 0) { ({{ itemCheckedDataFlashSales.size }}) }
      </button>
      } @if (profile.permissions.checkAccess([APP_PERMISSION.PRODUCT], [ACTION_ACCESS.CREATE])) {
      <button matRipple [routerLink]="['/product/flash-sales/create']" class="btn btn-primary">
        <span class="icon material-symbols-outlined fill">add_circle</span>
        เพิ่มสินค้า
      </button>
      } }
    </div>
  </div>

  <div class="tabs-wrapper">
    <mat-tab-group
      animationDuration="0ms"
      [disableRipple]="true"
      [selectedIndex]="tab"
      (selectedIndexChange)="changeTab($event)">
      <mat-tab>
        <ng-template mat-tab-label>
          <span class="icon material-symbols-outlined fill">shopping_basket</span>
          <span>สินค้าทั่วไป</span>
        </ng-template>
        <div class="tabs-content-wrapper">
          <div class="top-content-wrapper align-items-center flex-wrap mb-3">
            <div class="top-content-left">
              <div class="title-blue">รายการสินค้า ({{ paginationProduct.data.length }})</div>
              @if (showFilterMinStock) {
              <div class="d-flex align-items-center gap-2">
                <mat-checkbox
                  class="alert-checkbox"
                  [(ngModel)]="isFilterMinStock"
                  (change)="getProductList()">
                  <span class="color--red-600"> สินค้าใกล้หมด </span>
                </mat-checkbox>
                <span
                  matTooltipClass="tooltip-black"
                  matTooltip="สต๊อกคงเหลือช่องทางใดช่องทางหนึ่งน้อยกว่าหรือเท่ากับ 2"
                  matTooltipPosition="above"
                  class="icon-20 color--red-600 material-symbols-outlined fill cursor-pointer"
                  >info</span
                >
              </div>
              }
            </div>
            <div class="top-content-right">
              @if (service.companyId == COMPANY_ID.BHB) {
              <button
                matRipple
                class="btn btn-outline-secondary"
                [disabled]="checkboxLaunch.length < 1"
                (click)="productToWeb()">
                <img
                  src="/assets/images/icons/icon-product-to-web{{ checkboxLaunch.length < 1 ? '-disabled' : '' }}.svg"
                  width="20" />
                นำสินค้าขึ้นเว็บ @if (checkboxLaunch.length > 0) { ({{ checkboxLaunch.length }}) }
              </button>
              }
              <button
                matRipple
                class="btn btn-outline-secondary"
                [disabled]="checkboxUnNew.length < 1"
                (click)="updateProductNew(false)">
                ไม่เป็นสินค้าใหม่ @if (checkboxUnNew.length > 0) { ({{ checkboxUnNew.length }}) }
              </button>
              <button
                matRipple
                class="btn btn-outline-secondary"
                [disabled]="checkboxNew.length < 1"
                (click)="updateProductNew(true)">
                เป็นสินค้าใหม่ @if (checkboxNew.length > 0) { ({{ checkboxNew.length }}) }
              </button>
              <app-select-sort
                [control]="sortControl"
                [datas]="list.sort"
                fieldValue="value"
                placeholder="เลือก"
                (selectionChange)="onSort()">
                <ng-template #triggerRef let-option> {{ option.text }}</ng-template>
                <ng-template #optionRef let-option> {{ option.text }}</ng-template>
              </app-select-sort>
            </div>
          </div>

          @if (service.companyId == COMPANY_ID.BHB) {
          <div class="table-responsive table-main-tabs-wrapper">
            <table class="table table-fixed-column">
              <colgroup>
                <col width="70" />
                <col width="130" />
                <col width="200" />
                <col width="200" />
                <col width="310" />
                <col width="120" />
                <col width="150" />
                <col width="100" />
                <col width="100" />
                <col width="100" />
                <col width="100" />
                <col width="100" />
                <col width="100" />
                <col width="100" />
                <col width="100" />
                <col width="100" />
                <col width="150" />
                <col width="150" />
                <col width="180" />
              </colgroup>
              <thead>
                <tr class="tr-product">
                  <th rowspan="2">
                    <app-checkbox-all-table
                      [checkedValue]="itemChecked"
                      [datas]="productList"
                      fieldValue="checkboxValue"
                      (onChange)="setItemCheckedData($event, productList)"></app-checkbox-all-table>
                  </th>
                  <th rowspan="2">รูปสินค้า</th>

                  <th rowspan="2">รหัสสินค้า</th>
                  <th rowspan="2">รหัสสินค้าจริง</th>
                  <th rowspan="2">ชื่อสินค้า</th>
                  <th rowspan="2" class="text-end">ราคาขาย (บาท)</th>
                  <th rowspan="2" class="text-end">ราคาสมาชิก (บาท)</th>
                  <th
                    colspan="3"
                    class="text-center bg--brand-blue-100--f color--brand-blue-700--f">
                    ออนไลน์
                  </th>
                  <th colspan="2" class="text-center bg--orange-100--f color--orange-700--f">
                    Shopee
                  </th>
                  <th colspan="2" class="text-center bg--blue-100--f color--blue-700--f">Lazada</th>
                  <th rowspan="2" class="text-center">สต๊อกรวม</th>
                  <th rowspan="2" class="text-center">จองรวม</th>
                  <th rowspan="2" class="text-center">สต๊อกเว็บคงเหลือ</th>
                  <th rowspan="2" class="text-center">สต๊อกคงเหลือรวม</th>

                  <th rowspan="2" class="fixed box-shadow-left th-fixed">จัดการ</th>
                </tr>
                <tr class="tr-product tr-sub">
                  <th class="text-center bg--brand-blue-50--f color--brand-blue-700--f">สต๊อก</th>
                  <th class="text-center bg--brand-blue-50--f color--brand-blue-700--f">
                    สต๊อกเว็บ
                  </th>
                  <th class="text-center bg--brand-blue-50--f color--brand-blue-700--f">
                    <div class="d-flex justify-content-center align-items-center gap-1">
                      <div>จอง</div>
                      <span
                        matTooltipClass="tooltip-black"
                        matTooltip="สถานะจองคือสินค้าที่อยู่ในออเดอร์ที่ยังไม่ปิดรายการ เมื่อออเดอร์ถูกปิดรายการจึงจะตัดสต๊อก"
                        matTooltipPosition="above"
                        class="icon-16 icon-brand-blue-700 material-symbols-outlined fill cursor-pointer"
                        >info</span
                      >
                    </div>
                  </th>

                  <th class="text-center bg--orange-50--f color--orange-700--f">สต๊อก</th>
                  <th class="text-center bg--orange-50--f color--orange-700--f">
                    <div class="d-flex justify-content-center align-items-center gap-1">
                      <div>จอง</div>
                      <span
                        matTooltipClass="tooltip-black"
                        matTooltip="สถานะจองคือสินค้าที่อยู่ในออเดอร์ที่ยังไม่ปิดรายการ เมื่อออเดอร์ถูกปิดรายการจึงจะตัดสต๊อก"
                        matTooltipPosition="above"
                        class="icon-16 icon-orange-700 material-symbols-outlined fill cursor-pointer"
                        >info</span
                      >
                    </div>
                  </th>

                  <th class="text-center bg--blue-50--f color--blue-700--f">สต๊อก</th>
                  <th class="text-center bg--blue-50--f color--blue-700--f">
                    <div class="d-flex justify-content-center align-items-center gap-1">
                      <div>จอง</div>
                      <span
                        matTooltipClass="tooltip-black"
                        matTooltip="สถานะจองคือสินค้าที่อยู่ในออเดอร์ที่ยังไม่ปิดรายการ เมื่อออเดอร์ถูกปิดรายการจึงจะตัดสต๊อก"
                        matTooltipPosition="above"
                        class="icon-16 icon-blue-700 material-symbols-outlined fill cursor-pointer"
                        >info</span
                      >
                    </div>
                  </th>
                </tr>
              </thead>

              <tbody>
                @for (item of productList; track $index) {
                <tr class="tr-product">
                  <td class="td-checkbox">
                    <app-checkbox-table
                      [checkedValue]="itemChecked"
                      [value]="item.checkboxValue"
                      (onChange)="setItemCheckedData($event, [item])"></app-checkbox-table>
                  </td>
                  <td>
                    <img
                      width="90"
                      height="90"
                      alt="Product"
                      class="img-product"
                      [src]="getCover(item)?.file.thumbnail || '/assets/images/default-product.svg'" />

                    @if (item.is_launch) {
                    <div class="tag launch my-2">นำขึ้นเว็บ</div>
                    } @if (item.is_product_discount) {
                    <div class="tag sales my-2">ลดราคา</div>
                    } @if (item.is_new) {
                    <div class="tag new my-2">สินค้าใหม่</div>
                    } @if (item.is_flash_sale) {
                    <div class="tag flash-sales my-2">Flash Sales</div>
                    }
                  </td>
                  <td class="px-0 py-3" colspan="16">
                    <table class="w-100">
                      <colgroup>
                        <col width="200" />
                        <col width="200" />
                        <col width="310" />
                        <col width="120" />
                        <col width="150" />
                        <col width="100" />
                        <col width="100" />
                        <col width="100" />
                        <col width="100" />
                        <col width="100" />
                        <col width="100" />
                        <col width="100" />
                        <col width="100" />
                        <col width="100" />
                        <col width="150" />
                        <col width="150" />
                      </colgroup>
                      <tbody>
                        <tr class="tr-sub-product">
                          <td>
                            <app-text-tooltip> {{ item.code || '-' }} </app-text-tooltip>
                          </td>
                          <td>
                            <app-text-tooltip> {{ item.real_code || '-' }} </app-text-tooltip>
                          </td>
                          <td>
                            <app-text-tooltip> {{ item.name }} </app-text-tooltip>
                            <!-- <div class="color-size-text">{{ item.color }}, {{ item.size }}</div> -->
                          </td>
                          <td class="text-end">
                            {{ item.isShowStock ? formatPrice(item.selling_price) : '' }}
                          </td>
                          <td class="text-end">
                            {{ item.isShowStock ? formatPrice(item.member_price) : '' }}
                          </td>
                          <td class="text-center">
                            {{ item.isShowStock ? formatPriceAutoDecimal(getStock(item,
                            channelId.online)?.stock) : '' }}
                          </td>
                          <td class="text-center">
                            {{ item.isShowStock ? formatPriceAutoDecimal(getStock(item,
                            channelId.online)?.stock_web) : '' }}
                          </td>
                          <td class="text-center">
                            {{ item.isShowStock ? formatPriceAutoDecimal(getStock(item,
                            channelId.online)?.book) : '' }}
                          </td>

                          <td class="text-center">
                            {{ item.isShowStock ? formatPriceAutoDecimal(getStock(item,
                            channelId.shopee)?.stock) : '' }}
                          </td>
                          <td class="text-center">
                            {{ item.isShowStock ? formatPriceAutoDecimal(getStock(item,
                            channelId.shopee)?.book) : '' }}
                          </td>

                          <td class="text-center">
                            {{ item.isShowStock ? formatPriceAutoDecimal(getStock(item,
                            channelId.lazada)?.stock) : '' }}
                          </td>
                          <td class="text-center">
                            {{ item.isShowStock ? formatPriceAutoDecimal(getStock(item,
                            channelId.lazada)?.book) : '' }}
                          </td>

                          <td class="text-center">
                            {{ item.isShowStock ? formatPriceAutoDecimal(item.stock) : '' }}
                          </td>
                          <td class="text-center">
                            {{ item.isShowStock ? formatPriceAutoDecimal(item.book) : '' }}
                          </td>
                          <td class="text-center">
                            {{ item.isShowStock ? formatPriceAutoDecimal(item.remain_web) : '' }}
                          </td>
                          <td class="text-center">
                            {{ item.isShowStock ? formatPriceAutoDecimal(item.remain) : '' }}
                          </td>
                        </tr>
                        @for (itemD of item.details; track $index) {
                        <tr class="tr-sub-product">
                          <td>
                            <app-text-tooltip> {{ itemD.code || '-' }} </app-text-tooltip>
                          </td>
                          <td>
                            <app-text-tooltip> {{ itemD.real_code || '-' }} </app-text-tooltip>
                          </td>
                          <td>
                            <div class="d-flex flex-column">
                              <div>
                                <app-text-tooltip> {{ itemD.name || '-' }} </app-text-tooltip>
                              </div>
                              <div class="color-size-text">
                                {{ productColorSizeText(itemD.color, itemD.size) }}
                              </div>
                            </div>
                          </td>
                          <td class="text-end">{{ formatPrice(itemD.selling_price) }}</td>
                          <td class="text-end">{{ formatPrice(itemD.member_price) }}</td>
                          <td class="text-center">
                            {{ formatPriceAutoDecimal(getStock(itemD, channelId.online)?.stock) }}
                          </td>
                          <td class="text-center">
                            {{ formatPriceAutoDecimal(getStock(itemD, channelId.online)?.stock_web)
                            }}
                          </td>
                          <td class="text-center">
                            {{ formatPriceAutoDecimal(getStock(itemD, channelId.online)?.book) }}
                          </td>

                          <td class="text-center">
                            {{ formatPriceAutoDecimal(getStock(itemD, channelId.shopee)?.stock) }}
                          </td>
                          <td class="text-center">
                            {{ formatPriceAutoDecimal(getStock(itemD, channelId.shopee)?.book) }}
                          </td>

                          <td class="text-center">
                            {{ formatPriceAutoDecimal(getStock(itemD, channelId.lazada)?.stock) }}
                          </td>
                          <td class="text-center">
                            {{ formatPriceAutoDecimal(getStock(itemD, channelId.lazada)?.book) }}
                          </td>

                          <td class="text-center">{{ formatPriceAutoDecimal(itemD.stock) }}</td>
                          <td class="text-center">{{ formatPriceAutoDecimal(itemD.book) }}</td>
                          <td class="text-center">
                            {{ formatPriceAutoDecimal(itemD.remain_web) }}
                          </td>
                          <td class="text-center">{{ formatPriceAutoDecimal(itemD.remain) }}</td>
                        </tr>
                        }
                      </tbody>
                    </table>
                  </td>
                  <td class="fixed box-shadow-left">
                    <div class="d-flex justify-content-start align-items-center">
                      @if (item.action.view) {
                      <button
                        matRipple
                        matTooltip="ดู"
                        matTooltipPosition="above"
                        class="btn btn-outline-secondary btn-icon me-2"
                        [routerLink]="['/product/view/' + item.id]">
                        <span class="icon material-symbols-outlined fill">visibility</span>
                      </button>
                      }
                      <!-- ~ -->
                      @if (item.action_stock.add) {
                      <button
                        matRipple
                        (click)="openModalAddStock('เพิ่มสต๊อก', item)"
                        class="btn btn-outline-secondary h-36px">
                        <span class="icon material-symbols-outlined fill">add_circle</span>
                        เพิ่มสต๊อก
                      </button>
                      }
                    </div>
                  </td>
                </tr>
                }
              </tbody>
            </table>

            @if(!productList.length) {
            <div class="data-not-found-wrapper">ไม่พบสินค้า กรุณาเพิ่มสินค้า</div>
            }
          </div>
          }@else {
          <div class="table-responsive table-main-tabs-wrapper">
            <table class="table table-fixed-column">
              <thead>
                <tr>
                  <th width="50">
                    <app-checkbox-all-table
                      [checkedValue]="itemChecked"
                      [datas]="productList"
                      fieldValue="checkboxValue"
                      (onChange)="setItemCheckedData($event, productList)"></app-checkbox-all-table>
                  </th>
                  <th width="120">รูปสินค้า</th>
                  <th width="200">รหัสสินค้า</th>
                  <th width="310">ชื่อสินค้า</th>
                  <th width="150" class="text-end">ราคาขาย (บาท)</th>
                  <th width="150" class="text-end">ราคาสมาชิก (บาท)</th>
                  <th width="100" class="text-center">สต๊อก</th>
                  <th width="100" class="text-center">สต๊อกเว็บ</th>
                  <th width="100" class="text-center">
                    <div class="d-flex justify-content-center align-items-center gap-1">
                      <div>จอง</div>
                      <span
                        matTooltipClass="tooltip-black"
                        matTooltip="สถานะจองคือสินค้าที่อยู่ในออเดอร์ที่ยังไม่ปิดรายการ เมื่อออเดอร์ถูกปิดรายการจึงจะตัดสต๊อก"
                        matTooltipPosition="above"
                        class="icon-16 icon-brand-blue-700 material-symbols-outlined fill cursor-pointer"
                        >info</span
                      >
                    </div>
                  </th>
                  <th width="150" class="text-center">สต๊อกเว็บคงเหลือ</th>
                  <th width="150" class="text-center">สต๊อกคงเหลือ</th>
                  <th width="190" class="fixed box-shadow-left th-fixed">จัดการ</th>
                </tr>
              </thead>
              <tbody>
                @for (item of productList; track $index) {
                <tr class="tr-product">
                  <td width="50" class="td-checkbox ps-3">
                    <app-checkbox-table
                      [checkedValue]="itemChecked"
                      [value]="item.checkboxValue"
                      (onChange)="setItemCheckedData($event, [item])"></app-checkbox-table>
                  </td>
                  <td width="120">
                    <img
                      width="90"
                      height="90"
                      alt="Product"
                      class="img-product"
                      [src]="getCover(item)?.file.thumbnail || '/assets/images/default-product.svg'" />

                    @if (item.is_product_discount) {
                    <div class="tag sales my-2">ลดราคา</div>
                    } @if (item.is_new) {
                    <div class="tag new my-2">สินค้าใหม่</div>
                    } @if (item.is_flash_sale) {
                    <div class="tag flash-sales my-2">Flash Sales</div>
                    }
                  </td>
                  <td class="px-0 py-3" colspan="9">
                    <table class="w-100">
                      <tbody>
                        <tr class="tr-sub-product">
                          <td width="200">{{ item.code }}</td>
                          <td width="310">
                            <app-text-tooltip> {{ item.name || '-' }} </app-text-tooltip>
                            <!-- @if (!item.details.length) {
                            <div class="color-size-text d-flex">
                              @if (item.product_color) {
                              <div
                                class="dot-color me-1"
                                [ngStyle]="{'background-color': item.product_color?.color_code || '#000000'}"></div>
                              } {{ item.color }}, {{ item.size }}
                            </div>
                            } -->
                          </td>
                          <td width="150" class="text-end">
                            {{!item.details.length ? formatPrice(item.selling_price) : ''}}
                          </td>

                          <td width="150" class="text-end">
                            {{!item.details.length ? formatPrice(item.member_price) : ''}}
                          </td>

                          <td width="100" class="text-center">
                            {{!item.details.length ? formatPriceAutoDecimal(item.stock) : ''}}
                          </td>
                          <td width="100" class="text-center">
                            {{!item.details.length ? formatPriceAutoDecimal(item.stock_web) : ''}}
                          </td>
                          <td width="100" class="text-center">
                            {{!item.details.length ? formatPriceAutoDecimal(item.book) : ''}}
                          </td>
                          <td width="150" class="text-center">
                            {{!item.details.length ? formatPriceAutoDecimal(item.remain_web) : ''}}
                          </td>
                          <td width="150" class="text-center">
                            {{!item.details.length ? formatPriceAutoDecimal(item.remain) : ''}}
                          </td>
                        </tr>
                        @for (itemD of item.details; track $index) {
                        <tr class="tr-sub-product">
                          <td width="200">{{ itemD.code }}</td>
                          <td width="310">
                            <div class="d-flex flex-column">
                              <div>
                                <app-text-tooltip> {{ itemD.name || '-' }} </app-text-tooltip>
                              </div>
                              <div class="color-size-text d-flex">
                                @if (itemD.product_color) {
                                <div
                                  class="dot-color me-1"
                                  [ngStyle]="{'background-color': itemD.product_color?.color_code || '#000000'}"></div>
                                } {{ productColorSizeText(itemD.color, itemD.size) }}
                              </div>
                            </div>
                          </td>

                          <td width="150" class="text-end">
                            {{ formatPrice(itemD.selling_price) }}
                          </td>
                          <td width="150" class="text-end">
                            {{ formatPrice(itemD.member_price) }}
                          </td>

                          <td width="100" class="text-center">
                            {{ formatPriceAutoDecimal(itemD.stock) }}
                          </td>
                          <td width="100" class="text-center">
                            {{ formatPriceAutoDecimal(itemD.stock_web) }}
                          </td>
                          <td width="100" class="text-center">
                            {{ formatPriceAutoDecimal(itemD.book) }}
                          </td>
                          <td width="150" class="text-center">
                            {{ formatPriceAutoDecimal(itemD.remain_web) }}
                          </td>
                          <td width="150" class="text-center">
                            {{ formatPriceAutoDecimal(itemD.remain) }}
                          </td>
                        </tr>
                        }
                      </tbody>
                    </table>
                  </td>
                  <td class="fixed box-shadow-left">
                    <div class="d-flex justify-content-start align-items-center">
                      @if (item.action.view) {
                      <button
                        matRipple
                        matTooltip="ดู"
                        matTooltipPosition="above"
                        class="btn btn-outline-secondary btn-icon me-2"
                        [routerLink]="['/product/view/' + item.id]">
                        <span class="icon material-symbols-outlined fill">visibility</span>
                      </button>
                      }
                      <!-- ~ -->
                      @if (item.action_stock.add) {
                      <button
                        matRipple
                        (click)="openModalAddStock('เพิ่มสต๊อก', item)"
                        class="btn btn-outline-secondary h-36px">
                        <span class="icon material-symbols-outlined fill">add_circle</span>
                        เพิ่มสต๊อก
                      </button>
                      }
                    </div>
                  </td>
                </tr>
                }
              </tbody>
            </table>

            @if(!productList.length) {
            <div class="data-not-found-wrapper">ไม่พบสินค้า กรุณาเพิ่มสินค้า</div>
            }
          </div>
          }

          <!-- Pagination -->
          @if (productList.length) {
          <div class="pagination-wrapper d-flex flex-wrap gap-2">
            <mat-paginator
              [pageIndex]="paginationProduct.data.pageIndex"
              [length]="paginationProduct.data.length"
              [pageSize]="paginationProduct.data.pageSize"
              [pageSizeOptions]="paginationProduct.data.pageSizeOptions"
              (page)="paginationProduct.setFromPaginator($event);getProductList()"
              aria-label="Select page">
            </mat-paginator>
            <div class="d-flex align-items-center gap-2 mat-mdc-paginator">
              <span>ไปที่หน้า</span>
              <div class="font-12" [style]="{ 'min-width': '70px', 'max-width': '70px' }">
                @if (!rerender) {
                <app-input-number
                  [control]="pageNumber"
                  placeholder="0"
                  [min]="1"
                  [max]="paginationProduct.totalPage || 1"
                  [remove_bottom]="true"
                  (onEnter)="onChangePage()"
                  (onblur)="onChangePage(true)">
                </app-input-number>
                }
              </div>
              / {{ formatPriceAutoDecimal(paginationProduct.totalPage) }}
            </div>
          </div>
          }
        </div>
      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label>
          <span class="icon material-symbols-outlined fill">percent</span>
          <span>สินค้าลดราคา</span>
        </ng-template>
        <div class="tabs-content-wrapper">
          @if (tab == 1) {
          <app-tab-products-discount
            (setItemCheckedDiscount)="setItemCheckedDiscount($event)"
            (onSearch)="onSearchDiscount = false"
            [onSearchDiscount]="onSearchDiscount"
            [filterTableProduct]="filterTableProduct"></app-tab-products-discount>
          }
        </div>
      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label>
          <span class="icon material-symbols-outlined fill">flash_on</span>
          <span>สินค้า Flash Sales</span>
        </ng-template>
        <div class="tabs-content-wrapper">
          @if (tab == 2) {
          <app-tab-products-flash-sales
            (setItemCheckedFlashSales)="setItemCheckedFlashSales($event)"
            (onSearch)="onSearchFlashSales = false"
            [onSearchFlashSales]="onSearchFlashSales"
            [filterTableProduct]="filterTableProduct"></app-tab-products-flash-sales>
          }
        </div>
      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label>
          <span class="icon material-symbols-outlined fill">heart_check</span>
          <span class="">Wish List</span>
          @if (notificationWishList) {
          <span class="dot"></span>
          }
        </ng-template>
        <div class="tabs-content-wrapper">
          @if (tab == 3) {
          <app-tab-products-wish-list
            (setItemCheckedWistList)="setItemCheckedWistList($event)"
            (onSearch)="onSearchWistList = false"
            [onSearchWistList]="onSearchWistList"
            [filterTableProduct]="filterTableProduct"></app-tab-products-wish-list>
          }
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
