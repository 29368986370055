import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { Service } from './service'

@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor(private service: Service) {}
  private pathFile = '/v1/files'

  upload(file: any): Observable<any> {
    return this.service.uploadFile(`${this.pathFile}`, file)
  }
  uploadEditor(file: any): Observable<any> {    
    return this.service.uploadFileEditor(`${this.pathFile}/images`, file)
  }
  uploads(files: any[]): Observable<any> {
    return this.service.uploadMultipleFile(`${this.pathFile}`, files)
  }
  signUrl(id: any): Observable<any> {
    return this.service.get(`${this.pathFile}/${id}/sign-url`)
  }
  delete(id: any): Observable<any> {
    return this.service.delete(`${this.pathFile}/${id}`)
  }
  getBlob(id: number): Observable<any> {
    return this.service.getBlob(`${this.pathFile}/${id}/download`)
  }
  getFile(id:number): Observable<any> {
    return this.service.get(`${this.pathFile}/${id}`)
  }
}
