<div mat-dialog-title class="dialog-title">
  <div class="dialog-title-head">{{data.title}}</div>
  <button class="btn btn-transparent btn-icon" (click)="onClose()">
    <img src="../assets/images/icons/icon-close.svg" />
  </button>
</div>

<mat-dialog-content>
  <div class="modal-content">
    <div class="d-flex flex-column gap-3">
      @if (payment) {
      <!-- ~ -->
      @for (item of payment.details; track $index) {
      <!-- ~ -->
      @if (item.file) {
      <div class="box-qr-img">
        <img [src]="item.file.url" />
      </div>
      }
      <!-- ~ -->
      }
      <!-- ~ -->
      }
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="footer-button-wrapper d-flex justify-content-end w-100">
    <button matRipple class="btn btn-primary btn-md" (click)="onClose()">ปิด</button>
  </div>
</mat-dialog-actions>
