<div mat-dialog-title class="dialog-title">
  <div class="dialog-title-head">{{data.title}}</div>
  <button class="btn btn-transparent btn-icon" (click)="onClose()">
    <img src="../assets/images/icons/icon-close.svg" />
  </button>
</div>

<mat-dialog-content>
  <div class="modal-content">
    <div class="container gx-0">
      <div class="row gy-2">
        <div class="col-12">
          <div class="row">
            <div class="col-12 col-md-9">
              <label
                class="label"
                [class.text-danger]="form.controls['name'].touched && form.controls['name'].invalid">
                ชื่อระดับสมาชิก
                <span class="text-danger">*</span>
              </label>
              <app-input [control]="form.controls['name']">
                @if (form.controls['name'].hasError('required')) {
                <ng-container error>กรุณากรอกข้อมูล</ng-container>
                } @else if (form.controls['name'].hasError('pattern')) {
                <ng-container error>ชื่อระดับสมาชิกถูกใช้งานแล้ว</ng-container>
                } @else if (form.controls['name'].hasError('formApi')) {
                <ng-container error>{{ form.controls['name'].errors?.['formApi']}}</ng-container>
                } @else if (form.controls['name'].hasError('maxlength')) {
                  <ng-container error>กรุณากรอกข้อมูลไม่เกิน 250 ตัวอักษร</ng-container>
                }
              </app-input>
            </div>
            <div class="col-12 col-md-3 pb-3">
              <label class="label"> สีระดับสมาชิก </label>
              <div>
                <input type="color" [(ngModel)]="color" class="color-picker" [value]="color" />
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-6">
          <label
            class="label"
            [class.text-danger]="form.controls['min_amount'].touched && form.controls['min_amount'].invalid">
            ยอดสั่งซื้อขั้นต่ำ
            <span class="text-danger">*</span>
          </label>
          <app-input-number
            [control]="form.controls['min_amount']"
            [min]="0"
            [useZero]="true"
            unit="บาท">
            @if (form.controls['min_amount'].hasError('required')) {
            <ng-container error>กรุณากรอกข้อมูล</ng-container>
            } @else if (form.controls['min_amount'].hasError('formApi')) {
            <ng-container error>{{ form.controls['min_amount'].errors?.['formApi']}}</ng-container>
            }</app-input-number
          >
        </div>

        <div class="col-12 col-sm-6">
          <label
            class="label"
            [class.text-danger]="form.controls['max_amount'].touched && form.controls['max_amount'].invalid">
            ยอดสั่งซื้อสูงสุด
            <span class="text-danger">*</span>
          </label>
          <app-input-number [control]="form.controls['max_amount']" [min]="0" unit="บาท"
            >@if (form.controls['max_amount'].hasError('required')) {
            <ng-container error>กรุณากรอกข้อมูล</ng-container>
            } @else if (form.controls['max_amount'].hasError('min')) {
            <ng-container error>ยอดสั่งซื้อสูงสุดต้องไม่น้อยกว่ายอดสั่งซื้อขั้นต่ำ</ng-container>
            } @else if (form.controls['max_amount'].hasError('formApi')) {
            <ng-container error>{{ form.controls['max_amount'].errors?.['formApi']}}</ng-container>
            }</app-input-number
          >
        </div>

        <div class="col-12">
          <label class="label"> คำอธิบาย </label>
          <app-text-editor
            [control]="form.controls['description']"
            [toolbarControl]="[
            [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
            ['bold', 'italic', 'underline'],
            ['bullet_list', 'ordered_list'],
            ['align_left', 'align_center', 'align_right'],
          ]"></app-text-editor>
        </div>

        <div class="col-12">
          <label class="label"> สถานะการใช้งาน </label>
          <div class="mt-2">
            <mat-slide-toggle
              hideIcon="true"
              color="primary"
              class="slide-toggle"
              [formControl]="form.controls.is_active">
              ใช้งาน
            </mat-slide-toggle>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="footer-button-wrapper d-flex justify-content-end w-100">
    <button matRipple class="btn btn-outline-secondary btn-md" (click)="onClose()">ยกเลิก</button>
    <button matRipple class="btn btn-primary btn-md" (click)="onConfirm()">บันทึก</button>
  </div>
</mat-dialog-actions>
