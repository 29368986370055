import { Injectable } from '@angular/core'
import { OrderService, PublicService } from '../services'
import { MatDialog } from '@angular/material/dialog'
import { ModalErrorComponent } from '../components/modal-error/modal-error'
import { formatPrice, formatPriceAutoDecimal, ORDER_ID } from '../helpers'

@Injectable({ providedIn: 'root' })
export class AlertOrder {
  constructor(public dialog: MatDialog, public publicService: PublicService) {}

  checkCancelAlert(order: any, isMark: boolean) {
    if (order && order.is_cancel && !order.is_cancel_alert) {
      const total = formatPrice(Math.abs(order.payment_diff_amount))
      if (order.status_id == ORDER_ID.CANCEL) {
        let detail = `<div class="mt-2">เนื่องจากสินค้าหมดทุกรายการ ลูกค้าจะได้รับเครดิตคืน ${total} บาท<br/>และออเดอร์นี้จะถูกยกเลิกอัตโนมัติ</div>`
        this.dialog.open(ModalErrorComponent, {
          data: {
            title: `สินค้าหมดทุกรายการในออเดอร์ #${order.order_no}`,
            detail,
          },
        })
      } else {
        let detail = `<div class="mt-2">รายการสินค้าที่หมด</div>`
        detail += `<ul>`
        for (const product of order.products) {
          if (product.cancel_quantity) {
            detail += `<li>${product.product_name} : สั่ง ${formatPriceAutoDecimal(
              product.cancel_quantity + product.quantity
            )} ชิ้น ซื้อสำเร็จ ${formatPriceAutoDecimal(product.quantity)} ชิ้น</li>`
          }
        }
        detail += `</ul>`
        detail += `<div class="mt-2">เนื่องจากสินค้าหมดบางรายการ ลูกค้าจะได้รับเครดิตคืน ${total} บาท</div>`
        this.dialog.open(ModalErrorComponent, {
          data: {
            title: `สินค้าหมดบางรายการในออเดอร์ #${order.order_no}`,
            detail,
          },
        })
      }
      if (isMark) {
        this.publicService.markCancelAlert(order.id).subscribe(resMark => {})
      }
    }
  }
}
