<div class="my-profile-wrapper mt-2">
  <div class="profile-account">
    <div class="name">Araya Thongakaraniroj</div>

    <div>
      <button class="button-logout">
        <span class="text-base-regular"> {{ lang == 'en' ? 'Log out' : 'ออกจากระบบ' }} </span>
      </button>
    </div>
  </div>
</div>

<div class="menu-profile-wrapper mt-2">
  <div class="menu-profile">
    <a class="menu-profile-item"> {{ lang == 'en' ? 'My Profile' : 'โปรไฟล์ของฉัน' }} </a>
    <a class="menu-profile-item">
      {{ lang == 'en' ? 'Shipping Address' : 'ที่อยู่สำหรับจัดส่ง' }}
    </a>
    <a class="menu-profile-item active"> {{ lang == 'en' ? 'My Tier' : 'ระดับของฉัน' }} </a>
    <a class="menu-profile-item"> {{ lang == 'en' ? 'My Wish List' : 'รายการที่อยากได้' }} </a>
    <a class="menu-profile-item"> {{ lang == 'en' ? 'My Coupons' : 'คูปองของฉัน' }} </a>
    <a class="menu-profile-item"> {{ lang == 'en' ? 'My Order' : 'คำสั่งซื้อของฉัน' }} </a>
  </div>
</div>
