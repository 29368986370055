import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core'
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog'
import { MatButtonModule } from '@angular/material/button'
import { MatInputModule } from '@angular/material/input'
import { CustomSnackBar } from '../../../../components/snackbar/snackbar'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { ModalConfirmComponent } from '../../../../components/modal-confirm/modal-confirm'
import { ModalLeaveComponent } from '../../../../components/modal-leave/modal-leave'
import { InputComponent } from '../../../../components/input/input'
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { Loading } from '../../../../globals'
import { ProductCategoryService } from '../../../../services'
import { forkJoin } from 'rxjs'
import { ValidateForm, setErrorForm, setErrorFormArray } from '../../../../helpers'
import { imports } from '../../../../imports'

@Component({
  selector: 'app-modal-category',
  templateUrl: './modal-category.html',
  styleUrls: ['./modal-category.scss'],
  standalone: true,
  imports: [
    ...imports,
    MatInputModule,
    MatButtonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatCheckboxModule,
    InputComponent,
    MatSlideToggleModule,
  ],
})
export class ModalCategoryComponent implements OnInit {
  product_sub_categories = new FormArray<FormGroup<any>>([])

  form = new FormGroup({
    name: new FormControl(this.data?.dataManage?.name || '', [
      Validators.required,
      Validators.maxLength(100),
    ]),
    name_en: new FormControl(this.data?.dataManage?.name_en || '', [
      Validators.required,
      Validators.maxLength(100),
    ]),
    is_subcategory: new FormControl(!!this.data?.dataManage?.product_sub_categories),
    is_active: new FormControl(true),
    product_sub_categories: this.product_sub_categories,
  })

  dataManage: any

  categoriesFormGroup(index: any) {
    return this.product_sub_categories.controls[index] as FormGroup
  }

  productSubSecond(index: any) {
    return this.categoriesFormGroup(index).get('sub_categories') as FormArray
  }

  subItems(indexC: any, index: any, get: string) {
    return this.productSubSecond(indexC).controls[index]?.get(get) as FormControl
  }

  log(log: any) {
    console.log('log', log)
  }

  get disableCheckSub() {
    return this.form.controls['product_sub_categories'].controls.some(c => {
      return (
        !c.controls['can_delete'].value ||
        (c.controls['sub_categories'] as FormArray<FormGroup<any>>).controls.some(c => {
          return !c.controls['can_delete'].value
        })
      )
    })
  }

  constructor(
    public dialogRef: MatDialogRef<ModalCategoryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public customSnackBar: CustomSnackBar,
    public loading: Loading,
    public productCategoryService: ProductCategoryService
  ) {}

  ngOnInit(): void {
    if (this.data.type == 'edit') {
      const product_sub_categories = this.data.dataManage.product_sub_categories || []
      for (const item of product_sub_categories) {
        let sub_categories = new FormArray<FormGroup<any>>([])
        if (item.sub_categories) {
          for (let index = 0; index < item.sub_categories.length; index++) {
            const e = item.sub_categories[index]
            let data_sub = new FormGroup<any>({
              id: new FormControl(e.id),
              can_delete: new FormControl(!!e.can_delete),
              name: new FormControl(e.name, [Validators.required, Validators.maxLength(100)]),
              name_en: new FormControl(e.name_en, [Validators.required, Validators.maxLength(100)]),
            })
            sub_categories.push(data_sub)
          }
        }

        let data = new FormGroup<any>({
          id: new FormControl(item.id || null),
          can_delete: new FormControl(!!item.can_delete),
          name: new FormControl<any>(item.name, [Validators.required, Validators.maxLength(100)]),
          name_en: new FormControl<any>(item.name_en, [
            Validators.required,
            Validators.maxLength(100),
          ]),
          sub_categories: sub_categories,
        })

        this.product_sub_categories.push(data)
      }

      this.form.controls.is_active.setValue(this.data?.dataManage?.is_active)

      if (this.disableCheckSub) {
        this.form.controls.is_subcategory.disable()
      }
    }
  }

  onClose() {
    if (this.data.type == 'edit' && this.form.dirty) {
      const dialogRef = this.dialog.open(ModalLeaveComponent, {
        data: {
          data: true,
        },
      })

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.dialogRef.close()
        }
      })
    } else {
      this.dialogRef.close()
    }
  }

  onConfirm(): void {
    ValidateForm(this.form)
    const isValidateSubName = this.validateSubName()
    if (!this.form.valid) return
    if (!this.form.valid && !isValidateSubName) return

    if (this.data.type == 'edit') {
      this.openModalConfirm()
    } else {
      this.onSave()
    }
  }

  openModalConfirm() {
    const dialogRefConfirm = this.dialog.open(ModalConfirmComponent, {
      data: {},
    })

    dialogRefConfirm.afterClosed().subscribe(result => {
      if (result) {
        this.onSave()
      }
    })

    return dialogRefConfirm
  }

  validateSubName() {
    const subListValue = this.product_sub_categories.getRawValue()
    let valid = true
    let index = 0
    let indexSub = 0
    const subList = this.product_sub_categories.controls as any

    for (const sub_list of subList) {
      const subName = sub_list.controls.name as FormControl
      const subNameEn = sub_list.controls.name_en as FormControl
      const sub_categories = sub_list.controls.sub_categories.controls as any

      if (
        subName.value &&
        subListValue.some((d: any, i: number) => d.name == subName.value && index != i)
      ) {
        subName.setErrors({
          duplicate: true,
        })
        valid = false
      } else {
        subName.updateValueAndValidity()
      }
      if (
        subNameEn.value &&
        subListValue.some((d: any, i: number) => d.name_en == subNameEn.value && index != i)
      ) {
        subNameEn.setErrors({
          duplicate: true,
        })
        valid = false
      } else {
        subNameEn.updateValueAndValidity()
      }

      for (const sub of sub_categories) {
        const subName = sub.controls.name as FormControl
        const subNameEn = sub.controls.name_en as FormControl

        if (
          subName.value &&
          subListValue.some((d: any, i: number) => d.name == subName.value && indexSub != i)
        ) {
          subName.setErrors({
            duplicate: true,
          })
          valid = false
        } else {
          subName.updateValueAndValidity()
        }
        if (
          subNameEn.value &&
          subListValue.some((d: any, i: number) => d.name_en == subNameEn.value && indexSub != i)
        ) {
          subNameEn.setErrors({
            duplicate: true,
          })
          valid = false
        } else {
          subNameEn.updateValueAndValidity()
        }
        indexSub++
      }
      index++
    }
    return valid
  }

  onSave(): void {
    const isEdit = this.data.type == 'edit'

    const value = this.form.getRawValue()
    const payload = {
      id: null,
      name: value.name,
      name_en: value.name_en,
      is_active: value.is_active,
      product_sub_categories: value.is_subcategory ? value.product_sub_categories : null,
    }

    // return console.log(payload)

    const api = isEdit
      ? this.productCategoryService.updateProductCategory(this.data.dataManage.id, payload)
      : this.productCategoryService.addProductCategory(payload)
    this.loading.start()
    api.subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          this.customSnackBar.success('บันทึกข้อมูลสำเร็จ')
          this.dialogRef.close(true)
        } else {
          if (res.errors[0]?.field) {
            setErrorForm(this.form, res.errors)
          } else {
            this.customSnackBar.failSave(res.message)
          }
        }
      }
      this.loading.stop()
    })
  }

  onChangeSubcategory(event: any) {
    if (event.checked) {
      this.addSubCategory()
    } else {
      while (this.product_sub_categories.length !== 0) {
        this.product_sub_categories.removeAt(0)
      }
    }
  }

  addSubCategory() {
    let data = new FormGroup<any>({
      id: new FormControl(null),
      can_delete: new FormControl(true),
      name: new FormControl<any>('', [Validators.required, Validators.maxLength(100)]),
      name_en: new FormControl<any>('', [Validators.required, Validators.maxLength(100)]),

      sub_categories: new FormArray<FormGroup<any>>([]),
    })

    this.product_sub_categories.push(data)
  }

  addSubSecondCategory(index: any) {
    let data = new FormGroup<any>({
      id: new FormControl(null),
      can_delete: new FormControl(true),
      name: new FormControl<any>('', [Validators.required, Validators.maxLength(100)]),
      name_en: new FormControl<any>('', [Validators.required, Validators.maxLength(100)]),
    })

    this.productSubSecond(index).push(data)
  }

  delSubCategory(index: any, can_delete: boolean) {
    if (can_delete) {
      this.product_sub_categories.removeAt(index)
    }
  }

  delSubSecondCategory(index: any, i: any, can_delete: boolean) {
    if (can_delete) {
      this.productSubSecond(index).removeAt(i)
    }
  }
}
