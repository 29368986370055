export const minTier = (list: any[]) => {
  return list.reduce((st: any, t: any) => {
    return !st || t.min_amount < st.min_amount ? t : st
  }, null)
}

export const maxTier = (list: any[]) => {
  return list.reduce((st: any, t: any) => {
    return !st || t.min_amount < st.min_amount ? t : st
  }, null)
}

export const currenTier = (list: any[], totalPrice: number) => {
  const tier = list
    .sort((a: any, b: any) => (a.min_amount > b.min_amount ? 1 : -1))
    .reduce((st: any, tier: any) => {
      return !st || (totalPrice >= tier.min_amount && tier.min_amount > st.min_amount) ? tier : st
    }, minTier(list))

  return tier
}

export const findTier = (list: any[], totalPrice: number) => {
  let next = null
  let diff = 0
  let persent = 0
  const current = currenTier(list, totalPrice)

  if (current) {
    next = list[list.indexOf(current) + 1]
    if (next) {
      diff = next.min_amount - totalPrice
      persent = (totalPrice * 100) / next.min_amount
    }
  }

  return {
    totalPrice,
    current,
    next,
    diff,
    persent,
  }
}
