import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core'
import { AbstractControl, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatInputModule } from '@angular/material/input'
import { MatSelect, MatSelectModule } from '@angular/material/select'
import { MatFormFieldModule, MatSuffix } from '@angular/material/form-field'
import { CommonModule } from '@angular/common'
import { SanitizeHtmlPipe } from '../../pipes/inner-html'
import { ModalSelectProductSingleComponent } from '../modal-select-product-single/modal-select-product-single'
import { ModalSelectCategoryProductComponent } from '../modal-select-category-product/modal-select-category-product'
import { ModalAddURLComponent } from '../modal-add-url/modal-add-url'
import { Loading, Profile } from '../../globals'
import { Router } from '@angular/router'
import { MatDialog } from '@angular/material/dialog'
import { CustomSnackBar } from '../snackbar/snackbar'
import { SelectComponent } from '../select/select'
import { EnumLinkListId, LINK_LIST_ID } from '../../helpers/link'
import { COMPANY_ID } from '../../helpers'

@Component({
  selector: 'app-select-add-link',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    FormsModule,
    MatSuffix,
    MatSelectModule,
    MatInputModule,
    SanitizeHtmlPipe,
    SelectComponent,
  ],
  templateUrl: './select-add-link.html',
  styleUrls: ['./select-add-link.scss'],
})
export class SelectAddLinkComponent implements OnInit {
  @Input() controlLinkType!: FormControl | AbstractControl
  @Input() controlLinkName: FormControl | AbstractControl = new FormControl('')

  @Input() product_id = null
  @Input() product_detail_id = null
  @Input() product_category_id = null
  @Input() link_path = null
  @Input() trigger_name = ''

  @Output() onProductId = new EventEmitter()
  @Output() onProductDetailId = new EventEmitter()
  @Output() onCategoryId = new EventEmitter()
  @Output() onLinkPath = new EventEmitter()

  triggerSelectName = ''

  readonly cms_link_list_id = new FormControl()
  readonly COMPANY_ID = COMPANY_ID

  listAddLinkPANIA: any[] = [
    {
      id: LINK_LIST_ID.NONE,
      name: 'ไม่ระบุ',
    },
    {
      id: LINK_LIST_ID.PRODUCT_DETAIL,
      name: 'หน้ารายละเอียดสินค้า',
    },
    {
      id: LINK_LIST_ID.CATEGORY,
      name: 'หมวดหมู่สินค้า',
    },
    {
      id: LINK_LIST_ID.URL,
      name: 'กำหนด URL',
    },
    {
      id: LINK_LIST_ID.CAMPAIGN,
      name: 'แคมเปญ',
    },
    {
      id: LINK_LIST_ID.COLLECTION,
      name: 'คอลเลคชัน',
    },
  ]

  listAddLinkBHB: any[] = [
    {
      id: LINK_LIST_ID.NONE,
      name: 'ไม่ระบุ',
    },
    {
      id: LINK_LIST_ID.PRODUCT_DETAIL,
      name: 'หน้ารายละเอียดสินค้า',
    },
    {
      id: LINK_LIST_ID.CATEGORY,
      name: 'หมวดหมู่สินค้า',
    },
    {
      id: LINK_LIST_ID.URL,
      name: 'กำหนด URL',
    },
  ]

  get listAddLink() {
    return this.profile.companyId == COMPANY_ID.BHB ? this.listAddLinkBHB : this.listAddLinkPANIA
  }

  get isTrggier() {
    return (
      this.controlLinkType.value == LINK_LIST_ID.PRODUCT_DETAIL ||
      this.controlLinkType.value == LINK_LIST_ID.CATEGORY ||
      this.controlLinkType.value == LINK_LIST_ID.URL
    )
  }

  constructor(
    public profile: Profile,
    public router: Router,
    public dialog: MatDialog,
    public customSnackBar: CustomSnackBar,
    public loading: Loading
  ) {}

  ngOnInit(): void {
    this.cms_link_list_id.setValue(this.controlLinkType.value, {
      emitEvent: false,
    })
    this.controlLinkType.valueChanges.subscribe(value => {
      this.cms_link_list_id.setValue(value, {
        emitEvent: false,
      })
    })
  }

  onClickAddLink(e: any) {
    if (!e.isUserInput) {
      return
    }
    const cms_link_list_id = e.source.value
    const value = {
      cms_link_list_id,
    }
    switch (cms_link_list_id) {
      case LINK_LIST_ID.NONE:
        this.triggerSelectName = ''
        this.setValue(value)
        return
      case LINK_LIST_ID.PRODUCT_DETAIL:
        return this.openModalSelectProductSingle(value)
      case LINK_LIST_ID.CATEGORY:
        return this.openModalSelectCategoryProduct(value)
      case LINK_LIST_ID.URL:
        return this.openModalAddURLComponent(value)
      case LINK_LIST_ID.CAMPAIGN:
        this.triggerSelectName = ''
        this.setValue(value)
        return
      case LINK_LIST_ID.COLLECTION:
        this.triggerSelectName = ''
        this.setValue(value)
        return
      default:
        this.triggerSelectName = ''
        this.setValue(value)
        return null
    }
  }

  setValue(value: any) {
    console.log('value', value)

    this.controlLinkType.setValue(value.cms_link_list_id ?? null)
    this.onProductId.emit(value.product_id || null)
    this.onProductDetailId.emit(value.product_detail_id || null)
    this.onCategoryId.emit(value.product_category_id || null)
    this.onLinkPath.emit(value.link_path || null)
  }

  openModalSelectProductSingle(value: any) {
    const dialogRef = this.dialog.open(ModalSelectProductSingleComponent, {
      data: {
        product_id: this.product_id,
        product_detail_id: this.product_detail_id,
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.triggerSelectName = result.name
        this.setValue({
          ...value,
          product_id: result.product_id,
          product_detail_id: result.product_detail_id,
        })
      } else {
        this.cms_link_list_id.setValue(this.controlLinkType.value, {
          emitEvent: false,
        })
      }
    })
  }

  openModalSelectCategoryProduct(value: any) {
    const dialogRef = this.dialog.open(ModalSelectCategoryProductComponent, {
      data: {
        product_category_id: this.product_category_id,
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.triggerSelectName = result.category_name
        this.setValue({
          ...value,
          product_category_id: result.category_id,
        })
      } else {
        this.cms_link_list_id.setValue(this.controlLinkType.value, {
          emitEvent: false,
        })
      }
    })
  }

  openModalAddURLComponent(value: any) {
    const dialogRef = this.dialog.open(ModalAddURLComponent, {
      data: {
        link_path: this.link_path,
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.triggerSelectName = result
        this.setValue({
          ...value,
          link_path: result,
        })
      } else {
        this.cms_link_list_id.setValue(this.controlLinkType.value, {
          emitEvent: false,
        })
      }
    })
  }
}
