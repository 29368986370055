import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core'
import { Router } from '@angular/router'
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  FormsModule,
  Validators,
} from '@angular/forms'
import {
  EmailReg,
  ValidateForm,
  setErrorForm,
  ValidateArrayForm,
  Moment,
  convertDateToApi,
  Ascending,
} from '../../../../../helpers'
import { UploadImageProfileComponent } from '../../../../../components/upload-image-profile/upload-image-profile'
import { ModalLeaveComponent } from '../../../../../components/modal-leave/modal-leave'
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog'
import {
  CompanyProfileService,
  CaratService,
  ProductCategoryService,
} from '../../../../../services'
import { CustomSnackBar } from '../../../../../components/snackbar/snackbar'
import { ACTION_ACCESS, APP_PERMISSION, Loading, Profile } from '../../../../../globals'
import { forkJoin } from 'rxjs'
import { ModalConfirmComponent } from '../../../../../components/modal-confirm/modal-confirm'
import { InputNumberComponent } from '../../../../../components/input-number/input-number'
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
  CdkDrag,
  CdkDropList,
} from '@angular/cdk/drag-drop'
import { DatePickerComponent } from '../../../../../components/date-picker/date-picker'
import { UploadFileFlexibleComponent } from '../../../../../components/upload-file-flexible/upload-file-flexible'
import { RadioLayoutComponent } from '../../../../../components/radio-layout/radio-layout'
import { MatRadioModule } from '@angular/material/radio'
import { SelectComponent } from '../../../../../components/select/select'
import { TextareaComponent } from '../../../../../components/text-area/text-area'
import { InputComponent } from '../../../../../components/input/input'
import { imports } from '../../../../../imports'
import { ModalDeleteComponent } from '../../../../../components/modal-delete/modal-delete'
import { MatRipple } from '@angular/material/core'
import { MatIconModule } from '@angular/material/icon'
import { MatTabsModule } from '@angular/material/tabs'
import { MatButtonModule } from '@angular/material/button'
import { MatInputModule } from '@angular/material/input'
import { MatFormFieldModule } from '@angular/material/form-field'
import { LINK_LIST_ID } from '../../../../../helpers/link'
import { CMSMenuService } from '../../../../../services/cms-menu.service'

@Component({
  selector: 'app-modal-manage-home',
  standalone: true,
  imports: [
    ...imports,
    InputComponent,
    TextareaComponent,
    UploadImageProfileComponent,
    ModalLeaveComponent,
    InputNumberComponent,
    DatePickerComponent,
    CdkDrag,
    CdkDropList,
    UploadFileFlexibleComponent,
    RadioLayoutComponent,
    MatRadioModule,
    SelectComponent,
    CdkDrag,
    CdkDropList,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatTabsModule,
    MatIconModule,
    InputComponent,
    MatRipple,
  ],
  templateUrl: './modal-manage-home.html',
  styleUrls: ['./modal-manage-home.scss'],
})
export class ModalManageHomeComponent implements OnInit {
  // @Input() form: any = new FormGroup('')
  // @Input() sectionIndex: number = 0
  // @Input() showButtonDelete: boolean = false
  // @Output() deleteSection = new EventEmitter()
  readonly APP_PERMISSION = APP_PERMISSION
  readonly ACTION_ACCESS = ACTION_ACCESS

  // mobile = window.matchMedia('(max-width: 1000px)')

  isEdit: boolean = false
  isView: boolean = false

  defaultMenu = [
    new FormGroup({
      code: new FormControl('campaign'),
      cms_link_list_id: new FormControl(),
      product_category_id: new FormControl(null),
      name_th: new FormControl('แคมเปญ'),
      name_en: new FormControl('Campaign'),
      is_defalut: new FormControl(true),
    }),
    new FormGroup({
      code: new FormControl('explore'),
      cms_link_list_id: new FormControl(),
      product_category_id: new FormControl(null),
      name_th: new FormControl('สำรวจสินค้า'),
      name_en: new FormControl('Explore'),
      is_defalut: new FormControl(true),
    }),
    new FormGroup({
      code: new FormControl('sales'),
      cms_link_list_id: new FormControl(),
      product_category_id: new FormControl(null),
      name_th: new FormControl('สินค้าลดราคา'),
      name_en: new FormControl('Sales'),
      is_defalut: new FormControl(true),
    }),
  ]
  menu = new FormArray<FormGroup>([
    ...this.defaultMenu,
    new FormGroup({
      code: new FormControl('category'),
      cms_link_list_id: new FormControl(LINK_LIST_ID.CATEGORY),
      product_category_id: new FormControl(null),
      name_th: new FormControl(''),
      name_en: new FormControl(''),
      is_defalut: new FormControl(false),
    }),
    new FormGroup({
      code: new FormControl('category'),
      cms_link_list_id: new FormControl(LINK_LIST_ID.CATEGORY),
      product_category_id: new FormControl(null),
      name_th: new FormControl(''),
      name_en: new FormControl(''),
      is_defalut: new FormControl(false),
    }),
    new FormGroup({
      code: new FormControl('category'),
      cms_link_list_id: new FormControl(LINK_LIST_ID.CATEGORY),
      product_category_id: new FormControl(null),
      name_th: new FormControl(''),
      name_en: new FormControl(''),
      is_defalut: new FormControl(false),
    }),
    new FormGroup({
      code: new FormControl('category'),
      cms_link_list_id: new FormControl(LINK_LIST_ID.CATEGORY),
      product_category_id: new FormControl(null),
      name_th: new FormControl(''),
      name_en: new FormControl(''),
      is_defalut: new FormControl(false),
    }),
  ])

  categories: any[] = []

  constructor(
    public dialogRef: MatDialogRef<ModalManageHomeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public profile: Profile,
    public router: Router,
    public dialog: MatDialog,
    public customSnackBar: CustomSnackBar,
    public loading: Loading,
    public companyProfileService: CompanyProfileService,
    public cmsMenuService: CMSMenuService
  ) {}

  ngOnInit(): void {
    this.initData()
  }

  initData() {
    const apis = [this.cmsMenuService.getMenuList(), this.cmsMenuService.getCategoryList()]
    this.loading.start()
    forkJoin(apis).subscribe(([resMenuList, resCategoryList]) => {
      if (!resMenuList.is_error) {
        let menuList = Ascending(resMenuList.data, 'seq')

        this.menu.clear()
        const defaultMenu = [...this.defaultMenu].reverse()
        for (const f of defaultMenu) {
          const value = f.getRawValue()
          const linkDefault = menuList.find((l: any) => l.code == value.code)
          if (linkDefault) {
            linkDefault.name_th = value.name_th
            linkDefault.name_en = value.name_en
            linkDefault.is_defalut = true
          } else {
            menuList = [{ ...value }, ...menuList]
          }
        }

        for (let index = 0; index < 7; index++) {
          const link = menuList[index] || {
            code: 'category',
            is_defalut: false,
          }
          this.menu.push(
            new FormGroup({
              code: new FormControl(link.code),
              cms_link_list_id: new FormControl(link.cms_link_list_id),
              product_category_id: new FormControl(link.product_category_id),
              name_th: new FormControl(
                link.product_category ? link.product_category.name_th : link.name_th
              ),
              name_en: new FormControl(
                link.product_category ? link.product_category.name_en : link.name_en
              ),
              is_defalut: new FormControl(!!link.is_defalut),
            })
          )
        }
      } else {
        this.customSnackBar.fail()
      }

      if (!resCategoryList.is_error) {
        this.categories = [{ id: '', name_th: 'ไม่ระบุ', name_en: 'None' }, ...resCategoryList.data]
      } else {
        this.customSnackBar.fail()
      }

      this.loading.stop()
    })
  }

  categoryList(categories: any[], product_category_id: any) {
    return categories.filter(
      c =>
        !c.id ||
        c.id == product_category_id ||
        !this.menu.value.some(v => v.product_category_id == c.id)
    )
  }

  chnageSelect(f: FormGroup<any>) {
    const category = this.categories.find(
      (d: any) => d.id == f.controls['product_category_id'].value
    )
    if (category) {
      f.controls['name_th'].setValue(category.name_th)
      f.controls['name_en'].setValue(category.name_en)
    }
  }

  drop(event: CdkDragDrop<any[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex)
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      )
    }
    // this.checkValidate()
    // this.form.markAllAsTouched()
  }

  onClose(): void {
    this.dialogRef.close()
  }

  onConfirm(): void {
    const payload = this.menu.controls.map((c, seq) => {
      const value = c.getRawValue()
      return {
        seq: seq + 1,
        code: value.code,
        product_category_id: value.product_category_id || null,
        is_default: !!value.is_defalut,
      }
    })

    this.loading.start()
    this.cmsMenuService.updateMenuList(payload).subscribe(res => {
      if (!res.is_error) {
        this.customSnackBar.success('บันทึกข้อมูลสำเร็จ')
        this.dialogRef.close(true)
      } else {
        this.customSnackBar.failSave()
      }
      this.loading.stop()
    })
  }
}
