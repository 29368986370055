<mat-radio-group
  [formControl]="formControl"
  (change)="onChange.emit($event.value);onChangeValue.emit($event)">
  <div class="list-wrapper d-flex flex-wrap gap-4">
    @for (item of list; track $index) {
    <!-- ~ -->
    @if(item.show){
    <div class="radio-button-wrapper d-flex flex-column">
      <img [src]="item.img" />
      <mat-radio-button [value]="item.value">{{item.text}}</mat-radio-button>
    </div>
    } }
  </div>
</mat-radio-group>
