import { Component } from '@angular/core'
import { StatusUserComponent } from '../../../components/status-user/status-user'
import { SearchComponent } from '../../../components/search/search'
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatDialog } from '@angular/material/dialog'
import { MatTooltipModule } from '@angular/material/tooltip'
import { ModalCategoryComponent } from './modal-category/modal-category'
import { ModalSubcategoryComponent } from './modal-subcategory/modal-subcategory'
import { ModalDeleteComponent } from '../../../components/modal-delete/modal-delete'
import { CustomSnackBar } from '../../../components/snackbar/snackbar'
import { COMPANY_ID, Moment, Pagination } from '../../../helpers'
import {
  ACTION_ACCESS,
  APP_PERMISSION,
  FilterTableProductCategory,
  Loading,
  Profile,
} from '../../../globals'
import { ProductCategoryService } from '../../../services'
import { imports } from '../../../imports'
import { TextTooltipComponent } from '../../../components/text-tooltip/text-tooltip'

@Component({
  selector: 'app-category',
  standalone: true,
  imports: [...imports, StatusUserComponent, SearchComponent, MatPaginatorModule, MatTooltipModule],
  templateUrl: './category.html',
  styleUrls: ['./category.scss'],
})
export class CategoryComponent {
  readonly Moment = Moment
  readonly APP_PERMISSION = APP_PERMISSION
  readonly ACTION_ACCESS = ACTION_ACCESS
  readonly COMPANY_ID = COMPANY_ID

  readonly paginationCategory = new Pagination({
    sortBy: 'asc',
    sortName: 'name',
  })

  categoryList: any[] = []

  constructor(
    public profile: Profile,
    public loading: Loading,
    public dialog: MatDialog,
    public customSnackBar: CustomSnackBar,
    public productCategoryService: ProductCategoryService,
    public filterTableProductCategory: FilterTableProductCategory
  ) {}

  ngOnInit(): void {
    this.getCategoryList()
  }

  getCategoryList() {
    const pagination = this.paginationCategory.get()
    const dataFilter = this.filterTableProductCategory.getData()
    const payload = {
      ...pagination,
      ...dataFilter,
    }
    // console.log(payload)
    // return

    this.loading.start()
    this.productCategoryService.getProductCategoryList(payload).subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          this.categoryList = res.data.records
          this.paginationCategory.setFromResponse(res.data)
        } else {
          this.customSnackBar.fail()
        }
      }
      this.loading.stop()
    })
  }

  openModalCategory(title: string, type: 'add' | 'edit', dataManage?: any) {
    const dialogRef = this.dialog.open(ModalCategoryComponent, {
      disableClose: true,
      data: {
        title,
        type,
        dataManage,
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getCategoryList()
      }
    })

    return dialogRef
  }

  openModalSubcategory(data: any) {
    const dialogRef = this.dialog.open(ModalSubcategoryComponent, {
      disableClose: true,
      data: {
        data,
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
      }
    })

    return dialogRef
  }

  confirmDelete(dataManage: any) {
    if (dataManage.can_delete) {
      const dialogRefDelete = this.dialog.open(ModalDeleteComponent, {
        data: {
          detail: `คุณต้องการลบ “${dataManage.name}” ออกจากหมวดหมู่สินค้าใช่หรือไม่`,
        },
      })

      dialogRefDelete.afterClosed().subscribe(result => {
        if (result) {
          this.deleteCategory(dataManage)
        }
      })
    }
  }

  deleteCategory(dataManage: any) {
    this.loading.start()
    this.productCategoryService.deleteProductCategory(dataManage.id).subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          this.customSnackBar.success('ลบข้อมูลสำเร็จ')
          this.getCategoryList()
        } else {
          this.customSnackBar.fail(res.message || 'ลบข้อมูลไม่สำเร็จ')
        }
      }
      this.loading.stop()
    })
  }
}
