<div class="page-content pb-0">
  <div class="breadcrumb-wrapper">
    <div class="breadcrumb-text breadcrumb-link">ตั้งค่า</div>
    <div class="breadcrumb-separator">/</div>
    <div class="breadcrumb-text">จัดการเนื้อหา (CMS)</div>
    <div class="breadcrumb-separator">/</div>
    <div class="breadcrumb-text">นโยบายและข้อกำหนด</div>
  </div>

  <div class="title-wrapper">นโยบายและข้อกำหนด</div>

  <div class="page-content-inner d-flex flex-column pb-0">
    <div class="tab-box">
      <div class="box-left">
        <div class="box-top d-flex justify-content-between">
          <div class="d-flex align-items-center">นโยบายและข้อกำหนด</div>
        </div>
        <div class="box-body">
          @for (item of policyList.controls; track $index) {
          <div
            class="box d-flex justify-content-between align-items-center manage {{ $index != policyList.length && 'mb-3' }}"
            [class.active]="policyType == item.value.type"
            [class.disabled]="policyType != item.value.type && isEdit"
            (click)="isView ? setSelection(item.value.type) : ''">
            <div class="box-name d-flex justify-content-between align-items-center">
              <div class="text-truncate">{{item.value.name}}</div>
            </div>
          </div>
          }
        </div>
      </div>
      <div class="box-right">
        @for (item of policyList.controls; track $index) {
        <!-- ~~ -->
        @if(policyType == item.value.type) {
        <div class="box-body right min-hight">
          @if (dataManage?.length > 0) {
          <div class="form-label color--neutral-500">
            วันที่อัปเดต: {{ Moment(dataManage?.[$index]?.updated_at).format('DD-MM-YYYY, HH:mm') }}
            อัปเดตโดย: {{ dataManage?.[$index]?.updated_by?.full_name || '-' }}
          </div>
          <div class="mb-3">
            <app-status [status]="dataManage?.[$index]?.is_public ? 'public' : 'not_public'">
              {{dataManage?.[$index]?.is_public ? 'เผยแพร่แล้ว' : 'ยังไม่เผยแพร่'}}
            </app-status>
          </div>
          }

          <div class="tabs-modal-wrapper">
            <mat-tab-group
              animationDuration="0ms"
              [selectedIndex]="tab"
              (selectedIndexChange)="changeTab($event)">
              <mat-tab label="ภาษาไทย">
                <div class="tabs-content-wrapper pt-3 px-2">
                  <label class="label-view">เนื้อหาคอนเทนต์</label>
                  @if (isEdit) {
                  <app-text-editor
                    [control]="item.controls['detail_th']"
                    [toolbarControl]="[
                  [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
                  ['bold', 'italic', 'underline'],
                  ['bullet_list', 'ordered_list'],
                  ['align_left', 'align_center', 'align_right'],
                  ['link']
                ]"></app-text-editor>
                  } @else {
                  <div class="box-view">
                    @if (item.value.detail_th) {
                    <div
                      [innerHtml]="sanitizer.bypassSecurityTrustHtml(readHTML(item.value.detail_th))"></div>
                    } @else { - }
                  </div>
                  }
                </div>
              </mat-tab>
              <mat-tab label="ภาษาอังกฤษ">
                <div class="tabs-content-wrapper pt-3 px-2">
                  <label class="label-view">เนื้อหาคอนเทนต์</label>
                  @if (isEdit) {
                  <app-text-editor
                    [control]="item.controls['detail_en']"
                    [toolbarControl]="[
                    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
                    ['bold', 'italic', 'underline'],
                    ['bullet_list', 'ordered_list'],
                    ['align_left', 'align_center', 'align_right'],
                    ['link']
                  ]"></app-text-editor>
                  } @else {
                  <div class="box-view">
                    @if (item.value.detail_en) {
                    <div
                      [innerHtml]="sanitizer.bypassSecurityTrustHtml(readHTML(item.value.detail_en))"></div>
                    } @else { - }
                  </div>
                  }
                </div>
              </mat-tab>
            </mat-tab-group>
          </div>
        </div>
        }
        <!-- ~~ -->
        }
      </div>
    </div>

    <div class="page-footer-btn">
      @if (isView) {
      @if (profile.permissions.checkAccess([APP_PERMISSION.CMS], [ACTION_ACCESS.UPDATE])) {
      <div class="d-flex flex-wrap justify-content-end">
        <button
          matRipple
          class="btn btn-outline-secondary min-w-165px"
          [routerLink]="['/setting/cms/policy/edit']"
          [queryParams]="{id: policyType}">
          แก้ไข
        </button>
      </div>
      }
      } @else if(isEdit) {
      <div class="footer-btn d-flex flex-wrap justify-content-between">
        <div class="footer-btn-left d-flex flex-wrap">
          <button matRipple class="btn btn-outline-secondary min-w-165px" (click)="onCancel()">
            ยกเลิก
          </button>
        </div>

        <div class="footer-btn-right d-flex flex-wrap">
          <button matRipple class="btn btn-outline-secondary min-w-165px" (click)="openPreview()">
            ดูตัวอย่าง
          </button>
          @if (profile.permissions.checkAccess([APP_PERMISSION.CMS], [ACTION_ACCESS.UPDATE]) ||
          profile.permissions.checkAccess([APP_PERMISSION.CMS], [ACTION_ACCESS.CREATE])) {
          <button
            matRipple
            class="btn btn-outline-secondary min-w-165px"
            (click)="onSave('private')">
            บันทึกร่าง
          </button>
          <button matRipple class="btn btn-primary min-w-165px" (click)="onSave('public')">
            เผยแพร่ทันที
          </button>
          }
        </div>
      </div>
      }
    </div>
  </div>
</div>
