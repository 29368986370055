import { Component, Inject, Injectable } from '@angular/core'
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog'
import { MatButtonModule } from '@angular/material/button'
import { FormsModule } from '@angular/forms'
import { MatInputModule } from '@angular/material/input'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatTabsModule } from '@angular/material/tabs'
import { MatIconModule } from '@angular/material/icon'
import { MatRipple } from '@angular/material/core'
import { TopMenuComponent } from '../../../../../components/preview/top-menu/top-menu'
import { FileService, ProductService } from '../../../../../services'
import { Loading } from '../../../../../globals'
import { CustomSnackBar } from '../../../../../components/snackbar/snackbar'
import { CardProductComponent } from '../../../../../components/preview/card-product/card-product'
import { LINK_LIST_ID } from '../../../../../helpers/link'
import { FooterComponent } from '../../../../../components/preview/footer/footer'
import { groupProducts, ProductData } from '../../../../../helpers/preview/product'
import { FooterBHBComponent } from '../../../../../components/preview-bhb/footer/footer'
import { HeaderBHBComponent } from '../../../../../components/preview-bhb/header/header'
import { CardProductBnhComponent } from '../../../../../components/preview/card-product-bnh/card-product-bnh'

@Component({
  selector: 'app-modal-preview',
  templateUrl: './modal-preview.html',
  styleUrls: ['./modal-preview.scss'],
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatTabsModule,
    MatIconModule,
    MatRipple,
    HeaderBHBComponent,
    CardProductComponent,
    FooterBHBComponent,
    CardProductBnhComponent
  ],
})
export class ModalPreviewMainComponent {
  readonly LINK_LIST_ID = LINK_LIST_ID

  cms_bhb_main_details: any[] = []
  file_main_banner_file: any
  file_sub_banner_file: any
  mainProducts: any[] = []
  readonly indexListDefault = 8
  indexList = this.indexListDefault
  mainProductSeconds: any[] = []

  get productSeconds() {
    return this.mainProductSeconds.slice(0, this.indexList)
  }

  constructor(
    public dialogRef: MatDialogRef<ModalPreviewMainComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public fileService: FileService,
    public loading: Loading,
    public customSnackBar: CustomSnackBar,
    public productService: ProductService
  ) {}

  ngOnInit(): void {
    console.log(this.data)

    if (this.data.formValue.file_banner_id) {
      this.getFile(this.data.formValue.file_banner_id, (res: any) => {
        this.file_main_banner_file = res.data
      })
    }

    if (this.data.formValue.file_sub_banner_id) {
      this.getFile(this.data.formValue.file_sub_banner_id, (res: any) => {
        this.file_sub_banner_file = res.data
      })
    }

    this.setContents()

    document.addEventListener('fullscreenchange', () => {
      document.exitFullscreen()
    })
  }

  getFile(id: any, call: Function) {
    this.loading.start()
    this.fileService.getFile(id).subscribe(res => {
      if (!res.is_error) {
        call(res)
      } else {
        this.customSnackBar.fail()
      }
      this.loading.stop()
    })
  }

  setContents() {
    this.cms_bhb_main_details = (this.data.formValue.cms_bhb_main_details || []).map((d: any) => {
      d.file = null
      this.getFile(d.file_id, (res: any) => {
        d.file = res.data
      })
      return d
    })

    const mainProducts: (ProductData | null)[] = groupProducts(
      (this.data.formValue.cms_bhb_main_products || []).filter((d: any) => d.is_first)
    ).map((product: any) => {
      return new ProductData(product)
    })
    // for (let index = 0; index < 4; index++) {
    //   if (!mainProducts[index]) {
    //     mainProducts[index] = null;
    //   }
    // }
    this.mainProducts = mainProducts.slice(0, 4)

    const cms_bhb_main_products_second = (
      this.data.formValue.cms_bhb_main_products_second || []
    ).map((d: any, i: number) => {
      return {
        ...d,
        seq: i + 1,
      }
    })
    const mainProductSeconds = groupProducts(
      cms_bhb_main_products_second.filter((d: any) => d.is_second)
    )
    this.mainProductSeconds = mainProductSeconds.map((product: any) => {
      return new ProductData(product)
    })
    this.indexList = this.indexListDefault
    console.log(this.productSeconds)
  }

  onClose(): void {
    this.dialogRef.close()
  }
}
