import {
  APP_ID,
  Component,
  Directive,
  ElementRef,
  Inject,
  Input,
  OnInit,
  PLATFORM_ID,
  ViewChild,
  inject,
} from '@angular/core'
import { FormArray, FormControl, FormGroup, NgControl, Validators } from '@angular/forms'
import { interval, Subscription } from 'rxjs'

@Directive({
  selector: '[disableC]',
})
export class DisableControlDirective {
  @Input() set disableControl(condition: boolean) {
    if (this.ngControl.control) {
      this.ngControl.control[condition ? 'disable' : 'enable']()
    }
  }

  constructor(private ngControl: NgControl) {}
}

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.html',
  styleUrl: './video-player.scss',
  standalone: true,
  imports: [],
  providers: [DisableControlDirective],
})
export class VideoPlayerComponent implements OnInit {
  @Input() videoSrc: string = ''
  @Input() loop: boolean = true
  @Input() muted: boolean = true
  @Input() autoplay: boolean = true
  @Input() controls: boolean = true

  @ViewChild('myVideo', { static: true }) video!: ElementRef<HTMLVideoElement>
  readonly isBrowser = true

  isPlaying: boolean = false
  isMuted: boolean = true
  volume: number = 100
  progress: number = 0
  private progressSubscription!: Subscription

  constructor() {}

  ngAfterViewInit(): void {
    if (this.isBrowser && this.autoplay) {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            this.video.nativeElement.play()
          } else {
            this.video.nativeElement.pause()
          }
        })
      })

      observer.observe(this.video.nativeElement)
    }
  }

  ngOnInit(): void {
    if (this.isBrowser) {
      if (this.autoplay) {
        this.isPlaying = false
      } else {
        this.isPlaying = true
      }
      this.progressSubscription = interval(1000).subscribe(() => {
        if (this.video.nativeElement.readyState >= 2) {
          const duration = this.video.nativeElement.duration
          const currentTime = this.video.nativeElement.currentTime
          this.progress = (currentTime / duration) * 100
        }
      })
      document.addEventListener('fullscreenchange', this.onFullScreenChange.bind(this))
    }
  }

  ngOnDestroy(): void {
    if (this.isBrowser) {
      if (this.progressSubscription) {
        this.progressSubscription.unsubscribe()
      }

      document.removeEventListener('fullscreenchange', this.onFullScreenChange.bind(this))
    }
  }

  onFullScreenChange(): void {
    if (this.isBrowser) {
      const videoElement = this.video.nativeElement
      if (document.fullscreenElement === videoElement) {
        videoElement.classList.add('fullscreen')
      } else {
        videoElement.classList.remove('fullscreen')
      }
    }
  }

  togglePlayPause(): void {
    if (this.isBrowser) {
      this.isPlaying = !this.isPlaying
      if (this.video.nativeElement.paused) {
        this.video.nativeElement.play()
      } else {
        this.video.nativeElement.pause()
      }
    }
  }

  toggleMute(): void {
    if (this.isBrowser) {
      this.video.nativeElement.muted = !this.video.nativeElement.muted
      this.isMuted = this.video.nativeElement.muted
    }
  }

  skip(seconds: number): void {
    if (this.isBrowser) {
      this.video.nativeElement.currentTime += seconds
    }
  }

  toggleFullScreen(): void {
    if (this.isBrowser) {
      const videoElement = this.video.nativeElement
      if (videoElement.requestFullscreen) {
        videoElement.requestFullscreen()
      } else if ((videoElement as any).webkitRequestFullscreen) {
        ;(videoElement as any).webkitRequestFullscreen()
      } else if ((videoElement as any).msRequestFullscreen) {
        ;(videoElement as any).msRequestFullscreen()
      }
    }
  }

  seek(event: Event): void {
    if (this.isBrowser) {
      const value = (event as CustomEvent).detail?.value || (event.target as HTMLInputElement).value
      const seekTime = (Number(value) / 100) * this.video.nativeElement.duration
      this.video.nativeElement.currentTime = seekTime
    }
  }

  seekToProgress(event: MouseEvent): void {
    if (this.isBrowser) {
      const progressElement = event.target as HTMLProgressElement
      const rect = progressElement.getBoundingClientRect()
      const offsetX = event.clientX - rect.left
      const progressWidth = progressElement.offsetWidth
      const newProgress = (offsetX / progressWidth) * 100
      this.progress = newProgress

      const seekEvent = {
        target: { value: newProgress },
      } as unknown as Event
      this.seek(seekEvent)
    }
  }
}
