import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { Service } from './service'

@Injectable({
  providedIn: 'root',
})
export class CMSSizeService {
  constructor(private service: Service) {}
  private pathSize = '/v1/cms/size-guide'

  getSizeList(payload: any): Observable<any> {
    return this.service.post(`${this.pathSize}/query`, payload)
  }
  getSizeById(id: number): Observable<any> {
    return this.service.get(`${this.pathSize}/${id}`)
  }
  createSize(payload: any): Observable<any> {
    return this.service.post(`${this.pathSize}`, payload)
  }
  updateSize(id: any, payload: any): Observable<any> {
    return this.service.put(`${this.pathSize}/${id}`, payload)
  }
  deleteSize(id: number, payload: any = {}): Observable<any> {
    return this.service.delete(`${this.pathSize}/${id}`, payload)
  }
}
