import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { Service } from './service'
import { CARAT_ID } from '../helpers'

@Injectable({
  providedIn: 'root',
})
export class CaratService {
  constructor(private service: Service) {}
  private pathCarat = '/v1/carats'

  getCaratList(payload: any): Observable<any> {
    return this.service.post(`${this.pathCarat}/query`, payload)
  }
  getCarat(): Observable<any> {
    return this.service.get(`${this.pathCarat}/${CARAT_ID[this.service.companyId]}`)
  }
  // addCarat(payload: any): Observable<any> {
  //   return this.service.post(`${this.pathCarat}`, payload)
  // }
  updateCarat(payload: any): Observable<any> {
    return this.service.put(`${this.pathCarat}/${CARAT_ID[this.service.companyId]}`, payload)
  }
  // deleteCarat(id: number, payload: any = {}): Observable<any> {
  //   return this.service.delete(`${this.pathCarat}/${id}`, payload)
  // }
}
