import { Component, Inject } from '@angular/core'
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms'
import { MatCheckboxModule } from '@angular/material/checkbox'
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog'
import { DatePickerComponent } from '../../../components/date-picker/date-picker'
import { InputNumberComponent } from '../../../components/input-number/input-number'
import { InputComponent } from '../../../components/input/input'
import { CustomSnackBar } from '../../../components/snackbar/snackbar'
import { TimePickerComponent } from '../../../components/time-picker/time-picker'
import { Loading } from '../../../globals'
import { Moment, convertDateToApi, mergeDateAndTime } from '../../../helpers'
import { imports } from '../../../imports'
import { OrderService } from '../../../services'
import { showCustomerName } from '../../../helpers/customer'

@Component({
  selector: 'app-modal-close',
  templateUrl: './modal-close.html',
  styleUrls: ['./modal-close.scss'],
  standalone: true,
  imports: [
    ...imports,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatCheckboxModule,
    DatePickerComponent,
    InputComponent,
    InputNumberComponent,
    TimePickerComponent,
  ],
})
export class ModalCloseComponent {
  readonly showCustomerName = showCustomerName

  forms = new FormArray<FormGroup>([])

  constructor(
    public dialogRef: MatDialogRef<ModalCloseComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public customSnackBar: CustomSnackBar,
    public loading: Loading,
    public orderService: OrderService
  ) {}

  ngOnInit(): void {
    for (const address of this.data.item.delivery_addresses) {
      if (this.data.item.is_multi_delivery ? address.is_used : address.is_main) {
        let delivery_moment_date = Moment()
        if (address.delivery_date) {
          delivery_moment_date = Moment(address.delivery_date)
        }
        let time = delivery_moment_date.format('HH:mm:ss')

        const form = new FormGroup({
          data: new FormControl(address),
          cost: new FormControl(address.cost || null, [Validators.max(1000000000)]),
          tracking_no: new FormControl(address.tracking_no || '', [Validators.maxLength(100)]),
          date: new FormControl(delivery_moment_date || ''),
          time: new FormControl(time || ''),
        })
        this.forms.push(form)
      }
    }
  }

  onClose(): void {
    this.dialogRef.close()
  }

  onConfirm(): void {
    const values = this.forms.getRawValue()
    const payload = values.map((value: any) => {
      let delivery_date = null
      if (value.date) {
        delivery_date = convertDateToApi(mergeDateAndTime(value.date, value.time))
      }
      return {
        id: value.data.id,
        name: value.data.name,
        telephone: value.data.telephone,
        address: value.data.address,
        is_main: value.data.is_main,
        cost: value.cost,
        tracking_no: value.tracking_no,
        delivery_date: delivery_date,
      }
    })

    // return console.log(payload)

    this.loading.start()
    this.orderService.closeOrder(this.data.item.id, payload).subscribe((res: any) => {
      if (!res.is_error) {
        this.customSnackBar.success('บันทึกข้อมูลสำเร็จ')
        this.dialogRef.close(true)
      } else {
        this.customSnackBar.failSave(res.message)
      }
      this.loading.stop()
    })
  }
}
