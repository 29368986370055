import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { Service } from './service'

@Injectable({
  providedIn: 'root',
})
export class CMSSizeMeasureService {
  constructor(private service: Service) {}
  private pathSizeMeasure = '/v1/cms/size-measurement'

  getSizeMeasureList(payload: any): Observable<any> {
    return this.service.post(`${this.pathSizeMeasure}/query`, payload)
  }
  getSizeMeasureById(id: number): Observable<any> {
    return this.service.get(`${this.pathSizeMeasure}/${id}`)
  }
  createSizeMeasure(payload: any): Observable<any> {
    return this.service.post(`${this.pathSizeMeasure}`, payload)
  }
  updateSizeMeasure(id: any, payload: any): Observable<any> {
    return this.service.put(`${this.pathSizeMeasure}/${id}`, payload)
  }
  deleteSizeMeasure(id: number, payload: any = {}): Observable<any> {
    return this.service.delete(`${this.pathSizeMeasure}/${id}`, payload)
  }
}
