import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { Service } from './service'

@Injectable({
  providedIn: 'root',
})
export class MasterService {
  constructor(private service: Service) {}
  private pathMaster = '/v1/masters'

  getBanks(): Observable<any> {
    return this.service.get(`${this.pathMaster}/banks`)
  }
  getBankType(): Observable<any> {
    return this.service.get(`${this.pathMaster}/bank-account-types`)
  }
  getChannel(): Observable<any> {
    return this.service.get(`${this.pathMaster}/channels`)
  }
  getStatus(): Observable<any> {
    return this.service.get(`${this.pathMaster}/statuses`)
  }
  getGender(): Observable<any> {
    return this.service.get(`${this.pathMaster}/genders`)
  }
}
