<mat-dialog-content>
  <div class="modal-content">
    <div class="d-flex flex-column align-items-center">
      <img src="/assets/images/icons/icon-check-success-green.svg" width="84" />
      <div class="text-alert mt-2">
        นำสินค้าขึ้นเว็บสำเร็จ {{ formatPriceAutoDecimal(data.count) }} รายการ
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="footer-button-wrapper d-flex justify-content-center w-100">
    <button matRipple class="btn btn-primary btn-md" (click)="onClose()">ปิด</button>
  </div>
</mat-dialog-actions>
