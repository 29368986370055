<div mat-dialog-title class="dialog-title">
  <div class="dialog-title-head">ดูตัวอย่าง</div>
  <button class="btn btn-transparent btn-icon" (click)="onClose()">
    <img src="../assets/images/icons/icon-close.svg" />
  </button>
</div>

<mat-dialog-content>
  <div class="modal-content py-2">
    <div class="page-top-content">
      <div class="box-content">
        <app-top-menu theme="black"></app-top-menu>
      </div>
    </div>

    <div class="box-content">
      <div class="d-flex gap-2 bt">
        <span> Homepage </span>
        <span>|</span>
        {{ data.formValue.category_name }}
      </div>

      <div class="flex flex-column align-items-center gap-3 mb-3 text-center">
        <div class="text-center c-name mb-4 mt-4">{{ data.formValue.category_name }}</div>

        <div class="box-slide-tag d-flex align-items-center justify-content-center gap-3 mb-3">
          <button class="button-move button-prev" disabled>
            <img src="/assets/preview/icons/icon-scroll-next.svg" width="24" height="24" />
          </button>

          <div class="d-flex align-items-center gap-3 box-sl">
            @for (item of categoryTemp; track $index) {
            <button [class.active]="$index == 0" class="button-slide-tag">{{ item.name }}</button>
            }
          </div>

          <button class="button-move">
            <img src="/assets/preview/icons/icon-scroll-next.svg" width="24" height="24" />
          </button>
        </div>
      </div>

      <div class="box-product-filter mt-4">
        <div class="product-filter-body">
          <div class="box-left">
            5 Products
            <img
              src="/assets/preview/icons/icon-product-filter.svg"
              width="30"
              height="30"
              class="cursor-pointer" />
          </div>
          <div class="box-right">
            Sort By
            <div class="icon-sort cursor-pointer">
              <img src="/assets/preview/icons/icon-product-sort.svg" width="20" height="20" />
            </div>
          </div>
        </div>
      </div>

      @for (content of contents; track content;let i = $index) {
      <section>
        <div class="layout-pattern-product image-{{ content.layout_type }} pt-5">
          <div class="box-image-link">
            <div class="box-img">
              @if (content.file) { @if(content.file.mime_type.includes('image')){
              <img [src]="content.file.url" [alt]="'image' + i" />
              }@else {
              <video
                [src]="content.file.url"
                autoplay
                muted
                loop
                [controls]="false"
                playsinline
                webkit-playsinline></video>
              } }
            </div>
          </div>
          <div class="box-products">
            @for (product of content.products; track product) {
            <app-card-product [productData]="product"></app-card-product>
            }
          </div>
        </div>
      </section>
      }
    </div>

    <app-footer></app-footer>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="footer-button-wrapper d-flex justify-content-end w-100">
    <button class="btn btn-primary btn-md" matRipple (click)="onClose()">กลับ</button>
  </div>
</mat-dialog-actions>
