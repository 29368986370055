import { Component } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { MatDialog } from '@angular/material/dialog'
import { ModalLeaveComponent } from '../../components/modal-leave/modal-leave'
import { Profile, Loading, ACTION_ACCESS, APP_PERMISSION } from '../../globals'
import { imports } from '../../imports'
import { SelectComponent } from '../../components/select/select'
import { InputComponent } from '../../components/input/input'
import { InputNumberComponent } from '../../components/input-number/input-number'
import { DatePickerComponent } from '../../components/date-picker/date-picker'
import { TextareaComponent } from '../../components/text-area/text-area'
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms'
import { CustomSnackBar } from '../../components/snackbar/snackbar'
import { ExpenseService, FileService } from '../../services'
import {
  EXPENSE_ID,
  ValidateForm,
  convertDateToApi,
  formatPrice,
  setErrorForm,
} from '../../helpers'
import { InputProductComponent } from './input-product/input-product'

@Component({
  selector: 'app-record-expenses',
  standalone: true,
  imports: [
    ...imports,
    ModalLeaveComponent,
    SelectComponent,
    InputComponent,
    InputNumberComponent,
    DatePickerComponent,
    TextareaComponent,
    InputProductComponent,
  ],
  templateUrl: './record-expenses.html',
  styleUrls: ['./record-expenses.scss'],
})
export class RecordExpensesComponent {
  readonly APP_PERMISSION = APP_PERMISSION
  readonly ACTION_ACCESS = ACTION_ACCESS
  readonly formatPrice = formatPrice

  errorUpload = ''
  recordExpense = new FormControl(null)
  details = new FormArray<FormGroup>([])
  form = new FormGroup({
    details: this.details,
    date: new FormControl(null),
    remark: new FormControl(''),
    files: new FormControl([]),
  })

  list = {
    recordExpenses: [],
    expense: [],
  }

  get expense(): any {
    return this.list.expense.find((d: any) => d.expense_category_id == this.recordExpense.value)
  }

  get files() {
    return this.form.controls['files'].value || <any[]>[]
  }

  constructor(
    public route: ActivatedRoute,
    public Router: Router,
    public dialog: MatDialog,
    public profile: Profile,
    public customSnackBar: CustomSnackBar,
    public fileService: FileService,
    public loading: Loading,
    public expenseService: ExpenseService
  ) {}

  ngOnInit() {
    this.getList()
  }

  getList() {
    const payload = {
      page: 1,
      page_size: 9999,
      search: null,
      sort_by: 'asc',
      sort_name: 'id',
    }

    this.loading.start()
    this.expenseService.getExpenseCategoryList(payload).subscribe((resEGL: any) => {
      if (!resEGL.is_error) {
        this.list.recordExpenses = resEGL.data.records
        const id = resEGL.data.records[0]?.id
        // const id = EXPENSE_ID.DAM_PRODUCT as any
        if (id) {
          this.recordExpense.setValue(id)
        }
        this.getExpenseList()
      } else {
        this.customSnackBar.fail()
      }
      this.loading.stop()
    })
  }

  getExpenseList() {
    // this.loading.start()
    // this.expenseService.getExpenseList({}).subscribe((resExpenses: any) => {
    //   if (!resExpenses.is_error) {
    //     this.list.expense = resExpenses.data
    this.getExpense()
    //   } else {
    //     this.customSnackBar.fail()
    //   }
    //   this.loading.stop()
    // })
  }

  getExpense() {
    this.details.clear()
    // this.loading.start()
    // this.expenseService.getExpense(this.expense?.id).subscribe(res => {
    //   if (!res.is_error) {
    //     const expense = res.data
    //     this.form.reset({
    //       date: expense.date,
    //       remark: expense.remark,
    //       files: expense.files.map((f: any) => {
    //         return f.file
    //       }),
    //     })

    //     for (const detail of res.data.details) {
    //       this.addDetail(detail)
    //     }
    //   } else {
    this.form.reset()
    setTimeout(() => {
      this.addDetail({})
    })
    //   }
    //   this.loading.stop()
    // })
  }

  addDetail(value: any = {}) {
    const isProduct = this.recordExpense.value == EXPENSE_ID.DAM_PRODUCT

    const form = new FormGroup({
      detail: new FormControl(value),
      product_id: new FormControl(null),
      product_detail_id: new FormControl(null),
      code: isProduct
        ? new FormControl(value.code || null, [Validators.required])
        : new FormControl(value.code || '', [Validators.required, Validators.maxLength(50)]),
      name: isProduct
        ? new FormControl({ value: value.name || '', disabled: true })
        : new FormControl(value.name || '', [Validators.required, Validators.maxLength(250)]),
      price: new FormControl(value.price || null, [Validators.required]),
      qty: isProduct
        ? new FormControl({ value: value.qty || null, disabled: !value.product_id }, [
            Validators.required,
          ])
        : new FormControl(value.qty || null),
    })

    this.details.push(form)
  }

  productSelect(product: any, item: FormGroup) {
    const rawValue = item.getRawValue()
    if (product) {
      item.reset({
        detail: rawValue.detail,
        product_id: product.product_id,
        product_detail_id: product.product_detail_id,
        code: product.code || '',
        name: product.name || '',
        price: product.selling_price || null,
        qty: null,
      })
    } else {
      item.reset({
        detail: rawValue.detail,
      })
    }
  }

  setQty(control: AbstractControl, num: 1 | -1) {
    if (control.disabled) return

    let value = (control.value || 0) + num
    if (value < 0) {
      value = 0
    }
    control.setValue(value)
  }

  onConfirm(): void {
    ValidateForm(this.form)
    if (!this.form.valid) return

    const rawValue = this.form.getRawValue()
    const payload = {
      expense_category_id: this.recordExpense.value,
      expense_category_detail: '',
      date: rawValue.date ? convertDateToApi(rawValue.date) : null,
      remark: rawValue.remark,
      details: rawValue.details.map((d: any) => {
        return {
          product_id: d.product_id,
          product_detail_id: d.product_detail_id,
          code: d.code,
          name: d.name,
          price: d.price,
          qty: d.qty,
        }
      }),
      files: rawValue.files?.map((f: any) => {
        return {
          file_id: f.id,
        }
      }),
    }

    // return console.log(payload)

    // const api = this.expense
    //   ? this.expenseService.updateExpense(this.expense.id, payload)
    // : this.expenseService.addExpense(payload)
    this.loading.start()
    this.expenseService.addExpense(payload).subscribe(res => {
      if (!res.is_error) {
        this.customSnackBar.success('บันทึกข้อมูลสำเร็จ')
        this.getExpenseList()
      } else {
        if (res.errors[0]?.field) {
          setErrorForm(this.form, res.errors)
        } else {
          this.customSnackBar.failSave(res.message)
        }
      }
      this.loading.stop()
    })
  }

  uploadFile(e: any) {
    const files = e.target.files
    if (files.length) {
      for (const file of files) {
        if (file.size > 10 * Math.pow(1024, 2)) {
          this.errorUpload = 'ขนาดไฟล์เกิน 10 Mb'
          return
        }
      }

      this.loading.start()
      this.fileService.uploads(files).subscribe((res: any) => {
        if (!res.is_error) {
          const value = [...res.data, ...this.files].slice(0, 3) as never[]
          this.form.controls['files'].setValue(value)
        } else {
          this.errorUpload = 'เกิดข้อผิดพลาด กรุณาอัพโหลดไฟล์อีกครั้ง'
        }
        this.loading.stop()
      })
    }
    e.target.value = ''
  }

  delFile(i: number) {
    const value = [...this.files] as never[]
    value.splice(i, 1)
    this.form.controls['files'].setValue(value)
  }
}
