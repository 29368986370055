import { Moment } from './moment'

export const CARAT_ID = {
  1: 1,
  2: 10001,
} as const

export const checkUseCarat = (resData: any) => {
  const now = Moment().unix()
  const start = resData.order_start_date ? Moment(resData.order_start_date).unix() : null
  const end = resData.order_end_date ? Moment(resData.order_end_date).unix() : null
  const isMin = start !== null && start < now
  const isMax = end === null || end > now

  return isMin && isMax
}
