<svg
  viewBox="0 0 20 20"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  class="dot-color"
  [style]="{ width: '14px', height: '14px' }">
  <rect
    x="0.25"
    y="0.25"
    width="19.5"
    height="19.5"
    rx="9.75"
    [attr.stroke]="active ? '#020000' : 'white'"
    stroke-width="0.5" />
  <circle cx="10" cy="10" r="7.5" [attr.fill]="fill" stroke="#D4D4D4" />
</svg>
