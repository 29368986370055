import { Component, Input, Output } from '@angular/core'
import { MatFormFieldModule, MatSuffix } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatSelectModule } from '@angular/material/select'
import { APP_PERMISSION, Loading, NavMenu, Profile } from '../../globals'
import { MatRipple } from '@angular/material/core'
import { Router, RouterModule } from '@angular/router'
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu'
import { MatDialog } from '@angular/material/dialog'
import { ModalLogoutComponent } from '../modal-logout/modal-logout'
import { COMPANY_ID, COMPANY_NAME, COMPANY_NAME1, setLocalStorage } from '../../helpers'
import { Service } from '../../services/service'
import { CustomSnackBar } from '../snackbar/snackbar'
@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatSuffix,
    MatRipple,
    RouterModule,
    MatMenuModule,
    ModalLogoutComponent,
  ],
  templateUrl: './header.html',
  styleUrls: ['./header.scss'],
})
export class HeaderComponent {
  readonly APP_PERMISSION = APP_PERMISSION
  readonly COMPANY_ID = COMPANY_ID
  readonly COMPANY_NAME1 = COMPANY_NAME1 as any

  pathDefaultSetting: any

  constructor(
    public NavMenu: NavMenu,
    public router: Router,
    public profile: Profile,
    public dialog: MatDialog,
    public loading: Loading,
    public service: Service,
    public customSnackBar: CustomSnackBar
  ) {}

  ngOnInit(): void {
    this.pathDefaultSetting = this.profile.permissions.defaultSetting()
  }

  checkActive(menu: any) {
    return this.router.url.indexOf(menu) === 0
  }

  changeCompany(id: any) {
    if (this.service.setCompanyId(id)) {
      this.loading.start()
      this.service.get(`/v1/change-company`).subscribe(res => {
        if (!res.is_error) {
          // console.log('change-company')

          localStorage.clear()
          setLocalStorage('access_token', res.data.access_token)
          setLocalStorage('refresh_token', res.data.refresh_token)

          if (id == COMPANY_ID.BHB) {
            //send to extension
            window.postMessage({
              key: 'login_from_web',
              value: res.data,
            })
          }

          sessionStorage.clear()
          setLocalStorage('company-id', id)
          location.href = '/'
        } else {
          this.customSnackBar.fail()
        }
        this.loading.stop()
      })
    }
  }

  openLogout() {
    const dialogRefConfirm = this.dialog.open(ModalLogoutComponent, {
      data: {},
    })

    dialogRefConfirm.afterClosed().subscribe(result => {
      if (result) {
        this.profile.logout()
      }
    })
  }
}
