import { Component, Inject } from '@angular/core'
import { MatCheckboxModule } from '@angular/material/checkbox'
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog'
import { MatPaginatorModule } from '@angular/material/paginator'
import { imports } from '../../../imports'

@Component({
  selector: 'app-modal-invalid-proeduct-to-web',
  templateUrl: './modal-invalid-proeduct-to-web.html',
  styleUrls: ['./modal-invalid-proeduct-to-web.scss'],
  standalone: true,
  imports: [
    ...imports,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatCheckboxModule,
    MatPaginatorModule,
  ],
})
export class ModalInvalidProeductToWebComponent {
  errors: any[] = []

  constructor(
    public dialogRef: MatDialogRef<ModalInvalidProeductToWebComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.errors = this.data.errors.map((err: any) => {
      const [code, text] = err.message.split(':')
      return {
        ...err,
        code: code.trim(),
        text: text.trim(),
      }
    })
  }

  onClose(): void {
    this.dialogRef.close()
  }
}
