import { Component, Inject, Input, PLATFORM_ID } from '@angular/core'

@Component({
  selector: 'app-footer-bhb',
  templateUrl: './footer.html',
  styleUrl: './footer.scss',
  standalone: true,
  imports: [],
})
export class FooterBHBComponent {
  @Input() lang: any
  constructor() {}

  ngOnInit(): void {}
}
