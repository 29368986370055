import { Component, Inject } from '@angular/core'
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
  MatDialog,
} from '@angular/material/dialog'
import { CustomSnackBar } from '../../../../components/snackbar/snackbar'
import { MatCheckbox, MatCheckboxModule } from '@angular/material/checkbox'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { DatePickerComponent } from '../../../../components/date-picker/date-picker'
import { DatePickerRangeComponent } from '../../../../components/date-picker-range/date-picker-range'
import { InputComponent } from '../../../../components/input/input'
import { SelectComponent } from '../../../../components/select/select'
import { TextareaComponent } from '../../../../components/text-area/text-area'
import { imports } from '../../../../imports'
import {
  MARKETPLACE_SHOP,
  Moment,
  Pagination,
  ValidateForm,
  convertDateToApi,
  formatPrice,
  formatPriceAutoDecimal,
  productImage,
  setErrorForm,
} from '../../../../helpers'
import { ModalConfirmComponent } from '../../../../components/modal-confirm/modal-confirm'
import { SearchComponent } from '../../../../components/search/search'
import { CheckboxTableComponent } from '../../../../components/checkbox-table/checkbox-table.component'
import { MatPaginator } from '@angular/material/paginator'
import { Loading } from '../../../../globals'
import { MaketplaceService, ProductService } from '../../../../services'
import { InputNumberComponent } from '../../../../components/input-number/input-number'
import { Observable, forkJoin } from 'rxjs'

@Component({
  selector: 'app-modal-product',
  templateUrl: './modal-product.html',
  styleUrls: ['./modal-product.scss'],
  standalone: true,
  imports: [
    ...imports,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatCheckboxModule,
    DatePickerComponent,
    DatePickerRangeComponent,
    InputNumberComponent,
    SelectComponent,
    TextareaComponent,
    MatCheckbox,
    ModalConfirmComponent,
    SearchComponent,
    CheckboxTableComponent,
    MatPaginator,
  ],
})
export class ModalProductComponent {
  readonly formatPrice = formatPrice
  readonly formatPriceAutoDecimal = formatPriceAutoDecimal

  search = new FormControl('')
  readonly paginationProduct = new Pagination({
    sortName: 'name',
    sortBy: 'asc',
  })
  rerender = false
  pageNumber = new FormControl(1)
  productList: any[] = []
  productSelect = new Map<any, any>()

  imgDefault = '/assets/images/icons/img-default-table.svg'

  get total() {
    let amount = 0
    let quantity = 0
    let price = 0
    this.productSelect.forEach(data => {
      if (data.checked) {
        amount++
        quantity += data.quantity || 0
        price += (data.quantity || 0) * (data.priceControl.value || 0)
      }
    })
    return { amount, quantity, price }
  }

  constructor(
    public dialogRef: MatDialogRef<ModalProductComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public customSnackBar: CustomSnackBar,
    public loading: Loading,
    public productService: ProductService,
    public maketplaceService: MaketplaceService
  ) {}

  ngOnInit(): void {
    this.getProductList()
  }

  onChangePage(reset = false) {
    if (this.pageNumber.value) {
      const page = this.pageNumber.value - 1
      if (this.paginationProduct.data.pageIndex != page) {
        this.paginationProduct.data.pageIndex = page
        this.getProductList()
      }
    } else if (reset) {
      this.pageNumber.setValue(this.paginationProduct.data.pageIndex + 1)
      this.rerender = true
      setTimeout(() => {
        this.rerender = false
      })
    }
  }

  getProductList() {
    const pagination = this.paginationProduct.get()
    const payload = {
      ...pagination,
      search: this.search.value || undefined,
      filter: {
        is_actives: [true],
        is_stock: true,
      },
    }

    // console.log(payload)
    // return

    this.loading.start()
    this.productService.getProductForOrderList(payload).subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          this.productList = res.data.records.map((product: any) => {
            const key = `${product.id}-${product.product_detail_id || ''}`

            const newProduct = {
              checked: false,
              key,
              quantity: 1,
              priceControl: new FormControl(product.selling_price || null),
              stock: product.stock,
              image: productImage(product),
              product: {
                product_id: product.id,
                product_detail_id: product.product_detail_id,
                cost: product.cost_price,
                product_code: product.code,
                product_name: product.name,
                color: product.color,
                size: product.size,
                files: product.files || [],
              },
            }

            return newProduct
          })
          this.paginationProduct.setFromResponse(res.data)
        } else {
          this.customSnackBar.fail()
        }
      }
      this.loading.stop()
    })
  }

  productChecked(item: any, checked: boolean) {
    const dataSelected = this.productSelect.get(item.key)
    if (dataSelected) {
      dataSelected.checked = checked
    } else {
      item.checked = true
      this.productSelect.set(item.key, item)
    }

    if (!item.checked) {
      item.quantity = 1
    }
  }

  addQuantity(item: any) {
    item.quantity += 1
    this.productChecked(item, true)
  }

  reduceQuantity(item: any) {
    item.quantity -= 1
    this.productChecked(item, item.quantity > 0)
  }

  onClose(): void {
    this.dialogRef.close()
  }

  onConfirm(): void {
    const payload: any[] = []
    this.productSelect.forEach(data => {
      if (data.checked) {
        const quantity = data.quantity
        const cost_price = data.product.cost || 0

        const product = {
          product_id: data.product.product_id,
          product_detail_id: data.product.product_detail_id || null,
          cost_price,
          stock: quantity,
        }

        payload.push(product)
      }
    })

    // return console.log(payload)

    const api =
      this.data.shop == MARKETPLACE_SHOP.SHOPEE
        ? this.maketplaceService.addShopee(payload)
        : this.maketplaceService.addLazada(payload)
    api.subscribe(res => {
      if (!res.is_error) {
        this.dialogRef.close(true)
      } else {
        this.customSnackBar.fail()
      }
    })
  }
}
