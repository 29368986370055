<div class="setting-menu-wrapper">
  <div class="nav-profile {{ checkIsSetting() && 'nav-main' }}">
    @if (service.companyId == COMPANY_ID.BHB) {
    <img class="img-nav" alt="Logo" src="/assets/images/logos/logo-web-1.svg" height="40" />
    } @else {
    <img alt="Logo" src="/assets/images/logos/logo-web-2.svg" height="20" class="mb-2" />
    }
    <div class="profile-info">
      <div class="user-name">{{ profile.fullName }}</div>
      <div class="role-name">{{ profile.roleName }}</div>
    </div>
  </div>

  <div class="setting-menu-item-wrapper {{ checkIsSetting() && 'nav-main' }}">
    @if (profile.permissions.checkAccess([APP_PERMISSION.ORDER])) {
    <a
      matRipple
      [class.active]="checkActive('/order')"
      [routerLink]="['/order']"
      class="setting-menu-item">
      <span class="icon icon-grey material-symbols-outlined fill">list_alt</span>
      <div class="setting-menu-text">ออเดอร์</div>
    </a>
    }
    <!-- ~ -->
    @if (profile.permissions.checkAccess([APP_PERMISSION.PRODUCT])) {
    <a
      matRipple
      class="setting-menu-item"
      [class.active]="checkActive('/product')"
      [routerLink]="['/product']">
      <span class="icon icon-grey material-symbols-outlined fill">shopping_basket</span>
      <div class="setting-menu-text">สินค้า</div>
    </a>
    }
    <!-- ~ -->
    @if (profile.permissions.checkAccess([APP_PERMISSION.REPORT])) {
    <a
      matRipple
      [class.active]="checkActive('/report')"
      [routerLink]="['/report']"
      class="setting-menu-item">
      <span class="icon icon-grey material-symbols-outlined fill">assessment</span>
      <div class="setting-menu-text">รายงาน</div>
    </a>
    }
    <!-- ~ -->
    @if (profile.permissions.checkAccess([APP_PERMISSION.CUSTOMER])) {
    <a
      matRipple
      class="setting-menu-item"
      [class.active]="checkActive('/customer')"
      [routerLink]="['/customer']"
      [queryParams]="{ tab: 0 }">
      <span class="icon icon-grey material-symbols-outlined fill">assignment_ind</span>
      <div class="setting-menu-text">ผู้ติดต่อ</div>
    </a>
    }
    <!-- ~ -->
    @if (profile.permissions.checkAccess([APP_PERMISSION.EXPENSE])) {
    <a
      matRipple
      class="setting-menu-item"
      [class.active]="checkActive('/record-expenses')"
      [routerLink]="['/record-expenses']">
      <span class="icon icon-grey material-symbols-outlined fill">paid</span>
      <div class="setting-menu-text">ค่าใช้จ่าย</div>
    </a>
    }
    <!-- ~ -->
    @if (profile.permissions.checkAccess([APP_PERMISSION.HISTORY])) {
    <a
      matRipple
      class="setting-menu-item"
      [class.active]="checkActive('/history')"
      [routerLink]="['/history']">
      <span class="icon icon-grey material-symbols-outlined fill">history</span>
      <div class="setting-menu-text">ประวัติ</div>
    </a>
    }
  </div>

  <div class="setting setting-main {{ !checkIsSetting() && 'setting-main' }}">
    <div class="d-flex align-items-center">
      <span class="icon icon-grey material-symbols-outlined fill me-2">settings</span>
      <span class="setting-main-text">ตั้งค่า</span>
    </div>
  </div>

  <div class="setting-menu-item-wrapper {{ !checkIsSetting() && 'setting-main' }}">
    <!-- active-main -->
    @if (profile.permissions.checkAccess([APP_PERMISSION.COMPANY])) {
    <a
      matRipple
      [class.active]="checkActive('/setting/company')"
      [routerLink]="['/setting/company-manage/view']"
      class="setting-menu-item">
      <span class="icon icon-grey material-symbols-outlined fill">apartment</span>
      <div class="setting-menu-text">ข้อมูลบริษัท</div>
    </a>
    }
    <!-- ~ -->
    @if (profile.permissions.checkAccess([APP_PERMISSION.BANK_COMPANY])) {
    <a
      matRipple
      [class.active]="checkActive('/setting/payment-channel')"
      [routerLink]="['/setting/payment-channel']"
      class="setting-menu-item">
      <span class="icon icon-grey material-symbols-outlined fill">payments</span>
      <div class="setting-menu-text">ข้อมูลการชำระเงิน</div>
    </a>
    }
    <!-- ~ -->
    @if (profile.permissions.checkAccess([APP_PERMISSION.CURRENCY]) ) {
    <a
      matRipple
      [class.active]="checkActive('/setting/currency')"
      [routerLink]="['/setting/currency']"
      class="setting-menu-item">
      <span class="icon icon-grey material-symbols-outlined fill">currency_exchange</span>
      <div class="setting-menu-text">ตั้งค่าสกุลเงิน</div>
    </a>
    }
    <!-- ~ -->
    @if (profile.permissions.checkAccess([APP_PERMISSION.SHIPPING]) ) {
    <a
      matRipple
      [class.active]="checkActive('/setting/shipping-manage')"
      [routerLink]="['/setting/shipping-manage/view']"
      class="setting-menu-item">
      <span class="icon icon-grey material-symbols-outlined fill">local_shipping</span>
      <div class="setting-menu-text">ข้อมูลค่าขนส่ง</div>
    </a>
    }
    <!-- @if (profile.permissions.checkAccess(APP_PERMISSION.DISTRIBUTION_CHANNEL)) {
    <a matRipple class="setting-menu-item">
      <span class="icon icon-grey material-symbols-outlined fill">account_tree</span>
      <div class="setting-menu-text">ข้อมูลช่องทางการจำหน่าย</div>
    </a>
    } @if (profile.permissions.checkAccess(APP_PERMISSION.DELIVERY_CHANNEL)) {
    <a matRipple class="setting-menu-item">
      <span class="icon icon-grey material-symbols-outlined fill">local_shipping</span>
      <div class="setting-menu-text">ข้อมูลช่องทางการจัดส่ง</div>
    </a>
    } -->
    @if (profile.permissions.checkAccess([APP_PERMISSION.PROMOTION])) {
    <a
      matRipple
      class="setting-menu-item"
      [class.active]="checkActive('/setting/promotion')"
      [routerLink]="['/setting/promotion']">
      <span class="icon icon-grey material-symbols-outlined fill">percent</span>
      <div class="setting-menu-text">ข้อมูลโปรโมชั่น</div>
    </a>
    }
    <!-- ~ -->
    @if (profile.permissions.checkAccess([APP_PERMISSION.CUSTOMER])) {
    <a
      matRipple
      [class.active]="checkActive('/setting/tier')"
      [routerLink]="['/setting/tier']"
      class="setting-menu-item">
      <span class="icon icon-grey material-symbols-outlined fill">group</span>
      <div class="setting-menu-text">ข้อมูลระดับสมาชิก</div>
    </a>
    }
    <!-- @if (profile.permissions.checkAccess(APP_PERMISSION.TAG)) {
    <a matRipple class="setting-menu-item">
      <span class="icon icon-grey material-symbols-outlined fill">sell</span>
      <div class="setting-menu-text">ข้อมูลแท็ก</div>
    </a>
    }  -->
    @if (profile.permissions.checkAccess([APP_PERMISSION.CARATS])) {
    <a
      matRipple
      class="setting-menu-item"
      [class.active]="checkActive('/setting/carats')"
      [routerLink]="['/setting/carats/view']">
      <span class="icon icon-grey material-symbols-outlined fill">diamond</span>
      <div class="setting-menu-text">ตั้งค่ากะรัต</div>
    </a>
    }
    <!-- ~ -->
    @if (service.companyId == COMPANY_ID.BHB &&
    profile.permissions.checkAccess([APP_PERMISSION.PRODUCT_CATEGORY,
    APP_PERMISSION.BRAND_PRODUCT])) {
    <div
      matRipple
      class="setting-menu-item main-menu"
      [class.active-main]="checkActive('/setting/product')"
      (click)="toggleSubMenu(1)">
      <span class="icon icon-grey material-symbols-outlined fill">shopping_basket</span>
      <div class="setting-menu-text">ข้อมูลสินค้า</div>

      <div class="arrow-down-wrapper">
        <span class="icon icon-grey material-symbols-outlined fill"
          >{{ submenu[1] ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</span
        >
      </div>
    </div>
    @if(submenu[1]) {
    <div class="setting-sub-menu-wrapper">
      @if (profile.permissions.checkAccess([APP_PERMISSION.PRODUCT_CATEGORY])) {
      <a
        matRipple
        class="setting-sub-menu-item"
        [class.active]="checkActive('/setting/product-category')"
        [routerLink]="['/setting/product-category']">
        หมวดหมู่สินค้า
      </a>
      }
      <!-- ~ -->
      @if (profile.permissions.checkAccess([APP_PERMISSION.BRAND_PRODUCT])) {
      <a
        matRipple
        class="setting-sub-menu-item"
        [class.active]="checkActive('/setting/product-brand')"
        [routerLink]="['/setting/product-brand']">
        แบรนด์สินค้า
      </a>
      }
    </div>
    } }
    <!-- ~ -->
    @if (service.companyId == COMPANY_ID.PANIA &&
    profile.permissions.checkAccess([APP_PERMISSION.PRODUCT_CATEGORY])) {
    <a
      matRipple
      [class.active]="checkActive('/setting/category')"
      [routerLink]="['/setting/category']"
      class="setting-menu-item">
      <span class="icon icon-grey material-symbols-outlined fill">shopping_basket</span>
      <div class="setting-menu-text">หมวดหมู่สินค้า</div>
    </a>
    }
    <!-- ~ -->
    @if (profile.permissions.checkAccess([APP_PERMISSION.USER_MANAGEMENT,
    APP_PERMISSION.ROLE_AND_PERMISSION])) {
    <a
      matRipple
      class="setting-menu-item"
      [class.active]="checkActive('/setting/user-management')"
      [routerLink]="['/setting/user-management']"
      [queryParams]="{ tab: 0 }">
      <span class="icon icon-grey material-symbols-outlined fill">person_edit</span>
      <div class="setting-menu-text">ข้อมูลผู้ใช้งาน</div>
    </a>
    } @if (profile.permissions.checkAccess([APP_PERMISSION.MARKETPLACE]) ) {
    <a
      matRipple
      class="setting-menu-item"
      [class.active]="checkActive('/setting/marketplace')"
      [routerLink]="['/setting/marketplace']"
      [queryParams]="{ tab: 0 }">
      <span class="icon icon-grey material-symbols-outlined fill">account_tree</span>
      <div class="setting-menu-text">เชื่อมต่อ Marketplace</div>
    </a>
    } @if (profile.permissions.checkAccess([APP_PERMISSION.CMS])) {
    <div
      matRipple
      class="setting-menu-item main-menu"
      [class.active-main]="checkActive('/setting/cms')"
      (click)="toggleSubMenu(2)">
      <span class="icon icon-grey material-symbols-outlined fill">web</span>
      <div class="setting-menu-text">จัดการเนื้อหา (CMS)</div>

      <div class="arrow-down-wrapper">
        <span class="icon icon-grey material-symbols-outlined fill"
          >{{ submenu[2] ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</span
        >
      </div>
    </div>
    @if(submenu[2]) {
    <div class="setting-sub-menu-wrapper">
      <a
        matRipple
        class="setting-sub-menu-item"
        [class.active]="checkActive('/setting/cms/home')"
        [routerLink]="['/setting/cms/home/view']">
        หน้าหลัก
      </a>
      <!-- ~ -->
      @if (service.companyId == COMPANY_ID.PANIA) {
      <a
        matRipple
        class="setting-sub-menu-item"
        [class.active]="checkActive('/setting/cms/campaign')"
        [routerLink]="['/setting/cms/campaign/view']">
        แคมเปญ
      </a>
      <a
        matRipple
        class="setting-sub-menu-item"
        [class.active]="checkActive('/setting/cms/collection')"
        [routerLink]="['/setting/cms/collection/view']">
        คอลเลกชัน
      </a>
      <a
        matRipple
        class="setting-sub-menu-item"
        [class.active]="checkActive('/setting/cms/product-list')"
        [routerLink]="['/setting/cms/product-list/view']">
        หน้ารวมสินค้า
      </a>
      }
      <a
        matRipple
        class="setting-sub-menu-item"
        [class.active]="checkActive('/setting/cms/product-details')"
        [routerLink]="['/setting/cms/product-details']">
        ขนาดสินค้า
      </a>
      <a
        matRipple
        class="setting-sub-menu-item"
        [class.active]="checkActive('/setting/cms/tier')"
        [routerLink]="['/setting/cms/tier/view']">
        สิทธิประโยชน์
      </a>
      <a
        matRipple
        class="setting-sub-menu-item"
        [class.active]="checkActive('/setting/cms/about-us')"
        [routerLink]="['/setting/cms/about-us/view']">
        แบรนด์ของเรา
      </a>
      <a
        matRipple
        class="setting-sub-menu-item"
        [class.active]="checkActive('/setting/cms/policy')"
        [routerLink]="['/setting/cms/policy/view']">
        นโยบายและข้อกำหนด
      </a>
      <a
        matRipple
        class="setting-sub-menu-item"
        [class.active]="checkActive('/setting/cms/faqs')"
        [routerLink]="['/setting/cms/faqs']">
        คำถามที่พบบ่อย
      </a>
    </div>
    } }
    <!-- ~ -->
  </div>
</div>
