<div class="video-container">
  <video #myVideo [muted]="muted" [loop]="loop" [autoplay]="autoplay">
    <source [src]="videoSrc" type="video/mp4" />
  </video>

  @if (controls) {
  <!-- Custom Controls -->
  <div class="custom-controls">
    <!-- ปุ่ม Play/Pause -->
    <button (click)="togglePlayPause()">
      @if (isPlaying) {
      <img
        src="/assets/icons/icon-video-play.svg"
        alt="play pause video"
        width="24"
        height="24"
        title="play video"
      />
      }@else {
      <img
        src="/assets/icons/icon-video-stop.svg"
        alt="pause video"
        width="24"
        height="24"
        aria-hidden="true"
      />
      }
    </button>

    <div class="timeline">
      <progress
        class="progressBar"
        (click)="seekToProgress($event)"
        [value]="progress"
        [max]="100"
      ></progress>
      <input
        class="seek"
        type="range"
        min="0"
        max="100"
        step="0.1"
        (input)="seek($event)"
        [value]="progress"
      />
    </div>

    <!-- <button (click)="skip(5)">
      <img
        src="https://www.jacquemus.com/on/demandware.static/Sites-Jacquemus-Site/-/default/dw6fcd85ee/images/forward.svg"
        alt="forward video"
        title="forward video"
        width="24"
        height="24"
      />
    </button> -->

    <!-- ปุ่ม Fullscreen -->
    <!-- <button (click)="toggleFullScreen()">
      <img
        src="https://www.jacquemus.com/on/demandware.static/Sites-Jacquemus-Site/-/default/dwb17e9dd2/images/fullscreen.svg"
        alt="full screen"
        title="full screen"
        width="24"
        height="24"
      />
    </button> -->

    <!-- ปุ่มเปิด/ปิดเสียง -->
    <!-- <button (click)="toggleMute()">
      <img
        src="https://www.jacquemus.com/on/demandware.static/Sites-Jacquemus-Site/-/default/dw15243bb5/images/sound.svg"
        alt="mute video"
        title="mute video"
        width="14"
        height="14"
      />
    </button> -->
  </div>
  }
</div>
