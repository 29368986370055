<div class="page-content-inner">
  <div class="title-black mb-1">รายการสินค้า</div>

  <div class="detail-text">
    ทั้งหมด
    <span class="color--brand-blue">
      {{ formatNoDecimal(productSum.total)}} รายการ / {{ formatNoDecimal(productSum.stock)}} ชิ้น
    </span>
  </div>

  <div class="top-content-wrapper">
    <div class="top-content-left">
      <app-search-filter-report-product
        placeholder="ค้นหา"
        (onConfirm)="paginationReportProduct.reset();getReportProductList();itemChecked.clear()"></app-search-filter-report-product>
    </div>
    <div class="top-content-right mb-3">
      @if (profile.permissions.checkAccess([APP_PERMISSION.REPORT], [ACTION_ACCESS.VIEW])) {
      <button
        matRipple
        class="btn btn-outline-secondary"
        (click)="openModalDownloadFile('ดาวน์โหลดไฟล์รายการสินค้า', { message: 'คุณต้องการดาวน์โหลดไฟล์รายการสินค้าที่เลือกใช่หรือไม่' })">
        <span class="icon material-symbols-outlined fill">download_2</span>
        ส่งออกไฟล์
      </button>
      }
    </div>
  </div>

  <div class="table-responsive table-main-tabs-wrapper">
    <table class="table table-fixed-column">
      <thead>
        <tr class="tr-vertical-top">
          <th width="70">
            <app-checkbox-all-table
              [checkedValue]="itemChecked"
              [datas]="productList"
              fieldValue="id"></app-checkbox-all-table>
          </th>
          <th width="120">เลขที่ออเดอร์</th>
          <th width="120">สินค้า</th>
          <th width="120">หมวดหมู่</th>
          <th width="180">หมวดหมู่ย่อย</th>
          <th width="120">แบรนด์</th>
          <th width="100" class="text-end">จำนวน</th>
          <th width="120" class="text-end">ต้นทุนต่อหน่วย</th>
          <th width="120" class="text-end">
            <app-sort-by
              key="total_price"
              [pagination]="paginationReportProduct"
              (onChange)="getReportProductList()"
              divClass="justify-content-end">
              ยอดขาย
            </app-sort-by>
          </th>
          <th width="120" class="text-end">
            <app-sort-by
              key="margin"
              [pagination]="paginationReportProduct"
              (onChange)="getReportProductList()"
              divClass="justify-content-end">
              กำไร
            </app-sort-by>
          </th>
          <th width="140">
            <app-sort-by
              key="updated_by"
              [pagination]="paginationReportProduct"
              (onChange)="getReportProductList()">
              สร้างโดย
            </app-sort-by>
          </th>
          <th width="150">
            <app-sort-by
              key="created_by"
              [pagination]="paginationReportProduct"
              (onChange)="getReportProductList()">
              วันที่สร้างออเดอร์
            </app-sort-by>
          </th>
        </tr>
      </thead>

      <tbody>
        @for (item of productList; track item) {
        <tr class="tr-vertical-top">
          <td class="td-checkbox">
            <app-checkbox-table [checkedValue]="itemChecked" [value]="item.id"></app-checkbox-table>
          </td>
          <td>
            <a
              [href]="'/order/edit/' + item.order_id"
              target="_blank"
              class="color--brand-blue text-decoration-none">
              {{ item.order_no || '-' }}
            </a>
          </td>
          <td>
            <app-text-tooltip> {{ item.product_name || '-' }} </app-text-tooltip>
          </td>
          <td>
            <app-text-tooltip> {{ item.category_name || '-' }} </app-text-tooltip>
          </td>
          <td>
            @if (item?.sub_category_name || item?.sub_second_category_name) {
            <app-text-tooltip>
              {{ item?.sub_category_name }} {{ item?.sub_second_category_name ? ' / ' +
              item?.sub_second_category_name : ''}}
            </app-text-tooltip>
            } @else { - }
          </td>
          <td>
            <app-text-tooltip> {{ item.brand_name || '-' }} </app-text-tooltip>
          </td>
          <td class="text-end">{{ formatNoDecimal(item.quantity) }}</td>
          <td class="text-end">{{ formatPrice(item.cost_price) }}</td>
          <td class="text-end">{{ formatPrice(item.total_price) }}</td>
          <td class="text-end">{{ formatPrice(item.margin) }}</td>
          <td>{{ item.created_by?.full_name || '-' }}</td>
          <td>{{ item.created_at ? Moment(item.created_at).format('DD-MM-YYYY HH:mm') : '-' }}</td>
        </tr>
        }
      </tbody>
      @if (productList.length) {
      <tfoot>
        <tr class="tr-sum-total-reprot">
          <td colspan="6">รวม</td>
          <td class="text-end">{{ formatNoDecimal(sumField('quantity')) }}</td>
          <td class="text-end">{{ formatPrice(sumField('cost_price')) }}</td>
          <td class="text-end">{{ formatPrice(sumField('total_price')) }}</td>
          <td class="text-end">{{ formatPrice(sumField('margin')) }}</td>
          <td class="text-end"></td>
          <td class="text-end"></td>
        </tr>
      </tfoot>
      }
    </table>

    @if(!productList.length) {
    <div class="data-not-found-wrapper">ไม่พบข้อมูลรายการสินค้า</div>
    }
  </div>

  <!-- Pagination -->
  @if (productList.length) {
  <div class="pagination-wrapper">
    <mat-paginator
      [pageIndex]="paginationReportProduct.data.pageIndex"
      [length]="paginationReportProduct.data.length"
      [pageSize]="paginationReportProduct.data.pageSize"
      [pageSizeOptions]="paginationReportProduct.data.pageSizeOptions"
      aria-label="Select page"
      (page)="paginationReportProduct.setFromPaginator($event);getReportProductList()">
    </mat-paginator>
  </div>
  }
</div>
