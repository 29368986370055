import { Injectable } from '@angular/core'
import { __searchAndFitler } from './_mix'
import { FormControl, FormGroup } from '@angular/forms'
import { Moment, convertDateToApi } from '../../helpers'
import { MasterService, TransportService } from '../../services'
import { Loading } from '../loading'
import { CustomSnackBar } from '../../components/snackbar/snackbar'
import { forkJoin } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class FilterTableOrder extends __searchAndFitler {
  readonly list = {
    channel: <any[]>[],
    transport: <any[]>[],
  }

  form = new FormGroup({
    status_id: new FormControl<any>('all'),
    start_date: new FormControl(''),
    end_date: new FormControl(''),
  })

  get isFilter() {
    const data = this.getData()
    return data.filter && Object.keys(data.filter).length
  }

  get startEndDateFormat() {
    return [this.form.controls['start_date'].value, this.form.controls['end_date'].value]
      .reduce((ds: any[], d: any) => {
        if (d) {
          ds.push(Moment(d).format('DD/MM/YYYY'))
        }
        return ds
      }, [])
      .join(' - ')
  }

  constructor(
    public masterService: MasterService,
    public transportService: TransportService,
    public loading: Loading,
    public customSnackBar: CustomSnackBar
  ) {
    const filter = {
      channel_ids: [],
      transport_ids: [],
    }
    super('/order', filter)

    this._initForm(this.form)
  }

  async initData() {
    const apis = [this.masterService.getChannel(), this.transportService.getTransportList({})]

    await new Promise(rev => {
      this.loading.start()
      forkJoin(apis).subscribe(([resChannel, resTransport]: any) => {
        if (resChannel) {
          if (!resChannel.is_error) {
            this.list.channel = resChannel.data
          } else {
            this.customSnackBar.fail(resChannel.message)
          }
        }

        if (resTransport) {
          if (!resTransport.is_error) {
            this.list.transport = resTransport.data
          } else {
            this.customSnackBar.fail(resTransport.message)
          }
        }

        rev(null)

        this.loading.stop()
      })
    })
  }

  isChannelChecked(id: any) {
    return this.filter['channel_ids'].some((val: any) => val === id)
  }

  channelChange(id: any) {
    const channel_ids = this.filter['channel_ids']
    if (channel_ids.includes(id)) {
      channel_ids.splice(channel_ids.indexOf(id), 1)
    } else {
      channel_ids.push(id)
    }
  }

  removeChannel(id: any) {
    this.channelChange(id)
  }

  isTransportChecked(id: any) {
    return this.filter['transport_ids'].some((val: any) => val === id)
  }

  transportChange(id: any) {
    const transport_ids = this.filter['transport_ids']
    if (transport_ids.includes(id)) {
      transport_ids.splice(transport_ids.indexOf(id), 1)
    } else {
      transport_ids.push(id)
    }
  }

  removeTransport(id: any) {
    this.transportChange(id)
  }

  removeStartEndDate() {
    this.form.controls['start_date'].reset()
    this.form.controls['end_date'].reset()
  }

  getData() {
    const filter: any = {}
    const data: any = {
      filter,
    }

    const channel_ids = this.filter['channel_ids']
    if (channel_ids.length) {
      filter.channel_ids = channel_ids
    }

    const transport_ids = this.filter['transport_ids']
    if (transport_ids.length) {
      filter.transport_ids = transport_ids
    }

    const value = this.form.getRawValue()

    if (value.start_date) {
      filter.start_date = convertDateToApi(value.start_date)
    }
    if (value.end_date) {
      filter.end_date = convertDateToApi(value.end_date, 'day')
    }

    data.filter = filter

    const searchValue = this.search.value
    if (searchValue) {
      data.search = searchValue
    }

    return data
  }
}
