<div mat-dialog-title class="dialog-title">
  <div class="dialog-title-head">{{data.title}}</div>
  <button class="btn btn-transparent btn-icon" (click)="onClose()">
    <img src="../assets/images/icons/icon-close.svg" />
  </button>
</div>

<mat-dialog-content>
  <div class="modal-content">
    <div class="detail-wrapper">
      <div class="d-flex flex-column gap-1">
        <div class="text-bold color--neutral-950">เลขที่ออเดอร์ {{ data.item.order_no }}</div>
        <div class="d-flex align-items-center gap-2">
          <div class="color--neutral-950">ชื่อลูกค้า:</div>
          <div class="color--neutral-950">
            {{ showCustomerName(data.item.customer_name, data.item.customer_last_name) }}
          </div>
        </div>
      </div>

      @for (formG of forms.controls; track $index) {
      <div class="line"></div>

      <div class="d-flex flex-column gap-1">
        <div class="text-bold color--neutral-950">ที่อยู่จัดส่ง {{ $index + 1 }}</div>
        <div class="customer-info">
          <div class="d-flex align-items-center gap-2">
            <span class="icon icon-dark-grey material-symbols-rounded fill">local_shipping</span>
            <div class="info-text-md info-text-bold">{{ formG.value.data.name }}</div>
          </div>
          <div class="separator">|</div>
          <div class="info-text-md">{{ formG.value.data.telephone }}</div>
        </div>
        <div class="info-text-md text-pre-line">{{ formG.value.data.address }}</div>
      </div>

      <div class="container gx-0">
        <div class="row">
          <div class="col-12">
            <label
              class="formG-label"
              [class.text-danger]="formG.controls['tracking_no'].touched && formG.controls['tracking_no'].invalid">
              เลขที่พัสดุ
            </label>

            <app-input [control]="formG.controls['tracking_no']">
              @if (formG.controls['tracking_no'].hasError('maxlength')) {
              <ng-container error>กรุณากรอกข้อมูลไม่เกิน 100 ตัวอักษร</ng-container>
              }
            </app-input>
          </div>

          <div class="col-12">
            <label
              class="formG-label"
              [class.text-danger]="formG.controls['tracking_no'].touched && formG.controls['tracking_no'].invalid">
              ค่าใช้จ่าย (เช่น ค่าจัดส่งที่เราออก หรือค่ากล่องพัสดุ)
            </label>

            <app-input-number [control]="formG.controls['cost']" unit="บาท" placeholder="0.00">
              @if (formG.controls['cost'].hasError('max')) {
              <ng-container error>กรุณากรอกไม่เกิน 1,000,000,000</ng-container>
              }
            </app-input-number>
          </div>

          <div class="col-12 col-sm-6">
            <label
              class="formG-label"
              [class.text-danger]="formG.controls['date'].touched && formG.controls['date'].invalid">
              วันเวลาที่ส่งของ
            </label>

            <app-date-picker [control]="formG.controls['date']" placeholder="วว/ดด/ปปปป">
            </app-date-picker>
          </div>

          <div class="col-12 col-sm-6">
            <label
              class="formG-label"
              [class.text-danger]="formG.controls['time'].touched && formG.controls['time'].invalid">
              เวลา
            </label>

            <app-time-picker [control]="formG.controls['time']"> </app-time-picker>
          </div>
        </div>
      </div>
      }

      <!-- <div class="line"></div>

      <div class="d-flex flex-column gap-1">
        <div class="text-bold color--neutral-950">ที่อยู่จัดส่ง 2</div>
        <div class="customer-info">
          <div class="d-flex align-items-center gap-2">
            <span class="icon icon-dark-grey material-symbols-rounded fill">local_shipping</span>
            <div class="info-text-md info-text-bold">Budsaben Achirawutdecha</div>
          </div>
          <div class="separator">|</div>
          <div class="info-text-md">097-854-5456</div>
        </div>
        <div class="info-text-md">
          The room Ratchada-Ladprao 518/415 ลาดพร้าว 32 จันทรเกษม จตุจักร กทม 10900
        </div>
      </div>

      <div class="container gx-0">
        <div class="row">
          <div class="col-12">
            <label
              class="formG-label"
              [class.text-danger]="formG.controls['tracking_no'].touched && formG.controls['tracking_no'].invalid"
            >
              เลขที่พัสดุ
            </label>

            <app-input [control]="formG.controls['tracking_no']"> </app-input>
          </div>

          <div class="col-12">
            <label
              class="formG-label"
              [class.text-danger]="formG.controls['tracking_no'].touched && formG.controls['tracking_no'].invalid"
            >
              ค่าใช้จ่าย (เช่น ค่าจัดส่งที่เราออก หรือค่ากล่องพัสดุ)
            </label>

            <app-input-number [control]="formG.controls['cost']" unit="บาท" placeholder="0.00">
            </app-input-number>
          </div>

          <div class="col-12 col-sm-6">
            <label
              class="formG-label"
              [class.text-danger]="formG.controls['date'].touched && formG.controls['date'].invalid"
            >
              วันเวลาที่ส่งของ
            </label>

            <app-date-picker [control]="formG.controls['date']" placeholder="วว/ดด/ปปปป">
            </app-date-picker>
          </div>

          <div class="col-12 col-sm-6">
            <label
              class="formG-label"
              [class.text-danger]="formG.controls['time'].touched && formG.controls['time'].invalid"
            >
              เวลา
            </label>

            <app-time-picker [control]="formG.controls['time']"></app-time-picker>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="footer-button-wrapper d-flex justify-content-end w-100">
    <button matRipple class="btn btn-outline-secondary btn-md" (click)="onClose()">ยกเลิก</button>
    <button matRipple class="btn btn-primary btn-md" (click)="onConfirm()">ปิดรายการ</button>
  </div>
</mat-dialog-actions>
