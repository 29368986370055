<div mat-dialog-title class="dialog-title">
  <div class="dialog-title-head">ระดับสมาชิกของคุณ</div>
  <button class="btn btn-transparent btn-icon" (click)="onClose()">
    <img src="../assets/images/icons/icon-close.svg" />
  </button>
</div>

<mat-dialog-content>
  <div class="modal-content py-2">
    <div class="box-tier" [style]="{'background-color': color + '30'}">
      <div class="d-flex justify-content-between align-items-center">
        <span class="title-tier" [style]="{color}">{{ tier ? tier.current.name : '-' }}</span>
        <span class="icon-16 material-symbols-outlined fill title-tier" [style]="{color}">
          diamond
        </span>
      </div>

      <div class="progress mt-3">
        <div
          class="progress-bar"
          role="progressbar"
          [style]="{'width': tier?.persent + '%', 'background-color': color}"
          [attr.aria-valuenow]="tier?.persent"
          aria-valuemin="0"
          aria-valuemax="100"></div>
      </div>
      <div class="font-14 mt-2" [style]="{color}">
        สั่งซื้อเพิ่มอีก {{ tier ? formatPrice(tier.diff) : '-' }} บาท เพื่อเลื่อนเป็น {{ tier &&
        tier.next ? tier.next.name : '-' }}
      </div>
    </div>
    <div
      class="box-tier-detail"
      [style]="{'background-color': color}"
      matRipple
      (click)="openTier()">
      ดูระดับสมาชิก
      <span class="icon material-symbols-outlined fill ps-1">chevron_right</span>
    </div>

    <div class="mt-4">สิทธิพิเศษ</div>
    <div
      class="font-14"
      [innerHtml]="sanitizer.bypassSecurityTrustHtml(readHTML(tier && tier.current ? tier.current.description || '' : ''))"></div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="footer-button-wrapper d-flex justify-content-end w-100">
    <button class="btn btn-primary btn-md" matRipple (click)="onConfirm()">ปิด</button>
  </div>
</mat-dialog-actions>
