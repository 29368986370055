export const COMPANY_ID = {
  BHB: 1,
  PANIA: 2,
} as const

export const COMPANY_KEY = {
  [COMPANY_ID.BHB]: 'bhb',
  [COMPANY_ID.PANIA]: 'pania',
} as const

export type EnumCompanyId = (typeof COMPANY_ID)[keyof typeof COMPANY_ID]

export const COMPANY_NAME = {
  [COMPANY_ID.BHB]: 'Brandname Hunter',
  [COMPANY_ID.PANIA]: 'PANIA',
} as const

export const COMPANY_NAME1 = {
  [COMPANY_ID.BHB]: 'Order Management',
  [COMPANY_ID.PANIA]: 'PANIA',
} as const
