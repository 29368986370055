import { Component, ElementRef, Inject, Input, PLATFORM_ID, ViewChild } from '@angular/core'
import { RouterModule } from '@angular/router'
import { isPlatformBrowser } from '@angular/common'

@Component({
  selector: 'app-scroll-silde',
  templateUrl: './scroll-silde.html',
  styleUrl: './scroll-silde.scss',
  standalone: true,
  imports: [],
})
export class ScrollSildeComponent {
  @ViewChild('divscroll') divscroll!: ElementRef
  @ViewChild('content') content!: ElementRef

  @Input() layout: 1 | 2 = 1
  @Input() hideIconDisabled = false

  isReady = false
  contentWidth = 0
  isPrev = false
  isNext = false

  // isDesktop = false;

  constructor() {
    // this.isDesktop = this.deviceService.isDesktop();
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.setContentWidth()
    this.setScroll()

    window.addEventListener('resize', () => {
      this.setContentWidth()
      this.setScroll()
    })

    this.divscroll.nativeElement.addEventListener('scroll', () => {
      this.setContentWidth()
      this.setScroll()
    })
  }

  setContentWidth() {
    setTimeout(() => {
      this.contentWidth = this.content.nativeElement.offsetWidth
    })
  }

  setScroll() {
    setTimeout(() => {
      const scrollLeft = this.divscroll.nativeElement.scrollLeft
      this.isPrev = scrollLeft != 0
      this.isNext =
        this.divscroll.nativeElement.scrollWidth -
          Math.round(this.divscroll.nativeElement.offsetWidth + scrollLeft) >
        2
    })
  }

  onMove(on: 'prev' | 'next') {
    const scrollLeft = this.divscroll.nativeElement.scrollLeft
    const offsetWidth = this.divscroll.nativeElement.offsetWidth

    this.divscroll.nativeElement.scrollTo({
      left: on == 'prev' ? scrollLeft - offsetWidth : scrollLeft + offsetWidth,
      behavior: 'smooth',
    })
  }
}
