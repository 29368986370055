<div mat-dialog-title class="dialog-title">
  <div class="dialog-title-head">{{data.title}}</div>
  <button class="btn btn-transparent btn-icon" (click)="onClose()">
    <img src="../assets/images/icons/icon-close.svg" />
  </button>
</div>

<mat-dialog-content>
  <div class="modal-content">
    <div class="modal-title mb-3">หมวดหมู่หลัก</div>

    <div class="container px-0">
      <div class="row gx-3 gy-2">
        <div class="col-12 col-md-6">
          <label
            class="label"
            [class.text-danger]="form.controls['name'].touched && form.controls['name'].invalid">
            ชื่อหมวดหมู่สินค้าหลัก (ภาษาไทย)
            <span class="text-danger">*</span>
          </label>
          <app-input [control]="form.controls['name']">
            @if (form.controls['name'].hasError('required')) {
            <ng-container error>กรุณากรอกข้อมูล</ng-container>
            } @else if (form.controls['name'].hasError('maxlength')) {
            <ng-container error>กรุณากรอกข้อมูลไม่เกิน 100 ตัวอักษร</ng-container>
            } @else if (form.controls['name'].hasError('formApi')) {
            <ng-container error>{{ form.controls['name'].errors?.['formApi']}}</ng-container>
            }
          </app-input>
        </div>

        <div class="col-12 col-md-6">
          <label
            class="label"
            [class.text-danger]="form.controls['name_en'].touched && form.controls['name_en'].invalid">
            ชื่อหมวดหมู่สินค้าหลัก (ภาษาอังกฤษ)
            <span class="text-danger">*</span>
          </label>
          <app-input [control]="form.controls['name_en']">
            @if (form.controls['name_en'].hasError('required')) {
            <ng-container error>กรุณากรอกข้อมูล</ng-container>
            } @else if (form.controls['name_en'].hasError('maxlength')) {
            <ng-container error>กรุณากรอกข้อมูลไม่เกิน 100 ตัวอักษร</ng-container>
            } @else if (form.controls['name_en'].hasError('formApi')) {
            <ng-container error>{{ form.controls['name_en'].errors?.['formApi']}}</ng-container>
            }
          </app-input>
        </div>

        <div class="col-12 mb-2">
          <mat-checkbox
            [formControl]="form.controls.is_subcategory"
            (change)="onChangeSubcategory($event)">
            มีหมวดหมู่ย่อย
          </mat-checkbox>
        </div>

        @if (form.controls.is_subcategory.value) {
        <div class="line"></div>
        <div class="modal-title mb-3">หมวดหมู่ย่อย</div>

        @for (item of form.controls['product_sub_categories'].controls; track item; let index =
        $index) {
        <div class="d-flex gap-3">
          <div class="subcategory-number">{{index + 1}}</div>
          <div class="row gx-3 gy-2" [ngStyle]="{'max-width': '490px'}">
            <div class="col-12 col-md-6">
              <label
                class="label"
                [class.text-danger]="item.controls['name'].touched && item.controls['name'].invalid">
                ชื่อหมวดหมู่สินค้าย่อย (ภาษาไทย)
                <span class="text-danger">*</span>
              </label>
              <app-input [control]="item.controls['name']">
                @if (item.controls['name'].hasError('required')) {
                <ng-container error>กรุณากรอกข้อมูล</ng-container>
                } @else if (item.controls['name'].hasError('maxlength')) {
                <ng-container error>กรุณากรอกข้อมูลไม่เกิน 100 ตัวอักษร</ng-container>
                } @else if (item.controls['name'].hasError('formApi')) {
                <ng-container error>{{ item.controls['name'].errors?.['formApi']}}</ng-container>
                } @else if (item.controls['name'].hasError('duplicate')) {
                <ng-container error>หมวดหมู่สินค้าย่อยนี้มีในระบบแล้ว</ng-container>
                }
              </app-input>
            </div>

            <div class="col-12 col-md-6">
              <label
                class="label text-nowrap"
                [class.text-danger]="item.controls['name_en'].touched && item.controls['name_en'].invalid">
                ชื่อหมวดหมู่สินค้าย่อย (ภาษาอังกฤษ) <span class="text-danger">*</span>
              </label>
              <app-input [control]="item.controls['name_en']">
                @if (item.controls['name_en'].hasError('required')) {
                <ng-container error>กรุณากรอกข้อมูล</ng-container>
                } @else if (item.controls['name_en'].hasError('maxlength')) {
                <ng-container error>กรุณากรอกข้อมูลไม่เกิน 100 ตัวอักษร</ng-container>
                } @else if (item.controls['name_en'].hasError('formApi')) {
                <ng-container error>{{ item.controls['name_en'].errors?.['formApi']}}</ng-container>
                } @else if (item.controls['name_en'].hasError('duplicate')) {
                <ng-container error>หมวดหมู่สินค้าย่อยนี้มีในระบบแล้ว</ng-container>
                }
              </app-input>
            </div>
          </div>

          @if (product_sub_categories.value.length > 1) {
          <div class="delete-button">
            <button
              matRipple
              class="btn btn-outline-danger btn-icon border-0"
              [disabled]="!item.controls['can_delete'].value"
              (click)="delSubCategory(index, item.controls['can_delete'].value)">
              <span class="icon material-symbols-outlined fill">delete</span>
            </button>
          </div>
          }
        </div>

        @for (p of productSubSecond(index).controls; track p; let i = $index) {
        <div class="d-flex gap-3">
          <!-- <div></div> -->
          <div class="subcategory-number"></div>

          <div class="row gx-3 gy-2" [ngStyle]="{'max-width': '490px'}">
            <div class="col-12 col-md-6 d-flex flex-nowrap">
              <div class="text-nowrap pt-2 pe-2">{{index + 1}}.{{i+1}}</div>

              <app-input
                [control]="subItems(index, i, 'name')"
                placeholder="หมวดหมู่ย่อยรอง (ภาษาไทย)">
                @if (subItems(index, i, 'name').hasError('required')) {
                <ng-container error>กรุณากรอกข้อมูล</ng-container>
                } @else if (subItems(index, i, 'name').hasError('maxlength')) {
                <ng-container error>กรุณากรอกข้อมูลไม่เกิน 100 ตัวอักษร</ng-container>
                } @else if (subItems(index, i, 'name').hasError('duplicate')) {
                <ng-container error>หมวดหมู่ย่อยรองนี้มีในระบบแล้ว</ng-container>
                }
              </app-input>
            </div>

            <div class="col-12 col-md-6">
              <app-input
                [control]="subItems(index, i, 'name_en')"
                placeholder="หมวดหมู่ย่อยรอง (ภาษาอังกฤษ)">
                @if (subItems(index, i, 'name_en').hasError('required')) {
                <ng-container error>กรุณากรอกข้อมูล</ng-container>
                } @else if (subItems(index, i, 'name_en').hasError('maxlength')) {
                <ng-container error>กรุณากรอกข้อมูลไม่เกิน 100 ตัวอักษร</ng-container>
                } @else if (subItems(index, i, 'name_en').hasError('duplicate')) {
                <ng-container error>หมวดหมู่ย่อยรองนี้มีในระบบแล้ว</ng-container>
                }
              </app-input>
            </div>
          </div>

          <div class="">
            <button
              matRipple
              class="btn btn-outline-danger btn-icon border-0"
              [disabled]="!subItems(index, i, 'can_delete').value"
              (click)="delSubSecondCategory(index, i, subItems(index, i, 'can_delete').value)">
              <span class="icon material-symbols-outlined fill">delete</span>
            </button>
          </div>
        </div>
        }
        <div class="subcategory-button sub-button" (click)="addSubSecondCategory(index)">
          <span class="icon material-symbols-outlined fill me-2"> add_circle </span>
          เพิ่มหมวดหมู่ย่อยรอง
        </div>
        }

        <div class="mb-3">
          <button matRipple class="btn btn-outline-secondary w-fit" (click)="addSubCategory()">
            <span class="icon material-symbols-outlined fill"> add_circle </span>
            เพิ่มหมวดหมู่ย่อย
          </button>
        </div>
        }
      </div>
    </div>

    <div class="line"></div>

    <div class="container gx-0">
      <div class="row gy-2">
        <div class="col-12">
          <label class="label"> สถานะการใช้งาน </label>
          <div class="mt-2">
            <mat-slide-toggle
              hideIcon="true"
              color="primary"
              class="slide-toggle"
              [formControl]="form.controls.is_active">
              ใช้งาน
            </mat-slide-toggle>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="footer-button-wrapper d-flex justify-content-end w-100">
    <button matRipple class="btn btn-outline-secondary btn-md" (click)="onClose()">ยกเลิก</button>
    <button matRipple class="btn btn-primary btn-md" (click)="onConfirm()">บันทึก</button>
  </div>
</mat-dialog-actions>
