import { Component, Inject, OnInit } from '@angular/core'
import { FormControl, FormGroup, FormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatRipple } from '@angular/material/core'
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatPaginator } from '@angular/material/paginator'
import { MatRadioModule } from '@angular/material/radio'
import { Router } from '@angular/router'
import { forkJoin } from 'rxjs'
import { CustomSnackBar } from '../../components/snackbar/snackbar'
import { ACTION_ACCESS, APP_PERMISSION, Loading, Profile } from '../../globals'
import {
  COMPANY_ID,
  Pagination,
  formatPrice,
  formatPriceAutoDecimal,
  productImage,
} from '../../helpers'
import { imports } from '../../imports'
import { ProductCategoryService, ProductService } from '../../services'
import { InputNumberComponent } from '../input-number/input-number'
import { InputSearchComponent } from '../input-search/input-search'
import { SelectComponent } from '../select/select'

@Component({
  selector: 'app-modal-select-product-single',
  standalone: true,
  imports: [
    ...imports,
    MatRadioModule,
    SelectComponent,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatIconModule,
    MatRipple,
    InputSearchComponent,
    InputNumberComponent,
    MatPaginator,
  ],
  templateUrl: './modal-select-product-single.html',
  styleUrls: ['./modal-select-product-single.scss'],
})
export class ModalSelectProductSingleComponent implements OnInit {
  // @Input() form: any = new FormGroup('')
  // @Input() sectionIndex: number = 0
  // @Input() showButtonDelete: boolean = false
  // @Output() deleteSection = new EventEmitter()
  readonly APP_PERMISSION = APP_PERMISSION
  readonly ACTION_ACCESS = ACTION_ACCESS
  readonly formatPrice = formatPrice
  readonly formatPriceAutoDecimal = formatPriceAutoDecimal
  readonly pagination = new Pagination({
    sortName: 'code',
  })

  // mobile = window.matchMedia('(max-width: 1000px)')

  rerender = false
  pageNumber = new FormControl(1)

  formSearch = new FormGroup({
    search_name: new FormControl(),
    category: new FormControl(),
    sub_category: new FormControl(),
  })
  form = new FormGroup({
    key: new FormControl(),
    value: new FormControl(),
  })

  listProduct: any[] = []

  list = {
    category: <any[]>[],
  }

  get subCategories() {
    return (
      this.list.category.find((d: any) => d.id == this.formSearch.controls.category.value)
        ?.product_sub_categories || []
    )
  }

  constructor(
    public dialogRef: MatDialogRef<ModalSelectProductSingleComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public profile: Profile,
    public router: Router,
    public dialog: MatDialog,
    public customSnackBar: CustomSnackBar,
    public loading: Loading,
    public productCategoryService: ProductCategoryService,
    public productService: ProductService
  ) {}

  ngOnInit(): void {
    this.initData()
    this.getProductList()
  }

  initData() {
    const apis = [
      this.productCategoryService.getProductCategoryList({
        is_all: true,
      }),
    ]
    this.loading.start()
    forkJoin(apis).subscribe(([resProductCategoryList]) => {
      if (!resProductCategoryList.is_error) {
        this.list.category = resProductCategoryList.data
      } else {
        this.customSnackBar.fail()
      }
      this.loading.stop()
    })
  }

  changeCatecory() {
    this.pagination.reset()
    this.formSearch.controls['sub_category'].reset()
    this.getProductList()
  }

  changeSubCatecory() {
    this.getProductList()
  }

  onSearch() {
    this.pagination.reset()
    this.getProductList()
  }

  onChangePage(reset = false) {
    if (this.pageNumber.value) {
      const page = this.pageNumber.value - 1
      if (this.pagination.data.pageIndex != page) {
        this.pagination.data.pageIndex = page
        this.getProductList()
      }
    } else if (reset) {
      this.pageNumber.setValue(this.pagination.data.pageIndex + 1)
      this.rerender = true
      setTimeout(() => {
        this.rerender = false
      })
    }
  }

  getProductList() {
    const pagination = this.pagination.get()
    const foemValue = this.formSearch.getRawValue()
    const payload: any = {
      ...pagination,
      search: foemValue.search_name || undefined,
      filter: {
        is_actives: [true],
      },
    }

    if (this.profile.companyId == COMPANY_ID.BHB) {
      payload.filter.is_launchs = [true]
    }

    if (this.data.except_product_ids && this.data.except_product_ids[0]) {
      payload.filter.except_product_ids = this.data.except_product_ids
    }

    if (foemValue.category) {
      payload.filter.category_ids = [foemValue.category]
    }

    if (foemValue.sub_category) {
      payload.filter.sub_category_ids = [foemValue.sub_category]
    }

    if (this.data.is_size_guide === false) {
      payload.filter.is_size_guide = false
    }

    if (this.data.is_size_measurement === false) {
      payload.filter.is_size_measurement = false
    }

    if (this.data.color) {
      payload.color = this.data.color
    }

    // console.log(payload)
    // return

    this.loading.start()
    this.productService.getProductForOrderList(payload).subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          this.listProduct = res.data.records.map((product: any) => {
            const key = `${product.id}-${product.product_detail_id || ''}`

            const newProduct = {
              key,
              product_id: product.id,
              ...product,
              image: productImage(product),
            }

            return newProduct
          })
          this.pagination.setFromResponse(res.data)
          this.pageNumber.setValue(res.data.page)
        } else {
          this.customSnackBar.fail()
        }
      }
      this.loading.stop()
    })
  }

  onClose(): void {
    this.dialogRef.close(false)
  }

  onConfirm(): void {
    const formValue = this.form.getRawValue()
    this.dialogRef.close(formValue.value)
  }

  changeProduct(key: any, value: any) {
    this.form.setValue({
      key,
      value,
    })
  }
}
