<div class="is-sort {{ divClass }}" (click)="clickSort()">
  <ng-content></ng-content>
  <div class="icon-th-sort">
    @if (isActive('asc')) { @if(companyId == 1){
    <img src="/assets/images/icons/icon-sort-up.svg" />
    }@else {
    <img src="/assets/images/icons/icon-sort-up-gray.svg" />
    } }
    <!-- @ -->
    @if (isActive('desc')) { @if(companyId == 1){
    <img src="/assets/images/icons/icon-sort-down.svg" />
    }@else {
    <img src="/assets/images/icons/icon-sort-down-gray.svg" />
    } }
  </div>
</div>
