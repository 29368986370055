<div mat-dialog-title class="dialog-title">
  <div class="dialog-title-head">ดูตัวอย่าง</div>
  <button class="btn btn-transparent btn-icon" (click)="onClose()">
    <img src="../assets/images/icons/icon-close.svg" />
  </button>
</div>

<mat-dialog-content>
  <div class="modal-content py-2">
    <app-header-bhb [lang]="data.lang" />

    @if (policies) {
    <h1 class="text-center text-4xl-bold color--Primary-Black--f p-title my-5">
      {{ policies.is_privacy_policy ? data.lang == 'th'? 'นโยบายความเป็นส่วนตัว':'Privacy & Policy'
      : data.lang == 'th'? 'ข้อกำหนดและเงื่อนไข': 'Term & Condition' }}
    </h1>

    <div class="px-5 pb-5">
      <div
        [innerHtml]="
          sanitizer.bypassSecurityTrustHtml(readHTML(policies['detail_' + data.lang] || ''))"></div>
    </div>
    }

    <app-footer-bhb [lang]="data.lang" />
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="footer-button-wrapper d-flex justify-content-end w-100">
    <button class="btn btn-primary btn-md" matRipple (click)="onClose()">กลับ</button>
  </div>
</mat-dialog-actions>
