<div mat-dialog-title class="dialog-title">
  <div class="dialog-title-head">ดูตัวอย่าง</div>
  <button class="btn btn-transparent btn-icon" (click)="onClose()">
    <img src="../assets/images/icons/icon-close.svg" />
  </button>
</div>

<mat-dialog-content>
  <div class="modal-content py-2">
    <app-header-bhb />
    <!-- ~ -->
    @if (cms_bhb_main_details.length) {
    <div class="box-product-images">
      @for (content_detail of cms_bhb_main_details; track $index) {
      <div
        class="box-product-image"
        [class.one-image]="data.formValue.cms_bhb_main_details.length == 1"
        [class.two-image]="data.formValue.cms_bhb_main_details.length == 2">
        @if (content_detail.file?.mime_type?.includes('image')) {
        <img [src]="content_detail.file?.url" />
        } @else {
        <video
          [src]="content_detail.file?.url"
          autoplay
          muted
          loop
          [controls]="false"
          playsinline
          webkit-playsinline></video>
        }
      </div>
      }
    </div>
    }
    <!-- ~ -->

    @if (file_main_banner_file) {
    <div class="box-product-images">
      <div class="box-product-image file-wrapper-best-seller">
        @if (file_main_banner_file.mime_type.includes('image')) {
        <img [src]="file_main_banner_file.url" />
        } @else {
        <video
          [src]="file_main_banner_file.url"
          autoplay
          muted
          loop
          [controls]="false"
          playsinline
          webkit-playsinline></video>
        }
      </div>
    </div>
    }

    <div class="d-flex align-items-center justify-content-between my-4">
      <div class="text-top-monthly">Top Monthly Selling</div>
      <div class="d-flex">
        <img src="/assets/preview-bhb/temp/best-seller-slide.svg" height="32" />
      </div>
    </div>

    <div class="card-products pt-0">
      @for (productData of mainProducts; track $index) {
      <div class="padd-product">
        <app-card-product [productData]="productData"></app-card-product>
      </div>
      }
    </div>

    @if (file_sub_banner_file) {
    <div class="box-product-images">
      <div class="box-product-image file-wrapper-sub">
        @if (file_sub_banner_file.mime_type.includes('image')) {
        <img [src]="file_sub_banner_file.url" />
        } @else {
        <video
          [src]="file_sub_banner_file.url"
          autoplay
          muted
          loop
          [controls]="false"
          playsinline
          webkit-playsinline></video>
        }
      </div>
    </div>
    }
    <div class="card-products">
      @for (productData of productSeconds; track $index) {
      <div class="padd-product">
        <app-card-product-bnh [productData]="productData"></app-card-product-bnh>
      </div>
      }
    </div>

    @if (mainProductSeconds.length > indexList) {
    <div class="box-button-read-more mt-4 mb-5">
      <a pButton class="button-read-more">
        <span class="text-base-bold"> See More Products </span>
      </a>
    </div>
    }

    <app-footer-bhb />
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="footer-button-wrapper d-flex justify-content-end w-100">
    <button class="btn btn-primary btn-md" matRipple (click)="onClose()">กลับ</button>
  </div>
</mat-dialog-actions>
