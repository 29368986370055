import { Component } from '@angular/core'
import { MatTabsModule } from '@angular/material/tabs'

import { ActivatedRoute, Router } from '@angular/router'
import { MatDialog } from '@angular/material/dialog'
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatTooltipModule } from '@angular/material/tooltip'
import { ACTION_ACCESS, APP_PERMISSION, FilterReportUser, Loading, Profile } from '../../../globals'
import { SearchComponent } from '../../../components/search/search'
import { CustomSnackBar } from '../../../components/snackbar/snackbar'
import { imports } from '../../../imports'
import {
  DownloadFileBlob,
  Pagination,
  formatNoDecimal,
  formatPrice,
  formatPriceAutoDecimal,
} from '../../../helpers'
import { DatePickerRangeComponent } from '../../../components/date-picker-range/date-picker-range'
import { FormControl, FormGroup } from '@angular/forms'
import { CheckboxAllTableComponent } from '../../../components/checkbox-table/checkbox-all-table.component'
import { CheckboxTableComponent } from '../../../components/checkbox-table/checkbox-table.component'
import { SearchFilterComponent } from './search-filter/search-filter'
import { ModalDownloadFileComponent } from '../../../components/modal-download-file/modal-download-file'
import { SelectComponent } from '../../../components/select/select'
import { ReportService } from '../../../services'
import { MatSuffix } from '@angular/material/form-field'
import { TextTooltipComponent } from '../../../components/text-tooltip/text-tooltip'

@Component({
  selector: 'app-report-user',
  standalone: true,
  imports: [
    ...imports,
    MatTabsModule,
    SearchComponent,
    MatPaginatorModule,
    MatTooltipModule,
    DatePickerRangeComponent,
    CheckboxAllTableComponent,
    CheckboxTableComponent,
    SearchFilterComponent,
    SelectComponent,
    MatSuffix,
    TextTooltipComponent,
  ],
  templateUrl: './user.html',
  styleUrls: ['./user.scss'],
})
export class ReportUserComponent {
  readonly APP_PERMISSION = APP_PERMISSION
  readonly ACTION_ACCESS = ACTION_ACCESS
  readonly formatPrice = formatPrice
  readonly formatPriceAutoDecimal = formatPriceAutoDecimal
  readonly formatNoDecimal = formatNoDecimal

  readonly paginationReportUser = new Pagination({
    sortBy: 'desc',
  })

  reportList: any = []
  itemChecked = new Set<any>()

  constructor(
    public route: ActivatedRoute,
    public Router: Router,
    public dialog: MatDialog,
    public profile: Profile,
    public loading: Loading,
    public customSnackBar: CustomSnackBar,
    public reportService: ReportService,
    public filterReportUser: FilterReportUser
  ) {}

  ngOnInit(): void {
    this.getReportUserList()
  }

  getReportUserList(): void {
    const pagination = this.paginationReportUser.get()
    const dataFilter = this.filterReportUser.getData()
    const payload = {
      ...pagination,
      ...dataFilter,
    }

    this.loading.start()
    this.reportService.getReportUsersList(payload).subscribe(res => {
      if (!res.is_error) {
        this.reportList = res.data.records

        this.paginationReportUser.setFromResponse(res.data)
      } else {
        this.customSnackBar.fail()
      }
      this.loading.stop()
    })
  }

  sumField(field: string) {
    return this.reportList.reduce((total: number, product: any) => {
      return total + (product[field] || 0)
    }, 0)
  }

  openModalDownloadFile(title: string, detail: any) {
    const dialogRef = this.dialog.open(ModalDownloadFileComponent, {
      data: {
        title,
        detail,
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.downloadFile()
      }
    })

    return dialogRef
  }

  downloadFile() {
    const pagination = this.paginationReportUser.get()
    const dataFilter = this.filterReportUser.getData()
    dataFilter.filter.user_ids =
      this.itemChecked.size > 0 ? [...this.itemChecked.values()] : undefined

    const payload = {
      sort_by: pagination.sort_by,
      sort_name: pagination.sort_name,
      ...dataFilter,
    }
    this.loading.start()
    this.reportService.exportReportUsersList(payload).subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          DownloadFileBlob(res.body, `report-user.xlsx`)
          this.itemChecked.clear()
          this.customSnackBar.success('ดาวน์โหลดข้อมูลสำเร็จ')
        } else {
          this.customSnackBar.fail('ดาวน์โหลดข้อมูลไม่สำเร็จ')
        }
      }
      this.loading.stop()
    })
  }
}
