import { Component, Inject } from '@angular/core'
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog'
import { CustomSnackBar } from '../../../components/snackbar/snackbar'
import { imports } from '../../../imports'
import { MatRadioModule } from '@angular/material/radio'
import { Moment, channelIcon, channelId, formatPrice } from '../../../helpers'
import { FormControl, FormGroup } from '@angular/forms'
import { OrderService } from '../../../services'
import { Loading } from '../../../globals'
import { showCustomerName } from '../../../helpers/customer'
import { TextTooltipComponent } from '../../../components/text-tooltip/text-tooltip'

@Component({
  selector: 'app-modal-merge-order',
  templateUrl: './modal-merge-order.html',
  styleUrls: ['./modal-merge-order.scss'],
  standalone: true,
  imports: [
    ...imports,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatRadioModule,
    TextTooltipComponent,
  ],
})
export class ModalMergeOrderComponent {
  readonly Moment = Moment
  readonly channelIcon = channelIcon as any
  readonly channelId = channelId
  readonly formatPrice = formatPrice
  readonly showCustomerName = showCustomerName

  check = new FormControl(null)

  constructor(
    public dialogRef: MatDialogRef<ModalMergeOrderComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public customSnackBar: CustomSnackBar,
    public loading: Loading,
    public orderService: OrderService
  ) {}

  onClose(): void {
    this.dialogRef.close()
  }

  onConfirm(): void {
    const main_id = this.check.value
    const payload = {
      main_id,
      order_ids: this.data.orders.map((d: any) => d.id).filter((id: any) => id != main_id),
    }

    // return console.log(payload)

    this.loading.start()
    this.orderService.mergeOrder(payload).subscribe((res: any) => {
      if (!res.is_error) {
        this.customSnackBar.success('บันทึกข้อมูลสำเร็จ')
        this.dialogRef.close(true)
      } else {
        this.customSnackBar.failSave(res.message)
      }
      this.loading.stop()
    })
  }
}
