<div class="top-menu theme-{{ theme }}">
  <div class="box-left">
    <div class="currency cursor-pointer">{{lang == 'th' ? 'สกุลเงิน' : 'Currency'}}: THB | ฿</div>

    <div class="lang d-flex align-items-center gap-1 cursor-pointer">
      <img
        src="/assets/preview/icons/icon-lang-{{ theme }}.svg"
        width="14"
        height="14"
        [style]="{ 'margin-top': '-3px' }" />
      <span>{{lang == 'th' ? 'ภาษาไทย' : 'English'}}</span>
    </div>
    <div class="search d-flex align-items-center gap-1 cursor-pointer">
      <img
        src="/assets/preview/icons/icon-search-{{ theme }}.svg"
        width="19"
        height="18"
        [style]="{ 'margin-top': '-3px' }" />
      {{lang == 'th' ? 'ค้นหา' : 'Search'}}
    </div>
  </div>

  <div class="box-right">
    <div class="account cursor-pointer">{{lang == 'th' ? 'บัญชีของฉัน' : 'My Account'}}</div>
    <div class="wishlist cursor-pointer">{{lang == 'th' ? 'รายการโปรด' : 'Wish List'}}</div>
    <div class="bag cursor-pointer">
      <img src="/assets/preview/icons/icon-bag-{{ theme }}.svg" width="24" height="24" />
    </div>
  </div>
</div>

<div class="menu theme-{{ theme }}">
  <div class="box-menu d-flex flex-column align-items-center">
    <a routerLink="/" class="logo">
      <img src="/assets/preview/logos/logo-menu-{{ theme }}.svg" />
    </a>
    <div class="list-menu">
      @for (item of menus; track $index) {
      <!-- <a class="router-link">{{lang == 'th' ? 'แคมเปญ' : 'Campaign'}} </a> -->
      <!-- <a class="router-link">{{lang == 'th' ? 'สำรวจสินค้า' : 'Explore'}} </a> -->
      <!-- ~ -->
      <!-- ~ -->
      <a class="router-link"> {{ lang ? item['name_' + lang] : item.name_en }} </a>
      <!-- ~ -->
      <!-- <a class="router-link">{{lang == 'th' ? 'ลดราคา' : 'Sales'}} </a> -->
      <!-- <a class="router-link"> Member </a> -->
    }
    </div>
  </div>
</div>

<div class="top-menu-mobile">
  <div>
    <img src="/assets/preview/icons/icon-menu-{{ theme }}.svg" width="24" />
  </div>
  <div>
    <img src="/assets/preview/icons/icon-search-{{ theme }}.svg" width="24" />
  </div>
  <div class="flex-1 d-flex justify-content-center">
    <a>
      <img src="/assets/preview/logos/logo-menu-mobile-{{ theme }}.svg" height="32" />
    </a>
  </div>
  <div>
    <img src="/assets/preview/icons/icon-user-{{ theme }}.svg" width="24" />
  </div>
  <div>
    <img src="/assets/preview/icons/icon-bag-{{ theme }}.svg" width="24" />
  </div>
</div>
