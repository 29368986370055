<div class="page-content pb-0">
  <div class="breadcrumb-wrapper">
    <div class="breadcrumb-text breadcrumb-link">ตั้งค่า</div>
    <div class="breadcrumb-separator">/</div>
    <div class="breadcrumb-text">จัดการเนื้อหา (CMS)</div>
    <div class="breadcrumb-separator">/</div>
    <div class="breadcrumb-text">{{ isCreate ? 'เพิ่ม' : 'แก้ไข' }}คู่มือขนาดสินค้า</div>
  </div>

  <div class="title-wrapper">{{ isCreate ? 'เพิ่ม' : 'แก้ไข' }}คู่มือขนาดสินค้า</div>

  <div class="page-content-inner d-flex flex-column pb-0">
    <div class="title-blue">รายละเอียด</div>
    <div class="row mt-3">
      <div class="col-12">
        <label class="form-label"> ชื่อคู่มือขนาดสินค้า <span class="text-danger">*</span> </label>
        <app-input [control]="form.controls['name']" remainingText="100">
          @if (form.controls['name'].hasError('required')) {
          <ng-container error>กรุณากรอกข้อมูล</ng-container>
          } @else if (form.controls['name'].hasError('formApi')) {
          <ng-container error> {{ form.controls['name'].errors?.['formApi']}} </ng-container>
          }
        </app-input>
      </div>
      <div class="col-12">
        <label class="form-label"> หัวข้อ (ภาษาไทย) </label>
        <app-input [control]="form.controls['subject_th']" remainingText="100">
          @if (form.controls['subject_th'].hasError('required')) {
          <ng-container error>กรุณากรอกข้อมูล</ng-container>
          } @else if (form.controls['subject_th'].hasError('formApi')) {
          <ng-container error> {{ form.controls['subject_th'].errors?.['formApi']}} </ng-container>
          }
        </app-input>
      </div>
      <div class="col-12">
        <label class="form-label"> หัวข้อ (ภาษาอังกฤษ) </label>
        <app-input [control]="form.controls['subject_en']" remainingText="100">
          @if (form.controls['subject_en'].hasError('required')) {
          <ng-container error>กรุณากรอกข้อมูล</ng-container>
          } @else if (form.controls['subject_en'].hasError('formApi')) {
          <ng-container error> {{ form.controls['subject_en'].errors?.['formApi']}} </ng-container>
          }
        </app-input>
      </div>
      <div class="col-12">
        <label class="form-label"> คำอธิบายหัวข้อ (ภาษาไทย) </label>
        <app-input [control]="form.controls['description_th']" remainingText="100">
          @if (form.controls['description_th'].hasError('required')) {
          <ng-container error>กรุณากรอกข้อมูล</ng-container>
          } @else if (form.controls['description_th'].hasError('formApi')) {
          <ng-container error>
            {{ form.controls['description_th'].errors?.['formApi']}}
          </ng-container>
          }
        </app-input>
      </div>
      <div class="col-12">
        <label class="form-label"> คำอธิบายหัวข้อ (ภาษาอังกฤษ) </label>
        <app-input [control]="form.controls['description_en']" remainingText="100">
          @if (form.controls['description_en'].hasError('required')) {
          <ng-container error>กรุณากรอกข้อมูล</ng-container>
          } @else if (form.controls['description_en'].hasError('formApi')) {
          <ng-container error>
            {{ form.controls['description_en'].errors?.['formApi']}}
          </ng-container>
          }
        </app-input>
      </div>
    </div>

    <div class="title-blue mt-3">ตารางข้อมูล</div>

    <label class="form-label mt-3"> หน่วยที่ใช้ </label>
    <mat-radio-group
      [formControl]="form.controls['is_centimeter']"
      class="radio-button-wrapper d-flex align-items-center">
      <div class="me-2">
        <mat-radio-button [value]="true" [disabled]="isView">เซนติเมตร (ซม.)</mat-radio-button>
      </div>
      <div class="">
        <mat-radio-button [value]="false" [disabled]="isView">นิ้ว</mat-radio-button>
      </div>
    </mat-radio-group>

    <app-table-size
      [isCreate]="isCreate"
      [isEdit]="isEdit"
      [size_xs]="form.controls.size_xs"
      [size_ys]="form.controls.size_ys"
      [head_name]="form.controls.head_name"></app-table-size>

    <div class="title-blue mt-3">
      รายการสินค้า ({{ form.controls['product_lists'].value?.length || 0 }})
    </div>

    <div class="search-with-button-wrapper d-flex justify-content-between mt-2">
      <app-search
        [control]="search"
        placeholder="ค้นหา"
        (onEnter)="paginationManual.reset();onSearch()">
      </app-search>
      <button
        matRipple
        class="btn btn-outline-danger"
        (click)="deleteProduct(selectKeys())"
        [disabled]="itemChecked.size < 1">
        <span class="icon material-symbols-outlined fill">delete</span>
        ลบข้อมูล @if (itemChecked.size > 0) { ({{ itemChecked.size }}) }
      </button>
    </div>

    <div class="table-responsive table-main-tabs-wrapper mt-3">
      <table class="table table-fixed-column">
        <thead>
          <tr>
            <th width="50">
              <app-checkbox-all-table
                [checkedValue]="itemChecked"
                [datas]="products"
                fieldValue="key"></app-checkbox-all-table>
            </th>
            <th width="90"></th>
            <th width="320">รหัสสินค้า</th>
            <th width="350">ชื่อสินค้า</th>
            <th width="130" class="text-end">ราคา (บาท)</th>
            @if (!isView) {
            <th class="width-action fixed box-shadow-left"></th>
            }
          </tr>
        </thead>

        <tbody>
          @for (item of pageList; track $index) {
          <tr>
            <td width="50">
              <app-checkbox-table
                [checkedValue]="itemChecked"
                [value]="item.key"></app-checkbox-table>
            </td>
            <td width="90">
              <img
                [src]="item.files?.[0]?.file?.thumbnail || '/assets/images/default-product.svg'"
                width="60" />
            </td>
            <td width="320">{{ item['code'] }}</td>
            <td width="350">
              <div>{{ item.name }}</div>
              <div class="product-detail">
                @if (item.product_detail?.product_color) {
                <div
                  class="tag-color"
                  [ngStyle]="{'background-color': item.product_detail?.product_color?.color_code || '#000000' }"></div>
                }
                <div>{{ item.color }}, {{ item.size }}</div>
              </div>
            </td>
            <td width="130" class="text-end">{{ formatPrice(item.selling_price) }}</td>
            @if (!isView) {
            <td class="width-action fixed box-shadow-left">
              <div class="w-100 d-flex justify-content-center">
                <button
                  matRipple
                  class="btn btn-outline-danger btn-icon"
                  (click)="deleteProduct([item.key])">
                  <span class="icon material-symbols-outlined fill">delete</span>
                </button>
              </div>
            </td>
            }
          </tr>
          }
        </tbody>
      </table>

      @if (!form.controls['product_lists'].value?.length) {
      <div class="data-not-found-wrapper flex-column align-items-center">
        <div>ไม่พบสินค้า กรุณาเลือกสินค้า</div>
        <button class="btn btn-primary me-1 mt-4 w-fit" (click)="addProduct()">
          <span class="icon material-symbols-outlined fill"> add_circle </span>
          เลือกสินค้า
        </button>
      </div>
      }
    </div>

    @if (form.controls['product_lists'].value?.length) {
    <div class="pagination-wrapper d-flex flex-wrap gap-2">
      <mat-paginator
        [pageIndex]="paginationManual.data.pageIndex"
        [length]="paginationManual.data.length"
        [pageSize]="paginationManual.data.pageSize"
        [pageSizeOptions]="paginationManual.data.pageSizeOptions"
        (page)="paginationManual.setFromPaginator($event);pageNumber.setValue(paginationManual.data.pageIndex + 1)"
        aria-label="Select page">
      </mat-paginator>
      <div class="d-flex align-items-center gap-2 mat-mdc-paginator">
        <span>ไปที่หน้า</span>
        <div class="font-12" [style]="{ 'min-width': '70px', 'max-width': '70px' }">
          @if (!rerender) {
          <app-input-number
            [control]="pageNumber"
            placeholder="0"
            [min]="1"
            [max]="paginationManual.totalPage || 1"
            [remove_bottom]="true"
            (onEnter)="onChangePage()"
            (onblur)="onChangePage(true)">
          </app-input-number>
          }
        </div>
        / {{ formatPriceAutoDecimal(paginationManual.totalPage) }}
      </div>
    </div>

    <div>
      <button matRipple class="btn btn-primary mt-3" (click)="addProduct()">
        <span class="icon material-symbols-outlined fill">add_circle</span>
        เลือกสินค้า
      </button>
    </div>
    }

    <div class="page-footer-btn">
      <div class="d-flex flex-wrap justify-content-between gap-2">
        <div class="d-flex flex-wrap gap-2"></div>

        <div class="d-flex flex-wrap gap-2">
          <button matRipple class="btn btn-outline-secondary min-w-100px" (click)="onCancel()">
            ยกเลิก
          </button>
          @if (profile.permissions.checkAccess([APP_PERMISSION.CMS], [ACTION_ACCESS.UPDATE]) ||
          profile.permissions.checkAccess([APP_PERMISSION.CMS], [ACTION_ACCESS.CREATE])) {
          <button matRipple (click)="onSave()" class="btn btn-primary min-w-100px">บันทึก</button>
          }
        </div>
      </div>
    </div>
  </div>
</div>
