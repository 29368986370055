import { Ascending } from '../sort'

export const PRODUCT_VIEW = {
  CATEGORIES: 'categories',
  SALES: 'sales',
  FLASH_SALES: 'flash-sales',
  MEMBER: 'member',
} as const

export const imageDefault = '/assets/logos/logo-gray.svg'

export type PRODUCT_VIEW_KEY = keyof typeof PRODUCT_VIEW

export type PRODUCT_VIEW_VALUE = (typeof PRODUCT_VIEW)[PRODUCT_VIEW_KEY]

export type type__value_basket = {
  key: string
  name: any
  color: any
  size: any
  product_code: any
  product_id: any
  product_detail_id: any
  selling_price: any
  price: any
  cost: any
  remain_web: any
  isDiscountWithMember: any
  percentDiscountWithMember: any
  is_flash_sale: boolean
  is_new: any
  out_of_stock: boolean
  quantity: number
  image: any
  is_not_found: boolean
}

export type type__value_basket_key = keyof type__value_basket

export const defaultImage = '/assets/images/default-product-image.png'

export const imageSrcset = (srss: string[], defaultImg = defaultImage) => {
  return [...srss, defaultImg].filter(d => d).join(', ')
}

export const productKey = (product_id: any, product_detail_id: any) => {
  return `${product_id}-${product_detail_id || ''}`
}

export const calcMemberPrice = (item: any) => {
  const selling_price = item.selling_price || 0
  const member_price = item.member_price || 0
  let percent_discount = 0

  if (member_price > 0 && selling_price > 0) {
    percent_discount = parseFloat(
      (((selling_price - member_price) * 100) / selling_price).toFixed(2)
    )
  }

  return {
    discount_price: member_price,
    price: member_price,
    percent_discount,
  }
}

export const calcProductPrice = (item: any) => {
  // const member_price = item.member_price
  // const is_member_orice = typeof item.member_price == 'number'
  if (item.is_flash_sale && item.flash_sale) {
    return {
      discount_price: item.flash_sale.discount_campaign_price,
      price: item.flash_sale.discount_normal_price,
      percent_discount: item.flash_sale.discount_campaign_percent,
    }
  }

  if (item.is_product_discount && item.product_discount) {
    return {
      discount_price: item.product_discount.discount_price,
      price: item.product_discount.normal_discount_price,
      percent_discount: item.product_discount.percent_discount,
    }
  }

  return {
    discount_price: 0,
    price: item.selling_price || 0,
    percent_discount: 0,
  }
}

export const productImage = (product: any, product_detail?: any) => {
  const files = product_detail?.files || product.files || []
  return files.find((f: any) => f.is_cover) || files[0]
}

export const groupProducts = (products: any[]) => {
  Ascending(products, 'seq')
  return products.reduce((newProducts: any[], item: any) => {
    let product = newProducts.find(p => p.id == item.product_id)
    if (!product) {
      product = {
        ...item.product,
        product_id: item.product_id,
        details: [],
      }
      newProducts.push(product)
    }

    if (item.product_detail_id) {
      product.details.push(item.product_detail)
    }

    return newProducts
  }, [])
}

export const convertFlashSaleToProductData = (item: any) => {
  const files = (item.files || []).map((file: any) => {
    return {
      file,
      file_id: file.id,
    }
  })

  const flash_sale = {
    discount_campaign_price: item.discount_campaign_price || 0,
    discount_normal_price: item.discount_normal_price || 0,
    discount_campaign_percent: item.discount_campaign_percent || 0,
  }

  const product_discount = {
    discount_price: item.discount_price || 0,
    normal_discount_price: item.normal_discount_price || 0,
    percent_discount: item.percent_discount || 0,
  }

  const product_color_uuid = 1
  const product = {
    ...item,
    id: item.product_id,
    details: <any[]>[],
    files,
    selling_price: item.normal_price,
    is_flash_sale: flash_sale.discount_campaign_price > 0,
    flash_sale,
    is_product_discount: product_discount.discount_price > 0,
    product_discount,
    product_color: {
      color: item.color,
      color_code: item.color_code,
      // file: item.file,
      // file_id: item.file.id,
      uuid: product_color_uuid,
    },
    product_color_uuid,
  }

  if (item.product_detail_id) {
    product.details.push({
      ...product,
      id: item.product_detail_id,
    })
  }

  const productData = new ProductData(product)
  productData.activeSize(0)
  productData.showDotColor = false
  return productData
}

export class ProductData {
  readonly imageDefault = imageDefault

  isMultiSize = false
  #detailIndex = 0
  details: any[] = []
  #colorDetailIndex = 0
  #colorImageIndex = 0
  #sizeIndex = -1
  colorDetails: any[] = []
  imageCover: any
  showDotColor = false
  productCompleteLooks: (ProductData | null)[] = []
  #productMayLikeColors: (ProductData | null)[] = []
  productMayLikeStyles: (ProductData | null)[] = []

  get activeIndex() {
    return this.#detailIndex
  }

  get activeColorIndex() {
    return this.#colorDetailIndex
  }

  get activeColorImageIndex() {
    return this.#colorImageIndex
  }

  get activeSizeIndex() {
    return this.#sizeIndex
  }

  get detailMain() {
    return this.details[0]
  }

  get detail() {
    return this.details[this.#detailIndex]
  }

  get colorDetail() {
    return this.colorDetails[this.#colorDetailIndex]
  }

  get colorImage() {
    return this.colorDetail?.images?.[this.#colorImageIndex]
  }

  get sizeDetail() {
    return this.colorDetail?.details?.[this.#sizeIndex]
  }

  get sizeDetailWithDefault() {
    return this.colorDetail?.details?.[this.activeSizeIndex] || this.details[0]
  }

  get isEmpty() {
    return !this.detail
  }

  get name() {
    return this.detail?.name || ''
  }

  get code() {
    return this.detail?.code || ''
  }

  get color() {
    return this.detail?.color || ''
  }

  get size() {
    return this.detail?.size || ''
  }

  get sellingPrice() {
    return this.detail?.selling_price || 0
  }

  get barcode() {
    return this.detail?.barcode || 0
  }

  get outOfStock() {
    return (this.detail?.remain_web || 0) < 1
  }

  get default_selling_price() {
    return this.details[0]?.selling_price || 0
  }

  get color_detail_with_default() {
    return this.colorDetail?.details[0] || this.details[0]
  }

  get color_menber_default_price() {
    return this.color_detail_with_default?.useMemberPrice?.price || 0
  }

  get color_menber_default_percent_discount() {
    return this.color_detail_with_default?.useMemberPrice?.percent_discount || 0
  }

  get color_default_price() {
    return this.color_detail_with_default?.useDiscountPrice?.price || 0
  }

  get color_default_is_discount() {
    return (
      this.color_detail_with_default &&
      (this.color_detail_with_default.is_product_discount ||
        this.color_detail_with_default.is_flash_sale)
    )
  }

  get color_default_percent_discount() {
    return this.color_detail_with_default?.useDiscountPrice?.percent_discount || 0
  }

  get color_default_selling_price() {
    return this.color_detail_with_default?.selling_price || 0
  }

  get color_default_out_of_stock() {
    return (this.color_detail_with_default?.remain_web || 0) < 1
  }

  get detail_with_default() {
    return this.sizeDetail || this.color_detail_with_default
  }

  get detail_image_with_default() {
    return this.detail_with_default?.images || []
  }

  get detail_is_discount() {
    return (
      this.detail_with_default &&
      (this.detail_with_default.is_product_discount || this.detail_with_default.is_flash_sale)
    )
  }

  get detail_member_discount_price() {
    return this.detail_with_default?.useMemberPrice?.discount_price || 0
  }

  get detail_member_price() {
    return this.detail_with_default?.useMemberPrice?.price || 0
  }

  get detail_member_percent_discount() {
    return this.detail_with_default?.useMemberPrice?.percent_discount || 0
  }

  get detail_discount_price() {
    return this.detail_with_default?.useDiscountPrice?.discount_price || 0
  }

  get detail_price() {
    return this.detail_with_default?.useDiscountPrice?.price || 0
  }

  get detail_percent_discount() {
    return this.detail_with_default?.useDiscountPrice?.percent_discount || 0
  }

  get detail_selling_price() {
    return this.detail_with_default?.selling_price || 0
  }

  get detail_out_of_stock() {
    return (this.detail_with_default?.remain_web || 0) < 1
  }

  get product_colors() {
    return this.product.product_colors || []
  }

  get productMayLikeColors() {
    return this.detail_with_default?.productMayLikeColors || this.#productMayLikeColors
  }

  constructor(public product: any) {
    this.update()
  }

  update() {
    //product.details = size
    const details = Ascending(this.product.details || [], 'seq')
    this.isMultiSize = details.length > 0
    const files = this.product.files || []
    this.showDotColor = details.length > 0

    this.productCompleteLooks = this.setSlideProducts(this.product.product_complete_looks)

    this.#productMayLikeColors = this.setSlideProducts(this.product.product_may_like_colors)

    this.productMayLikeStyles = this.setSlideProducts(this.product.product_may_like_styles)

    if (details.length) {
      //all size
      this.details = details.map((product_detail: any) => {
        const productMayLikeColors = this.setSlideProducts(product_detail.product_may_like_colors)

        const product_id = product_detail.product_id
        const product_detail_id = product_detail.id

        const images = this.setImages(product_detail.files || [], product_id, product_detail_id)

        // console.log('product_detail', product_detail);
        //stock
        const remain_web = product_detail.remain_web || 0

        return {
          ...product_detail,
          remain_web,
          key: productKey(product_id, product_detail_id),
          productMayLikeColors,
          product_id,
          product_detail_id,
          is_member_price: product_detail.member_price > 0,
          useMemberPrice: calcMemberPrice(product_detail),
          useDiscountPrice: calcProductPrice(product_detail),
          image: images[0],
          images,
        }
      })
    } else {
      //no detail = no size
      const images = this.setImages(files || [], this.product.id, null)

      // console.log('this.product', this.product);
      //stock
      const remain_web = this.product.remain_web || 0

      this.details = [
        {
          ...this.product,
          remain_web,
          productMayLikeColors: this.#productMayLikeColors,
          key: productKey(this.product.id, null),
          product_id: this.product.id,
          product_detail_id: null,
          is_member_price: this.product.member_price > 0,
          useMemberPrice: calcMemberPrice(this.product),
          useDiscountPrice: calcProductPrice(this.product),
          image: images[0],
          images,
        },
      ]
      this.#sizeIndex = 0
    }

    this.colorDetails = this.details.reduce((colorDetails: any[], detail: any) => {
      const product_color_uuid = detail.product_color_uuid || ''
      let colorDetail = colorDetails.find((cd: any) => cd.product_color_uuid == product_color_uuid)

      if (!colorDetail) {
        colorDetail = {
          product_color_uuid,
          product_color: detail.product_color,
          details: [],
          images: detail.images,
        }
        colorDetails.push(colorDetail)
      }

      colorDetail.details.push(detail)

      return colorDetails
    }, [])

    this.imageCover = files.find((f: any) => f.is_cover)
  }

  setSlideProducts(datas: any[]) {
    const products: (ProductData | null)[] = groupProducts(datas || []).map((pm: any) => {
      return new ProductData(pm)
    })
    for (let index = 0; index < 4; index++) {
      if (!products[index]) {
        products[index] = null
      }
    }

    return products
  }

  setImages(images: any[], product_id: any, product_detail_id: any) {
    Ascending(images, 'file_id')
    const cover = images.find((im: any) => im.is_cover)
    if (cover) {
      images = [cover, ...images.filter((im: any) => !im.is_cover)]
    }

    if (!images.length) {
      images = [
        {
          file: {
            name: '',
            thumbnail: '',
            url: '',
          },
        },
      ]
    }

    return images.map(
      (f: any) =>
        new ProductImage(
          f.file.name || 'product image',
          f.file.thumbnail,
          f.file.url,
          product_id,
          product_detail_id,
          f.is_cover
        )
    )
  }

  activeBy(field: 'key' | 'code', value: any) {
    let colorDetailIndex = 0
    for (const colorDetail of this.colorDetails) {
      const sizeIndex = colorDetail.details.findIndex((d: any) => d[field] == value)
      if (sizeIndex > -1) {
        this.#colorDetailIndex = colorDetailIndex
        this.#sizeIndex = sizeIndex
        break
      }
      colorDetailIndex++
    }
  }

  active(index: number) {
    if (this.details[index]) {
      this.#detailIndex = index
    }
  }

  activeColor(index: number) {
    if (this.colorDetails[index]) {
      this.#colorDetailIndex = index
      this.#colorImageIndex = 0
    }
  }

  activeColorImage(index: number) {
    if (this.colorDetail.images[index]) {
      this.#colorImageIndex = index
    }
  }

  activeSize(index: number) {
    if (this.colorDetail?.details?.[index]) {
      this.#sizeIndex = index
    }
  }

  checkStockRemain(detail: any) {
    return detail.remain_web > 0
  }

  checkOutOfStock(detail: any) {
    return detail.remain_web < 1
  }

  checkColorPriceMember(isLogin: boolean) {
    return (
      isLogin &&
      this.color_menber_default_price > 0 &&
      this.color_menber_default_price < this.color_default_price
    )
  }

  colorIsDiscountWithMember(isLogin: boolean) {
    if (this.checkColorPriceMember(isLogin)) {
      return this.color_detail_with_default.is_member_price
    }
    return this.color_default_is_discount
  }

  colorPriceWithMember(isLogin: boolean) {
    if (this.checkColorPriceMember(isLogin)) {
      return this.color_menber_default_price
    }
    return this.color_default_price
  }

  colorPercentDiscountWithMember(isLogin: boolean) {
    if (this.checkPriceMember(isLogin)) {
      return this.color_menber_default_percent_discount
    }
    return this.color_default_percent_discount
  }

  checkPriceMember(isLogin: boolean) {
    return isLogin && this.detail_member_price > 0 && this.detail_member_price < this.detail_price
  }

  isDiscountWithMember(isLogin: boolean) {
    if (this.checkPriceMember(isLogin)) {
      return this.detail_with_default.is_member_price
    }
    return this.detail_is_discount
  }

  discountPriceWithMember(isLogin: boolean) {
    if (this.checkPriceMember(isLogin)) {
      return this.detail_member_discount_price
    }
    return this.detail_discount_price
  }

  priceWithMember(isLogin: boolean) {
    if (this.checkPriceMember(isLogin)) {
      return this.detail_member_price
    }
    return this.detail_price
  }

  percentDiscountWithMember(isLogin: boolean) {
    if (this.checkPriceMember(isLogin)) {
      return this.detail_member_percent_discount
    }
    return this.detail_percent_discount
  }

  getDataToBasket(profileService: any): type__value_basket {
    const remain_web = this.sizeDetail.remain_web || 0
    return {
      key: productKey(this.sizeDetail.product_id, this.sizeDetail.product_detail_id),
      name: this.sizeDetail.name,
      color: this.sizeDetail.color,
      size: this.sizeDetail.size,
      product_code: this.product.code,
      product_id: this.sizeDetail.product_id,
      product_detail_id: this.sizeDetail.product_detail_id,
      selling_price: this.sizeDetail.selling_price,
      price: this.priceWithMember(profileService.isMember),
      cost: this.sizeDetail.cost_price,
      remain_web,
      isDiscountWithMember: this.isDiscountWithMember(profileService.isMember),
      percentDiscountWithMember: this.percentDiscountWithMember(profileService.isLogin()),
      is_flash_sale: this.sizeDetail.is_flash_sale,
      is_new: this.sizeDetail.is_new,
      out_of_stock: remain_web < 1,
      quantity: 0,
      image: this.sizeDetail.image,
      is_not_found: false,
    }
  }
}

class ProductImage {
  readonly imageDefault = imageDefault
  readonly defaultImage = defaultImage

  get imageUrl() {
    return this.thumbnail || this.url
  }

  get path() {
    return this.imageUrl || this.imageDefault
  }

  srcset = ''

  constructor(
    readonly name: string,
    readonly thumbnail: string,
    readonly url: string,
    readonly product_id: any,
    readonly product_detail_id: any,
    readonly is_cover: any
  ) {
    let srcsets = []
    if (thumbnail) {
      srcsets.push(`${thumbnail} 1440w`)
    }
    if (url) {
      srcsets.push(`${url}`)
    }
    if (!srcsets.length) {
      srcsets.push(defaultImage)
    }
    this.srcset = srcsets.join(', ')
  }
}
