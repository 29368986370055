<div mat-dialog-title class="dialog-title">
  <div class="dialog-title-head">ดูตัวอย่าง</div>
  <button class="btn btn-transparent btn-icon" (click)="onClose()">
    <img src="../assets/images/icons/icon-close.svg" />
  </button>
</div>

<mat-dialog-content>
  <div class="modal-content py-2">
    <div
      class="home-top-content {{ data.formValue.is_content_white ? 'theme-white' : 'theme-black' }}">
      @if (file_banner) { @if (file_banner.mime_type.includes('image')) {
      <img [src]="file_banner.url" class="img-main-banner" />
      }@else {
      <video
        class="img-main-banner"
        [src]="file_banner.url"
        autoplay
        muted
        loop
        [controls]="false"
        playsinline
        webkit-playsinline></video>
      } }

      <div class="box-content">
        <app-top-menu [theme]="data.formValue.is_content_white ? 'white' : 'black'"></app-top-menu>
        <div class="head d-flex flex-column align-items-center">
          <h1>{{ data.formValue.content_th }}</h1>
          <span class="text-des"> {{ data.formValue.content_description_th }} </span>
          <div class="bottom px-3">
            <div class="d-flex flex-wrap justify-content-center">
              @for (item of data.formValue.cms_pania_link_menus; track $index) {
              <!-- ~ -->
              @if (item.cms_link_list_id == LINK_LIST_ID.URL) {
              <a class="text-link link-menu text-wrap text-underline" [href]="item.link_path">
                {{ item.link_name }}
              </a>
              } @else {
              <a class="text-link link-menu text-wrap text-underline"> {{ item.link_name }} </a>
              }
              <!-- ~ -->
              }
            </div>
          </div>
        </div>
      </div>
    </div>

    @for (item of contents; track $index) {
    <div class="box-product-images">
      @for (content_detail of item.cms_pania_main_content_details; track $index) {
      <div
        class="box-product-image"
        [class.one-image]="item.cms_pania_main_content_details.length == 1"
        [class.two-image]="item.cms_pania_main_content_details.length == 2">
        @if (content_detail.file?.mime_type?.includes('image')) {
        <img [src]="content_detail.file?.url" />
        } @else {
        <video
          [src]="content_detail.file?.url"
          autoplay
          muted
          loop
          [controls]="false"
          playsinline
          webkit-playsinline></video>
        }
      </div>
      }
    </div>

    <div class="card-products">
      @for (p of item.products; track $index) {
      <app-card-product [productData]="p"></app-card-product>
      }
    </div>

    @if (item.product_category_id) {
    <div class="box-button-read-more mt-4 mb-5">
      <a pButton class="button-read-more">
        <span class="text-base-bold"> ดูเพิ่มเติม </span>
      </a>
    </div>
    }
    <!-- ~ -->
    }

    <app-footer></app-footer>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="footer-button-wrapper d-flex justify-content-end w-100">
    <button class="btn btn-primary btn-md" matRipple (click)="onClose()">กลับ</button>
  </div>
</mat-dialog-actions>
