<div [matMenuTriggerFor]="menu" #trigger>
  <mat-form-field appearance="outline" class="input">
    <input matInput #input (keydown.enter)="enter($event)" (focus)="input.value = ''" />
    @if (!isOpen) {
    <div class="mask-input-tag">{{ values.length + ' แท็ก' }}</div>
    }
  </mat-form-field>
</div>

<mat-menu #menu="matMenu" yPosition="below" backdropClass="input-tag-backdrop">
  <div class="input-tag-content" (click)="$event.stopPropagation()">
    @for (item of values; track $index) {
    <div class="item-tag">
      <div class="w-100">{{ item.name }}</div>
      <div class="item-tag-close" (click)="delItem(item)">
        <img src="/assets/images/icons/icon-delete-cross.svg" />
      </div>
    </div>
    }
  </div>
</mat-menu>
