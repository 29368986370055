import { Component, EventEmitter, Injectable, Input, Output, SimpleChanges } from '@angular/core'
import { MatFormFieldModule, MatSuffix } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatSelectModule } from '@angular/material/select'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'

import { AbstractControl, FormControl } from '@angular/forms'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { Observable, map, startWith } from 'rxjs'
import { AsyncPipe } from '@angular/common'
import { LabelTierColorComponent } from '../../../components/label-tier-color/label-tier-color'
import { ProductService } from '../../../services'

@Component({
  selector: 'app-input-product',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatSuffix,
    ReactiveFormsModule,
    FormsModule,
    MatAutocompleteModule,
    AsyncPipe,
    LabelTierColorComponent,
  ],
  templateUrl: './input-product.html',
  styleUrls: ['./input-product.scss'],
})
export class InputProductComponent {
  @Input() control!: FormControl | AbstractControl
  @Input() placeholder = ''
  @Input() unit = ''
  @Input() maxlength = ''
  @Input() minlength = ''
  @Input() hintLabel = ''
  @Input() disabled = false

  @Output() onInput = new EventEmitter()
  @Output() onChange = new EventEmitter()
  @Output() onEnter = new EventEmitter()
  @Output() onSelectionChange = new EventEmitter()

  datas: any[] = []
  optionSelected: any

  constructor(public productService: ProductService) {}

  get formControl() {
    if (this.control instanceof AbstractControl) {
      return this.control as FormControl
    }
    return this.control
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['disabled']) {
      if (changes['disabled'].currentValue) {
        this.control.disable()
      } else {
        this.control.enable()
      }
    }
  }

  findProduct() {
    this.optionSelected = null
    const search = this.control.value

    if (search) {
      const payload = {
        page: 1,
        page_size: 10,
        search,
      }

      this.productService.getProductList(payload).subscribe(res => {
        if (!res.is_error) {
          this.datas = res.data.records.reduce((products: any[], p: any) => {
            products.push({
              ...p,
              product_id: p.id,
              product_detail_id: null,
            })
            for (const product_detail of p.details) {
              products.push({
                ...product_detail,
                product_id: p.id,
                product_detail_id: product_detail.id,
              })
            }

            return products
          }, [])
        }
      })
    } else {
      this.datas = []
    }
  }

  onSelectOption(e: any, option: any) {
    if (e.isUserInput) {
      this.optionSelected = option
    }
  }

  onClose() {
    setTimeout(() => {
      this.onSelectionChange.emit(this.optionSelected)
    })
  }
}
