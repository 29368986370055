import { isPlatformBrowser, isPlatformServer } from '@angular/common'
import {
  APP_ID,
  Component,
  ElementRef,
  Inject,
  Input,
  makeStateKey,
  OnInit,
  PLATFORM_ID,
  TransferState,
  ViewChild,
} from '@angular/core'

@Component({
  selector: 'app-profile-account-bhb',
  standalone: true,
  imports: [],
  templateUrl: './profile-account.html',
  styleUrl: './profile-account.scss',
})
export class ProfileAccountBHBComponent implements OnInit {
  readonly isBrowser = isPlatformBrowser(this.platformID)

  @Input() lang = 'en'

  constructor(
    @Inject(PLATFORM_ID) private platformID: Object,
    @Inject(APP_ID) private appID: string
  ) {}

  ngOnInit(): void {}
}
