import { Component, EventEmitter, Output } from '@angular/core'
import { MatTabsModule } from '@angular/material/tabs'

import { ActivatedRoute, Router } from '@angular/router'
import { MatDialog } from '@angular/material/dialog'
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatTooltipModule } from '@angular/material/tooltip'
import { Loading, Profile, FilterTableProduct } from '../../../globals'
import { SearchComponent } from '../../../components/search/search'
import { CustomSnackBar } from '../../../components/snackbar/snackbar'
import { imports } from '../../../imports'
import { ModalDownloadFileComponent } from '../../../components/modal-download-file/modal-download-file'
import { Pagination, DownloadFileBlob } from '../../../helpers'
import { ProductStockService } from '../../../services'
import { DatePickerRangeComponent } from '../../../components/date-picker-range/date-picker-range'
import { FormControl, FormGroup } from '@angular/forms'
import { CheckboxAllTableComponent } from '../../../components/checkbox-table/checkbox-all-table.component'
import { CheckboxTableComponent } from '../../../components/checkbox-table/checkbox-table.component'
import { SortByComponent } from '../../../components/sort-by/sort-by'
import { HistoryOrderListComponent } from './history-order-list/history-order-list'
import { HistoryOrderInvoiceListComponent } from './history-invoice-list/history-invoice-list'
import { HistoryOrderDeletedListComponent } from './history-deleted-list/history-deleted-list'
import { HistoryOrderCanceledListComponent } from './history-canceled-list/history-canceled-list'

@Component({
  selector: 'app-history-order',
  standalone: true,
  imports: [
    ...imports,
    MatTabsModule,
    SearchComponent,
    MatPaginatorModule,
    MatTooltipModule,
    DatePickerRangeComponent,
    CheckboxAllTableComponent,
    CheckboxTableComponent,
    SortByComponent,
    HistoryOrderListComponent,
    HistoryOrderInvoiceListComponent,
    HistoryOrderDeletedListComponent,
    HistoryOrderCanceledListComponent,
  ],
  templateUrl: './order.html',
  styleUrls: ['./order.scss'],
})
export class HistoryOrderComponent {
  tab = 0

  constructor(
    public route: ActivatedRoute,
    public Router: Router,
    public dialog: MatDialog,
    public profile: Profile,
    public loading: Loading,
    public customSnackBar: CustomSnackBar,
    public filterTableHistoryOrder: FilterTableProduct,
    public productStockService: ProductStockService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params: any) => {
      if (params.tab) {
        this.tab = params.tab
      }
    })
  }

  changeTab($tabIndex: any) {
    this.tab = $tabIndex
    this.navigateToTab(this.tab)
  }

  navigateToTab(tab: number) {
    this.Router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        tab: tab,
      },
      skipLocationChange: false,
    })
  }
}
