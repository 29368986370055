import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core'
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog'
import { MatButtonModule } from '@angular/material/button'
import { MatInputModule } from '@angular/material/input'
import { CustomSnackBar } from '../../../../components/snackbar/snackbar'
import { Loading } from '../../../../globals'
import { imports } from '../../../../imports'

@Component({
  selector: 'app-modal-subcategory',
  templateUrl: './modal-subcategory.html',
  styleUrls: ['./modal-subcategory.scss'],
  standalone: true,
  imports: [
    ...imports,
    MatInputModule,
    MatButtonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
  ],
})
export class ModalSubcategoryComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ModalSubcategoryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public customSnackBar: CustomSnackBar,
    public loading: Loading
  ) {}

  ngOnInit(): void {}

  onClose() {
    this.dialogRef.close()
  }

  onConfirm(): void {
    this.dialogRef.close(true)
  }
}
