import { Component, Inject } from '@angular/core'
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog'
import { MatButtonModule } from '@angular/material/button'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { FormsModule } from '@angular/forms'
import { imports } from '../../../imports'
import { CustomSnackBar } from '../../../components/snackbar/snackbar'
import { FilterTableProduct, FilterTableUser } from '../../../globals'
import { status_ids_list } from '../filter-data'

@Component({
  selector: 'app-modal-filter-wish-list',
  templateUrl: './modal-filter-wish-list.html',
  styleUrls: ['./modal-filter-wish-list.scss'],
  standalone: true,
  imports: [
    ...imports,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatCheckboxModule,
  ],
})
export class ModalFilterWishListComponent {
  readonly status_ids_list = status_ids_list

  constructor(
    public dialogRef: MatDialogRef<ModalFilterWishListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public customSnackBar: CustomSnackBar,
    public filterTableProduct: FilterTableProduct
  ) {
    this.filterTableProduct.initData()
  }

  onClose(): void {
    this.dialogRef.close()
  }

  onReset(): void {
    this.filterTableProduct.resetModalFilter()
  }

  onConfirm(): void {
    this.filterTableProduct.confirm()
    this.dialogRef.close(true)
  }
}
