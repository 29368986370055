import { Component, Inject } from '@angular/core'
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog'
import { MatCheckboxModule } from '@angular/material/checkbox'

import { MatPaginatorModule } from '@angular/material/paginator'
import { Loading } from '../../../../globals'
import { imports } from '../../../../imports'
import { UploadFileFlexibleComponent } from '../../../../components/upload-file-flexible/upload-file-flexible'
import { FormControl, FormGroup } from '@angular/forms'
import { UploadImageMoreComponent } from '../../../../components/upload-image-more/upload-image-more'

@Component({
  selector: 'app-modal-upload-image',
  templateUrl: './modal-upload-image.html',
  styleUrls: ['./modal-upload-image.scss'],
  standalone: true,
  imports: [
    ...imports,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatCheckboxModule,
    MatPaginatorModule,
    UploadFileFlexibleComponent,
    UploadImageMoreComponent,
  ],
})
export class ModalUploadImageComponent {
  dataManage: any
  form = new FormGroup({
    cover: new FormControl(),
    more: new FormControl<any[]>([]),
  })

  constructor(
    public dialogRef: MatDialogRef<ModalUploadImageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public loading: Loading
  ) {}

  ngOnInit(): void {
    const files = [...(this.data.files || [])]
    this.form.reset({
      cover: this.data.cover || null,
      more: files,
    })
  }

  onClose(): void {
    this.dialogRef.close()
  }

  onConfirm(): void {
    const value = this.form.getRawValue()
    this.dialogRef.close(value)
  }
}
