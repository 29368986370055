import { Injectable } from '@angular/core'
import { __searchAndFitler } from './_mix'
import { FormControl, FormGroup } from '@angular/forms'
import { Moment, ORDER_ID, convertDateToApi } from '../../helpers'
import { Loading } from '../loading'
import { CustomSnackBar } from '../../components/snackbar/snackbar'

@Injectable({ providedIn: 'root' })
export class FilterTablePrroductSeller extends __searchAndFitler {
  readonly list = {
    status_ids: <any[]>[
      { id: `${ORDER_ID.CREATE},${ORDER_ID.VERIFY}`, text: 'รอชำระเงิน' },
      { id: `${ORDER_ID.PAID}`, text: 'รอพิมพ์ที่อยู่' },
      { id: `${ORDER_ID.PRINTED}`, text: 'รอเลขพัสดุ' },
      { id: `${ORDER_ID.SHIPMENT}`, text: 'ส่งสินค้าแล้ว' },
      // { id: `${ORDER_ID.CANCEL}`, text: 'ยกเลิก' },
    ],
  }

  get isFilter() {
    const data = this.getData()
    return data.filter && Object.keys(data.filter).length
  }

  constructor(public loading: Loading, public customSnackBar: CustomSnackBar) {
    const filter = {
      status_ids: [],
    }
    super('/product/seller', filter)

    this.saveStorage = false
  }

  initData() {}

  isStatusIdChecked(id: any) {
    return this.filter['status_ids'].some((val: any) => val === id)
  }

  statusIdChange(id: any) {
    const status_ids = this.filter['status_ids']
    if (status_ids.includes(id)) {
      status_ids.splice(status_ids.indexOf(id), 1)
    } else {
      status_ids.push(id)
    }
  }

  removeStatusId(id: any) {
    this.statusIdChange(id)
  }

  getData() {
    const filter: any = {}
    const data: any = {
      filter,
    }

    const status_ids = this.filter['status_ids']
    if (status_ids.length) {
      filter.status_ids = status_ids.reduce((ids: number[], str: string) => {
        return [...ids, ...str.split(',').map((idr: string) => parseInt(idr))]
      }, [])
    }

    const searchValue = this.search.value
    if (searchValue) {
      data.search = searchValue
    }

    return data
  }
}
