<div class="tabs-content-wrapper">
  <div class="top-content-wrapper">
    <div class="top-content-left">
      <app-search-filter-history-order
        placeholder="ค้นหา"
        (onConfirm)="paginationHistory.reset();getList();itemChecked.clear()"></app-search-filter-history-order>
    </div>
    <div class="top-content-right mb-3">
      <label class="label"> วันที่ปิดออเดอร์ </label>
      <app-date-picker-range
        [controlStr]="form.controls['start_closed_date']"
        [controlEnd]="form.controls['end_closed_date']"
        [hideError]="true"
        (onChange)="paginationHistory.reset();getList();itemChecked.clear()"></app-date-picker-range>

      @if (profile.permissions.checkAccess([APP_PERMISSION.HISTORY], [ACTION_ACCESS.VIEW])) {
      <button
        matRipple
        class="btn btn-outline-secondary"
        (click)="openModalDownloadFile('ดาวน์โหลดไฟล์ออเดอร์', { message: 'คุณต้องการดาวน์โหลดไฟล์ออเดอร์ที่เลือกใช่หรือไม่' })">
        <span class="icon material-symbols-outlined fill">download_2</span>
        ส่งออกไฟล์
      </button>
      }
    </div>
  </div>

  <div class="table-responsive table-main-tabs-wrapper">
    <table class="table table-fixed-column">
      <thead>
        <tr class="tr-vertical-top">
          @if (profile.permissions.checkAccess([APP_PERMISSION.HISTORY], [ACTION_ACCESS.VIEW])) {
          <th width="70" class="colum-checkbox">
            <app-checkbox-all-table
              [checkedValue]="itemChecked"
              [datas]="historyList"
              fieldValue="id"></app-checkbox-all-table>
          </th>
          }
          <th width="160">
            <app-sort-by key="order_no" [pagination]="paginationHistory" (onChange)="getList()">
              เลขที่ออเดอร์
            </app-sort-by>
          </th>
          <th width="200">
            <app-sort-by key="customer_name" [pagination]="paginationHistory" (onChange)="getList()"
              >ชื่อลูกค้า</app-sort-by
            >
          </th>
          <th width="160">เบอร์โทรศัพท์</th>
          <th width="250">ที่อยู่จัดส่ง</th>
          <th width="160">ช่องทางโซเชียล</th>
          <th width="160">ชื่อโซเชียล</th>
          <th width="180">รหัสสินค้า</th>
          <th width="250">ชื่อสินค้า</th>
          <th width="100" class="text-center">จำนวน</th>
          <th width="160" class="text-end">ราคาต่อหน่วย (บาท)</th>
          <th width="160" class="text-end">รวม (บาท)</th>
          <th width="250">สินค้าจัดส่งหลายที่อยู่</th>
          <th width="160">การจัดส่ง</th>
          <th width="200">หมายเหตุ</th>
          <th width="160">ธนาคาร</th>
          <th width="160">เลขพัสดุ</th>
          <th width="160" class="text-end">ค่าใช้จ่ายเพิ่มเติม</th>
          <th width="160">เลขที่บน Marketplace</th>
          <th width="160" class="text-end">ราคารวมสินค้า (บาท)</th>
          <th width="160" class="text-end">ค่าจัดส่ง</th>
          <th width="160" class="text-end">ส่วนลดเพิ่มเติม (บาท)</th>
          <th width="160" class="text-end">รหัสส่วนลด (บาท)</th>
          <th width="160" class="text-end">ส่วนลดเครดิต (บาท)</th>
          <th width="160" class="text-end">ส่วนลดกะรัต (บาท)</th>
          <th width="160" class="text-end">ยอดรวมสุทธิ (บาท)</th>
          <th width="160">กะรัตที่ได้</th>
          <th width="160" class="text-end">กำไร (บาท)</th>
          <th width="160">สร้างออเดอร์โดย</th>
          <th width="160">
            <app-sort-by key="created_by" [pagination]="paginationHistory" (onChange)="getList()">
              วันที่สร้างออเดอร์
            </app-sort-by>
          </th>
          <th width="160">
            <app-sort-by key="payment_date" [pagination]="paginationHistory" (onChange)="getList()">
              วันที่ชำระเงิน
            </app-sort-by>
          </th>
          <th width="160">
            <app-sort-by key="printed_date" [pagination]="paginationHistory" (onChange)="getList()">
              วันที่พิมพ์
            </app-sort-by>
          </th>
          <th width="160">
            <app-sort-by key="closed_date" [pagination]="paginationHistory" (onChange)="getList()">
              วันที่ปิดรายการ
            </app-sort-by>
          </th>
          <th width="100" class="text-center">Logs</th>
        </tr>
      </thead>

      <tbody>
        @for (item of historyList; track item) {
        <tr class="tr-vertical-top">
          @if (profile.permissions.checkAccess([APP_PERMISSION.HISTORY], [ACTION_ACCESS.VIEW])) {
          <td class="colum-checkbox">
            <app-checkbox-table [checkedValue]="itemChecked" [value]="item.id"></app-checkbox-table>
          </td>
          }
          <td>
            <a
              [href]="'/order/edit/' + item.id"
              target="_blank"
              class="color--brand-blue text-decoration-none">
              {{ item.order_no || '-' }}
            </a>
          </td>
          <td>
            <app-text-tooltip
              >{{ showCustomerName(item.customer_name, item.customer_last_name) }}</app-text-tooltip
            >
          </td>
          <td>{{ item.telephone || '-' }}</td>
          <td>
            <app-text-tooltip
              >{{ item.is_multi_delivery ? '-' : item.main_address || '-' }}</app-text-tooltip
            >
          </td>
          <td>{{ item.channel?.name || '-' }}</td>
          <td>
            <app-text-tooltip>
              <span class="name-channel" [innerHTML]="item.channel_name || '-'"></span>
            </app-text-tooltip>
          </td>
          <td class="px-0 py-3" colspan="6">
            <table class="w-100">
              <colgroup>
                <col width="180" />
                <col width="250" />
                <col width="100" />
                <col width="160" />
                <col width="160" />
                <col width="250" />
              </colgroup>

              <tbody>
                @for (product of item.products; track $index) {
                <tr class="tr-sub-product">
                  <td>{{ product.item.code || '-' }}</td>
                  <td>{{ product.item.name || '-' }}</td>
                  <td class="text-center">{{ formatPriceAutoDecimal(product.quantity) }}</td>
                  <td class="text-end">{{ formatPrice(product.price) }}</td>
                  <td class="text-end">{{ formatPrice(product.total_price) }}</td>
                  <td class="text-pre-line">{{ product.delivery_address?.address || '-' }}</td>
                </tr>
                }
              </tbody>
            </table>
          </td>
          <td>{{ item.transport_name || '-' }}</td>
          <td>
            <app-text-tooltip>{{ item.remark || '-' }}</app-text-tooltip>
          </td>
          <td>{{ item.bank_account_name || '-' }}</td>
          <td class="px-0 py-3" colspan="2">
            <table class="w-100">
              <colgroup>
                <col width="160" />
                <col width="160" />
              </colgroup>

              <tbody>
                @for (product of item.products; track $index) {
                <tr class="tr-sub-product">
                  <td>{{ product.delivery_address?.tracking_no || '-' }}</td>
                  <td class="text-end">{{ formatPrice(product.delivery_address?.cost || 0) }}</td>
                </tr>
                }
              </tbody>
            </table>
          </td>
          <td>{{ item.market_place_id || '-' }}</td>
          <td class="text-end">{{ formatPrice(item.total_product_price) }}</td>
          <td class="text-end">{{ formatPrice(item.shipping_cost) }}</td>
          <td class="text-end">{{ formatPrice(item.discount) }}</td>
          <td class="text-end">{{ formatPrice(item.discount_code_price) }}</td>
          <td class="text-end">{{ formatPrice(item.credit_code_price) }}</td>
          <td class="text-end">{{ formatPrice(item.carat_code_price) }}</td>
          <td class="text-end">{{ formatPrice(item.total_price) }}</td>
          <td>{{ formatPriceAutoDecimal(item.received_carat) }}</td>
          <td class="text-end">{{ formatPrice(item.margin) }}</td>
          <td>{{ item.created_by?.full_name }}</td>
          <td>{{ item.created_at ? Moment(item.created_at).format('DD-MM-YYYY HH:mm') : '-' }}</td>
          <td>
            {{ item.payment_date ? Moment(item.payment_date).format('DD-MM-YYYY HH:mm') : '-' }}
          </td>
          <td>
            {{ item.printed_date ? Moment(item.printed_date).format('DD-MM-YYYY HH:mm') : '-' }}
          </td>
          <td>
            {{ item.closed_date ? Moment(item.closed_date).format('DD-MM-YYYY HH:mm') : '-' }}
          </td>
          <td>
            <div class="d-flex justify-content-center">
              <button
                matRipple
                matTooltip="ดู"
                matTooltipPosition="above"
                class="btn btn-outline-secondary btn-icon"
                (click)="openLog(item)">
                <span class="icon material-symbols-outlined fill">description</span>
              </button>
            </div>
          </td>
        </tr>
        }
      </tbody>
      @if (historyList.length) {
      <tfoot class="tfoot-fixed">
        <tr class="tr-sum-total">
          <td
            [attr.colspan]="profile.permissions.checkAccess([APP_PERMISSION.HISTORY], [ACTION_ACCESS.VIEW]) ? 2 : 1">
            รวม
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td class="text-end">{{ formatPrice(sumField('products.total_price')) }}</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td class="text-end">{{ formatPrice(sumField('products.delivery_address.cost')) }}</td>
          <td></td>
          <td class="text-end">{{ formatPrice(sumField('total_product_price')) }}</td>
          <td class="text-end">{{ formatPrice(sumField('shipping_cost')) }}</td>
          <td class="text-end">{{ formatPrice(sumField('discount')) }}</td>
          <td class="text-end">{{ formatPrice(sumField('discount_code_price')) }}</td>
          <td class="text-end">{{ formatPrice(sumField('credit_code_price')) }}</td>
          <td class="text-end">{{ formatPrice(sumField('carat_code_price')) }}</td>
          <td class="text-end">{{ formatPrice(sumField('total_price')) }}</td>
          <td class="text-end"></td>
          <td class="text-end">{{ formatPrice(sumField('margin')) }}</td>
          <td class="text-end"></td>
          <td class="text-end"></td>
          <td class="text-end"></td>
          <td class="text-end"></td>
          <td class="text-end"></td>
          <td class="text-end"></td>
        </tr>
      </tfoot>
      }
    </table>

    @if(!historyList.length) {
    <div class="data-not-found-wrapper">ไม่พบข้อมูลออเดอร์</div>
    }
  </div>

  <!-- Pagination -->
  @if (historyList.length) {
  <div class="pagination-wrapper">
    <mat-paginator
      [pageIndex]="paginationHistory.data.pageIndex"
      [length]="paginationHistory.data.length"
      [pageSize]="paginationHistory.data.pageSize"
      [pageSizeOptions]="paginationHistory.data.pageSizeOptions"
      aria-label="Select page"
      (page)="paginationHistory.setFromPaginator($event);getList()">
    </mat-paginator>
  </div>
  }
</div>
