<div mat-dialog-title class="dialog-title">
  <div class="dialog-title-head">ดูตัวอย่าง</div>
  <button class="btn btn-transparent btn-icon" (click)="onClose()">
    <img src="../assets/images/icons/icon-close.svg" />
  </button>
</div>

<mat-dialog-content>
  <div class="modal-content py-2">
    <app-header-bhb [lang]="data.lang" />

    <div>
      <h1 class="text-center text-4xl-bold color--Primary-Black--f p-title my-5">
        {{ data.lang == 'th'? 'แบรนด์ของเรา': 'Our brand' }}
      </h1>
    </div>
    <!-- ~ -->
    <div class="page-body">
      <div class="image-wrapper">
        @if (files?.mime_type?.includes('image')) {
        <img [src]="files?.url" />
        } @else {
        <video
          [src]="files?.url"
          autoplay
          muted
          loop
          [controls]="false"
          playsinline
          webkit-playsinline></video>
        }
      </div>

      <h3 class="text-center text-2xl my-4">
        {{ data?.formValue['bhb_subject_'+data.lang] }}
      </h3>

      <div class="detail-wrapper">
        <div
          [innerHtml]="
        sanitizer.bypassSecurityTrustHtml(readHTML(data.formValue['detail_' + data.lang] || ''))
        "></div>
      </div>
    </div>

    <app-footer-bhb [lang]="data.lang" />
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="footer-button-wrapper d-flex justify-content-end w-100">
    <button class="btn btn-primary btn-md" matRipple (click)="onClose()">กลับ</button>
  </div>
</mat-dialog-actions>
