<div class="page-content pb-0">
  <div class="breadcrumb-wrapper">
    <div class="breadcrumb-text breadcrumb-link">ตั้งค่า</div>
    <div class="breadcrumb-separator">/</div>
    <div class="breadcrumb-text">จัดการเนื้อหา (CMS)</div>
    <div class="breadcrumb-separator">/</div>
    <div class="breadcrumb-text">แบรนด์ของเรา</div>
  </div>

  <div class="title-wrapper">แบรนด์ของเรา</div>

  <div class="page-content-inner d-flex flex-column pb-0">
    <div class="page-body-wrapper row">
      <div class="col-12">
        @if (dataManage && profile.companyId == COMPANY_ID.BHB) {
        <div class="form-label color--neutral-500">
          วันที่อัปเดต: {{ Moment(dataManage?.updated_at).format('DD-MM-YYYY, HH:mm') }} อัปเดตโดย:
          {{ dataManage?.updated_by?.full_name || '-' }}
        </div>
        <div class="mb-3">
          <app-status [status]="dataManage?.is_public ? 'public' : 'not_public'">
            {{dataManage?.is_public ? 'เผยแพร่แล้ว' : 'ยังไม่เผยแพร่'}}
          </app-status>
        </div>
        }
      </div>
      @if (profile.companyId == COMPANY_ID.BHB) {
      <div class="col-12 mt-3">
        <label class="form-label {{ isView && 'neutral-500'}}"> หัวข้อคอนเทนต์ (ภาษาไทย) </label>
        @if(isEdit) {
        <app-input [control]="form.controls['bhb_subject_th']" remainingText="100"> </app-input>
        } @else {
        <div class="label-view">{{ form.controls['bhb_subject_th'].value || '-' }}</div>
        }
      </div>
      <div class="col-12">
        <label class="form-label {{ isView && 'neutral-500'}}"> หัวข้อคอนเทนต์ (ภาษาอังกฤษ) </label>
        @if(isEdit) {
        <app-input [control]="form.controls['bhb_subject_en']" remainingText="100"> </app-input>
        } @else {
        <div class="label-view">{{ form.controls['bhb_subject_en'].value || '-' }}</div>
        }
      </div>
      }

      <div class="col-12 {{ profile.companyId == COMPANY_ID.PANIA ? 'col-sm-4' : 'col-md-9 mt-4' }}">
        @if (profile.companyId == COMPANY_ID.PANIA) {
        <div class="title-blue mb-3">
          ภาพแบรนด์ @if(isEdit){<span class="color--red-600">*</span>}
        </div>
        } @else {
        <label class="form-label">
          ภาพแบนเนอร์ @if (isEdit) {<span class="text-danger">*</span>}
        </label>
        } 
        @if (profile.companyId == COMPANY_ID.PANIA) {
          @if (isEdit) {
            <div class="font-14 color--neutral-500 mb-1">
              (ขนาดรูปภาพ : 720px x 830px / ไม่เกิน 5 Mb)
            </div>
          }
        } @else {
        <div class="font-14 color--neutral-500 mb-1">
          (ขนาดรูปภาพ : 1440px x 1024px / ไม่เกิน 5 Mb) | (ขนาดวีดิโอ : 1920px x 1080px / ไม่เกิน 30
          Mb)
        </div>
        }
        <div class="upload-file-wrapper{{profile.companyId == COMPANY_ID.BHB ? '-bhb' : ''}} my-2">
          <app-upload-file-flexible
            [type]="isEdit ? 'edit' : 'view'"
            [imgOnly]="profile.companyId == COMPANY_ID.PANIA"
            [maxImageSize]="5"
            [maxVideoSize]="30"
            [isRequired]="form.controls['file'].touched && form.controls['file'].invalid"
            [logoDefault]="dataManage?.file_main_banner_file?.url"
            (upload)="form.controls['file'].setValue($event); isErrorFile = false" />
        </div>
      </div>
      <div class="col-12 {{ profile.companyId == COMPANY_ID.PANIA ? 'col-sm-8' : '' }} ">
        <div class="tabs-modal-wrapper {{ profile.companyId == COMPANY_ID.PANIA ? 'box' : '' }}">
          @if (dataManage && profile.companyId == COMPANY_ID.PANIA) {
          <div class="form-label color--neutral-500">
            วันที่อัปเดต: {{ Moment(dataManage?.updated_at).format('DD-MM-YYYY, HH:mm') }}
            อัปเดตโดย: {{ dataManage?.updated_by?.full_name || '-' }}
          </div>
          <div class="mb-3">
            <app-status [status]="dataManage?.is_public ? 'public' : 'not_public'">
              {{dataManage?.is_public ? 'เผยแพร่แล้ว' : 'ยังไม่เผยแพร่'}}
            </app-status>
          </div>
          }
          <mat-tab-group
            animationDuration="0ms"
            [selectedIndex]="tab"
            (selectedIndexChange)="changeTab($event)">
            <mat-tab label="ภาษาไทย">
              <div class="tabs-content-wrapper">
                <label class="label-view">เนื้อหาคอนเทนต์</label>
                @if (isEdit) {
                <app-text-editor
                  [control]="form.controls['detail_th']"
                  [toolbarImg]="true"
                  [toolbarControl]="[
                [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
                ['bold', 'italic', 'underline'],
                ['bullet_list', 'ordered_list'],
                ['align_left', 'align_center', 'align_right'],
              ]"></app-text-editor>
                } @else {
                <div class="box-view">
                  @if (form.value.detail_th) {
                  <div
                    [innerHtml]="sanitizer.bypassSecurityTrustHtml(readHTML(form.value.detail_th))"></div>
                  } @else { - }
                </div>
                }
              </div>
            </mat-tab>
            <mat-tab label="ภาษาอังกฤษ">
              <div class="tabs-content-wrapper">
                <label class="label-view">เนื้อหาคอนเทนต์</label>
                @if (isEdit) {
                <app-text-editor
                  [control]="form.controls['detail_en']"
                  [toolbarImg]="true"
                  [toolbarControl]="[
                  [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
                  ['bold', 'italic', 'underline'],
                  ['bullet_list', 'ordered_list'],
                  ['align_left', 'align_center', 'align_right'],
                ]"></app-text-editor>
                } @else {
                <div class="box-view">
                  @if (form.value.detail_en) {
                  <div
                    [innerHtml]="sanitizer.bypassSecurityTrustHtml(readHTML(form.value.detail_en))"></div>
                  } @else { - }
                </div>
                }
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </div>

    <div class="page-footer-btn">
      @if (isView) { @if (profile.permissions.checkAccess([APP_PERMISSION.CMS],
      [ACTION_ACCESS.UPDATE])) {
      <div class="d-flex justify-content-end">
        <button
          matRipple
          class="btn btn-outline-secondary min-w-165px"
          [routerLink]="['/setting/cms/about-us/edit']">
          แก้ไข
        </button>
      </div>
      } } @else if(isEdit) {
      <div class="footer-btn d-flex flex-wrap justify-content-between">
        <div class="footer-btn-left d-flex flex-wrap">
          <button matRipple class="btn btn-outline-secondary min-w-165px" (click)="onCancel()">
            ยกเลิก
          </button>
        </div>

        <div class="footer-btn-right d-flex flex-wrap">
          <button matRipple class="btn btn-outline-secondary min-w-165px" (click)="openPreview()">
            ดูตัวอย่าง
          </button>
          @if (profile.permissions.checkAccess([APP_PERMISSION.CMS], [ACTION_ACCESS.UPDATE]) ||
          profile.permissions.checkAccess([APP_PERMISSION.CMS], [ACTION_ACCESS.CREATE])) {
          <button
            matRipple
            class="btn btn-outline-secondary min-w-165px"
            (click)="onSave('private')">
            บันทึกร่าง
          </button>
          <button matRipple class="btn btn-primary min-w-165px" (click)="onSave('public')">
            เผยแพร่ทันที
          </button>
          }
        </div>
      </div>
      }
    </div>
  </div>
</div>
