import { Component, Inject } from '@angular/core'
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog'
import { MatCheckboxModule } from '@angular/material/checkbox'

import { MatPaginatorModule } from '@angular/material/paginator'
import { Loading } from '../../../../globals'
import { imports } from '../../../../imports'
import { UploadFileFlexibleComponent } from '../../../../components/upload-file-flexible/upload-file-flexible'
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms'
import { UploadImageMoreComponent } from '../../../../components/upload-image-more/upload-image-more'
import { UploadImageTableComponent } from '../../../../components/upload-image-table/upload-image-table'
import { COMPANY_ID, ValidateArrayForm, ValidateForm } from '../../../../helpers'
import { Service } from '../../../../services/service'

@Component({
  selector: 'app-modal-color-product',
  templateUrl: './modal-color-product.html',
  styleUrls: ['./modal-color-product.scss'],
  standalone: true,
  imports: [
    ...imports,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatCheckboxModule,
    MatPaginatorModule,
    UploadFileFlexibleComponent,
    UploadImageMoreComponent,
    UploadImageTableComponent,
  ],
})
export class ModalColorProductComponent {
  readonly COMPANY_ID = COMPANY_ID
  form = new FormArray([
    new FormGroup({
      id: new FormControl(),
      color: new FormControl('', [
        Validators.required,
        Validators.maxLength(15),
        this.validateName(),
      ]),
      color_code: new FormControl('#000000', [Validators.required]),
      file: new FormControl<any>(null, [
        (control: AbstractControl) => {
          if (this.service.companyId == COMPANY_ID.PANIA && !control.value) {
            return {
              required: true,
            }
          }
          return null
        },
      ]),
      uuid: new FormControl(crypto.randomUUID()),
    }),
  ])

  constructor(
    public dialogRef: MatDialogRef<ModalColorProductComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public service: Service,
    public loading: Loading
  ) {}

  ngOnInit(): void {
    if (this.data.product_colors.length) {
      this.form.clear()
      for (const product_color of this.data.product_colors) {
        this.addColor(product_color)
      }
    }
  }

  onClose(): void {
    this.dialogRef.close()
  }

  onConfirm(): void {
    ValidateArrayForm(this.form)
    if (this.form.invalid) return

    this.dialogRef.close(this.form.getRawValue())
  }

  validateName() {
    return (control: AbstractControl) => {
      const parent = control.parent
      if (
        parent &&
        this.form.value.some(v => {
          return v.color == control.value && v.uuid != parent.value.uuid
        })
      ) {
        return {
          duplicate: true,
        }
      }

      return null
    }
  }

  addColor(value: any) {
    let newData = new FormGroup({
      id: new FormControl(value?.id),
      color: new FormControl(value?.color || '', [Validators.required, this.validateName()]),
      color_code: new FormControl(value?.color_code || '#000000', [Validators.required]),
      file: new FormControl(
        value?.file,
        this.service.companyId == COMPANY_ID.PANIA ? [Validators.required] : null
      ),
      uuid: new FormControl(value?.uuid || crypto.randomUUID()),
    })
    this.form.push(newData)
  }

  delColor(index: number) {
    this.form.removeAt(index)
  }
}
