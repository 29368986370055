import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  model,
} from '@angular/core'
import { AbstractControl, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatInputModule } from '@angular/material/input'
import { MatSelect, MatSelectModule } from '@angular/material/select'
import { MatFormFieldModule, MatSuffix } from '@angular/material/form-field'
import { CommonModule } from '@angular/common'
import { MatRippleModule } from '@angular/material/core'

@Component({
  selector: 'app-select-sub-category[datas][controlSubCategory][controlSubSubCategory]',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    FormsModule,
    MatSuffix,
    MatSelectModule,
    MatInputModule,
    MatRippleModule,
  ],
  templateUrl: './select-sub-category.html',
  styleUrls: ['./select-sub-category.scss'],
})
export class SelectSubCategoryComponent implements OnInit {
  @ContentChild(TemplateRef) optionRef: any
  @Input() controlSubCategory!: FormControl | AbstractControl
  @Input() controlSubSubCategory!: FormControl | AbstractControl
  @Input() placeholder = ''
  @Input() label = ''
  @Input() datas!: any[]
  @Input() disabled = false
  @Input() multiple = false
  @Input() type = ''
  @Input() remove_bottom = false
  readonly searchText = model('')
  @Input() search: string[] = []
  @Input() empty = 'ไม่พบข้อมูล'
  @Input() disabledNotAllOption = false

  @Output() selectionChange = new EventEmitter()
  @Output() onSelectionChange = new EventEmitter()
  // @Input('eng')
  // eng?: TemplateRef<any>;
  @ViewChild('select') select: any

  optionNone = {
    id: '',
    name: 'ไม่ระบุ',
  }
  selectSubValue: any

  get formControlCategory() {
    if (this.controlSubCategory instanceof AbstractControl) {
      return this.controlSubCategory as FormControl
    }
    return this.controlSubCategory
  }

  get subCategory() {
    if (this.controlSubCategory.value === '') {
      return this.optionNone
    }
    return this.datas.find((c: any) => c.id == this.controlSubCategory.value)
  }

  get subDatas() {
    if (this.subCategory) {
      return this.subCategory.sub_categories || []
    }
    return []
  }

  get subSubCategory() {
    return this.subDatas.find((c: any) => c.id == this.controlSubSubCategory.value)
  }

  get subTempCategory() {
    return this.datas.find((c: any) => c.id == this.selectSubValue)
  }

  get subTempDatas() {
    if (this.subTempCategory) {
      return this.subTempCategory.sub_categories || []
    }
    return []
  }

  ngOnInit(): void {
    this.controlSubCategory.valueChanges.subscribe(value => {
      this.checkIsSubSub(value)
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['disabled']) {
      if (changes['disabled'].currentValue) {
        this.controlSubCategory.disable()
        this.controlSubSubCategory.disable()
      } else {
        this.controlSubCategory.enable()
        this.controlSubSubCategory.enable()
      }
    }
    if (changes['datas']) {
      this.checkIsSubSub()
    }
  }

  isNoSearch(option: any) {
    if (!this.search.length) return false
    if (!this.searchText) return false
    const searchText = this.searchText().toLowerCase()
    return !this.search.some(
      (field: string) =>
        typeof option[field] == 'string' && option[field].toLowerCase().includes(searchText)
    )
  }

  handleSelectItem(option: any, select: MatSelect) {
    this.checkIsSubSub(option.id)
    if (!this.selectSubValue) {
      this.controlSubCategory.setValue(option.id)
      this.controlSubSubCategory.setValue(null)
      select.close()
    }
  }

  handleSelectSubItem(option: any, select: MatSelect) {
    this.controlSubCategory.setValue(this.subTempCategory.id)
    this.controlSubSubCategory.setValue(option.id)
    select.close()
  }

  isSubSub(value = this.controlSubCategory.value) {
    return this.datas.some(d => d.id == value && (d.sub_categories || []).length > 0)
  }

  checkIsSubSub(value = this.controlSubCategory.value) {
    this.selectSubValue = this.isSubSub(value) ? value : null
  }

  handleClose() {
    if (this.isSubSub() && !this.controlSubSubCategory.value) {
      this.controlSubCategory.setValue(null)
    }
    this.checkIsSubSub()
  }

  closeSubSelect() {
    this.selectSubValue = false
  }

  isSubActive(option: any) {
    return option.id == this.controlSubCategory.value
  }

  isSubSubActive(option: any) {
    return option.id == this.controlSubSubCategory.value
  }
}
