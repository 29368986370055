import { Component, Inject, Injectable } from '@angular/core'
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog'
import { MatButtonModule } from '@angular/material/button'
import { FormsModule } from '@angular/forms'
import { MatInputModule } from '@angular/material/input'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatTabsModule } from '@angular/material/tabs'
import { MatIconModule } from '@angular/material/icon'
import { MatRipple } from '@angular/material/core'
import { HeaderBHBComponent } from '../../../../components/preview-bhb/header/header'
import { FooterBHBComponent } from '../../../../components/preview-bhb/footer/footer'
import { Loading } from '../../../../globals'
import { FileService } from '../../../../services'
import { CustomSnackBar } from '../../../../components/snackbar/snackbar'
import { DomSanitizer } from '@angular/platform-browser'
import { readHTML } from '../../../../helpers'

@Component({
  selector: 'app-modal-preview-bhb',
  templateUrl: './modal-preview.html',
  styleUrls: ['./modal-preview.scss'],
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatTabsModule,
    MatIconModule,
    MatRipple,
    HeaderBHBComponent,
    FooterBHBComponent,
  ],
})
export class ModalPreviewAbountUsBHBComponent {
  readonly readHTML = readHTML
  files: any

  constructor(
    public dialogRef: MatDialogRef<ModalPreviewAbountUsBHBComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public fileService: FileService,
    public loading: Loading,
    public customSnackBar: CustomSnackBar,
    public sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    console.log(this.data)
    this.setData()
    document.addEventListener('fullscreenchange', () => {
      document.exitFullscreen()
    })
  }

  getFile(id: any, call: Function) {
    this.loading.start()
    this.fileService.getFile(id).subscribe(res => {
      if (!res.is_error) {
        call(res)
      } else {
        this.customSnackBar.fail()
      }
      this.loading.stop()
    })
  }

  setData() {
    this.getFile(this.data.formValue.file, (res: any) => {
      this.files = res.data
    })
  }

  onClose(): void {
    this.dialogRef.close()
  }
}
