import { Component } from '@angular/core'
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms'
import { MatCheckbox } from '@angular/material/checkbox'
import { MatDialog } from '@angular/material/dialog'
import { ActivatedRoute, Router } from '@angular/router'
import { forkJoin, of, Subscription } from 'rxjs'
import { InputNumberComponent } from '../../../components/input-number/input-number'
import { InputComponent } from '../../../components/input/input'
import { ModalErrorComponent } from '../../../components/modal-error/modal-error'
import { ModalLeaveComponent } from '../../../components/modal-leave/modal-leave'
import { SelectComponent } from '../../../components/select/select'
import { CustomSnackBar } from '../../../components/snackbar/snackbar'
import { TextareaComponent } from '../../../components/text-area/text-area'
import { Loading, Profile } from '../../../globals'
import {
  ORDER_ID,
  PAYMENT_TYPE,
  TRANSPORT_ID,
  ValidateForm,
  channelIcon,
  channelId,
  checkUseCarat,
  formatPrice,
  formatPriceAutoDecimal,
  productImage,
  productKey,
  setErrorForm,
  validateField,
} from '../../../helpers'
import { checkPomotionExpired } from '../../../helpers/promotion'
import { SHIPPING_TYPE } from '../../../helpers/shipping'
import { imports } from '../../../imports'
import {
  CaratService,
  CompanyProfileService,
  CustomerService,
  MasterService,
  OrderService,
  ProductService,
  PromotionService,
  PublicService,
  TierService,
  TransportService,
} from '../../../services'
import { ShippingService } from '../../../services/shipping.service'
import { InputCustomerComponent } from '../input-customer/input-customer'
import { ModalAddressComponent } from './modal-address/modal-address'
import { ModalProductComponent } from './modal-product/modal-product'

@Component({
  selector: 'app-order-detail',
  standalone: true,
  imports: [
    ...imports,
    SelectComponent,
    ReactiveFormsModule,
    InputComponent,
    TextareaComponent,
    MatCheckbox,
    InputNumberComponent,
    InputCustomerComponent,
  ],
  templateUrl: './order-detail.html',
  styleUrls: ['./order-detail.scss'],
})
export class OrderDetailComponent {
  readonly channelIcon = channelIcon as any
  readonly formatPrice = formatPrice
  readonly formatPriceAutoDecimal = formatPriceAutoDecimal
  readonly TRANSPORT_ID = TRANSPORT_ID
  readonly channelId = channelId
  readonly productImage = productImage
  readonly ORDER_ID = ORDER_ID

  isEdit: boolean = false
  isCreate: boolean = false
  isView: boolean = false
  errorProductEmpty = false

  imgDefault = '../../../../assets/images/icons/img-default-table.svg'

  readonly transport_id = new FormControl(TRANSPORT_ID.EMS, [Validators.required])
  readonly channel_id = new FormControl<number>(channelId.none)
  form = new FormGroup({
    channel_id: this.channel_id,
    channel_name: new FormControl('', [
      // Validators.maxLength(100),
      (control: AbstractControl) => {
        if (
          this.isCreate &&
          this.channel_id.value &&
          !control.value &&
          this.channel_id.value != channelId.none
        ) {
          return {
            required: true,
          }
        }

        return null
      },
    ]),
    transport_id: this.transport_id,
    transport_other_name: new FormControl('', [
      Validators.maxLength(250),
      (control: AbstractControl) => {
        if (this.transport_id.value == TRANSPORT_ID.OTHER && !control.value) {
          return {
            required: true,
          }
        }

        return null
      },
    ]),
    customer_id: new FormControl(null),
    customer_name: new FormControl('', [Validators.maxLength(100)]),
    customer_last_name: new FormControl('', [Validators.maxLength(100)]),
    telephone: new FormControl('', [Validators.pattern(/^[0-9\-]+$/), Validators.maxLength(100)]),
    is_tax: new FormControl(false),
    is_multi_delivery: new FormControl({ value: false, disabled: true }),
    shipping_cost: new FormControl<number | null>(null, Validators.max(1000000000)),
    remark: new FormControl(''),
    is_merge: new FormControl(false),
    is_select_merge_order: new FormControl(false),
    is_used: new FormControl(false),
    discount: new FormControl(null),
  })

  formTax = new FormGroup({
    tax_name: new FormControl('', [Validators.required, Validators.maxLength(100)]),
    tax_id: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]{13,13}$/)]),
    tax_address: new FormControl('', [Validators.required]),
    tax_telephone: new FormControl('', [
      Validators.required,
      Validators.pattern(/^[0-9\-]+$/),
      Validators.maxLength(100),
    ]),
    is_tax_same_address: new FormControl(false),
  })

  delivery_addresses: any[] = []
  products: any[] = []
  productStockEdit = new Map()
  productStock = new Map()
  customerChannels: any[] = []
  shipping = {
    standard_cost: 0,
    condition_type: 1,
    min_order: 0,
    min_no_order: 0,
    shipping_cost: 0,
  }
  companyProfile: any
  dataManage: any
  order_id: any
  #promotion: any
  promotion_id = null
  promotion_code = ''
  payment: any
  isNewCalc = false
  newDiscountCodePrice = 0
  rerender = false

  list = {
    tier: <any[]>[],
    customer: <any[]>[],
    channel: <any[]>[],
    transport: <any[]>[],
  }

  carat = {
    total_order_amount: 0,
  }

  get isEditField() {
    if (this.isCreate) return true
    if (this.isEdit && this.dataManage) {
      return [ORDER_ID.CREATE, ORDER_ID.VERIFY, ORDER_ID.PAID, ORDER_ID.PRINTED].includes(
        this.dataManage.status_id
      )
    }
    return false
  }

  get customer() {
    const customer_id = this.form.controls.customer_id.value
    if (this.dataManage && this.dataManage.customer?.id == customer_id) {
      return this.dataManage.customer
    }
    return this.list.customer.find((d: any) => d.id == customer_id)
  }

  get customerIds() {
    return [channelId.facebook, channelId.instragram]
  }

  get channelList() {
    return this.list.channel.filter(
      (d: any) =>
        this.customerIds.includes(d.id) ||
        d.id == channelId.none ||
        this.form.controls.channel_id.value == d.id
    )
  }

  get discount() {
    return this.form.controls.discount.value || 0
  }

  // totalProductPrice(onlyIsCarat: boolean): number {
  //   return this.products.reduce((total: number, d: any) => {
  //     if (onlyIsCarat && !d.is_carat) {
  //       return total
  //     }
  //     return (total += d.price.value * d.quantity.value || 0)
  //   }, 0)
  // }

  get totalProduct() {
    return this.products.reduce(
      (total, product: any) => {
        const quantity = product.quantity.value || 0
        total.totalCount += quantity
        const totalPrice = product.price.value * quantity
        total.totalPrice += totalPrice
        if (product.is_carat) {
          total.totalPriceOnlyCarat += totalPrice
        }

        let totalPriceRemain = totalPrice
        let discountCodePrice = 0
        // if (this.isNewCalc) {
        //   if (this.promotion) {
        //     if (this.promotion.is_percent) {
        //       if (
        //         this.promotion.products.some(
        //           (p: any) =>
        //             p.product_id == product.product_id &&
        //             p.product_detail_id == product.product_detail_id
        //         )
        //       ) {
        //         discountCodePrice = (this.promotion.value * totalPriceRemain) / 100
        //       }
        //     } else {
        //       discountCodePrice = this.promotion.value
        //     }
        //   }
        // }
        totalPriceRemain -= discountCodePrice

        total.totalPriceRemain += totalPriceRemain
        total.discountCodePrice += discountCodePrice

        return total
      },
      {
        totalCount: 0,
        totalPrice: 0, //ไม่รวมส่วนลด
        totalPriceOnlyCarat: 0, //ไม่รวมส่วนลดเฉพาะสินค้าที่เข้าร่วมกะรัต
        totalPriceRemain: 0, //รวมส่วนลด
        discountCodePrice: 0, //ส่วนลด
      }
    )
  }

  get shippingCost() {
    return this.form.controls.shipping_cost.value || 0
  }

  get discountCodePrice() {
    if (this.isNewCalc) {
      return this.newDiscountCodePrice
    }
    return this.dataManage?.discount_code_price || 0
  }

  get creditCodePrice() {
    return this.dataManage?.credit_code_price || 0
  }

  get caratCodePrice() {
    return this.dataManage?.carat_code_price || 0
  }

  get sumTotalOther() {
    return (
      this.shippingCost -
      (this.discount + this.discountCodePrice + this.creditCodePrice + this.caratCodePrice)
    )
  }

  get sumTotal() {
    if (this.isNewCalc || this.isCreate) {
      return this.totalProduct.totalPriceRemain + this.sumTotalOther
    }

    return this.dataManage?.total || 0
  }

  get caratAmount() {
    if (this.isNewCalc || this.isCreate) {
      const sumTotalOnlyIsCarat = this.totalProduct.totalPriceOnlyCarat

      return this.carat.total_order_amount && sumTotalOnlyIsCarat
        ? Math.floor(sumTotalOnlyIsCarat / this.carat.total_order_amount)
        : 0
    }

    return this.dataManage?.carat || 0
  }

  get paymentCreditDebit() {
    if (this.payment) {
      return this.payment.details.find((p: any) => p.payment_type == PAYMENT_TYPE.CREDIT_DEBIT)
    }
    return null
  }

  $channelId?: Subscription
  constructor(
    public profile: Profile,
    public route: ActivatedRoute,
    public router: Router,
    public dialog: MatDialog,
    public customSnackBar: CustomSnackBar,
    public loading: Loading,
    public orderService: OrderService,
    public companyProfileService: CompanyProfileService,
    public masterService: MasterService,
    public customerService: CustomerService,
    public transportService: TransportService,
    public caratService: CaratService,
    public tierService: TierService,
    public productService: ProductService,
    public publicService: PublicService,
    public promotionService: PromotionService,
    public shippingService: ShippingService
  ) {
    this.$channelId = this.form.controls.channel_id.valueChanges.subscribe(id => {
      const channel = this.customerChannels.find(c => c.channel_id == id)
      this.form.controls.channel_name.setValue(channel?.name || '')
    })
  }

  ngOnDestroy(): void {
    this.$channelId?.unsubscribe()
  }

  ngOnInit(): void {
    if (this.router.url.includes('view')) {
      this.isView = true
    }
    if (this.router.url.includes('edit')) {
      this.isEdit = true
    }
    if (this.router.url.includes('create')) {
      this.isCreate = true
    }
    this.route.params.subscribe(params => {
      this.initData(params['id'])
      this.order_id = params['id']
    })
  }

  initData(id: any) {
    const apis = [
      this.companyProfileService.getCompanyProfile(),
      this.masterService.getChannel(),
      this.transportService.getTransportList({}),
      this.caratService.getCarat(),
      this.tierService.getTierList({}),
      this.shippingService.getShipping(1),
    ]

    if (this.isEdit || this.isView) {
      apis.push(this.orderService.getOrder(id))
    }

    this.form.controls.is_tax.disable()
    this.formTax.disable()

    this.loading.start()
    forkJoin(apis).subscribe(
      ([
        resCompanyProfile,
        resChannel,
        resTransport,
        resCarat,
        resTier,
        resShipping,
        resOrder,
      ]: any) => {
        if (resCompanyProfile) {
          if (!resCompanyProfile.is_error) {
            this.companyProfile = resCompanyProfile.data
          } else {
            this.customSnackBar.fail(resCompanyProfile.message)
          }
        }

        if (resChannel) {
          if (!resChannel.is_error) {
            this.list.channel = resChannel.data
          } else {
            this.customSnackBar.fail(resChannel.message)
          }
        }

        if (resTransport) {
          if (!resTransport.is_error) {
            this.list.transport = resTransport.data
          } else {
            this.customSnackBar.fail(resTransport.message)
          }
        }

        if (resCarat) {
          if (!resCarat.is_error) {
            if (checkUseCarat(resCarat.data)) {
              this.carat = resCarat.data
            }
          } else {
            this.customSnackBar.fail(resCarat.message)
          }
        }

        if (resTier) {
          if (!resTier.is_error) {
            this.list.tier = resTier.data
          } else {
            this.customSnackBar.fail(resTier.message)
          }
        }

        if (resShipping) {
          if (!resShipping.is_error) {
            this.shipping.condition_type = resShipping.data.condition_type || SHIPPING_TYPE.STANDARD
            this.shipping.min_no_order = resShipping.data.min_no_order || 0
            this.shipping.min_order = resShipping.data.min_order || 0
            this.shipping.shipping_cost = resShipping.data.shipping_cost || 0
            this.shipping.standard_cost = resShipping.data.standard_cost || 0

            this.form.controls.shipping_cost.setValue(this.shippingCost)
          } else {
            this.customSnackBar.fail(resCarat.message)
          }
        }

        if (resOrder) {
          if (!resOrder.is_error) {
            this.dataManage = resOrder.data
            this.form.reset({
              channel_id: this.dataManage.channel_id || channelId.none,
              channel_name: this.dataManage.channel_name,
              transport_id: this.dataManage.transport_id,
              transport_other_name: this.dataManage.transport_other_name,
              customer_id: this.dataManage.customer_id,
              customer_name: this.dataManage.customer_name,
              customer_last_name: this.dataManage.customer_last_name,
              telephone: this.dataManage.telephone,
              is_tax: this.dataManage.is_tax,
              is_multi_delivery: this.dataManage.is_multi_delivery,
              shipping_cost: this.dataManage.shipping_cost,
              remark: this.dataManage.remark,
              is_merge: this.dataManage.is_merge,
              is_select_merge_order: this.dataManage.is_select_merge_order,
              is_used: this.dataManage.is_used,
              discount: this.dataManage.discount,
            })
            setTimeout(() => {
              this.form.controls.channel_name.setValue(this.dataManage.channel_name)
            }, 100)

            this.formTax.reset({
              tax_id: this.dataManage.tax_id,
              tax_name: this.dataManage.tax_name,
              is_tax_same_address: this.dataManage.is_tax_same_address,
              tax_address: this.dataManage.tax_address,
              tax_telephone: this.dataManage.tax_telephone,
            })

            this.delivery_addresses = this.dataManage.delivery_addresses.sort((a: any, b: any) =>
              a.is_main < b.is_main ? 1 : -1
            )

            const products = this.dataManage.products
              .filter((p: any) => p.quantity > 0)
              .map((p: any) => this.setProduct(p))

            this.productStockEdit.clear()
            products.forEach((p: any) => {
              const productStockSelf = this.productStockEdit.get(p.key) || {
                total_quantity: 0, //only edit
              }
              productStockSelf.total_quantity += p.quantity.value
              this.productStockEdit.set(p.key, productStockSelf)
            })

            this.updateProductStock(products)

            if (!this.isEditField) {
              this.form.disable()
            } else {
              this.setCheckBoxMultiAddress()
            }

            if ([ORDER_ID.SHIPMENT].includes(this.dataManage.status_id)) {
              this.form.controls.is_tax.enable()
              this.formTax.enable()
            }

            this.publicService
              .getPaymentByOrderId(resOrder.data.id)
              .subscribe((resPayment: any) => {
                if (!resPayment.is_error) {
                  this.payment = resPayment.data
                  if (this.payment.promotion_id) {
                    this.promotionService
                      .getPromotion(this.payment.promotion_id)
                      .subscribe(resPomotion => {
                        if (!resPomotion.is_error) {
                          this.#promotion = resPomotion.data
                          this.promotion_id = this.payment.promotion_id
                          this.promotion_code = this.payment.promotion_code
                        }
                      })
                  }
                }
              })
          } else {
            this.customSnackBar.fail(resOrder.message)
          }
        } else {
          this.updateShippingCost(this.shipping.standard_cost)
        }

        this.loading.stop()
      }
    )
  }

  setProductStock(products: any[]) {
    const productStockTemp = new Map()

    products.forEach((p: any) => {
      // console.log('p', p)

      const productStockSelf = productStockTemp.get(p.key) || {
        total_quantity: 0,
        total_stock: 0,
      }
      const quantity = p.quantity.value
      // console.log('quantity', quantity)

      productStockSelf.total_quantity += quantity
      productStockSelf.total_stock += quantity
      productStockTemp.set(p.key, productStockSelf)
    })

    return productStockTemp
    // this.productStock = productStockTemp
    // console.log("productStock", productStock);
  }

  setValueProductStock(products: any[]) {
    this.setProductStock(products).forEach((value, key) => {
      const productStockSelf = this.productStock.get(key)
      if (productStockSelf) {
        productStockSelf.total_quantity = value.total_quantity
      }
    })
    // this.updateShippingCost()
  }

  updateShippingCost(shipping_cost = this.form.controls.shipping_cost.value) {
    if (this.isCreate || this.isNewCalc) {
      //create order
      if (this.shipping.condition_type != SHIPPING_TYPE.STANDARD) {
        const totalProductPrice = this.totalProduct.totalPrice
        const totalProductCount = this.totalProduct.totalCount

        const isMinTotal = totalProductPrice >= this.shipping.min_order
        const isMinCount = totalProductCount >= this.shipping.min_no_order

        if (this.shipping.condition_type == SHIPPING_TYPE.MIN_TOTAL && isMinTotal) {
          shipping_cost = this.shipping.shipping_cost
        } else if (this.shipping.condition_type == SHIPPING_TYPE.COUNT_PRODUCT && isMinCount) {
          shipping_cost = this.shipping.shipping_cost
        } else if (
          this.shipping.condition_type == SHIPPING_TYPE.MIN_TOTAL_AND_COUNT_PRODUCT &&
          isMinTotal &&
          isMinCount
        ) {
          shipping_cost = this.shipping.shipping_cost
        } else {
          shipping_cost = this.shipping.standard_cost
        }
      }
    }

    // console.log(shipping_cost)

    if (shipping_cost != this.form.controls.shipping_cost.value) {
      this.form.controls.shipping_cost.setValue(shipping_cost)
      this.rerender = true
      setTimeout(() => {
        this.rerender = false
      })
    }
  }

  updateProductStock(productSelects: any[]) {
    // console.log('productSelects', productSelects)
    this.productStock.clear()
    this.productStock = this.setProductStock(productSelects)
    this.products = productSelects

    if (!productSelects.length) {
      this.updateShippingCost()
      return
    }
    // console.log('this.productStock', this.productStock)

    const payload = {
      page_size: 999,
      filter: {
        is_actives: [true],
        is_stock: true,
        ids: productSelects.map((d: any) => d.product_id),
      },
    }

    // console.log(payload)
    // return

    this.loading.start()
    this.productService.getProductForOrderList(payload).subscribe((res: any) => {
      if (!res.is_error) {
        res.data.records.forEach((product: any) => {
          const key = productKey(product.id, product.product_detail_id)
          const productStockSelf = this.productStock.get(key)
          if (productStockSelf) {
            const total_quantity_edit = this.productStockEdit.get(key)?.total_quantity || 0
            productStockSelf.total_stock = product.remain + total_quantity_edit
            this.productStock.set(key, productStockSelf)
          }
        })
      }
      this.updateShippingCost()
      this.loading.stop()
    })
  }

  indexProduct = 0
  setProduct(data: any) {
    this.indexProduct++
    return {
      ...data,
      key: productKey(data.product_id, data.product_detail_id),
      remain: data.quantity,
      index: this.indexProduct,
      delivery_address_uuid: new FormControl(
        {
          value: data.delivery_address_uuid || null,
          disabled: !this.isEditField,
        },
        [Validators.required]
      ),
      quantity: new FormControl(
        {
          value: data.quantity || null,
          disabled: !this.isEditField,
        },
        [Validators.required]
      ),
      price: new FormControl(
        {
          value: data.price || null,
          disabled: !this.isEditField,
        },
        [Validators.required]
      ),
    }
  }

  stopRender = false
  removeProduct(item: any, index: number) {
    this.stopRender = true
    this.products.splice(this.products.indexOf(item), 1)
    if (this.products.length < 2) {
      this.setCheckBoxMultiAddress()
    }

    if (this.products.length < 2) {
      this.setCheckBoxMultiAddress()
    }

    const productStockSelf = this.productStock.get(item.key)
    if (productStockSelf) {
      productStockSelf.total_quantity -= item.quantity.value
    }

    this.setValueProductStock(this.products)
    setTimeout(() => {
      this.stopRender = false
    })
    this.resetPayment()
    this.updateShippingCost()
  }

  sumPrice(item: any) {
    return item.price.value * item.quantity.value
  }

  maxQuantity(item: any) {
    return this.products.reduce((totalQuntity: number, data) => {
      if (item.key == data.key && data.index != item.index) {
        return totalQuntity - data.quantity.value
      }
      return totalQuntity
    }, this.productStock.get(item.key).total_stock)
  }

  setQuantity(num: 1 | -1, item: any) {
    if (!this.isEditField) return

    const quantity = item.quantity.value
    const max = this.maxQuantity(item)
    if (num == 1 ? quantity < max : quantity > 1) {
      item.quantity.setValue(quantity + num)
    }

    this.setValueProductStock(this.products)
    this.resetPayment()
    this.updateShippingCost()
  }

  // checkTaxSameAddress() {
  //   if (
  //     !this.formTax.controls.is_tax_same_address.value &&
  //     (this.isCreate || [ORDER_ID.CREATE].includes(this.dataManage?.status_id))
  //   ) {
  //     this.formTax.controls.tax_address.enable()
  //   } else {
  //     this.formTax.controls.tax_address.disable()
  //   }
  // }

  setCheckBoxMultiAddress() {
    if (this.products.length > 1) {
      this.form.controls.is_multi_delivery.enable()
    } else {
      this.form.controls.is_multi_delivery.reset()
      this.form.controls.is_multi_delivery.disable()
      this.resetProductAddress()
    }
  }

  resetProductAddress() {
    for (const item of this.products) {
      item.delivery_address_uuid.reset()
    }
  }

  customerSelection(data: any) {
    if (data) {
      this.form.controls.customer_id.setValue(data.id)
      this.form.controls.customer_last_name.setValue(data.last_name)
      this.form.controls.telephone.setValue(data.telephone)
      const address = {
        name: data.name || '',
        last_name: data.last_name || '',
        telephone: data.telephone || '',
        address: data.delivery_address || '',
        uuid: crypto.randomUUID(),
        fromCustomer: true,
      }
      this.addAddress(
        address,
        this.delivery_addresses.filter((d: any) => !d.fromCustomer)
      )
      this.customerChannels = data.channels || []
    } else {
      this.form.controls.customer_id.reset()
      this.form.controls.telephone.reset()
      this.form.controls.customer_last_name.reset()
      this.delivery_addresses = []
      this.customerChannels = []
    }
    this.form.controls['channel_name'].reset()
    this.form.controls.channel_id.setValue(channelId.none)

    this.products.forEach((p: any) => {
      p.delivery_address_uuid.reset()
    })

    this.form.controls.is_tax.setValue(false)
    this.formTax.reset({})
  }

  addAddress(newAddress: any, addresss: any[]) {
    if (!addresss.length) {
      newAddress.is_main = true
    }
    if (newAddress.is_main) {
      this.delivery_addresses = [
        newAddress,
        ...addresss.map((d: any) => {
          d.is_main = false
          return d
        }),
      ]
    } else {
      this.delivery_addresses = [...addresss, newAddress]
    }
    this.form.markAsDirty()
  }

  onCheckTax() {
    let customer = this.customer

    if (customer && customer.is_tax) {
      this.formTax.reset({
        tax_id: customer.tax_id,
        tax_name: customer.tax_name,
        tax_address: customer.tax_address,
        tax_telephone: customer.tax_telephone,
        is_tax_same_address: customer.is_tax_same_address,
      })
    } else {
      this.formTax.reset({})
    }
  }

  setTaxAddress() {
    let value = ''
    if (this.formTax.controls.is_tax_same_address.value) {
      const customer = this.list.customer.find(
        (d: any) => d.id == this.form.controls.customer_id.value
      )
      if (customer) {
        value = customer.address
      }
    }

    this.formTax.controls.tax_address.setValue(value)
  }

  selectChannel() {
    if (this.channel_id.value == channelId.none) {
      this.form.controls['channel_name'].reset()
    } else {
      const channelCustomer = (this.customer?.channels || []).find(
        (c: any) => c.channel_id == this.channel_id.value
      )
      if (channelCustomer) {
        this.form.controls['channel_name'].setValue(channelCustomer.name)
      }
    }
  }

  productItem(item: any) {
    return item.product_detail_id ? item.product_detail : item.product
  }

  openModalAddress(title: string, type: 'add' | 'edit', item: any): void {
    if (!this.delivery_addresses.length) {
      item.is_main = true
    }
    const dialogRef = this.dialog.open(ModalAddressComponent, {
      disableClose: true,
      data: {
        title,
        type,
        item,
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result == 'delete') {
        this.delivery_addresses.splice(this.delivery_addresses.indexOf(this.delivery_addresses), 1)
      } else if (result) {
        let address = this.delivery_addresses.find((d: any) => d.uuid == result.uuid)
        if (address) {
          for (const key in result) {
            address[key] = result[key]
          }
        } else {
          address = result
        }

        this.addAddress(
          address,
          this.delivery_addresses.filter((d: any) => d.uuid != address.uuid)
        )

        this.products.forEach((p: any) => {
          if (!this.delivery_addresses.some((d: any) => d.uuid == p.delivery_address_uuid.value)) {
            p.delivery_address_uuid.reset()
          }
        })
      }
    })
  }

  openModalProduct(title: string, type: 'add' | 'edit'): void {
    const dialogRef = this.dialog.open(ModalProductComponent, {
      disableClose: true,
      maxWidth: '90vw',
      maxHeight: '90vh',
      data: {
        title,
        type,
        products: this.products,
        productStock: this.productStock,
        productStockEdit: this.productStockEdit,
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // console.log('result', result)
        const products = [...this.products]
        const newProducts = result.map((d: any) => this.setProduct(d))
        if (type == 'add') {
          for (const np of newProducts) {
            const oldProduct = this.products.find((op: any) => {
              return (
                op.product_id == np.product_id &&
                (op.product_detail_id || null) == (np.product_detail_id || null) &&
                op.price.value == np.price.value
              )
            })
            if (oldProduct) {
              oldProduct.quantity.setValue(oldProduct.quantity.value + np.quantity.value)
            } else {
              products.push(np)
            }
          }

          this.updateProductStock(products)
        } else {
          this.updateProductStock(newProducts)
        }
        this.setCheckBoxMultiAddress()
        this.errorProductEmpty = false
        this.resetPayment()
        this.updateShippingCost()
      }
    })
  }

  resetPayment() {
    this.isNewCalc = true
    this.useDiscountCode()
  }

  payloadProducts() {
    return this.products.map((d: any) => {
      const quantity = d.quantity.value
      const price = d.price.value
      return {
        id: d.id || null,
        product_id: d.product_id || null,
        product_detail_id: d.product_detail_id || null,
        delivery_address_id: d.delivery_address_id || null,
        quantity,
        price,
        total_price: quantity * price,
        tracking_no: d.tracking_no || '',
        cost: d.cost,
        delivery_date: d.delivery_date,
        is_return_product: d.is_return_product || false,
        return_quantity: d.return_quantity || 0,
        actual_quantity: d.actual_quantity || 0,
        is_return_credit: d.is_return_credit || false,
        bank_id: d.bank_id || null,
        return_bank_account_name: d.return_bank_account_name || '',
        return_bank_account_no: d.return_bank_account_no || '',
        return_total_price: d.return_total_price || 0,
        return_shipping_cost: d.return_shipping_cost || 0,
        return_discount_code_price: d.return_discount_code_price || 0,
        return_credit_code_price: d.return_credit_code_price || 0,
        return_carat_code_price: d.return_carat_code_price || 0,
        delivery_address_uuid: d.delivery_address_uuid.value || '',
      }
    })
  }

  ci = 0
  async useDiscountCode(isLoading = false) {
    this.ci++
    const ci = this.ci
    return new Promise<number>(rev => {
      const promotion = this.#promotion
      if (promotion) {
        const products = this.payloadProducts()
        if (products.length) {
          if (isLoading) {
            this.loading.start()
          }

          const value = this.form.getRawValue()
          const payload = {
            total_price: this.totalProduct.totalPrice,
            customer_id: value.customer_id,
            products: this.payloadProducts(),
            order_id: this.payment?.order_id, //ส่งเฉพาะ recheck pomotion
          }

          this.publicService.checkPromotion(promotion.id, payload).subscribe((res: any) => {
            if (this.ci == ci && !res.is_error) {
              this.promotion_id = promotion.id
              this.promotion_code = promotion.code
              this.newDiscountCodePrice = res.data || 0
              rev(this.newDiscountCodePrice)
            } else {
              this.promotion_id = null
              this.promotion_code = ''
              this.newDiscountCodePrice = 0
              rev(0)
            }
            if (isLoading) {
              this.loading.stop()
            }
          })
        } else {
          this.promotion_id = null
          this.promotion_code = ''
          this.newDiscountCodePrice = 0
          rev(0)
        }
      } else {
        rev(0)
      }
    })
  }

  async getLastUpdateOrder() {
    return new Promise<any>(rev => {
      let dataRes = {}
      if (this.isEdit) {
        this.orderService.getOrder(this.dataManage.id).subscribe(res => {
          if (!res.is_error) {
            dataRes = res.data || {}
          }
          rev(dataRes)
        })
      } else {
        rev(dataRes)
      }
    })
  }

  async onSave() {
    ValidateForm(this.form)

    let is_vaild_tax = true
    if (this.form.controls['is_tax'].value && !this.formTax.disabled) {
      ValidateForm(this.formTax)

      is_vaild_tax = this.formTax.valid || (this.formTax.valid && this.formTax.disabled)
    }

    let is_valid_product = true
    if (this.form.controls.is_multi_delivery.value) {
      if (this.form.controls.is_multi_delivery) {
        for (const product of this.products) {
          validateField(product.delivery_address_uuid)
          if (!product.delivery_address_uuid.valid && !product.delivery_address_uuid.disabled) {
            is_valid_product = false
          }
        }
      }
    }

    if (this.form.invalid || !is_vaild_tax || !is_valid_product) return

    // if (!this.delivery_addresses.length) {
    //   return this.customSnackBar.fail('กรุณาเพิ่มที่อยู่จักส่ง')
    // }

    if (!this.products.length) {
      this.errorProductEmpty = true
      return
    }

    if (this.isNewCalc) {
      await this.useDiscountCode(true)
    }

    const lastUpdateData = (await this.getLastUpdateOrder()) || {}
    delete lastUpdateData.customer

    // console.log(lastUpdateData.updated_at, this.dataManage?.updated_at)
    // return

    if (this.isEdit && lastUpdateData.updated_at != this.dataManage?.updated_at) {
      const dialogRef = this.dialog.open(ModalErrorComponent, {
        data: {
          title: 'ไม่สามารถบันทึกได้',
          detail: 'มีการอัพเดทข้อมูลจากที่อื่น กรุณาลองใหม่อีกครั้ง',
        },
      })

      dialogRef.afterClosed().subscribe(result => {
        location.reload()
      })
      return
    }

    const value = this.form.getRawValue()
    const valueTax = this.formTax.getRawValue()
    const payload = {
      status_id: lastUpdateData.status_id || ORDER_ID.CREATE,
      ...lastUpdateData,
      sender_name: this.companyProfile.sender_name,
      sender_address: this.companyProfile.sender_address,
      sender_telephone: this.companyProfile.sender_telephone,
      channel_id: value.channel_id || channelId.none,
      channel_name: value.channel_name,
      transport_id: value.transport_id,
      transport_other_name: value.transport_other_name,
      customer_id: value.customer_id,
      customer_name: value.customer_name,
      customer_last_name: value.customer_last_name,
      telephone: value.telephone,
      is_tax: value.is_tax || false,
      tax_name: valueTax.tax_name,
      tax_id: valueTax.tax_id,
      tax_address: valueTax.tax_address,
      tax_telephone: valueTax.tax_telephone,
      is_tax_same_address: valueTax.is_tax_same_address,
      is_multi_delivery: value.is_multi_delivery,
      shipping_cost: value.shipping_cost || 0,
      remark: value.remark,
      total_price: this.sumTotal,
      is_merge: value.is_merge,
      is_select_merge_order: value.is_select_merge_order,
      is_used: value.is_used,
      discount: value.discount || 0,
      discount_code_price: this.discountCodePrice,
      carat_code_price: this.caratCodePrice,
      credit_code_price: this.creditCodePrice,
      carat: this.caratAmount,
      // discount_code_price,
      delivery_addresses: this.delivery_addresses.map((d: any) => {
        let oldData = (lastUpdateData.delivery_addresses || []).find((a: any) => a.uuid == d.uuid)
        return {
          ...(oldData || {}),
          id: d.id || null,
          name: d.name,
          telephone: d.telephone,
          address: d.address,
          is_main: d.is_main || false,
          uuid: d.uuid || null,
        }
      }),
      products: this.payloadProducts(),
      is_change: true,
    }

    if (!payload.customer_name) {
      if (lastUpdateData.customer_id) {
        payload.customer_id = lastUpdateData.customer_id
      }
      if (lastUpdateData.customer_name) {
        payload.customer_name = lastUpdateData.customer_name
      }
    }
    if (!payload.customer_last_name && lastUpdateData.customer_last_name) {
      payload.customer_last_name = lastUpdateData.customer_last_name
    }
    if (!payload.telephone && lastUpdateData.telephone) {
      payload.telephone = lastUpdateData.telephone
    }
    if (!payload.delivery_addresses.length && Array.isArray(lastUpdateData.delivery_addresses)) {
      payload.delivery_addresses = lastUpdateData.delivery_addresses
    }

    // return console.log('payload', payload)

    const apiOrder = this.isEdit
      ? this.orderService.updateOrder(this.dataManage.id, payload)
      : this.orderService.addOrder(payload)

    const apis = [
      apiOrder,
      of({
        is_error: false,
      }),
    ]

    const isSavePayment = this.payment && this.isNewCalc
    if (isSavePayment) {
      const payloadPayment = {
        ...this.payment,
        promotion_id: this.promotion_id || null,
        promotion_code: this.promotion_code || null,
        discount_code_price: this.discountCodePrice,
        carat_code_price: this.caratCodePrice,
        credit_code_price: this.creditCodePrice,
        grand_total_price: payload.total_price,
        received_carat: this.caratAmount, // ยอดกะรัตที่ได้จากการเปิดออเดอร์
        is_change: true,
      }

      apis[1] = this.publicService.savePayment(payloadPayment)
    }

    this.loading.start()
    forkJoin(apis).subscribe(([resOrder, resPayment]: any) => {
      if (!resOrder.is_error && !resPayment.is_error) {
        this.customSnackBar.success(this.isEdit ? 'แก้ไขออเดอร์สำเร็จ' : 'สร้างออเดอร์สำเร็จ')
        this.isSaved = true
        this.toList()
      } else {
        if (resOrder.is_error) {
          if (resOrder.errors[0]?.field) {
            setErrorForm(this.form, resOrder.errors)
          } else {
            this.customSnackBar.failSave(
              this.isEdit ? 'แก้ไขออเดอร์ไม่สำเร็จ' : 'สร้างออเดอร์ไม่สำเร็จ'
            )
          }
        } else if (resPayment.is_error) {
          this.customSnackBar.failSave(
            this.isEdit ? 'แก้ไขออเดอร์ไม่สำเร็จ' : 'สร้างออเดอร์ไม่สำเร็จ'
          )
        }
      }

      this.loading.stop()
    })
  }

  toList() {
    this.router.navigate(['/order'], {
      queryParams: {
        tab: 0,
      },
    })
  }

  isSaved = false
  openModalCancel(resolveFromRouter: (value: boolean) => void): void {
    if (this.isEdit && (this.form.dirty || this.formTax.dirty) && !this.isSaved) {
      const dialogCancel = this.dialog.open(ModalLeaveComponent, {
        data: {},
      })

      dialogCancel.afterClosed().subscribe(result => {
        resolveFromRouter(!!result)
      })
    } else {
      resolveFromRouter(true)
    }
  }
}
