<div mat-dialog-title class="dialog-title">
  <div class="dialog-title-head">ดูตัวอย่าง</div>
  <button class="btn btn-transparent btn-icon" (click)="onClose()">
    <img src="../assets/images/icons/icon-close.svg" />
  </button>
</div>

<mat-dialog-content>
  <div class="modal-content py-2">
    <div class="page-top-content">
      <div class="box-content">
        <app-top-menu theme="black"></app-top-menu>
      </div>
    </div>

    @for (item of collections; track $index) {
    <div class="collection-container">
      <div class="p-title-wrapper">
        <h1 class="content-name pb-1 mb-0">{{item?.content_name_th}}</h1>
        <div class="content-detail">{{item?.content_detail_th}}</div>
      </div>
      @if (item.images?.length) {

      <app-scroll-silde [layout]="1" [hideIconDisabled]="true">
        <div class="list-collection">
          @for (image of item.images; track $index) {
          <!-- ~ -->
          @if (image.file) {
          <div
            drag-scroll-item
            class="item image-{{ image.is_vertical ? 'vertical' : 'horizontal' }}">
            <!-- ~ -->
            @if (image.file?.mime_type?.includes('image')) {
            <img [class.is-vertical]="image.is_vertical" [src]="image.file.url" [alt]="$index" />
            }@else {
            <video
              [src]="image.file.url"
              [class.is-vertical]="image.is_vertical"
              autoplay
              muted
              loop
              [controls]="false"
              playsinline
              webkit-playsinline></video>
            }
          </div>
          } }
        </div>
      </app-scroll-silde>
      }
    </div>
    }

    <app-footer></app-footer>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="footer-button-wrapper d-flex justify-content-end w-100">
    <button class="btn btn-primary btn-md" matRipple (click)="onClose()">กลับ</button>
  </div>
</mat-dialog-actions>
