<div mat-dialog-title class="dialog-title">
  <div class="dialog-title-head">{{data.title}}</div>
  <button class="btn btn-transparent btn-icon" (click)="onClose()">
    <img src="../assets/images/icons/icon-close.svg" />
  </button>
</div>

<mat-dialog-content>
  <div class="modal-content">
    <div class="filter-item-wrapper">
      <div class="filter-title">ช่องทางการชำระเงิน</div>

      <div class="filter-checkbox-wrapper">
        @for (item of filterTableHistoryPayment.list.payment_types; track $index) {
        <mat-checkbox
          class="filter-checkbox"
          [checked]="filterTableHistoryPayment.isChecked(item.id, 'payment_types')"
          (change)="filterTableHistoryPayment.change(item.id, 'payment_types')">
          {{ item.text }}
        </mat-checkbox>
        }
      </div>
    </div>

    <div class="filter-item-wrapper">
      <div class="filter-title">ธนาคารรับโอน</div>

      <div class="filter-checkbox-wrapper">
        @for (item of filterTableHistoryPayment.list.bank_ids; track $index) {
        <mat-checkbox
          class="filter-checkbox"
          [checked]="filterTableHistoryPayment.isChecked(item.id, 'bank_ids')"
          (change)="filterTableHistoryPayment.change(item.id, 'bank_ids')">
          @if (item.code != 'NONE') {
            <img
            [alt]="item.name"
            src="/assets/images/logos/banks/{{ item.code }}.png"
            width="20"
            class="me-1" />
          }
          {{ item.text }}
        </mat-checkbox>
        }
      </div>
    </div>

    <div class="filter-item-wrapper">
      <div class="filter-title">สถานะรายการ</div>

      <div class="filter-checkbox-wrapper">
        @for (item of filterTableHistoryPayment.list.is_successes; track $index) {
        <mat-checkbox
          class="filter-checkbox"
          [checked]="filterTableHistoryPayment.isSuccessesChecked(item.id)"
          (change)="filterTableHistoryPayment.successesChange(item.id)">
          {{ item.text }}
        </mat-checkbox>
        }
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="footer-button-wrapper d-flex justify-content-end w-100">
    <button matRipple class="btn btn-outline-secondary btn-md" (click)="onReset()">ล้างค่า</button>
    <button matRipple class="btn btn-primary btn-md" (click)="onConfirm()">นำไปใช้</button>
  </div>
</mat-dialog-actions>
