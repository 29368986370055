<div>
  <mat-form-field appearance="outline" class="input" hintLabel="{{hintLabel}}">
    <mat-chip-grid #chipGrid [attr.aria-label]="placeholder" [formControl]="formControl">
      @for (item of formControl.value; track item) {
      <mat-chip-row
        (removed)="remove(item)"
        [editable]="true"
        (edited)="edit(item, $event)"
        [aria-description]="'กด Enter เพื่อแก้ไข ' + item">
        {{item}}
        <button matChipRemove [attr.aria-label]="'ลบ ' + item">
          <img
            src="/assets/images/icons/icon-remove-chips{{ profile.companyId == 1 ? '' : '-pink' }}.svg" />
        </button>
      </mat-chip-row>
      }
      <input
        matInput
        type="text"
        placeholder="{{placeholder || ''}}"
        (input)="onInput.emit($event)"
        (change)="onChange.emit($event)"
        (keydown.enter)="onEnter.emit($event)"
        [matChipInputFor]="chipGrid"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="addOnBlur"
        (matChipInputTokenEnd)="add($event)" />
    </mat-chip-grid>
    @if (control.invalid) {
    <mat-error>
      <ng-content select="[error]"></ng-content>
    </mat-error>
    }
  </mat-form-field>
</div>
