<div class="page-content pb-0">
  <div class="breadcrumb-wrapper">
    <div class="breadcrumb-text breadcrumb-link">ตั้งค่า</div>
    <div class="breadcrumb-separator">/</div>
    <div class="breadcrumb-text">จัดการเนื้อหา (CMS)</div>
    <div class="breadcrumb-separator">/</div>
    <div class="breadcrumb-text">คำถามที่พบบ่อย</div>
    <div class="breadcrumb-separator">/</div>
    <div class="breadcrumb-text">{{ isCreate ? 'เพิ่ม' : 'แก้ไข' }}หมวดหมู่คำถาม</div>
  </div>

  <div class="title-wrapper">{{ isCreate ? 'เพิ่ม' : 'แก้ไข' }}หมวดหมู่คำถาม</div>

  <div class="page-content-inner d-flex flex-column pb-0">
    @if (isEdit) {
    <div class="form-label color--neutral-500">
      วันที่อัปเดต: {{ Moment(dataManage?.updated_at).format('DD-MM-YYYY, HH:mm') }} อัปเดตโดย: {{
      dataManage?.updated_by?.full_name || '-' }}
    </div>
    <div class="mb-3">
      <app-status [status]="dataManage?.is_public ? 'public' : 'not_public'">
        {{dataManage?.is_public ? 'เผยแพร่แล้ว' : 'ยังไม่เผยแพร่'}}
      </app-status>
    </div>
    }

    <div class="row">
      <div class="col-12 col-sm-6">
        <label class="form-label">
          ชื่อหมวดหมู่คำถาม (ภาษาไทย) <span class="text-danger">*</span>
        </label>

        <app-input [control]="form.controls['name_th']">
          @if (form.controls['name_th'].hasError('required')) {
          <ng-container error>กรุณากรอกข้อมูล</ng-container>
          } @if (form.controls['name_th'].hasError('maxlength')) {
          <ng-container error>กรุณากรอกข้อมูลไม่เกิน 255 ตัวอักษร</ng-container>
          }
        </app-input>
      </div>
      <div class="col-12 col-sm-6">
        <label class="form-label">
          ชื่อหมวดหมู่คำถาม (ภาษาอังกฤษ) <span class="text-danger">*</span>
        </label>

        <app-input [control]="form.controls['name_en']">
          @if (form.controls['name_en'].hasError('required')) {
          <ng-container error>กรุณากรอกข้อมูล</ng-container>
          } @if (form.controls['name_en'].hasError('maxlength')) {
          <ng-container error>กรุณากรอกข้อมูลไม่เกิน 255 ตัวอักษร</ng-container>
          }
        </app-input>
      </div>
    </div>

    @for (item of details.controls; track $index) {
    <div class="box-question mt-2">
      <div class="row">
        <div class="col-12 col-lg-1 align-content-center">
          <div class="d-flex justify-content-between align-items-center">
            <span class="font-14">คำถามที่ {{ $index+1 }}</span>

            <div class="d-flex gap-2">
              <button
                *ngIf="$index != 0"
                matRipple
                class="btn btn-outline-danger btn-icon button-del show-mobile"
                (click)="delQuestion($index)">
                <span class="icon material-symbols-outlined fill">delete</span>
              </button>
              <div class="arrow-wrapper show-mobile" (click)="toggleSubMenu($index)">
                <span class="icon material-symbols-outlined fill">
                  {{ submenu[$index] ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-5">
          <label class="form-label"> (ภาษาไทย) <span class="text-danger">*</span> </label>
          <app-input [control]="item.controls['question_th']">
            @if (item.controls['question_th'].hasError('required')) {
            <ng-container error>กรุณากรอกข้อมูล</ng-container>
            }
          </app-input>
        </div>
        <div class="col-12 col-lg-5">
          <label class="form-label"> (ภาษาอังกฤษ) <span class="text-danger">*</span> </label>
          <app-input [control]="item.controls['question_en']">
            @if (item.controls['question_en'].hasError('required')) {
            <ng-container error>กรุณากรอกข้อมูล</ng-container>
            }
          </app-input>
        </div>
        <div class="col-sm-1 show-pc align-content-center justify-content-center">
          <div class="arrow-wrapper" (click)="toggleSubMenu($index)">
            <span class="icon material-symbols-outlined fill">
              {{ submenu[$index] ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
            </span>
          </div>
        </div>
      </div>
      <div class="row mb-4" *ngIf="submenu[$index]">
        <div class="col-12 col-lg-1"></div>
        <div class="col-12 col-lg-5">
          <label class="form-label mt-2">
            คำตอบ (ภาษาไทย) <span class="text-danger">*</span>
          </label>
          <app-text-editor
            [control]="item.controls['answer_th']"
            [errorText]="'กรุณากรอกข้อมูล'"
            [toolbarControl]="[
              ['italic'],
              ['bullet_list', 'ordered_list'],
              ['link']
            ]">
          </app-text-editor>
        </div>
        <div class="col-12 col-lg-5">
          <label class="form-label mt-2">
            คำตอบ (ภาษาอังกฤษ) <span class="text-danger">*</span>
          </label>
          <app-text-editor
            [control]="item.controls['answer_en']"
            [errorText]="'กรุณากรอกข้อมูล'"
            [toolbarControl]="[
              ['italic'],
              ['bullet_list', 'ordered_list'],
              ['link']
            ]">
          </app-text-editor>
        </div>
        <div class="col-sm-1 show-pc justify-content-center">
          <label class="form-label"></label>
          <button
            *ngIf="$index != 0"
            matRipple
            class="btn btn-outline-danger btn-icon button-del"
            (click)="delQuestion($index)">
            <span class="icon material-symbols-outlined fill">delete</span>
          </button>
        </div>
      </div>
    </div>

    }
    <button class="btn btn-outline-secondary w-fit my-2" (click)="addQuestion()">
      <span class="icon material-symbols-outlined fill"> add_circle </span>
      เพิ่มคำถาม
    </button>

    <div class="page-footer-btn">
      <div class="footer-btn d-flex flex-wrap justify-content-between">
        <div class="footer-btn-left d-flex flex-wrap">
          <button matRipple class="btn btn-outline-secondary min-w-165px" (click)="onCancel()">
            ยกเลิก
          </button>
        </div>
        @if (profile.permissions.checkAccess([APP_PERMISSION.CMS], [ACTION_ACCESS.UPDATE]) ||
        profile.permissions.checkAccess([APP_PERMISSION.CMS], [ACTION_ACCESS.CREATE])) {
        <div class="footer-btn-right d-flex flex-wrap">
          <button
            matRipple
            class="btn btn-outline-secondary min-w-165px"
            (click)="onSave('private')">
            บันทึกร่าง
          </button>
          <button matRipple class="btn btn-primary min-w-165px" (click)="onSave('public')">
            เผยแพร่ทันที
          </button>
        </div>
        }
      </div>
    </div>
  </div>
</div>
